import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKabulEdilenTeklifList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKabulEdilenTeklifList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  TeklifKabulEdilenList: any[];
  TeklifKabulEdilenList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  TeklifKabulEdilenListTOExcel: any[];
  TeklifKabulEdilenListTOExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp145295Visible: "visible" | "hidden";
  isComp508917Visible: "visible" | "hidden";
  isComp959154Visible: "visible" | "hidden";
  isComp708315Visible: "visible" | "hidden";
  isComp271347Visible: "visible" | "hidden";
  isComp610637Visible: "visible" | "hidden";
  isComp202078Visible: "visible" | "hidden";
  isComp431855Visible: "visible" | "hidden";
  isComp331468Visible: "visible" | "hidden";
  isComp15326Visible: "visible" | "hidden";
  isComp572894Visible: "visible" | "hidden";
}

export class KabulEdilenTeklifList_ScreenBase extends React.PureComponent<IKabulEdilenTeklifList_ScreenProps, any> {
  kabuledilentekliflist_876109_value_kuikaSelectBoxRef: React.RefObject<any>;
  kabuledilentekliflist_654603_value_kuikaSelectBoxRef: React.RefObject<any>;
  kabuledilentekliflist_743242_value_kuikaTableRef: React.RefObject<any>;
  kabuledilentekliflist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "68ec303f-84c0-4cd5-bc0d-e2cf4fc95c90",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Kabul Edilen Teklifler (Eser Bilgi Girişi)" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 523278, PropertyName: "value", Value: "Eser bilgileri girilmiş kayıtları da göster" },
        { Id: 876109, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 654603, PropertyName: "placeholder", Value: "A-z" },
        { Id: 449027, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 743242, PropertyName: "nodatafoundmessage", Value: "Teklif Kaydı Bulunamadı." },
        { Id: 359123, PropertyName: "title", Value: "Hızlı Teklif" },
        { Id: 428739, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 842439, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 488328, PropertyName: "title", Value: "Talep No" },
        { Id: 757395, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 23700, PropertyName: "title", Value: "Teklif No" },
        { Id: 626783, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 770089, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 504207, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 118125, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 533738, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 591830, PropertyName: "title", Value: "Firma" },
        { Id: 210659, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 909385, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 202543, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 389215, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 118673, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 704855, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 392049, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 508917, PropertyName: "title", Value: "Eser Bilgisi" },
        { Id: 959154, PropertyName: "value", Value: "Hızlı Teklif Onayı Bekleniyor" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  KabulEdilenTeklifListRecuringAction_29_Interval: any;

  constructor(props) {
    super(props);
    this.kabuledilentekliflist_876109_value_kuikaSelectBoxRef = React.createRef();
    this.kabuledilentekliflist_654603_value_kuikaSelectBoxRef = React.createRef();
    this.kabuledilentekliflist_743242_value_kuikaTableRef = React.createRef();
    this.kabuledilentekliflist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      TeklifKabulEdilenList: [],
      setPTotalCount01: [],
      TeklifKabulEdilenListTOExcel: [],
      ExportExcel: "",
      dataSource_876109: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "TalepNo", text: "Talep No" },
        { key: "TalepTarihi", text: "Talep Tarihi" },
        { key: "Firma", text: "Firma" }
      ],
      dataSource_654603: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp145295Visible: "hidden",
      isComp508917Visible: "hidden",
      isComp959154Visible: "hidden",
      isComp708315Visible: "hidden",
      isComp271347Visible: "hidden",
      isComp610637Visible: "hidden",
      isComp202078Visible: "hidden",
      isComp431855Visible: "hidden",
      isComp331468Visible: "hidden",
      isComp15326Visible: "hidden",
      isComp572894Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kabuledilentekliflist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KabulEdilenTeklifListPageInit();
    }

    if (false) {
      if (false) {
        await this.KabulEdilenTeklifListRecuringAction_29_();
      }
      this.KabulEdilenTeklifListRecuringAction_29_Interval = setInterval(async () => {
        await this.KabulEdilenTeklifListRecuringAction_29_();
      }, 29000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("kabuledilentekliflist", "");
  }

  componentWillUnmount() {
    if (this.KabulEdilenTeklifListRecuringAction_29_Interval) {
      clearInterval(this.KabulEdilenTeklifListRecuringAction_29_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kabuledilentekliflist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KabulEdilenTeklifListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.KabulEdilenTeklifListRecuringAction_29_();
      }
      this.KabulEdilenTeklifListRecuringAction_29_Interval = setInterval(async () => {
        await this.KabulEdilenTeklifListRecuringAction_29_();
      }, 29000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kabuledilentekliflist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      kabuledilentekliflist_626361_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  KabulEdilenTeklifListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      EserBilgisiOlusturulanGoster_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.kabuledilentekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.kabuledilentekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.kabuledilentekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp508917Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg041 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15326Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg041 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.kabuledilentekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.kabuledilentekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.kabuledilentekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;
    stateVars.isComp145295Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "hizliTeklif"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp508917Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg041 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959154Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isHizliOnayIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp708315Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isEditIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271347Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isTempIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isGeriDonduIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202078Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isOnayBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431855Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isReddedildiIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp331468Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isOnaylandiIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15326Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg041 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp572894Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kabuledilentekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "514456",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_626361_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      EserBilgisiOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListComponent_626361_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListComponent_626361_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListComponent_626361_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;
    formVars.kabuledilentekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_876109_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      EserBilgisiOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListComponent_876109_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListComponent_876109_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListComponent_876109_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;
    formVars.kabuledilentekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_654603_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      EserBilgisiOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListComponent_654603_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListComponent_654603_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListComponent_654603_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;
    formVars.kabuledilentekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_159543_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      EserBilgisiOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListComponent_159543_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListComponent_159543_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListComponent_159543_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;
    formVars.kabuledilentekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_485387_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      EserBilgisiOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListComponent_485387_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifKabulEdilenListTOExcel = result?.data.teklifKabulEdilenListTOExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_708315_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "N");
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmKitapAd",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "kitapAd")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "304016",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_271347_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "E");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "163364",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_610637_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "E");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "535088",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_406042_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "VerilenTeklifKarsilastirma",
      "TeklifTalepKartID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklifTalepKartId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "VerilenTeklifKarsilastirma",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "55753",
      null,
      "right",
      null,
      "90%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_689496_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "uyeId")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Muhatap");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "935062",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_572894_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "kabuledilentekliflist_743242_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KabulEdilenTeklifList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "932195",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      EserBilgisiOlusturulanGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KabulEdilenTeklifListRecuringAction_29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      EserBilgisiOlusturulanGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_626361_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_449027_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_876109_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_654603_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kabuledilentekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KabulEdilenTeklifList/KabulEdilenTeklifListRecuringAction_29_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.kabuledilentekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.kabuledilentekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.kabuledilentekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.TeklifKabulEdilenList = result?.data.teklifKabulEdilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KabulEdilenTeklifListRecuringAction_29_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KabulEdilenTeklifListRecuringAction_29_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.kabuledilentekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.kabuledilentekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.kabuledilentekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_743242 = this.state.TeklifKabulEdilenList;
    formVars.kabuledilentekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 514456, 304016, 163364, 535088, 55753, 935062, 932195] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.KabulEdilenTeklifListPageInit();
    }
  }
}
