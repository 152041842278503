import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITeklifVermeKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITeklifVermeKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  getTeklifID: any[];
  getTeklifID_dummy: any[];
  TeklifTalepInfoById: any[];
  TeklifTalepInfoById_dummy: any[];
  TeslimTuruAll: any[];
  TeslimTuruAll_dummy: any[];
  ParaBirimAll: any[];
  ParaBirimAll_dummy: any[];
  TeklifKartByTeklifTalepId: any[];
  TeklifKartByTeklifTalepId_dummy: any[];
  DokumanlarByKartIdTeklif: any[];
  DokumanlarByKartIdTeklif_dummy: any[];
  TeklifKartDuzeltmeNotuByTeklifKartID: any[];
  TeklifKartDuzeltmeNotuByTeklifKartID_dummy: any[];
  HizliOnayButtonVisibility: any[];
  HizliOnayButtonVisibility_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  teklifEdenUserNamebyTeklifEdenID: any[];
  teklifEdenUserNamebyTeklifEdenID_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  TeklifFiyatHesapla: any[];
  TeklifFiyatHesapla_dummy: any[];
  UploadFile: any;
  UploadFile_dummy: any;
  DokumanInsert: number;
  DokumanInsert_dummy: number;
  DokumanDeleteById: number;
  DokumanDeleteById_dummy: number;
  DokumanById: any[];
  DokumanById_dummy: any[];
  PrintPdf: string;
  PrintPdf_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  getTeklifFiyat: any[];
  getTeklifFiyat_dummy: any[];
  HizliOnayEserBilgileri: number;
  HizliOnayEserBilgileri_dummy: number;
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  getKUserbyUserName: any[];
  getKUserbyUserName_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  TeklifKartSave: number;
  TeklifKartSave_dummy: number;
  TeklifVermemeTalepDurumUpdate: number;
  TeklifVermemeTalepDurumUpdate_dummy: number;
  TeklifVermeOnay: number;
  TeklifVermeOnay_dummy: number;
  isComp815480Visible: "visible" | "hidden";
  isComp147536Visible: "visible" | "hidden";
  isComp259656Visible: "visible" | "hidden";
  isComp766779Visible: "visible" | "hidden";
  isComp447542Visible: "visible" | "hidden";
  isComp941986Visible: "visible" | "hidden";
  isComp92196Visible: "visible" | "hidden";
  isComp760145Visible: "visible" | "hidden";
  isComp336583Visible: "visible" | "hidden";
  isComp282467Visible: "visible" | "hidden";
  isComp323077Visible: "visible" | "hidden";
  isComp591896Enabled: "enabled" | "disabled";
  isComp447334Enabled: "enabled" | "disabled";
  isComp380055Enabled: "enabled" | "disabled";
  isComp413545Enabled: "enabled" | "disabled";
  isComp721971Enabled: "enabled" | "disabled";
  isComp740038Enabled: "enabled" | "disabled";
  isComp197332Enabled: "enabled" | "disabled";
  isComp397598Enabled: "enabled" | "disabled";
  isComp608589Enabled: "enabled" | "disabled";
  isComp147536Enabled: "enabled" | "disabled";
  isComp259656Enabled: "enabled" | "disabled";
  isComp802075Enabled: "enabled" | "disabled";
}

export class TeklifVermeKart_ScreenBase extends React.PureComponent<ITeklifVermeKart_ScreenProps, any> {
  teklifvermekart_591896_value_kuikaDateRef: React.RefObject<any>;
  teklifvermekart_447334_value_kuikaDateRef: React.RefObject<any>;
  teklifvermekart_413545_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermekart_397598_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermekart_318046_value_kuikaTableRef: React.RefObject<any>;
  teklifvermekart_431778_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "6916c2e1-ecb7-4e59-b86e-0b5e9e555185",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 704322, PropertyName: "label", Value: "Talep İçeriği" },
        { Id: 925774, PropertyName: "value", Value: "Teklif Muhatabı (Yayıncı, Yazar vb)" },
        { Id: 449685, PropertyName: "value", Value: "Talep No" },
        { Id: 110445, PropertyName: "value", Value: "Kitap Boyutu" },
        { Id: 691652, PropertyName: "value", Value: "Sayfa Sayısı" },
        { Id: 574184, PropertyName: "value", Value: "Kullanılacak İç Kağıt Cinsi" },
        { Id: 246310, PropertyName: "value", Value: "Kullanılacak İç Kağıt Gramajı" },
        { Id: 330712, PropertyName: "value", Value: "Kullanılacak Kapak Cinsi" },
        { Id: 446018, PropertyName: "value", Value: "Kullanılacak Kapak Gramajı" },
        { Id: 773494, PropertyName: "value", Value: "Kullanılacak Kapak Selefonu" },
        { Id: 816369, PropertyName: "value", Value: "İç Baskı Renk (Siyah Beyaz/Renkli)" },
        { Id: 717148, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 823746, PropertyName: "value", Value: "Baskı Adet" },
        { Id: 641470, PropertyName: "value", Value: "Nakliye" },
        { Id: 582001, PropertyName: "value", Value: "Not" },
        {
          Id: 73496,
          PropertyName: "title",
          Value:
            "Kitabın derlemeye tabii olması durumunda girilen adet içerisinde derleme nüshalarının da dahil edilerek giriş yapılmasına ilişkin bir uyarı koyulmalıdır. Ayrıca Baskı Adedi minimum 50 olacak şekilde sınırlama getirilmelidir."
        },
        { Id: 142877, PropertyName: "label", Value: "Teklif İçeriği" },
        { Id: 272852, PropertyName: "value", Value: "Teklif Tarihi" },
        { Id: 97400, PropertyName: "value", Value: "Teklif Geçerlilik Süresi" },
        { Id: 841558, PropertyName: "value", Value: "Birim Fiyatı" },
        { Id: 678150, PropertyName: "value", Value: "Hesapla" },
        { Id: 384377, PropertyName: "value", Value: "Teklif Fiyatı" },
        { Id: 376835, PropertyName: "value", Value: "Para Birimi" },
        { Id: 60378, PropertyName: "value", Value: "İndirim (%)" },
        { Id: 95493, PropertyName: "value", Value: "Kur (Teklif Kuru)" },
        { Id: 610203, PropertyName: "value", Value: "Diğer Koşullar" },
        { Id: 404258, PropertyName: "value", Value: "Teslim Türü" },
        { Id: 576902, PropertyName: "value", Value: "Not" },
        { Id: 801181, PropertyName: "value", Value: "Döküman (PDF Upload)" },
        { Id: 318046, PropertyName: "nodatafoundmessage", Value: "Yüklenen Dosya Bulunamadı." },
        { Id: 700093, PropertyName: "title", Value: "Title" },
        { Id: 38457, PropertyName: "value", Value: "[datafield:dosyaad]" },
        { Id: 815480, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 147536, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 259656, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 431778, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 414664, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 907051, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 404589, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 158141, PropertyName: "value", Value: "Liste Fiyat" },
        { Id: 747075, PropertyName: "value", Value: "İndirim" },
        { Id: 190940, PropertyName: "label", Value: "Hızlı Teklif Onayla" },
        { Id: 542748, PropertyName: "placeholder", Value: "Teklif Verilmeme Nedenini Giriniz..." },
        { Id: 952805, PropertyName: "label", Value: "Teklif Verme" },
        { Id: 592092, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 508444, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 454041, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 61628, PropertyName: "label", Value: "Red Et" },
        { Id: 80606, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.teklifvermekart_591896_value_kuikaDateRef = React.createRef();
    this.teklifvermekart_447334_value_kuikaDateRef = React.createRef();
    this.teklifvermekart_413545_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermekart_397598_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermekart_318046_value_kuikaTableRef = React.createRef();
    this.teklifvermekart_431778_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      getTeklifID: [],
      TeklifTalepInfoById: [],
      TeslimTuruAll: [],
      ParaBirimAll: [],
      TeklifKartByTeklifTalepId: [],
      DokumanlarByKartIdTeklif: [],
      TeklifKartDuzeltmeNotuByTeklifKartID: [],
      HizliOnayButtonVisibility: [],
      GenelParametreler: [],
      teklifEdenUserNamebyTeklifEdenID: [],
      SetValueOf: "",
      ChangeEnabledOf: "",
      ChangeVisibilityOf: "",
      TriggerEvent: "",
      TeklifFiyatHesapla: [],
      UploadFile: "",
      DokumanInsert: 0,
      DokumanDeleteById: 0,
      DokumanById: [],
      PrintPdf: "",
      SaveRecord: false,
      getTeklifFiyat: [],
      HizliOnayEserBilgileri: 0,
      UyelikTipiLowercase: [],
      SendMailSettings: [],
      getKUserbyUserName: [],
      SendMail: false,
      Notify: false,
      ValidasyonKontrol: [],
      TeklifKartSave: 0,
      TeklifVermemeTalepDurumUpdate: 0,
      TeklifVermeOnay: 0,
      isComp815480Visible: "visible",
      isComp147536Visible: "visible",
      isComp259656Visible: "visible",
      isComp766779Visible: "hidden",
      isComp447542Visible: "hidden",
      isComp941986Visible: "hidden",
      isComp92196Visible: "hidden",
      isComp760145Visible: "hidden",
      isComp336583Visible: "hidden",
      isComp282467Visible: "hidden",
      isComp323077Visible: "hidden",
      isComp591896Enabled: "enabled",
      isComp447334Enabled: "enabled",
      isComp380055Enabled: "enabled",
      isComp413545Enabled: "enabled",
      isComp721971Enabled: "enabled",
      isComp740038Enabled: "enabled",
      isComp197332Enabled: "enabled",
      isComp397598Enabled: "enabled",
      isComp608589Enabled: "enabled",
      isComp147536Enabled: "enabled",
      isComp259656Enabled: "enabled",
      isComp802075Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("teklifvermekart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TeklifVermeKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("teklifvermekart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("teklifvermekart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TeklifVermeKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      teklifvermekart_447334_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.teklifGecerlilikTarihi ?? undefined,
      teklifvermekart_802075_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.teklifBirimFiyat ?? undefined,
      teklifvermekart_380055_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.teklifFiyat ?? undefined,
      teklifvermekart_413545_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.paraBirimID ?? undefined,
      teklifvermekart_721971_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.indirimYuzde ?? undefined,
      teklifvermekart_740038_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.kur ?? undefined,
      teklifvermekart_197332_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.digerKosullar ?? undefined,
      teklifvermekart_397598_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.teslimTuruID ?? undefined,
      teklifvermekart_608589_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.aciklama ?? undefined,
      teklifvermekart_264671_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.listeFiyat ?? undefined,
      teklifvermekart_157614_value: this.state.TeklifKartByTeklifTalepId?.at?.(0)?.indirim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TeklifVermeKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      TeklifTalepId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      TeklifTalepId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      TeklifTalepId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmUID ?? this.props.screenInputs.prmuid,
        "Guid"
      ),
      MuhatapMiFirmaMi_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmMuhatapMiFirmaMi ?? this.props.screenInputs.prmmuhatapmifirmami,
        "string"
      ),
      TeklifTalepKartID_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TeklifVermeKart/TeklifVermeKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.getTeklifID = result?.data.getTeklifID;
    stateVars.TeklifTalepInfoById = result?.data.teklifTalepInfoById;
    formVars.teklifvermekart_496643_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.muhatap : null
    );
    formVars.teklifvermekart_847266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.talep_No : null
    );
    formVars.teklifvermekart_684833_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.kitapBoyutTanim : null
    );
    formVars.teklifvermekart_373186_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.sayfaSayisi : null
    );
    formVars.teklifvermekart_386329_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.icBaskiKagitCinsi : null
    );
    formVars.teklifvermekart_517357_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.icBaskiKagitGramaj : null
    );
    formVars.teklifvermekart_999678_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.kapakCinsi : null
    );
    formVars.teklifvermekart_446522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.kapakGramaj : null
    );
    formVars.teklifvermekart_542906_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.selefon : null
    );
    formVars.teklifvermekart_34603_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.icBaskiRenk : null
    );
    formVars.teklifvermekart_74358_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.ciltBicimi : null
    );
    formVars.teklifvermekart_105815_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.baskiAdet : null
    );
    formVars.teklifvermekart_65515_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.nakliyeDurum : null
    );
    formVars.teklifvermekart_80191_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifTalepInfoById?.length > 0 ? stateVars.TeklifTalepInfoById[0]?.aciklama : null
    );
    stateVars.TeslimTuruAll = result?.data.teslimTuruAll;

    formVars.teklifvermekart_397598_value =
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.teslimTuruID : null;
    formVars.teklifvermekart_397598_options = stateVars.TeslimTuruAll;
    stateVars.ParaBirimAll = result?.data.paraBirimAll;

    formVars.teklifvermekart_413545_value =
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.paraBirimID : null;
    formVars.teklifvermekart_413545_options = stateVars.ParaBirimAll;
    stateVars.TeklifKartByTeklifTalepId = result?.data.teklifKartByTeklifTalepId;
    formVars.teklifvermekart_447334_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0
        ? stateVars.TeklifKartByTeklifTalepId[0]?.teklifGecerlilikTarihi
        : null
    );
    formVars.teklifvermekart_447334_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "teklifvermekart_591896_value", "value", "", "", "")
    );
    formVars.teklifvermekart_802075_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.teklifBirimFiyat : null
    );
    formVars.teklifvermekart_380055_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.teklifFiyat : null
    );
    formVars.teklifvermekart_413545_value =
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.paraBirimID : null;
    formVars.teklifvermekart_413545_options = stateVars.ParaBirimAll;
    formVars.teklifvermekart_721971_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.indirimYuzde : null
    );
    formVars.teklifvermekart_740038_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.kur : null
    );
    formVars.teklifvermekart_197332_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.digerKosullar : null
    );
    formVars.teklifvermekart_397598_value =
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.teslimTuruID : null;
    formVars.teklifvermekart_397598_options = stateVars.TeslimTuruAll;
    formVars.teklifvermekart_608589_value = ReactSystemFunctions.toString(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.aciklama : null
    );
    formVars.teklifvermekart_264671_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.listeFiyat : null
    );
    formVars.teklifvermekart_157614_value = ReactSystemFunctions.value(
      this,
      stateVars.TeklifKartByTeklifTalepId?.length > 0 ? stateVars.TeklifKartByTeklifTalepId[0]?.indirim : null
    );
    stateVars.isComp941986Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TeklifKartByTeklifTalepId?.length > 0
            ? stateVars.TeklifKartByTeklifTalepId[0]?.isrwDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp282467Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TeklifKartByTeklifTalepId?.length > 0
            ? stateVars.TeklifKartByTeklifTalepId[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp323077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.TeklifKartByTeklifTalepId?.length > 0
            ? stateVars.TeklifKartByTeklifTalepId[0]?.isButtonsOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DokumanlarByKartIdTeklif = result?.data.dokumanlarByKartIdTeklif;

    stateVars.TeklifKartDuzeltmeNotuByTeklifKartID = result?.data.teklifKartDuzeltmeNotuByTeklifKartID;

    stateVars.HizliOnayButtonVisibility = result?.data.hizliOnayButtonVisibility;
    stateVars.isComp336583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.HizliOnayButtonVisibility?.length > 0
            ? stateVars.HizliOnayButtonVisibility[0]?.isHizliOnayButtonVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.teklifEdenUserNamebyTeklifEdenID = result?.data.teklifEdenUserNamebyTeklifEdenID;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0
            ? this.state.TeklifKartByTeklifTalepId[0]?.teklifTarihi
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "teklifvermekart_591896_value",
        moment().format("YYYY-MM-DDTHH:mm:ss"),
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0
            ? this.state.TeklifKartByTeklifTalepId[0]?.teklifTarihi
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "teklifvermekart_591896_value",
        ReactSystemFunctions.value(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0
            ? this.state.TeklifKartByTeklifTalepId[0]?.teklifTarihi
            : null
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp591896Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp447334Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp380055Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp413545Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp721971Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp740038Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp197332Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp397598Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp608589Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp147536Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp259656Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp815480Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp147536Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp259656Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp802075Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  TeklifVermeKartPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.teklifvermekart_496643_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.muhatap : null
    );

    formVars.teklifvermekart_847266_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.talep_No : null
    );

    formVars.teklifvermekart_684833_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.kitapBoyutTanim : null
    );

    formVars.teklifvermekart_373186_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.sayfaSayisi : null
    );

    formVars.teklifvermekart_386329_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.icBaskiKagitCinsi : null
    );

    formVars.teklifvermekart_517357_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.icBaskiKagitGramaj : null
    );

    formVars.teklifvermekart_999678_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.kapakCinsi : null
    );

    formVars.teklifvermekart_446522_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.kapakGramaj : null
    );

    formVars.teklifvermekart_542906_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.selefon : null
    );

    formVars.teklifvermekart_34603_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.icBaskiRenk : null
    );

    formVars.teklifvermekart_74358_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.ciltBicimi : null
    );

    formVars.teklifvermekart_105815_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.baskiAdet : null
    );

    formVars.teklifvermekart_65515_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.nakliyeDurum : null
    );

    formVars.teklifvermekart_80191_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.aciklama : null
    );

    formVars.teklifvermekart_447334_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0
        ? this.state.TeklifKartByTeklifTalepId[0]?.teklifGecerlilikTarihi
        : null
    );

    formVars.teklifvermekart_802075_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0
        ? this.state.TeklifKartByTeklifTalepId[0]?.teklifBirimFiyat
        : null
    );

    formVars.teklifvermekart_380055_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.teklifFiyat : null
    );

    formVars.teklifvermekart_413545_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.paraBirimID : null
    );

    stateVars.dataSource_413545 = this.state.ParaBirimAll;
    stateVars.dataSource_413545 = this.state.ParaBirimAll;
    formVars.teklifvermekart_721971_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.indirimYuzde : null
    );

    formVars.teklifvermekart_740038_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.kur : null
    );

    formVars.teklifvermekart_197332_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.digerKosullar : null
    );

    formVars.teklifvermekart_397598_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.teslimTuruID : null
    );

    stateVars.dataSource_397598 = this.state.TeslimTuruAll;
    stateVars.dataSource_397598 = this.state.TeslimTuruAll;
    formVars.teklifvermekart_608589_value = ReactSystemFunctions.toString(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.aciklama : null
    );

    stateVars.isComp766779Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_318046 = this.state.DokumanlarByKartIdTeklif;
    stateVars.isComp447542Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp941986Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0
            ? this.state.TeklifKartByTeklifTalepId[0]?.isrwDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_431778 = this.state.TeklifKartDuzeltmeNotuByTeklifKartID;
    formVars.teklifvermekart_264671_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.listeFiyat : null
    );

    formVars.teklifvermekart_157614_value = ReactSystemFunctions.value(
      this,
      this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.indirim : null
    );

    stateVars.isComp336583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.HizliOnayButtonVisibility?.length > 0
            ? this.state.HizliOnayButtonVisibility[0]?.isHizliOnayButtonVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp282467Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0
            ? this.state.TeklifKartByTeklifTalepId[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp323077Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0
            ? this.state.TeklifKartByTeklifTalepId[0]?.isButtonsOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TeklifVermeKartComponent_694983_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_802075_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.TeklifVermeKartComponent_133871_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_802075_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.TeklifVermeKartComponent_133871_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_678150_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.TeklifVermeKartComponent_133871_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_133871_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_802075_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_802075_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BirimFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_802075_value", "value", "", "", "")
        ),
        "number"
      ),
      BaskiAdet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.baskiAdet : null
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_133871_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifFiyatHesapla = result?.data.teklifFiyatHesapla;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_133871_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_133871_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "teklifvermekart_380055_value",
      ReactSystemFunctions.value(
        this,
        this.state.TeklifFiyatHesapla?.length > 0 ? this.state.TeklifFiyatHesapla[0]?.teklifFiyat : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_766779_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_766779_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifVermeKartComponent_766779_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("Teklif", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_766779_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanInsert = result?.data.dokumanInsert;
    stateVars.DokumanlarByKartIdTeklif = result?.data.dokumanlarByKartIdTeklif;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_766779_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_766779_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_318046 = this.state.DokumanlarByKartIdTeklif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_447542_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "teklifvermekart_318046_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e0bad3a0_61f9_6193_32cb_8415fbac7b1d_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_447542_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdTeklif = result?.data.dokumanlarByKartIdTeklif;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_447542_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_447542_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_318046 = this.state.DokumanlarByKartIdTeklif;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_129206_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "teklifvermekart_318046_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_129206_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanById = result?.data.dokumanById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_129206_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_129206_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.DokumanById?.length > 0 ? this.state.DokumanById[0]?.dosya : null)
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_259656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_147536_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_147536_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_147536_value", "value", "", "", "")
        ),
        "string"
      ),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TeklifKartID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      ),
      TeklifKartID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_259656_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.TeklifKartDuzeltmeNotuByTeklifKartID = result?.data.teklifKartDuzeltmeNotuByTeklifKartID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_259656_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_259656_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "teklifvermekart_147536_value", "", null);

    stateVars.dataSource_431778 = this.state.TeklifKartDuzeltmeNotuByTeklifKartID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_264671_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ListeFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_264671_value", "value", "", "", "")
        ),
        "number"
      ),
      Indirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_157614_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_264671_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getTeklifFiyat = result?.data.getTeklifFiyat;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_264671_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_264671_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "teklifvermekart_380055_value",
      ReactSystemFunctions.value(
        this,
        this.state.getTeklifFiyat?.length > 0 ? this.state.getTeklifFiyat[0]?.teklifFiyat : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_157614_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ListeFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_264671_value", "value", "", "", "")
        ),
        "number"
      ),
      Indirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_157614_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_157614_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getTeklifFiyat = result?.data.getTeklifFiyat;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_157614_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_157614_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "teklifvermekart_380055_value",
      ReactSystemFunctions.value(
        this,
        this.state.getTeklifFiyat?.length > 0 ? this.state.getTeklifFiyat[0]?.teklifFiyat : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_190940_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      UyelikTipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      actionkey_2: ReactSystemFunctions.convertToTypeByName("teklifyayinciyagonder", "string"),
      numara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.talep_No : null
        ),
        "string"
      ),
      UserName_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.userName : null
        ),
        "string"
      ),
      templateId_4: ReactSystemFunctions.convertToTypeByName("56d6ff05-bf2b-4a59-8fe7-cf46ebf5f946", "string"),
      receivers_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.userName : null
        ),
        "string"
      ),
      sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeIsmi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      ),
      UyeSoyad_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      language_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_190940_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HizliOnayEserBilgileri = result?.data.hizliOnayEserBilgileri;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.getKUserbyUserName = result?.data.getKUserbyUserName;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_190940_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_190940_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a3776fe6_3d99_500c_a793_9b20ca8ae4a4_notify",
        this.defaultML,
        "Teklif Onaylandı!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_952805_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_542748_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_542748_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_542748_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Prm3_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Prm4_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Prm5_0: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_952805_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_952805_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_952805_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_608589_value", "value", "", "", "")
        ),
        "string"
      ),
      DigerKosullar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_197332_value", "value", "", "", "")
        ),
        "string"
      ),
      Indirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_157614_value", "value", "", "", "")
        ),
        "number"
      ),
      ListeFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_264671_value", "value", "", "", "")
        ),
        "number"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_413545_value", "value", "ParaBirimAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000005", "Guid"),
      TeklifFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_380055_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilikTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_447334_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      TeklifTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_591896_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeslimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_397598_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      IndirimYuzde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_721971_value", "value", "", "", "")
        ),
        "number"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_740038_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifBirimFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_802075_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifVerilmemeNedeni_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_542748_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "cf5f3fb2_25e3_f3f0_d17b_e3e93950432e_confirmation",
        this.defaultML,
        "Teklif Verilmeyecek Olarak Kayıt Oluşturulacak; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_952805_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifKartSave = result?.data.teklifKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_952805_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_952805_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeKart/TeklifVermeKartComponent_952805_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVermemeTalepDurumUpdate = result?.data.teklifVermemeTalepDurumUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeKartComponent_952805_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_952805_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a7327dd8_1836_1ba5_d105_57c6fc351445_notify",
        this.defaultML,
        "Teklif Verilmedi Olarak Kaydedildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_592092_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_608589_value", "value", "", "", "")
        ),
        "string"
      ),
      DigerKosullar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_197332_value", "value", "", "", "")
        ),
        "string"
      ),
      Indirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_157614_value", "value", "", "", "")
        ),
        "number"
      ),
      ListeFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_264671_value", "value", "", "", "")
        ),
        "number"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_413545_value", "value", "ParaBirimAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      TeklifFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_380055_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilikTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_447334_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      TeklifTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_591896_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeslimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_397598_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      IndirimYuzde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_721971_value", "value", "", "", "")
        ),
        "number"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_740038_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifBirimFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_802075_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifVerilmemeNedeni_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0c7b1495_54dc_82f7_4e9e_615525dd6e5b_confirmation",
        this.defaultML,
        "Teklif Taslak Olarak Kayıt Edilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_592092_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifKartSave = result?.data.teklifKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_592092_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_592092_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "63d4bb9e_480c_e5a4_ab66_babef391eea8_notify",
        this.defaultML,
        "Taslak Olarak Kaydedildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_508444_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_413545_value", "value", "ParaBirimAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_413545_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_380055_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_380055_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_447334_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_447334_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_802075_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "teklifvermekart_802075_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_608589_value", "value", "", "", "")
        ),
        "string"
      ),
      DigerKosullar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_197332_value", "value", "", "", "")
        ),
        "string"
      ),
      Indirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_157614_value", "value", "", "", "")
        ),
        "number"
      ),
      ListeFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_264671_value", "value", "", "", "")
        ),
        "number"
      ),
      ParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_413545_value", "value", "ParaBirimAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      TeklifFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_380055_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifGecerlilikTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_447334_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepID ?? this.props.screenInputs.prmtekliftalepid,
        "Guid"
      ),
      TeklifTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_591896_value", "value", "", "", "")
        ),
        "Date"
      ),
      TeslimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_397598_value", "value", "TeslimTuruAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifEdenUyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      IndirimYuzde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_721971_value", "value", "", "", "")
        ),
        "number"
      ),
      Kur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_740038_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifBirimFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermekart_802075_value", "value", "", "", "")
        ),
        "number"
      ),
      TeklifVerilmemeNedeni_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2b891717_e7c2_4e39_7577_b72ec29d4c66_confirmation",
        this.defaultML,
        "Teklif Onaya Gönderilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_508444_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifKartSave = result?.data.teklifKartSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_508444_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_508444_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "26883cbb_338f_8d98_240a_fda764ce99c1_notify",
        this.defaultML,
        "Teklif Onaya Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_454041_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      TeklifOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TeklifOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "84158b07_d15f_aaca_2eb3_b99884c43f21_confirmation",
        this.defaultML,
        "Açıklma Girildi ise Teklif Düzeltme İçin Geri Gönderilecek, İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_454041_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifVermeOnay = result?.data.teklifVermeOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_454041_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_454041_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "09908143_5535_b680_7b93_3e31b9a068ab_notify",
        this.defaultML,
        "Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_61628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      TeklifOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TeklifOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      ),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("teklifreddetmatbaa", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifKartByTeklifTalepId?.length > 0 ? this.state.TeklifKartByTeklifTalepId[0]?.teklifNo : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "73495af0_c1cf_464b_802c_242a6ec66179_confirmation",
        this.defaultML,
        "Teklif Reddedilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_61628_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifVermeOnay = result?.data.teklifVermeOnay;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_61628_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_61628_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "04689877_6cdc_8d6a_6fe0_dc5355e524af_notify",
        this.defaultML,
        "Reddedildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_80606_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      TeklifOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TeklifOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getTeklifID?.length > 0 ? this.state.getTeklifID[0]?.getTeklifID : null
        ),
        "Guid"
      ),
      UyelikTipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      actionkey_2: ReactSystemFunctions.convertToTypeByName("teklifyayinciyagonder", "string"),
      uyeliktipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.talep_No : null
        ),
        "string"
      ),
      UserName_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.userName : null
        ),
        "string"
      ),
      templateId_4: ReactSystemFunctions.convertToTypeByName("e470b54d-9130-4456-a2f9-fa2fc08af50d", "string"),
      receivers_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.TeklifTalepInfoById?.length > 0 ? this.state.TeklifTalepInfoById[0]?.userName : null
        ),
        "string"
      ),
      sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      UyeIsmi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      ),
      UyeSoyad_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      language_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ca465aa0_dc4f_38c4_c0e9_77b89f017865_confirmation",
        this.defaultML,
        "Teklif Onaylanacak; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TeklifVermeKart/TeklifVermeKartComponent_80606_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TeklifVermeOnay = result?.data.teklifVermeOnay;
      stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      stateVars.getKUserbyUserName = result?.data.getKUserbyUserName;
      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TeklifVermeKartComponent_80606_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeKartComponent_80606_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c098743f_ebd1_8a85_f4c9_6fdb87fa6b74_notify",
        this.defaultML,
        "Teklif Onaylandı!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeKartComponent_591896_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "teklifvermekart_447334_value",
      ReactSystemFunctions.value(this, "teklifvermekart_591896_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
