import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  SiparisList: any[];
  SiparisList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  SiparisListToExcel: any[];
  SiparisListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp243022Visible: "visible" | "hidden";
}

export class SiparisList_ScreenBase extends React.PureComponent<ISiparisList_ScreenProps, any> {
  siparislist_477648_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparislist_453520_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparislist_348605_value_kuikaTableRef: React.RefObject<any>;
  siparislist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "58b51704-a2c3-4c8f-bc92-b7dd356e1471",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Sipariş Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 477648, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 453520, PropertyName: "placeholder", Value: "A-z" },
        { Id: 479543, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Sipariş Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Siparis No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 517244, PropertyName: "title", Value: "Teklif No" },
        { Id: 21498, PropertyName: "value", Value: "[datafield:teklifnos]" },
        { Id: 833287, PropertyName: "title", Value: "Firma" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 916867, PropertyName: "title", Value: "Sipariş Durumu" },
        { Id: 989618, PropertyName: "value", Value: "[datafield:siparisdurum]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  SiparisListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.siparislist_477648_value_kuikaSelectBoxRef = React.createRef();
    this.siparislist_453520_value_kuikaSelectBoxRef = React.createRef();
    this.siparislist_348605_value_kuikaTableRef = React.createRef();
    this.siparislist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      SiparisList: [],
      setPTotalCount01: [],
      SiparisListToExcel: [],
      ExportExcel: "",
      dataSource_477648: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "Firma", text: "Firma" },
        { key: "SiparisNo", text: "Sipariş No" }
      ],
      dataSource_453520: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp243022Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparislist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisListPageInit();
    }

    if (false) {
      if (false) {
        await this.SiparisListRecuringAction_30_();
      }
      this.SiparisListRecuringAction_30_Interval = setInterval(async () => {
        await this.SiparisListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("siparislist", "");
  }

  componentWillUnmount() {
    if (this.SiparisListRecuringAction_30_Interval) {
      clearInterval(this.SiparisListRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparislist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.SiparisListRecuringAction_30_();
      }
      this.SiparisListRecuringAction_30_Interval = setInterval(async () => {
        await this.SiparisListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparislist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SiparisListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_2: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.siparislist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.siparislist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.siparislist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp243022Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg047 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SiparisList = result?.data.siparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.siparislist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.siparislist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.siparislist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.SiparisList;
    stateVars.isComp243022Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg047 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.siparislist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "265606",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_477648_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListComponent_477648_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisList = result?.data.siparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListComponent_477648_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListComponent_477648_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisList;
    formVars.siparislist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_453520_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListComponent_453520_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisList = result?.data.siparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListComponent_453520_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListComponent_453520_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisList;
    formVars.siparislist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_597546_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListComponent_597546_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisList = result?.data.siparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListComponent_597546_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListComponent_597546_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisList;
    formVars.siparislist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_536573_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListComponent_536573_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisListToExcel = result?.data.siparisListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SiparisListComponent_685830_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "TaslakmiKartmi", "Kart");
    KuikaAppManager.addToPageInputVariables(
      "TSiparisKart",
      "SiparisKartId",
      ReactSystemFunctions.value(this, "siparislist_348605_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "FirmaAd", "-");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisList",
      "TSiparisKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "488960",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_7016_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UretimList",
      "prmSiparisNo",
      ReactSystemFunctions.value(this, "siparislist_348605_value", "siparis_No")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisList",
      "UretimList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "7198",
      null,
      "right",
      null,
      "90%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListComponent_880048_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisList = result?.data.siparisList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparislist_479543_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_477648_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparislist_453520_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisList/SiparisListRecuringAction_30_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.siparislist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.siparislist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.siparislist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.SiparisList = result?.data.siparisList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.siparislist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.siparislist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.siparislist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.SiparisList;
    formVars.siparislist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 265606, 488960, 7198] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SiparisListPageInit();
    }
  }
}
