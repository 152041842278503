import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMusteriSahisBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMusteriSahisBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  getGuid: any[];
  getGuid_dummy: any[];
  GenelParametrelerPDF: any[];
  GenelParametrelerPDF_dummy: any[];
  UyelikFormuById: any[];
  UyelikFormuById_dummy: any[];
  SelectCityAll: any[];
  SelectCityAll_dummy: any[];
  SelectDistrictAll: any[];
  SelectDistrictAll_dummy: any[];
  UyelikFormuDuzeltmeNotuByUyeID: any[];
  UyelikFormuDuzeltmeNotuByUyeID_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  DownloadFile: any;
  DownloadFile_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  POPSozlesmesiUpdate: number;
  POPSozlesmesiUpdate_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  getDownloadGuid: any[];
  getDownloadGuid_dummy: any[];
  POPSozlesmesiById: any[];
  POPSozlesmesiById_dummy: any[];
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  KullaniciDetayUserIdUyeIdUpdate: number;
  KullaniciDetayUserIdUyeIdUpdate_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  UpdateUserStartingScreen: string;
  UpdateUserStartingScreen_dummy: string;
  PowerUserOnay: number;
  PowerUserOnay_dummy: number;
  isComp54788Visible: "visible" | "hidden";
  isComp155718Visible: "visible" | "hidden";
  isComp241603Visible: "visible" | "hidden";
  isComp335232Visible: "visible" | "hidden";
  isComp389273Visible: "visible" | "hidden";
  isComp483185Visible: "visible" | "hidden";
  isComp221266Visible: "visible" | "hidden";
  isComp482092Visible: "visible" | "hidden";
  isComp547959Enabled: "enabled" | "disabled";
}

export class MusteriSahisBilgileri_ScreenBase extends React.PureComponent<IMusteriSahisBilgileri_ScreenProps, any> {
  musterisahisbilgileri_546287_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterisahisbilgileri_100145_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterisahisbilgileri_234228_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "8442a9f0-c4f6-4131-86b2-15b847a16b60",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 619404, PropertyName: "value", Value: "Müşteri Şahıs Bilgileri" },
        { Id: 532883, PropertyName: "value", Value: "TC Kimlik Numarası" },
        { Id: 547959, PropertyName: "placeholder", Value: "İmzalı POP Müşteri Şahıs Sözleşmesi" },
        { Id: 929728, PropertyName: "value", Value: "İl" },
        { Id: 458210, PropertyName: "value", Value: "İlçe" },
        { Id: 158154, PropertyName: "value", Value: "Adres" },
        { Id: 796351, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 944498, PropertyName: "value", Value: "Vergi Kimlik Numarası" },
        { Id: 842625, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 455124, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 281261, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 234228, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 306250, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 845632, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 372011, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 213001, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 887191, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 301668, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 488836, PropertyName: "label", Value: "Red Et" },
        { Id: 255926, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.musterisahisbilgileri_546287_value_kuikaSelectBoxRef = React.createRef();
    this.musterisahisbilgileri_100145_value_kuikaSelectBoxRef = React.createRef();
    this.musterisahisbilgileri_234228_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      getGuid: [],
      GenelParametrelerPDF: [],
      UyelikFormuById: [],
      SelectCityAll: [],
      SelectDistrictAll: [],
      UyelikFormuDuzeltmeNotuByUyeID: [],
      ChangeEnabledOf: "",
      GoBack: "",
      DownloadFile: "",
      SaveRecord: false,
      UploadFile: "",
      POPSozlesmesiUpdate: 0,
      SetValueOf: "",
      Notify: false,
      getDownloadGuid: [],
      POPSozlesmesiById: [],
      ValidasyonKontrol: [],
      KullaniciDetayUserIdUyeIdUpdate: 0,
      SendMailSettings: [],
      SendMail: false,
      UpdateUserStartingScreen: "",
      PowerUserOnay: 0,
      isComp54788Visible: "hidden",
      isComp155718Visible: "hidden",
      isComp241603Visible: "hidden",
      isComp335232Visible: "hidden",
      isComp389273Visible: "hidden",
      isComp483185Visible: "hidden",
      isComp221266Visible: "hidden",
      isComp482092Visible: "hidden",
      isComp547959Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("musterisahisbilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.MusteriSahisBilgileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("musterisahisbilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("musterisahisbilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MusteriSahisBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      musterisahisbilgileri_546825_value: this.state.UyelikFormuById?.at?.(0)?.tcKimlikNo ?? undefined,
      musterisahisbilgileri_547959_value: this.state.UyelikFormuById?.at?.(0)?.popSozlesmesiDosyaAd ?? undefined,
      musterisahisbilgileri_546287_value: this.state.UyelikFormuById?.at?.(0)?.cityID ?? undefined,
      musterisahisbilgileri_100145_value: this.state.UyelikFormuById?.at?.(0)?.districtID ?? undefined,
      musterisahisbilgileri_909820_value: this.state.UyelikFormuById?.at?.(0)?.adres ?? undefined,
      musterisahisbilgileri_614011_value: this.state.UyelikFormuById?.at?.(0)?.vergiDairesi ?? undefined,
      musterisahisbilgileri_952921_value: this.state.UyelikFormuById?.at?.(0)?.vergiKimlikNo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  MusteriSahisBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      prmID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.musterisahisbilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    stateVars.isComp54788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.powerUserOnay : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp241603Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp335232Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389273Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getGuid = result?.data.getGuid;
    stateVars.GenelParametrelerPDF = result?.data.genelParametrelerPDF;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.musterisahisbilgileri_546825_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.tcKimlikNo : null
    );
    formVars.musterisahisbilgileri_547959_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.musterisahisbilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.musterisahisbilgileri_546287_options = stateVars.SelectCityAll;
    formVars.musterisahisbilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.musterisahisbilgileri_100145_options = stateVars.SelectDistrictAll;
    formVars.musterisahisbilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.musterisahisbilgileri_614011_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.musterisahisbilgileri_952921_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    stateVars.isComp483185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectCityAll = result?.data.selectCityAll;

    formVars.musterisahisbilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.musterisahisbilgileri_546287_options = stateVars.SelectCityAll;
    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.musterisahisbilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.musterisahisbilgileri_100145_options = stateVars.SelectDistrictAll;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp547959Enabled", "disabled");
    stateVars.isComp54788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.powerUserOnay : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.musterisahisbilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    stateVars.isComp155718Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    formVars.musterisahisbilgileri_546825_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.tcKimlikNo : null
    );

    formVars.musterisahisbilgileri_547959_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    stateVars.isComp241603Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp335232Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389273Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    formVars.musterisahisbilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    stateVars.dataSource_546287 = this.state.SelectCityAll;
    stateVars.dataSource_546287 = this.state.SelectCityAll;
    formVars.musterisahisbilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    formVars.musterisahisbilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.musterisahisbilgileri_614011_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.musterisahisbilgileri_952921_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    stateVars.isComp483185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_234228 = this.state.UyelikFormuDuzeltmeNotuByUyeID;
    stateVars.isComp221266Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp482092Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriSahisBilgileri",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "12127",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriSahisBilgileri",
      "Profil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_155718_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_241603_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametrelerPDF?.length > 0
          ? this.state.GenelParametrelerPDF[0]?.ornekPOPSertifikaURLMusteriSahsi
          : null
      ),
      "MüşteriSozlesmesi.pdf"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_335232_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546825_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_546825_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_614011_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_614011_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_952921_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_952921_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      TCKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546825_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "musterisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_614011_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_952921_value", "value", "", "", "")
        ),
        "string"
      ),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_335232_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_335232_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_335232_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_335232_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_335232_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      POPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSozlesmesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_335232_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.POPSozlesmesiUpdate = result?.data.popSozlesmesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_335232_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_335232_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "musterisahisbilgileri_547959_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "856482b0_9415_c57d_f41a_81a710d59f06_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_497675_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_497675_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_497675_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_497675_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsPOPSozlesme",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriSahisBilgileri",
      "PDFViewerFormsPOPSozlesme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "591046",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_389273_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_389273_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.POPSozlesmesiById = result?.data.popSozlesmesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_389273_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_389273_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.POPSozlesmesiById?.length > 0 ? this.state.POPSozlesmesiById[0]?.popSozlesmesi : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.POPSozlesmesiById?.length > 0 ? this.state.POPSozlesmesiById[0]?.popSozlesmesiDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_546287_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_546287_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.musterisahisbilgileri_100145_options = stateVars.SelectDistrictAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_546287_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_546287_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_281261_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_455124_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_455124_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_455124_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_281261_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_281261_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_281261_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "musterisahisbilgileri_455124_value", "", null);

    stateVars.dataSource_234228 = this.state.UyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_213001_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_547959_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_547959_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546825_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_546825_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_614011_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_614011_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_952921_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_952921_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_547959_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm3_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm4_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm5_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      TCKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546825_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "musterisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      BasvuruTarihi_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_614011_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_952921_value", "value", "", "", "")
        ),
        "string"
      ),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_213001_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_213001_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_213001_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "dcd51e3f_4948_c529_2187_7c7c0879e355_notify",
        this.defaultML,
        "Form Taslak Olarak Kayıt Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_887191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_547959_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_547959_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546825_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_546825_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "musterisahisbilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_614011_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_614011_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_952921_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "musterisahisbilgileri_952921_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_547959_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm3_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm4_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm5_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      TCKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546825_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "musterisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      BasvuruTarihi_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_614011_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterisahisbilgileri_952921_value", "value", "", "", "")
        ),
        "string"
      ),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(-1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_887191_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_887191_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_887191_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1fe66355_c978_f27a_744f_fb0a151c010c_notify",
        this.defaultML,
        "Form Kaydı Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_887191_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_887191_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronayinagonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("9859035e-d0f0-48b5-997e-892a19c42cc1", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDF?.length > 0 ? this.state.GenelParametrelerPDF[0]?.powerUserMail : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDF?.length > 0 ? this.state.GenelParametrelerPDF[0]?.destekTelNo : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.firstName : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_887191_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_887191_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_887191_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.getMyUsername(),
      "971aaf56-0abd-4ba8-9418-d8757e306467"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_887191_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_887191_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_887191_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_887191_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_887191_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriSahisBilgileri",
      "MusteriBilgileriKontrol",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_301668_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "001cc7bb_0fc0_c27e_a145_c76c49b79425_confirmation",
        this.defaultML,
        "Üyelik Düzeltme İçin Geri Gönderilecek, Açıklma Girildi ise İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_301668_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriSahisBilgileriComponent_301668_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_301668_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
      ),
      "56d0a434-2975-4a71-913c-2623c157b257"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "51878ced_4bfc_96e0_0781_b3e7ee3a4db4_notify",
        this.defaultML,
        "Üyelik Bilgileri Düzeltme İçin Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_301668_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_301668_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("powerusergerigonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("2f71eae3-f529-4bc4-b700-59e0a52004b6", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDF?.length > 0 ? this.state.GenelParametrelerPDF[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.fullName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_301668_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_301668_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_301668_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_488836_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f2220eeb_62b7_2627_418d_1f733d30eab3_confirmation",
        this.defaultML,
        "Üyelik Red Edilecektir; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_488836_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriSahisBilgileriComponent_488836_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_488836_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fa911d44_ffec_43ad_fd7d_ed423ac81952_notify",
        this.defaultML,
        "Form Red Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_488836_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_488836_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruserred", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("4239c471-f052-4cd0-ae85-0e4ad91e9471", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDF?.length > 0 ? this.state.GenelParametrelerPDF[0]?.destekTelNo : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_488836_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_488836_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_488836_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriSahisBilgileriComponent_255926_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b86e403a_9a2f_057a_d785_324bffd8de41_confirmation",
        this.defaultML,
        "Üyelik Onaylanacaktır; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_255926_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriSahisBilgileriComponent_255926_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_255926_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
      ),
      "0c5ab6cd-0097-4f2a-8674-d3f48f6280bd"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "05ea5688_2df9_3de7_3d5a_0f4ba1c72c07_notify",
        this.defaultML,
        "Form Onaylandı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_255926_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_255926_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("ee24e6c3-69ce-47a9-9dad-dff5e55542d0", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDF?.length > 0 ? this.state.GenelParametrelerPDF[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.firstName : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MusteriSahisBilgileri/MusteriSahisBilgileriComponent_255926_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriSahisBilgileriComponent_255926_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriSahisBilgileriComponent_255926_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [12127, , 591046] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MusteriSahisBilgileriPageInit();
    }
  }
}
