import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { PDFViewerInfo_Screen } from "../screens/pdfviewerinfo/pdfviewerinfo";
import { BandrolYazisi_Screen } from "../screens/bandrolyazisi/bandrolyazisi";
import { TeklifVermeList_Screen } from "../screens/teklifvermelist/teklifvermelist";
import { TSiparisKart_Screen } from "../screens/tsipariskart/tsipariskart";
import { SiparisTeslimListMatbaa_Screen } from "../screens/siparisteslimlistmatbaa/siparisteslimlistmatbaa";
import { DashBoardYazar_Screen } from "../screens/dashboardyazar/dashboardyazar";
import { BandrolBilgileri_Screen } from "../screens/bandrolbilgileri/bandrolbilgileri";
import { MusteriOnay_Screen } from "../screens/musterionay/musterionay";
import { HizliTeklifList_Screen } from "../screens/hizlitekliflist/hizlitekliflist";
import { EserDetayIcBaskiKagitCinsi_List_Screen } from "../screens/eserdetayicbaskikagitcinsi_list/eserdetayicbaskikagitcinsi_list";
import { TedarikciBilgileriKontrol_Screen } from "../screens/tedarikcibilgilerikontrol/tedarikcibilgilerikontrol";
import { YayinciBilgileri_Screen } from "../screens/yayincibilgileri/yayincibilgileri";
import { EserDetayCiltBicimi_List_Screen } from "../screens/eserdetayciltbicimi_list/eserdetayciltbicimi_list";
import { AltRol_List_Screen } from "../screens/altrol_list/altrol_list";
import { AltKullaniciEkle_Screen } from "../screens/altkullaniciekle/altkullaniciekle";
import { EserBilgileri_Screen } from "../screens/eserbilgileri/eserbilgileri";
import { DashBoardTedarikci_Screen } from "../screens/dashboardtedarikci/dashboardtedarikci";
import { MusteriSahisBilgileri_Screen } from "../screens/musterisahisbilgileri/musterisahisbilgileri";
import { DashBoardMatbaa_Screen } from "../screens/dashboardmatbaa/dashboardmatbaa";
import { FormGoster_Screen } from "../screens/formgoster/formgoster";
import { EseriOnaylananTeklifList_Screen } from "../screens/eserionaylanantekliflist/eserionaylanantekliflist";
import { EserDetayIcBaskiRenk_List_Form_Screen } from "../screens/eserdetayicbaskirenk_list_form/eserdetayicbaskirenk_list_form";
import { DashBoardPowerUser_Screen } from "../screens/dashboardpoweruser/dashboardpoweruser";
import { Signup_Screen } from "../screens/signup/signup";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { UretimDetayKart_Screen } from "../screens/uretimdetaykart/uretimdetaykart";
import { EserDetayCiltBicimi_Form_Screen } from "../screens/eserdetayciltbicimi_form/eserdetayciltbicimi_form";
import { BaskiOncesiListMatbaa_Screen } from "../screens/baskioncesilistmatbaa/baskioncesilistmatbaa";
import { HizliTeklifAl_Screen } from "../screens/hizliteklifal/hizliteklifal";
import { LogList_Screen } from "../screens/loglist/loglist";
import { YazarBilgileri_Screen } from "../screens/yazarbilgileri/yazarbilgileri";
import { SignupAdminChange_Screen } from "../screens/signupadminchange/signupadminchange";
import { MenuYetki_Screen } from "../screens/menuyetki/menuyetki";
import { ParaBirim_List_Screen } from "../screens/parabirim_list/parabirim_list";
import { UretimListMatbaa_Screen } from "../screens/uretimlistmatbaa/uretimlistmatbaa";
import { TeklifTalepList_Screen } from "../screens/tekliftaleplist/tekliftaleplist";
import { SikayetList_Screen } from "../screens/sikayetlist/sikayetlist";
import { TeklifAlmaDetay_Screen } from "../screens/teklifalmadetay/teklifalmadetay";
import { EserDetayIcBaskiKagitGramaj_List_Screen } from "../screens/eserdetayicbaskikagitgramaj_list/eserdetayicbaskikagitgramaj_list";
import { SikayetKart_Screen } from "../screens/sikayetkart/sikayetkart";
import { PDFViewerFormsImza_Screen } from "../screens/pdfviewerformsimza/pdfviewerformsimza";
import { SiparisList_Screen } from "../screens/siparislist/siparislist";
import { SSSForm_Screen } from "../screens/sssform/sssform";
import { EserDetayKapakCinsi_List_Screen } from "../screens/eserdetaykapakcinsi_list/eserdetaykapakcinsi_list";
import { PDFViewerFormsVergiLevhasi_Screen } from "../screens/pdfviewerformsvergilevhasi/pdfviewerformsvergilevhasi";
import { TeklifVermeKart_Screen } from "../screens/teklifvermekart/teklifvermekart";
import { OneriSikayetKategori_List_Screen } from "../screens/onerisikayetkategori_list/onerisikayetkategori_list";
import { Signin_Screen } from "../screens/signin/signin";
import { SiparisTeslimList_Screen } from "../screens/siparisteslimlist/siparisteslimlist";
import { BandrolStokHareket_Screen } from "../screens/bandrolstokhareket/bandrolstokhareket";
import { GonderilenTeklifDetay_Screen } from "../screens/gonderilenteklifdetay/gonderilenteklifdetay";
import { DogrulamaKodu_Screen } from "../screens/dogrulamakodu/dogrulamakodu";
import { TeklifOnayPopUp_Screen } from "../screens/teklifonaypopup/teklifonaypopup";
import { BilgiKontrol_Screen } from "../screens/bilgikontrol/bilgikontrol";
import { PDFViewerFormsTicariSicilGazete_Screen } from "../screens/pdfviewerformsticarisicilgazete/pdfviewerformsticarisicilgazete";
import { BandrolTakipList_Screen } from "../screens/bandroltakiplist/bandroltakiplist";
import { PreviewPDF_Screen } from "../screens/previewpdf/previewpdf";
import { EserDetayKapakGramaj_List_Screen } from "../screens/eserdetaykapakgramaj_list/eserdetaykapakgramaj_list";
import { PDFViewerFormsYayinciSertifikasi_Screen } from "../screens/pdfviewerformsyayincisertifikasi/pdfviewerformsyayincisertifikasi";
import { VerilenTeklifKarsilastirma_Screen } from "../screens/verilenteklifkarsilastirma/verilenteklifkarsilastirma";
import { AltKullaniciList_Screen } from "../screens/altkullanicilist/altkullanicilist";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { KullanilmiyorSuresizYayin_Screen } from "../screens/kullanilmiyorsuresizyayin/kullanilmiyorsuresizyayin";
import { TeslimTuru_Form_Screen } from "../screens/teslimturu_form/teslimturu_form";
import { GonderilenTeklifList_Screen } from "../screens/gonderilentekliflist/gonderilentekliflist";
import { EserDetayIcBaskiKagitCinsi_List_Form_Screen } from "../screens/eserdetayicbaskikagitcinsi_list_form/eserdetayicbaskikagitcinsi_list_form";
import { YayinciBilgileriKontrol_Screen } from "../screens/yayincibilgilerikontrol/yayincibilgilerikontrol";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { MatbaaBilgileri_Screen } from "../screens/matbaabilgileri/matbaabilgileri";
import { PopupBandrolYapistirma_Screen } from "../screens/popupbandrolyapistirma/popupbandrolyapistirma";
import { HizliTeklifGiris_Screen } from "../screens/hizliteklifgiris/hizliteklifgiris";
import { PowerUserUyeList_Screen } from "../screens/poweruseruyelist/poweruseruyelist";
import { SSS_Screen } from "../screens/sss/sss";
import { Test_Screen } from "../screens/test/test";
import { AltRol_Form_Screen } from "../screens/altrol_form/altrol_form";
import { SiparisListMatbaa_Screen } from "../screens/siparislistmatbaa/siparislistmatbaa";
import { EserDetaySelefon_Form_Screen } from "../screens/eserdetayselefon_form/eserdetayselefon_form";
import { TeklifVerme_Screen } from "../screens/teklifverme/teklifverme";
import { MatbaaBilgileriKontrol_Screen } from "../screens/matbaabilgilerikontrol/matbaabilgilerikontrol";
import { SifreDegistir_Screen } from "../screens/sifredegistir/sifredegistir";
import { TSiparisListMatbaa_Screen } from "../screens/tsiparislistmatbaa/tsiparislistmatbaa";
import { KullaniciBilgiDegisikligi_Screen } from "../screens/kullanicibilgidegisikligi/kullanicibilgidegisikligi";
import { TedarikciSahisBilgileri_Screen } from "../screens/tedarikcisahisbilgileri/tedarikcisahisbilgileri";
import { EserDetayKapakGramaj_Form_Screen } from "../screens/eserdetaykapakgramaj_form/eserdetaykapakgramaj_form";
import { ParaBirim_Form_Screen } from "../screens/parabirim_form/parabirim_form";
import { MusteriTicariBilgileri_Screen } from "../screens/musteriticaribilgileri/musteriticaribilgileri";
import { OneriSikayetKategori_Form_Screen } from "../screens/onerisikayetkategori_form/onerisikayetkategori_form";
import { GenelParametreKart_Screen } from "../screens/genelparametrekart/genelparametrekart";
import { EserBilgileriList_Screen } from "../screens/eserbilgilerilist/eserbilgilerilist";
import { EserDetayKapakCinsi_Form_Screen } from "../screens/eserdetaykapakcinsi_form/eserdetaykapakcinsi_form";
import { HizliTeklifKarsilastirma_Screen } from "../screens/hizliteklifkarsilastirma/hizliteklifkarsilastirma";
import { EseriOnaylananTeklifListMatbaa_Screen } from "../screens/eserionaylanantekliflistmatbaa/eserionaylanantekliflistmatbaa";
import { TeklifTalepKart_Screen } from "../screens/tekliftalepkart/tekliftalepkart";
import { EserDetayKitapBoyut_List_Screen } from "../screens/eserdetaykitapboyut_list/eserdetaykitapboyut_list";
import { YazarBilgileriKontrol_Screen } from "../screens/yazarbilgilerikontrol/yazarbilgilerikontrol";
import { DashBoardMusteri_Screen } from "../screens/dashboardmusteri/dashboardmusteri";
import { MOnayBekleyenSiparisList_Screen } from "../screens/monaybekleyensiparislist/monaybekleyensiparislist";
import { MusteriBilgileriKontrol_Screen } from "../screens/musteribilgilerikontrol/musteribilgilerikontrol";
import { SiparisTeslimDetayKart_Screen } from "../screens/siparisteslimdetaykart/siparisteslimdetaykart";
import { Profil_Screen } from "../screens/profil/profil";
import { PDFViewerFormsPOPSozlesme_Screen } from "../screens/pdfviewerformspopsozlesme/pdfviewerformspopsozlesme";
import { ProfilEdit_Screen } from "../screens/profiledit/profiledit";
import { KabulEdilenTeklifList_Screen } from "../screens/kabuledilentekliflist/kabuledilentekliflist";
import { EserDetaySelefon_List_Screen } from "../screens/eserdetayselefon_list/eserdetayselefon_list";
import { TeslimTuru_List_Screen } from "../screens/teslimturu_list/teslimturu_list";
import { TedarikciTicariBilgileri_Screen } from "../screens/tedarikciticaribilgileri/tedarikciticaribilgileri";
import { PDFViewer_Screen } from "../screens/pdfviewer/pdfviewer";
import { DashBoardYayinci_Screen } from "../screens/dashboardyayinci/dashboardyayinci";
import { EserDetayIcBaskiKagitGramaj_Form_Screen } from "../screens/eserdetayicbaskikagitgramaj_form/eserdetayicbaskikagitgramaj_form";
import { UretimList_Screen } from "../screens/uretimlist/uretimlist";
import { BandrolTakipListMatbaa_Screen } from "../screens/bandroltakiplistmatbaa/bandroltakiplistmatbaa";
import { EserDetayIcBaskiRenk_List_Screen } from "../screens/eserdetayicbaskirenk_list/eserdetayicbaskirenk_list";
import { EserBilgileriKontrol_Screen } from "../screens/eserbilgilerikontrol/eserbilgilerikontrol";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { PowerUserEserList_Screen } from "../screens/powerusereserlist/powerusereserlist";
import { DashBoardPOP_Screen } from "../screens/dashboardpop/dashboardpop";
import { EserDetayKitapBoyut_Form_Screen } from "../screens/eserdetaykitapboyut_form/eserdetaykitapboyut_form";
import { BaskiOncesiList_Screen } from "../screens/baskioncesilist/baskioncesilist";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={DashBoardMatbaa_Screen} />
                        <Route exact path="/pdfviewerinfo" component={PDFViewerInfo_Screen} />
            <PrivateRoute exact path="/bandrolyazisi" component={BandrolYazisi_Screen} />
            <PrivateRoute exact path="/teklifvermelist" component={TeklifVermeList_Screen} />
            <PrivateRoute exact path="/tsipariskart" component={TSiparisKart_Screen} />
            <PrivateRoute exact path="/siparisteslimlistmatbaa" component={SiparisTeslimListMatbaa_Screen} />
            <PrivateRoute exact path="/dashboardyazar" component={DashBoardYazar_Screen} />
            <PrivateRoute exact path="/bandrolbilgileri" component={BandrolBilgileri_Screen} />
            <PrivateRoute exact path="/musterionay" component={MusteriOnay_Screen} />
            <PrivateRoute exact path="/hizlitekliflist" component={HizliTeklifList_Screen} />
            <PrivateRoute exact path="/eserdetayicbaskikagitcinsi_list" component={EserDetayIcBaskiKagitCinsi_List_Screen} />
            <PrivateRoute exact path="/tedarikcibilgilerikontrol" component={TedarikciBilgileriKontrol_Screen} />
            <PrivateRoute exact path="/yayincibilgileri" component={YayinciBilgileri_Screen} />
            <PrivateRoute exact path="/eserdetayciltbicimi_list" component={EserDetayCiltBicimi_List_Screen} />
            <PrivateRoute exact path="/altrol_list" component={AltRol_List_Screen} />
            <PrivateRoute exact path="/altkullaniciekle" component={AltKullaniciEkle_Screen} />
            <PrivateRoute exact path="/eserbilgileri" component={EserBilgileri_Screen} />
            <PrivateRoute exact path="/dashboardtedarikci" component={DashBoardTedarikci_Screen} />
            <PrivateRoute exact path="/musterisahisbilgileri" component={MusteriSahisBilgileri_Screen} />
            <PrivateRoute exact path="/dashboardmatbaa" component={DashBoardMatbaa_Screen} />
            <PrivateRoute exact path="/formgoster" component={FormGoster_Screen} />
            <PrivateRoute exact path="/eserionaylanantekliflist" component={EseriOnaylananTeklifList_Screen} />
            <PrivateRoute exact path="/eserdetayicbaskirenk_list_form" component={EserDetayIcBaskiRenk_List_Form_Screen} />
            <PrivateRoute exact path="/dashboardpoweruser" component={DashBoardPowerUser_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/uretimdetaykart" component={UretimDetayKart_Screen} />
            <PrivateRoute exact path="/eserdetayciltbicimi_form" component={EserDetayCiltBicimi_Form_Screen} />
            <PrivateRoute exact path="/baskioncesilistmatbaa" component={BaskiOncesiListMatbaa_Screen} />
            <PrivateRoute exact path="/hizliteklifal" component={HizliTeklifAl_Screen} />
            <PrivateRoute exact path="/loglist" component={LogList_Screen} />
            <PrivateRoute exact path="/yazarbilgileri" component={YazarBilgileri_Screen} />
            <Route exact path="/signupadminchange" component={SignupAdminChange_Screen} />
            <PrivateRoute exact path="/menuyetki" component={MenuYetki_Screen} />
            <PrivateRoute exact path="/parabirim_list" component={ParaBirim_List_Screen} />
            <PrivateRoute exact path="/uretimlistmatbaa" component={UretimListMatbaa_Screen} />
            <PrivateRoute exact path="/tekliftaleplist" component={TeklifTalepList_Screen} />
            <PrivateRoute exact path="/sikayetlist" component={SikayetList_Screen} />
            <PrivateRoute exact path="/teklifalmadetay" component={TeklifAlmaDetay_Screen} />
            <PrivateRoute exact path="/eserdetayicbaskikagitgramaj_list" component={EserDetayIcBaskiKagitGramaj_List_Screen} />
            <PrivateRoute exact path="/sikayetkart" component={SikayetKart_Screen} />
            <Route exact path="/pdfviewerformsimza" component={PDFViewerFormsImza_Screen} />
            <PrivateRoute exact path="/siparislist" component={SiparisList_Screen} />
            <PrivateRoute exact path="/sssform" component={SSSForm_Screen} />
            <PrivateRoute exact path="/eserdetaykapakcinsi_list" component={EserDetayKapakCinsi_List_Screen} />
            <Route exact path="/pdfviewerformsvergilevhasi" component={PDFViewerFormsVergiLevhasi_Screen} />
            <PrivateRoute exact path="/teklifvermekart" component={TeklifVermeKart_Screen} />
            <PrivateRoute exact path="/onerisikayetkategori_list" component={OneriSikayetKategori_List_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/siparisteslimlist" component={SiparisTeslimList_Screen} />
            <PrivateRoute exact path="/bandrolstokhareket" component={BandrolStokHareket_Screen} />
            <PrivateRoute exact path="/gonderilenteklifdetay" component={GonderilenTeklifDetay_Screen} />
            <Route exact path="/dogrulamakodu" component={DogrulamaKodu_Screen} />
            <PrivateRoute exact path="/teklifonaypopup" component={TeklifOnayPopUp_Screen} />
            <PrivateRoute exact path="/bilgikontrol" component={BilgiKontrol_Screen} />
            <Route exact path="/pdfviewerformsticarisicilgazete" component={PDFViewerFormsTicariSicilGazete_Screen} />
            <PrivateRoute exact path="/bandroltakiplist" component={BandrolTakipList_Screen} />
            <PrivateRoute exact path="/previewpdf" component={PreviewPDF_Screen} />
            <PrivateRoute exact path="/eserdetaykapakgramaj_list" component={EserDetayKapakGramaj_List_Screen} />
            <Route exact path="/pdfviewerformsyayincisertifikasi" component={PDFViewerFormsYayinciSertifikasi_Screen} />
            <PrivateRoute exact path="/verilenteklifkarsilastirma" component={VerilenTeklifKarsilastirma_Screen} />
            <PrivateRoute exact path="/altkullanicilist" component={AltKullaniciList_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/kullanilmiyorsuresizyayin" component={KullanilmiyorSuresizYayin_Screen} />
            <PrivateRoute exact path="/teslimturu_form" component={TeslimTuru_Form_Screen} />
            <PrivateRoute exact path="/gonderilentekliflist" component={GonderilenTeklifList_Screen} />
            <PrivateRoute exact path="/eserdetayicbaskikagitcinsi_list_form" component={EserDetayIcBaskiKagitCinsi_List_Form_Screen} />
            <PrivateRoute exact path="/yayincibilgilerikontrol" component={YayinciBilgileriKontrol_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/matbaabilgileri" component={MatbaaBilgileri_Screen} />
            <PrivateRoute exact path="/popupbandrolyapistirma" component={PopupBandrolYapistirma_Screen} />
            <PrivateRoute exact path="/hizliteklifgiris" component={HizliTeklifGiris_Screen} />
            <PrivateRoute exact path="/poweruseruyelist" component={PowerUserUyeList_Screen} />
            <PrivateRoute exact path="/sss" component={SSS_Screen} />
            <PrivateRoute exact path="/test" component={Test_Screen} />
            <PrivateRoute exact path="/altrol_form" component={AltRol_Form_Screen} />
            <PrivateRoute exact path="/siparislistmatbaa" component={SiparisListMatbaa_Screen} />
            <PrivateRoute exact path="/eserdetayselefon_form" component={EserDetaySelefon_Form_Screen} />
            <PrivateRoute exact path="/teklifverme" component={TeklifVerme_Screen} />
            <PrivateRoute exact path="/matbaabilgilerikontrol" component={MatbaaBilgileriKontrol_Screen} />
            <PrivateRoute exact path="/sifredegistir" component={SifreDegistir_Screen} />
            <PrivateRoute exact path="/tsiparislistmatbaa" component={TSiparisListMatbaa_Screen} />
            <PrivateRoute exact path="/kullanicibilgidegisikligi" component={KullaniciBilgiDegisikligi_Screen} />
            <PrivateRoute exact path="/tedarikcisahisbilgileri" component={TedarikciSahisBilgileri_Screen} />
            <PrivateRoute exact path="/eserdetaykapakgramaj_form" component={EserDetayKapakGramaj_Form_Screen} />
            <PrivateRoute exact path="/parabirim_form" component={ParaBirim_Form_Screen} />
            <PrivateRoute exact path="/musteriticaribilgileri" component={MusteriTicariBilgileri_Screen} />
            <PrivateRoute exact path="/onerisikayetkategori_form" component={OneriSikayetKategori_Form_Screen} />
            <PrivateRoute exact path="/genelparametrekart" component={GenelParametreKart_Screen} />
            <PrivateRoute exact path="/eserbilgilerilist" component={EserBilgileriList_Screen} />
            <PrivateRoute exact path="/eserdetaykapakcinsi_form" component={EserDetayKapakCinsi_Form_Screen} />
            <PrivateRoute exact path="/hizliteklifkarsilastirma" component={HizliTeklifKarsilastirma_Screen} />
            <PrivateRoute exact path="/eserionaylanantekliflistmatbaa" component={EseriOnaylananTeklifListMatbaa_Screen} />
            <PrivateRoute exact path="/tekliftalepkart" component={TeklifTalepKart_Screen} />
            <PrivateRoute exact path="/eserdetaykitapboyut_list" component={EserDetayKitapBoyut_List_Screen} />
            <PrivateRoute exact path="/yazarbilgilerikontrol" component={YazarBilgileriKontrol_Screen} />
            <PrivateRoute exact path="/dashboardmusteri" component={DashBoardMusteri_Screen} />
            <PrivateRoute exact path="/monaybekleyensiparislist" component={MOnayBekleyenSiparisList_Screen} />
            <PrivateRoute exact path="/musteribilgilerikontrol" component={MusteriBilgileriKontrol_Screen} />
            <PrivateRoute exact path="/siparisteslimdetaykart" component={SiparisTeslimDetayKart_Screen} />
            <PrivateRoute exact path="/profil" component={Profil_Screen} />
            <Route exact path="/pdfviewerformspopsozlesme" component={PDFViewerFormsPOPSozlesme_Screen} />
            <PrivateRoute exact path="/profiledit" component={ProfilEdit_Screen} />
            <PrivateRoute exact path="/kabuledilentekliflist" component={KabulEdilenTeklifList_Screen} />
            <PrivateRoute exact path="/eserdetayselefon_list" component={EserDetaySelefon_List_Screen} />
            <PrivateRoute exact path="/teslimturu_list" component={TeslimTuru_List_Screen} />
            <PrivateRoute exact path="/tedarikciticaribilgileri" component={TedarikciTicariBilgileri_Screen} />
            <Route exact path="/pdfviewer" component={PDFViewer_Screen} />
            <PrivateRoute exact path="/dashboardyayinci" component={DashBoardYayinci_Screen} />
            <PrivateRoute exact path="/eserdetayicbaskikagitgramaj_form" component={EserDetayIcBaskiKagitGramaj_Form_Screen} />
            <PrivateRoute exact path="/uretimlist" component={UretimList_Screen} />
            <PrivateRoute exact path="/bandroltakiplistmatbaa" component={BandrolTakipListMatbaa_Screen} />
            <PrivateRoute exact path="/eserdetayicbaskirenk_list" component={EserDetayIcBaskiRenk_List_Screen} />
            <PrivateRoute exact path="/eserbilgilerikontrol" component={EserBilgileriKontrol_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/powerusereserlist" component={PowerUserEserList_Screen} />
            <PrivateRoute exact path="/dashboardpop" component={DashBoardPOP_Screen} />
            <PrivateRoute exact path="/eserdetaykitapboyut_form" component={EserDetayKitapBoyut_Form_Screen} />
            <PrivateRoute exact path="/baskioncesilist" component={BaskiOncesiList_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
