import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPagination,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  Switch,
  TextInput,
  Tooltip,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { TeklifVermeList_ScreenBase } from "./teklifvermelist-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { Profil_Screen } from "../profil/profil";
import { TeklifTalepKart_Screen } from "../tekliftalepkart/tekliftalepkart";
import { TeklifVermeKart_Screen } from "../teklifvermekart/teklifvermekart";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class TeklifVermeList_Screen extends TeklifVermeList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              teklifvermelist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
              teklifvermelist_944312_value: "TeklifNo",
              teklifvermelist_504983_value: "ASC",
              teklifvermelist_315662_value: undefined,
              teklifvermelist_111137_value: "a3f966ea-c83a-477f-d1c3-08dc8c6ad3d3",
              teklifvermelist_583680_value: "TeklifNo",
              teklifvermelist_777486_value: "ASC",
              teklifvermelist_647624_value: undefined,
              teklifvermelist_990428_value: "a3f966ea-c83a-477f-d1c3-08dc8c6ad3d3",
              teklifvermelist_599014_value: false,
              teklifvermelist_917077_value: "TeklifNo",
              teklifvermelist_417300_value: "ASC",
              teklifvermelist_642213_value: undefined,
              teklifvermelist_638173_value: "a3f966ea-c83a-477f-d1c3-08dc8c6ad3d3",
              teklifvermelist_621353_value: "TeklifNo",
              teklifvermelist_336778_value: "ASC",
              teklifvermelist_246757_value: undefined,
              teklifvermelist_294168_value: "a3f966ea-c83a-477f-d1c3-08dc8c6ad3d3"
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="teklifvermelist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KRow
                  id="684466"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="101364"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="872324"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="54788"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.TeklifVermeListComponent_54788_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderTopLeftRadius: 200,
                            borderTopRightRadius: 200,
                            borderBottomRightRadius: 200,
                            borderBottomLeftRadius: 200,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "24px",
                            color: "rgba(2, 145, 201, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="964782"
                        value={ReactSystemFunctions.translate(this.ml, 964782, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "18px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="123219"
                    xs={16}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 0,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KContentMenu
                      id="759726"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KContentMenuPanel
                        id="708484"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="825746"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="teklifvermelist_801926_value">
                            <Image
                              id="801926"
                              zoomOnClick={false}
                              imageFit="fill"
                              style={
                                {
                                  borderTopLeftRadius: 200,
                                  borderTopRightRadius: 200,
                                  borderBottomRightRadius: 200,
                                  borderBottomLeftRadius: 200,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  width: "42px",
                                  height: "42px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Image>
                          </Form.Item>

                          <VerticalStack
                            id="135461"
                            direction="vertical"
                            size={1}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="172357"
                              value={this.state.MyContext?.at?.(0)?.fullName ?? undefined}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "15px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="420731"
                              value={this.state.MyContext?.at?.(0)?.uyeAd ?? undefined}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(140, 140, 140, 1)"
                                } as any
                              }
                            ></Label>
                          </VerticalStack>

                          <Icon
                            id="946607"
                            iconName="arrow_drop_down"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(2, 145, 201, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KContentMenuPanel>

                      <KContentMenuItem
                        id="412428"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.TeklifVermeListComponent_412428_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 412428, "label", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      ></KContentMenuItem>

                      <KContentMenuItem
                        id="668195"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.TeklifVermeListComponent_668195_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 668195, "label", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(245, 34, 45, 1)"
                          } as any
                        }
                      ></KContentMenuItem>
                    </KContentMenu>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="teklifvermelist_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="736978"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 64,
                      paddingBottom: 8,
                      paddingLeft: 64,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="54434"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KPanel
                      id="675949"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          textAlign: "-webkit-center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="607421"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="386864"
                          xs={20}
                          sm={20}
                          md={20}
                          lg={20}
                          xl={20}
                          xxl={20}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="328728"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 0,
                                paddingBottom: 8,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="492313"
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPanel
                                id="122811"
                                scrolling="hidden"
                                scrollRadius={false}
                                dynamicBackGroundColor={this.state.SetTabColor?.at?.(0)?.tab1Color ?? undefined}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 64,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 3,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 3,
                                    height: "42px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Button
                                  id="926552"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_926552_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 926552, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>
                              </KPanel>
                            </KCol>

                            <KCol
                              id="62271"
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPanel
                                id="147704"
                                scrolling="hidden"
                                scrollRadius={false}
                                dynamicBackGroundColor={this.state.SetTabColor?.at?.(0)?.tab2Color ?? undefined}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 64,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 3,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 3,
                                    height: "42px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Button
                                  id="949824"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_949824_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 949824, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>
                              </KPanel>
                            </KCol>

                            <KCol
                              id="462895"
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPanel
                                id="247521"
                                scrolling="hidden"
                                scrollRadius={false}
                                dynamicBackGroundColor={this.state.SetTabColor?.at?.(0)?.tab3Color ?? undefined}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 64,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 3,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 3,
                                    height: "42px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Button
                                  id="872566"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_872566_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 872566, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>
                              </KPanel>
                            </KCol>

                            <KCol
                              id="261430"
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPanel
                                id="331997"
                                scrolling="hidden"
                                scrollRadius={false}
                                dynamicBackGroundColor={this.state.SetTabColor?.at?.(0)?.tab4Color ?? undefined}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 64,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 3,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 3,
                                    height: "42px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Button
                                  id="3299"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_3299_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 3299, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>
                              </KPanel>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="143082"
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="189349"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Button
                              id="209835"
                              visibility={this.state.isComp209835Visible}
                              label={ReactSystemFunctions.translate(this.ml, 209835, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(2, 145, 201, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </HorizontalStack>
                        </KCol>
                      </KRow>

                      <KRow
                        id="182116"
                        visibility={this.state.isComp182116Visible}
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="705426"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="807851"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="14613"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="671072"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="773396"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="teklifvermelist_944312_value">
                                  <KSelectBox
                                    id="944312"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_944312_onChange();
                                    }}
                                    kuikaRef={this.teklifvermelist_944312_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_944312}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      944312,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Form.Item className="kFormItem" name="teklifvermelist_504983_value">
                                  <KSelectBox
                                    id="504983"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_504983_onChange();
                                    }}
                                    kuikaRef={this.teklifvermelist_504983_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_504983}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      504983,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Button
                                  id="67676"
                                  label={ReactSystemFunctions.translate(this.ml, 67676, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      width: "100px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_315662_value">
                                  <TextInput
                                    id="315662"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      315662,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100%",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>

                                <Button
                                  id="949676"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_949676_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 949676, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  icon="search"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(2, 145, 201, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_111137_value">
                                  <Image
                                    id="111137"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_111137_onClick();
                                    }}
                                    showCursorPointer
                                    zoomOnClick={false}
                                    imageFit="fit"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        height: "40px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </HorizontalStack>
                            </KCol>
                          </KRow>

                          <KTable
                            id="867802"
                            kuikaRef={this.teklifvermelist_867802_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.TeklifTaslakList}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              867802,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            pagination={false}
                            striped={false}
                            stripedColor="#F5F7FA"
                            insertRowActive={false}
                            searchable={false}
                            filtering={false}
                            transformedOnMobileResolution={false}
                            tableWidthMode="fit"
                            columnChooser={false}
                            insertRowPosition="top"
                            pageSize={10}
                            showSizeChanger={false}
                            multiSelect={false}
                            fixedHeader={false}
                            editable={false}
                            globalSearch={false}
                            resizableColumns={false}
                            showNoDataFound={true}
                            export={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="469263"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(186, 187, 187, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="560620"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="836657"
                                title={ReactSystemFunctions.translate(this.ml, 836657, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="726791"
                                  visibility={this.state.isComp726791Visible}
                                  condition={(rowData) => ReactSystemFunctions.isEqualTo(rowData.hizliTeklif, "true")}
                                  iconName="done_outline"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(2, 145, 201, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="402853"
                                title={ReactSystemFunctions.translate(this.ml, 402853, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="967638"
                                  value="[datafield:talepTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="13258"
                                title={ReactSystemFunctions.translate(this.ml, 13258, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="397342"
                                  value="[datafield:talep_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="59154"
                                title={ReactSystemFunctions.translate(this.ml, 59154, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="998618"
                                  value="[datafield:teklif_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="614318"
                                title={ReactSystemFunctions.translate(this.ml, 614318, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="353810"
                                  value="[datafield:muhatap]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="848148"
                                title={ReactSystemFunctions.translate(this.ml, 848148, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="552503"
                                  value="[datafield:kitapBoyutTanim]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="527802"
                                title={ReactSystemFunctions.translate(this.ml, 527802, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="644573"
                                  value="[datafield:icBaskiKagitCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="8791"
                                title={ReactSystemFunctions.translate(this.ml, 8791, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="403746"
                                  value="[datafield:icBaskiKagitGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="321571"
                                title={ReactSystemFunctions.translate(this.ml, 321571, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="938671"
                                  value="[datafield:kapakCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="95231"
                                title={ReactSystemFunctions.translate(this.ml, 95231, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="204338"
                                  value="[datafield:kapakGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="416239"
                                title={ReactSystemFunctions.translate(this.ml, 416239, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="513278"
                                  value="[datafield:ciltBicimi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="487649"
                                title={ReactSystemFunctions.translate(this.ml, 487649, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="992755"
                                  value="[datafield:icBaskiRenk]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="578447"
                                title={ReactSystemFunctions.translate(this.ml, 578447, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="831414"
                                  value="[datafield:selefon]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="635670"
                                title={ReactSystemFunctions.translate(this.ml, 635670, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="327640"
                                  value="[datafield:sayfaSayisi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="686281"
                                title={ReactSystemFunctions.translate(this.ml, 686281, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="320205"
                                  value="[datafield:baskiAdet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="459624"
                                title={ReactSystemFunctions.translate(this.ml, 459624, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="560434"
                                  value="[datafield:nakliyeDurum]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="215549"
                                title={ReactSystemFunctions.translate(this.ml, 215549, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="956403"
                                  value="[datafield:teklifTarihi]"
                                  formatter=""
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="3870"
                                title={ReactSystemFunctions.translate(this.ml, 3870, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="635192"
                                  value="[datafield:teklifGecerlilikTarihi]"
                                  formatter=""
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="422297"
                                title={ReactSystemFunctions.translate(this.ml, 422297, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    minWidth: "136px",
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="671385"
                                  value="[datafield:teklifFiyat]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="884227"
                                title={ReactSystemFunctions.translate(this.ml, 884227, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="96854"
                                  value="[datafield:talepDurum]"
                                  style={
                                    {
                                      borderTopLeftRadius: 200,
                                      borderTopRightRadius: 200,
                                      borderBottomRightRadius: 200,
                                      borderBottomLeftRadius: 200,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(250, 219, 20, 0.15)",
                                      paddingTop: 4,
                                      paddingRight: 12,
                                      paddingBottom: 4,
                                      paddingLeft: 12,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(250, 173, 20, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="932759"
                                title={ReactSystemFunctions.translate(this.ml, 932759, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="219875"
                                  value="[datafield:teklifDurum]"
                                  style={
                                    {
                                      borderTopLeftRadius: 200,
                                      borderTopRightRadius: 200,
                                      borderBottomRightRadius: 200,
                                      borderBottomLeftRadius: 200,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(0, 167, 229, 0.14)",
                                      paddingTop: 4,
                                      paddingRight: 12,
                                      paddingBottom: 4,
                                      paddingLeft: 12,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(0, 167, 229, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="741365"
                                title={ReactSystemFunctions.translate(this.ml, 741365, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="530659"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_530659_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp530659Visible}
                                  iconName="library_books"
                                  hoverText="Talep Detayını İncele"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="808736"
                                visibility={this.state.isComp808736Visible}
                                condition={(rowData) =>
                                  ReactSystemFunctions.isNotEqualTo(
                                    this.state.RoleSadeceGoruntule[0]?.sg022?.toString(),
                                    "1"
                                  )
                                }
                                title={ReactSystemFunctions.translate(this.ml, 808736, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="238226"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="323542"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_323542_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp323542Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTeklifGirildi, "0")
                                    }
                                    iconName="edit_note"
                                    hoverText="Teklif Ver"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "32pt",
                                        color: "rgba(250, 173, 20, 1)"
                                      } as any
                                    }
                                  ></Icon>

                                  <Icon
                                    id="635971"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_635971_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp635971Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTeklifGirildi, "1")
                                    }
                                    iconName="playlist_play"
                                    hoverText="Teklifi İncele & Onaya Gönder"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "32pt",
                                        color: "rgba(160, 217, 17, 1)"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>

                          <KRow
                            id="426529"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="347590"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPagination
                                id="958426"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.TeklifVermeListComponent_958426_onChange();
                                }}
                                kuikaRef={this.teklifvermelist_958426_value_kuikaPaginationRef}
                                total={this.state.setPTotalCount01?.at?.(0)?.totalCount ?? undefined}
                                pageSize={20}
                                defaultCurrent={1}
                                current={1}
                                defaultPageSize={10}
                                disabled={false}
                                hideOnSinglePage={true}
                                showLessItems={false}
                                showQuickJumper={false}
                                showSizeChanger={true}
                                options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                showFirstPageButton={false}
                                showLastPageButton={false}
                                showNumbers={true}
                                showText={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-right"
                                  } as any
                                }
                              ></KPagination>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="370428"
                        visibility={this.state.isComp370428Visible}
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="874859"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="656874"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="809797"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="563848"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="188242"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="teklifvermelist_583680_value">
                                  <KSelectBox
                                    id="583680"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_583680_onChange();
                                    }}
                                    kuikaRef={this.teklifvermelist_583680_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_583680}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      583680,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Form.Item className="kFormItem" name="teklifvermelist_777486_value">
                                  <KSelectBox
                                    id="777486"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_777486_onChange();
                                    }}
                                    kuikaRef={this.teklifvermelist_777486_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_777486}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      777486,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Button
                                  id="28097"
                                  label={ReactSystemFunctions.translate(this.ml, 28097, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      width: "100px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_647624_value">
                                  <TextInput
                                    id="647624"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      647624,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100%",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>

                                <Button
                                  id="753523"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_753523_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 753523, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  icon="search"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(2, 145, 201, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_990428_value">
                                  <Image
                                    id="990428"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_990428_onClick();
                                    }}
                                    showCursorPointer
                                    zoomOnClick={false}
                                    imageFit="fit"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        height: "40px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </HorizontalStack>
                            </KCol>
                          </KRow>

                          <KTable
                            id="793930"
                            kuikaRef={this.teklifvermelist_793930_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.TeklifOnayList}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              793930,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            searchable={false}
                            sorter={false}
                            filtering={false}
                            pagination={false}
                            striped={false}
                            stripedColor="#F5F7FA"
                            insertRowActive={false}
                            transformedOnMobileResolution={false}
                            tableWidthMode="fit"
                            columnChooser={false}
                            insertRowPosition="top"
                            pageSize={10}
                            showSizeChanger={false}
                            multiSelect={false}
                            fixedHeader={false}
                            editable={false}
                            globalSearch={false}
                            resizableColumns={false}
                            showNoDataFound={true}
                            export={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="296062"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(186, 187, 187, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="713633"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="665918"
                                title={ReactSystemFunctions.translate(this.ml, 665918, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="130983"
                                  value="[datafield:teklifTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="525792"
                                title={ReactSystemFunctions.translate(this.ml, 525792, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="822345"
                                  value="[datafield:teklif_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="564406"
                                title={ReactSystemFunctions.translate(this.ml, 564406, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="875182"
                                  value="[datafield:talep_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="314831"
                                title={ReactSystemFunctions.translate(this.ml, 314831, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="868850"
                                  value="[datafield:muhatap]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="299148"
                                title={ReactSystemFunctions.translate(this.ml, 299148, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="51831"
                                  value="[datafield:kitapBoyutTanim]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="848561"
                                title={ReactSystemFunctions.translate(this.ml, 848561, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="428300"
                                  value="[datafield:icBaskiKagitCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="601822"
                                title={ReactSystemFunctions.translate(this.ml, 601822, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="50068"
                                  value="[datafield:icBaskiKagitGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="206745"
                                title={ReactSystemFunctions.translate(this.ml, 206745, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="638191"
                                  value="[datafield:kapakCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="147498"
                                title={ReactSystemFunctions.translate(this.ml, 147498, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="437469"
                                  value="[datafield:kapakGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="813107"
                                title={ReactSystemFunctions.translate(this.ml, 813107, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="991972"
                                  value="[datafield:ciltBicimi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="923035"
                                title={ReactSystemFunctions.translate(this.ml, 923035, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="341444"
                                  value="[datafield:icBaskiRenk]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="184276"
                                title={ReactSystemFunctions.translate(this.ml, 184276, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="989641"
                                  value="[datafield:selefon]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="879794"
                                title={ReactSystemFunctions.translate(this.ml, 879794, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="444957"
                                  value="[datafield:sayfaSayisi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="10838"
                                title={ReactSystemFunctions.translate(this.ml, 10838, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="302357"
                                  value="[datafield:baskiAdet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="564336"
                                title={ReactSystemFunctions.translate(this.ml, 564336, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="709086"
                                  value="[datafield:nakliyeDurum]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="43930"
                                title={ReactSystemFunctions.translate(this.ml, 43930, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="57316"
                                  value="[datafield:teklifTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="646528"
                                title={ReactSystemFunctions.translate(this.ml, 646528, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="661676"
                                  value="[datafield:teklifGecerlilikTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="387841"
                                title={ReactSystemFunctions.translate(this.ml, 387841, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    minWidth: "136px",
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="600938"
                                  value="[datafield:teklifFiyat]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="729425"
                                title={ReactSystemFunctions.translate(this.ml, 729425, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="139557"
                                  value="[datafield:talepDurum]"
                                  style={
                                    {
                                      borderTopLeftRadius: 200,
                                      borderTopRightRadius: 200,
                                      borderBottomRightRadius: 200,
                                      borderBottomLeftRadius: 200,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(250, 219, 20, 0.15)",
                                      paddingTop: 4,
                                      paddingRight: 12,
                                      paddingBottom: 4,
                                      paddingLeft: 12,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(250, 173, 20, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="398869"
                                visibility={this.state.isComp398869Visible}
                                title={ReactSystemFunctions.translate(this.ml, 398869, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="600796"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_600796_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="library_books"
                                  hoverText="Talep Detayını İncele"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="828632"
                                visibility={this.state.isComp828632Visible}
                                title={ReactSystemFunctions.translate(this.ml, 828632, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="340519"
                                  iconName="remove_red_eye"
                                  hoverText="Teklif İncele"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="943301"
                                visibility={this.state.isComp943301Visible}
                                condition={(rowData) =>
                                  ReactSystemFunctions.isNotEqualTo(
                                    this.state.RoleSadeceGoruntule[0]?.sg022?.toString(),
                                    "1"
                                  )
                                }
                                title={ReactSystemFunctions.translate(this.ml, 943301, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="635394"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="740193"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_740193_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="fact_check"
                                    hoverText="Teklif Onayla"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(250, 173, 20, 1)"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>

                          <KRow
                            id="353196"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="943204"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPagination
                                id="961516"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.TeklifVermeListComponent_961516_onChange();
                                }}
                                kuikaRef={this.teklifvermelist_961516_value_kuikaPaginationRef}
                                total={this.state.setPTotalCount01?.at?.(0)?.totalCount ?? undefined}
                                pageSize={20}
                                defaultCurrent={1}
                                current={1}
                                defaultPageSize={10}
                                disabled={false}
                                hideOnSinglePage={true}
                                showLessItems={false}
                                showQuickJumper={false}
                                showSizeChanger={true}
                                options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                showFirstPageButton={false}
                                showLastPageButton={false}
                                showNumbers={true}
                                showText={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-right"
                                  } as any
                                }
                              ></KPagination>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="737027"
                        visibility={this.state.isComp737027Visible}
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="818063"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="731910"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="432467"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="95938"
                                direction="horizontal"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="teklifvermelist_599014_value">
                                  <Switch
                                    id="599014"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_599014_onChange();
                                    }}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>

                                <Label
                                  id="337377"
                                  value={ReactSystemFunctions.translate(this.ml, 337377, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </HorizontalStack>
                            </KCol>

                            <KCol
                              id="361976"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="791472"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="teklifvermelist_917077_value">
                                  <KSelectBox
                                    id="917077"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_917077_onChange();
                                    }}
                                    kuikaRef={this.teklifvermelist_917077_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_917077}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      917077,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Form.Item className="kFormItem" name="teklifvermelist_417300_value">
                                  <KSelectBox
                                    id="417300"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_417300_onChange();
                                    }}
                                    kuikaRef={this.teklifvermelist_417300_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_417300}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      417300,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Button
                                  id="643516"
                                  label={ReactSystemFunctions.translate(this.ml, 643516, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      width: "100px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_642213_value">
                                  <TextInput
                                    id="642213"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      642213,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100%",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>

                                <Button
                                  id="762656"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_762656_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 762656, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  icon="search"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(2, 145, 201, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_638173_value">
                                  <Image
                                    id="638173"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_638173_onClick();
                                    }}
                                    showCursorPointer
                                    zoomOnClick={false}
                                    imageFit="fit"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        height: "40px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </HorizontalStack>
                            </KCol>
                          </KRow>

                          <KTable
                            id="427031"
                            kuikaRef={this.teklifvermelist_427031_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.TeklifVerilenList}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              427031,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            searchable={false}
                            sorter={false}
                            filtering={false}
                            pagination={false}
                            striped={false}
                            stripedColor="#F5F7FA"
                            insertRowActive={false}
                            transformedOnMobileResolution={false}
                            tableWidthMode="fit"
                            columnChooser={false}
                            insertRowPosition="top"
                            pageSize={10}
                            showSizeChanger={false}
                            multiSelect={false}
                            fixedHeader={false}
                            editable={false}
                            globalSearch={false}
                            resizableColumns={false}
                            showNoDataFound={true}
                            export={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="816421"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(186, 187, 187, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="117306"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="357028"
                                title={ReactSystemFunctions.translate(this.ml, 357028, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="893800"
                                  visibility={this.state.isComp893800Visible}
                                  condition={(rowData) => ReactSystemFunctions.isEqualTo(rowData.hizliTeklif, "true")}
                                  iconName="done_outline"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(2, 145, 201, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="290310"
                                title={ReactSystemFunctions.translate(this.ml, 290310, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="283829"
                                  value="[datafield:teklifTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="729791"
                                title={ReactSystemFunctions.translate(this.ml, 729791, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="30580"
                                  value="[datafield:talep_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="275837"
                                title={ReactSystemFunctions.translate(this.ml, 275837, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="850332"
                                  value="[datafield:teklif_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="436947"
                                title={ReactSystemFunctions.translate(this.ml, 436947, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="949165"
                                  value="[datafield:muhatap]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="707135"
                                title={ReactSystemFunctions.translate(this.ml, 707135, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="709051"
                                  value="[datafield:kitapBoyutTanim]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="445179"
                                title={ReactSystemFunctions.translate(this.ml, 445179, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="668535"
                                  value="[datafield:icBaskiKagitCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="88002"
                                title={ReactSystemFunctions.translate(this.ml, 88002, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="319496"
                                  value="[datafield:icBaskiKagitGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="201674"
                                title={ReactSystemFunctions.translate(this.ml, 201674, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="322707"
                                  value="[datafield:kapakCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="330764"
                                title={ReactSystemFunctions.translate(this.ml, 330764, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="288583"
                                  value="[datafield:kapakGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="483561"
                                title={ReactSystemFunctions.translate(this.ml, 483561, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="132280"
                                  value="[datafield:ciltBicimi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="434614"
                                title={ReactSystemFunctions.translate(this.ml, 434614, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="38571"
                                  value="[datafield:icBaskiRenk]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="635753"
                                title={ReactSystemFunctions.translate(this.ml, 635753, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="264004"
                                  value="[datafield:selefon]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="6798"
                                title={ReactSystemFunctions.translate(this.ml, 6798, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="224344"
                                  value="[datafield:sayfaSayisi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="998583"
                                title={ReactSystemFunctions.translate(this.ml, 998583, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="612975"
                                  value="[datafield:baskiAdet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="236934"
                                title={ReactSystemFunctions.translate(this.ml, 236934, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="507877"
                                  value="[datafield:nakliyeDurum]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="746071"
                                title={ReactSystemFunctions.translate(this.ml, 746071, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="241440"
                                  value="[datafield:teklifTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="112349"
                                title={ReactSystemFunctions.translate(this.ml, 112349, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="424374"
                                  value="[datafield:teklifGecerlilikTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="936010"
                                title={ReactSystemFunctions.translate(this.ml, 936010, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    minWidth: "136px",
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="375729"
                                  value="[datafield:teklifFiyat]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="699280"
                                title={ReactSystemFunctions.translate(this.ml, 699280, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="876308"
                                  value="[datafield:talepDurum]"
                                  style={
                                    {
                                      borderTopLeftRadius: 200,
                                      borderTopRightRadius: 200,
                                      borderBottomRightRadius: 200,
                                      borderBottomLeftRadius: 200,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(250, 219, 20, 0.15)",
                                      paddingTop: 4,
                                      paddingRight: 12,
                                      paddingBottom: 4,
                                      paddingLeft: 12,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(250, 173, 20, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="272894"
                                visibility={this.state.isComp272894Visible}
                                title={ReactSystemFunctions.translate(this.ml, 272894, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="999022"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_999022_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="library_books"
                                  hoverText="Talep Detayını İncele"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="114232"
                                title={ReactSystemFunctions.translate(this.ml, 114232, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="911787"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_911787_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="remove_red_eye"
                                  hoverText="Teklif İncele"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="896163"
                                title={ReactSystemFunctions.translate(this.ml, 896163, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="566575"
                                  visibility={this.state.isComp566575Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(rowData.isTeklifOnaySurecIconsVisibility, "1")
                                  }
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="579595"
                                    visibility={this.state.isComp579595Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTalepEdenTeklifOnayIcoVisibility, "-1")
                                    }
                                    iconName="hourglass_top"
                                    hoverText="Müşteri Onay Sürecinde!"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(250, 173, 20, 1)"
                                      } as any
                                    }
                                  ></Icon>

                                  <Icon
                                    id="548593"
                                    visibility={this.state.isComp548593Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTalepEdenTeklifOnayIcoVisibility, "0")
                                    }
                                    iconName="not_interested"
                                    hoverText="Müşteri Teklifi Onaylamadı"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(245, 34, 45, 1)"
                                      } as any
                                    }
                                  ></Icon>

                                  <Icon
                                    id="333891"
                                    visibility={this.state.isComp333891Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTalepEdenTeklifOnayIcoVisibility, "1")
                                    }
                                    iconName="task_alt"
                                    hoverText="Müşteri Teklifi Onayladı"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(160, 217, 17, 1)"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>

                          <KRow
                            id="759256"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="535914"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPagination
                                id="387044"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.TeklifVermeListComponent_387044_onChange();
                                }}
                                kuikaRef={this.teklifvermelist_387044_value_kuikaPaginationRef}
                                total={this.state.setPTotalCount01?.at?.(0)?.totalCount ?? undefined}
                                pageSize={20}
                                defaultCurrent={1}
                                current={1}
                                defaultPageSize={10}
                                disabled={false}
                                hideOnSinglePage={true}
                                showLessItems={false}
                                showQuickJumper={false}
                                showSizeChanger={true}
                                options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                showFirstPageButton={false}
                                showLastPageButton={false}
                                showNumbers={true}
                                showText={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-right"
                                  } as any
                                }
                              ></KPagination>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="714252"
                        visibility={this.state.isComp714252Visible}
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="843352"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="142451"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="288292"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="938911"
                                direction="horizontal"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></HorizontalStack>
                            </KCol>

                            <KCol
                              id="327692"
                              xs={2}
                              sm={2}
                              md={2}
                              lg={2}
                              xl={2}
                              xxl={2}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="476538"
                              xs={14}
                              sm={14}
                              md={14}
                              lg={14}
                              xl={14}
                              xxl={14}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="21478"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="teklifvermelist_621353_value">
                                  <KSelectBox
                                    id="621353"
                                    onBlur={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_621353_onBlur();
                                    }}
                                    kuikaRef={this.teklifvermelist_621353_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_621353}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      621353,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Form.Item className="kFormItem" name="teklifvermelist_336778_value">
                                  <KSelectBox
                                    id="336778"
                                    onBlur={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_336778_onBlur();
                                    }}
                                    kuikaRef={this.teklifvermelist_336778_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_336778}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      336778,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <Form.Item className="kFormItem" name="teklifvermelist_246757_value">
                                  <TextInput
                                    id="246757"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      246757,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100%",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>

                                <Button
                                  id="462877"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.TeklifVermeListComponent_462877_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 462877, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  icon="search"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(2, 145, 201, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Form.Item className="kFormItem" name="teklifvermelist_294168_value">
                                  <Image
                                    id="294168"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.TeklifVermeListComponent_294168_onClick();
                                    }}
                                    showCursorPointer
                                    zoomOnClick={false}
                                    imageFit="fit"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        height: "40px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </HorizontalStack>
                            </KCol>
                          </KRow>

                          <KTable
                            id="797165"
                            kuikaRef={this.teklifvermelist_797165_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.TeklifVerilmeyenList}
                            size="middle"
                            bordered={true}
                            loading={false}
                            transformedOnMobileResolution={false}
                            showHeader={true}
                            fixedHeader={false}
                            editable={false}
                            tableWidthMode="fit"
                            globalSearch={false}
                            searchable={false}
                            sorter={false}
                            filtering={false}
                            pagination={false}
                            pageSize={10}
                            showSizeChanger={false}
                            columnChooser={false}
                            resizableColumns={false}
                            striped={false}
                            stripedColor="#F5F7FA"
                            insertRowActive={false}
                            insertRowPosition="top"
                            showNoDataFound={true}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              797165,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            multiSelect={false}
                            export={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="694989"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(186, 187, 187, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="735662"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="447760"
                                title={ReactSystemFunctions.translate(this.ml, 447760, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="56156"
                                  visibility={this.state.isComp56156Visible}
                                  condition={(rowData) => ReactSystemFunctions.isEqualTo(rowData.hizliTeklif, "true")}
                                  iconName="done_outline"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(2, 145, 201, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="128293"
                                title={ReactSystemFunctions.translate(this.ml, 128293, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="690690"
                                  value="[datafield:teklifTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="355217"
                                title={ReactSystemFunctions.translate(this.ml, 355217, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="888918"
                                  value="[datafield:talep_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="375102"
                                title={ReactSystemFunctions.translate(this.ml, 375102, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="857369"
                                  visibility={this.state.isComp857369Visible}
                                  value="[datafield:teklif_No]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="483745"
                                title={ReactSystemFunctions.translate(this.ml, 483745, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="527224"
                                  value="[datafield:muhatap]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="490516"
                                title={ReactSystemFunctions.translate(this.ml, 490516, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="921642"
                                  value="[datafield:kitapBoyutTanim]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="6952"
                                title={ReactSystemFunctions.translate(this.ml, 6952, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="778808"
                                  value="[datafield:icBaskiKagitCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="791116"
                                title={ReactSystemFunctions.translate(this.ml, 791116, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="573109"
                                  value="[datafield:icBaskiKagitGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="187153"
                                title={ReactSystemFunctions.translate(this.ml, 187153, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="82300"
                                  value="[datafield:kapakCinsi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="261125"
                                title={ReactSystemFunctions.translate(this.ml, 261125, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="861546"
                                  value="[datafield:kapakGramaj]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="639220"
                                title={ReactSystemFunctions.translate(this.ml, 639220, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="249409"
                                  value="[datafield:ciltBicimi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="182028"
                                title={ReactSystemFunctions.translate(this.ml, 182028, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="531956"
                                  value="[datafield:icBaskiRenk]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="112613"
                                title={ReactSystemFunctions.translate(this.ml, 112613, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="912367"
                                  value="[datafield:selefon]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="976758"
                                title={ReactSystemFunctions.translate(this.ml, 976758, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="519742"
                                  value="[datafield:sayfaSayisi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="693405"
                                title={ReactSystemFunctions.translate(this.ml, 693405, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="129042"
                                  value="[datafield:baskiAdet]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="984550"
                                title={ReactSystemFunctions.translate(this.ml, 984550, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="506610"
                                  value="[datafield:nakliyeDurum]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="205442"
                                title={ReactSystemFunctions.translate(this.ml, 205442, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="72035"
                                  value="[datafield:teklifTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="987720"
                                visibility={this.state.isComp987720Visible}
                                title={ReactSystemFunctions.translate(this.ml, 987720, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="479564"
                                  value="[datafield:teklifGecerlilikTarihi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="54945"
                                visibility={this.state.isComp54945Visible}
                                title={ReactSystemFunctions.translate(this.ml, 54945, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    minWidth: "136px",
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="189391"
                                  value="[datafield:teklifFiyat]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="200123"
                                visibility={this.state.isComp200123Visible}
                                title={ReactSystemFunctions.translate(this.ml, 200123, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="574018"
                                  value="[datafield:talepDurum]"
                                  style={
                                    {
                                      borderTopLeftRadius: 200,
                                      borderTopRightRadius: 200,
                                      borderBottomRightRadius: 200,
                                      borderBottomLeftRadius: 200,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(250, 219, 20, 0.15)",
                                      paddingTop: 4,
                                      paddingRight: 12,
                                      paddingBottom: 4,
                                      paddingLeft: 12,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "rgba(250, 173, 20, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="681816"
                                visibility={this.state.isComp681816Visible}
                                title={ReactSystemFunctions.translate(this.ml, 681816, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="28485"
                                  iconName="library_books"
                                  hoverText="Talep Detayını İncele"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KTableColumn>

                              <KTableColumn
                                id="687387"
                                title={ReactSystemFunctions.translate(this.ml, 687387, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Tooltip
                                  id="905576"
                                  title="[datafield:teklifVerilmemeNedeni]"
                                  placement="top"
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(186, 187, 187, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="365266"
                                    iconName="announcement"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(250, 173, 20, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </Tooltip>
                              </KTableColumn>

                              <KTableColumn
                                id="975642"
                                title={ReactSystemFunctions.translate(this.ml, 975642, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="107750"
                                  visibility={this.state.isComp107750Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(rowData.isTeklifOnaySurecIconsVisibility, "1")
                                  }
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="99688"
                                    visibility={this.state.isComp99688Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTalepEdenTeklifOnayIcoVisibility, "-1")
                                    }
                                    iconName="hourglass_top"
                                    hoverText="Müşteri Onay Sürecinde!"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(250, 173, 20, 1)"
                                      } as any
                                    }
                                  ></Icon>

                                  <Icon
                                    id="384340"
                                    visibility={this.state.isComp384340Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTalepEdenTeklifOnayIcoVisibility, "0")
                                    }
                                    iconName="not_interested"
                                    hoverText="Müşteri Teklifi Onaylamadı"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(245, 34, 45, 1)"
                                      } as any
                                    }
                                  ></Icon>

                                  <Icon
                                    id="545072"
                                    visibility={this.state.isComp545072Visible}
                                    condition={(rowData) =>
                                      ReactSystemFunctions.isEqualTo(rowData.isTalepEdenTeklifOnayIcoVisibility, "1")
                                    }
                                    iconName="task_alt"
                                    hoverText="Müşteri Teklifi Onayladı"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(160, 217, 17, 1)"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>

                          <KRow
                            id="439475"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="473436"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPagination
                                id="619083"
                                kuikaRef={this.teklifvermelist_619083_value_kuikaPaginationRef}
                                total={this.state.setPTotalCount01?.at?.(0)?.totalCount ?? undefined}
                                pageSize={20}
                                defaultCurrent={1}
                                current={1}
                                defaultPageSize={10}
                                disabled={false}
                                hideOnSinglePage={true}
                                showLessItems={false}
                                showQuickJumper={false}
                                showSizeChanger={true}
                                options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                showFirstPageButton={false}
                                showLastPageButton={false}
                                showNumbers={true}
                                showText={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-right"
                                  } as any
                                }
                              ></KPagination>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KDrawer
                  id="242761"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible242761: false }, () => {
                      this.callCallbackFunction(242761);
                    });
                  }}
                  visible={this.state.NavVisible242761}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible242761 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible242761: false }, () => {
                            this.callCallbackFunction(242761);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="532621"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible532621: false }, () => {
                      this.callCallbackFunction(532621);
                    });
                  }}
                  visible={this.state.NavVisible532621}
                  width="50%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible532621 && (
                      <TeklifTalepKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible532621: false }, () => {
                            this.callCallbackFunction(532621);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="439340"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible439340: false }, () => {
                      this.callCallbackFunction(439340);
                    });
                  }}
                  visible={this.state.NavVisible439340}
                  width="50%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible439340 && (
                      <TeklifTalepKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible439340: false }, () => {
                            this.callCallbackFunction(439340);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="298647"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible298647: false }, () => {
                      this.callCallbackFunction(298647);
                    });
                  }}
                  visible={this.state.NavVisible298647}
                  width="50%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible298647 && (
                      <TeklifTalepKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible298647: false }, () => {
                            this.callCallbackFunction(298647);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="52754"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible52754: false }, () => {
                      this.callCallbackFunction(52754);
                    });
                  }}
                  visible={this.state.NavVisible52754}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible52754 && (
                      <TeklifVermeKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible52754: false }, () => {
                            this.callCallbackFunction(52754);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="474892"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible474892: false }, () => {
                      this.callCallbackFunction(474892);
                    });
                  }}
                  visible={this.state.NavVisible474892}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible474892 && (
                      <TeklifVermeKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible474892: false }, () => {
                            this.callCallbackFunction(474892);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="234273"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible234273: false }, () => {
                      this.callCallbackFunction(234273);
                    });
                  }}
                  visible={this.state.NavVisible234273}
                  width="50%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible234273 && (
                      <TeklifVermeKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible234273: false }, () => {
                            this.callCallbackFunction(234273);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="161755"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible161755: false }, () => {
                      this.callCallbackFunction(161755);
                    });
                  }}
                  visible={this.state.NavVisible161755}
                  width="50%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible161755 && (
                      <TeklifVermeKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible161755: false }, () => {
                            this.callCallbackFunction(161755);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="659584"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible659584: false }, () => {
                      this.callCallbackFunction(659584);
                    });
                  }}
                  visible={this.state.NavVisible659584}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible659584 && (
                      <Profil_Screen
                        onClose={() => {
                          this.setState({ NavVisible659584: false }, () => {
                            this.callCallbackFunction(659584);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(TeklifVermeList_Screen))))
  )
);
export { tmp as TeklifVermeList_Screen };
//export default tmp;
//export { tmp as TeklifVermeList_Screen };
