import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBilgiKontrol_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBilgiKontrol_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class BilgiKontrol_ScreenBase extends React.PureComponent<IBilgiKontrol_ScreenProps, any> {
  bilgikontrol_797159_value_kuikaSelectBoxRef: React.RefObject<any>;
  bilgikontrol_957053_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d7a062c0-f1bb-421f-a13c-6b04e5bf8616",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 133322, PropertyName: "value", Value: "Müşteri Şahıs Bilgileri" },
        { Id: 487315, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 101220, PropertyName: "label", Value: "Profil" },
        { Id: 872729, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 584099, PropertyName: "placeholder", Value: "Şirket Adı" },
        { Id: 512445, PropertyName: "value", Value: "İmzalı POP Yayıncı Sözleşmesi" },
        { Id: 483964, PropertyName: "value", Value: "Site üzerinden örnek indirerek inceleyebilirsiniz." },
        { Id: 106221, PropertyName: "value", Value: "Adres" },
        { Id: 797159, PropertyName: "placeholder", Value: "Şehir" },
        { Id: 957053, PropertyName: "placeholder", Value: "İlçe" },
        { Id: 899957, PropertyName: "value", Value: "İmza Sürküleri" },
        { Id: 303982, PropertyName: "value", Value: "Lütfen pdf olarak yükleyin." },
        { Id: 574340, PropertyName: "value", Value: "Ticari Sicil Gazetesi" },
        { Id: 363612, PropertyName: "value", Value: "Lütfen pdf olarak yükleyin." },
        { Id: 873292, PropertyName: "placeholder", Value: "E-posta" },
        { Id: 867706, PropertyName: "placeholder", Value: "Telefon Numarası" },
        { Id: 166575, PropertyName: "placeholder", Value: "Vergi dairesi" },
        { Id: 473457, PropertyName: "placeholder", Value: "Vergi no" },
        { Id: 916174, PropertyName: "label", Value: "Kaydet" },
        { Id: 829847, PropertyName: "label", Value: "Kaydet" },
        { Id: 712643, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.bilgikontrol_797159_value_kuikaSelectBoxRef = React.createRef();
    this.bilgikontrol_957053_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bilgikontrol", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("bilgikontrol", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bilgikontrol", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
