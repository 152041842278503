import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHizliTeklifGiris_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHizliTeklifGiris_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  EserDetay_CiltBicimiAll: any[];
  EserDetay_CiltBicimiAll_dummy: any[];
  EserDetay_SelefonAll: any[];
  EserDetay_SelefonAll_dummy: any[];
  EserDetay_IcBaskiRenkAll: any[];
  EserDetay_IcBaskiRenkAll_dummy: any[];
  EserDetay_KapakCinsiAll: any[];
  EserDetay_KapakCinsiAll_dummy: any[];
  EserDetay_IcBaskiKagitCinsiAll: any[];
  EserDetay_IcBaskiKagitCinsiAll_dummy: any[];
  EserDetay_KitapBoyutAll: any[];
  EserDetay_KitapBoyutAll_dummy: any[];
  ParaBirimAll: any[];
  ParaBirimAll_dummy: any[];
  HizliTeklifById: any[];
  HizliTeklifById_dummy: any[];
  EserDetay_IcBaskiKagitGramajByKagitCinsi: any[];
  EserDetay_IcBaskiKagitGramajByKagitCinsi_dummy: any[];
  EserDetay_KapakGramajByKapakCinsi: any[];
  EserDetay_KapakGramajByKapakCinsi_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
}

export class HizliTeklifGiris_ScreenBase extends React.PureComponent<IHizliTeklifGiris_ScreenProps, any> {
  hizliteklifgiris_923446_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_570219_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_253304_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_454757_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_195740_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_738987_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_81822_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_673902_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_932122_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_44572_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_359198_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifgiris_553252_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0536e1f5-b18a-4a41-af06-c0bba19d7626",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 319201, PropertyName: "value", Value: "Hızlı Teklif Ver" },
        { Id: 717379, PropertyName: "value", Value: "Kitap Boyutu" },
        { Id: 500920, PropertyName: "value", Value: "Kullanılacak İç Kağıt Cinsi" },
        { Id: 120178, PropertyName: "value", Value: "Kullanılacak Kapak Cinsi" },
        { Id: 428624, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 631285, PropertyName: "value", Value: "Kullanılacak İç Kağıt Gramajı" },
        { Id: 904765, PropertyName: "value", Value: "Kullanılacak Kapak Gramajı" },
        { Id: 871761, PropertyName: "value", Value: "İç Baskı Renk (Siyah Beyaz/Renkli)" },
        { Id: 919228, PropertyName: "value", Value: "Kullanılacak Kapak Selefonu" },
        { Id: 751450, PropertyName: "value", Value: "Sayfa Birim Fiyatı" },
        { Id: 813199, PropertyName: "value", Value: "Para Birimi" },
        { Id: 178211, PropertyName: "value", Value: "Kapak Birim Fiyatı" },
        { Id: 944005, PropertyName: "value", Value: "Para Birimi" },
        { Id: 712049, PropertyName: "value", Value: "Cilt Birim Fiyatı" },
        { Id: 653222, PropertyName: "value", Value: "Para Birimi" },
        { Id: 670590, PropertyName: "value", Value: "Geçerlilik Tarihi" },
        { Id: 553252, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 907068, PropertyName: "value", Value: '"Fiyatlara KDV dahil değildir."' },
        { Id: 622625, PropertyName: "value", Value: "Diğer Koşullar" },
        { Id: 249861, PropertyName: "value", Value: "Not" },
        { Id: 508444, PropertyName: "label", Value: "Hızlı Teklif Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.hizliteklifgiris_923446_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_570219_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_253304_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_454757_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_195740_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_738987_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_81822_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_673902_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_932122_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_44572_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_359198_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifgiris_553252_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      EserDetay_CiltBicimiAll: [],
      EserDetay_SelefonAll: [],
      EserDetay_IcBaskiRenkAll: [],
      EserDetay_KapakCinsiAll: [],
      EserDetay_IcBaskiKagitCinsiAll: [],
      EserDetay_KitapBoyutAll: [],
      ParaBirimAll: [],
      HizliTeklifById: [],
      EserDetay_IcBaskiKagitGramajByKagitCinsi: [],
      EserDetay_KapakGramajByKapakCinsi: [],
      SaveRecord: false,
      Notify: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hizliteklifgiris", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HizliTeklifGirisPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hizliteklifgiris", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hizliteklifgiris", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HizliTeklifGirisPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      hizliteklifgiris_923446_value: this.state.HizliTeklifById?.at?.(0)?.kitapBoyutID ?? undefined,
      hizliteklifgiris_570219_value: this.state.HizliTeklifById?.at?.(0)?.icBaskiKagitCinsiID ?? undefined,
      hizliteklifgiris_253304_value: this.state.HizliTeklifById?.at?.(0)?.kapakCinsiID ?? undefined,
      hizliteklifgiris_454757_value: this.state.HizliTeklifById?.at?.(0)?.ciltBicimiID ?? undefined,
      hizliteklifgiris_195740_value: this.state.HizliTeklifById?.at?.(0)?.icBaskiKagitGramajID ?? undefined,
      hizliteklifgiris_738987_value: this.state.HizliTeklifById?.at?.(0)?.kapakGramajID ?? undefined,
      hizliteklifgiris_81822_value: this.state.HizliTeklifById?.at?.(0)?.icBaskiRenkID ?? undefined,
      hizliteklifgiris_673902_value: this.state.HizliTeklifById?.at?.(0)?.selefonID ?? undefined,
      hizliteklifgiris_764861_value: this.state.HizliTeklifById?.at?.(0)?.sayfaBFiyat ?? undefined,
      hizliteklifgiris_932122_value: this.state.HizliTeklifById?.at?.(0)?.sayfaParaBirimID ?? undefined,
      hizliteklifgiris_256283_value: this.state.HizliTeklifById?.at?.(0)?.kapakBFiyat ?? undefined,
      hizliteklifgiris_44572_value: this.state.HizliTeklifById?.at?.(0)?.kapakParaBirimID ?? undefined,
      hizliteklifgiris_51009_value: this.state.HizliTeklifById?.at?.(0)?.ciltBFiyat ?? undefined,
      hizliteklifgiris_359198_value: this.state.HizliTeklifById?.at?.(0)?.ciltParaBirimID ?? undefined,
      hizliteklifgiris_553252_value: this.state.HizliTeklifById?.at?.(0)?.gecerlilikTarihi ?? undefined,
      hizliteklifgiris_668439_value: this.state.HizliTeklifById?.at?.(0)?.digerKosullar ?? undefined,
      hizliteklifgiris_716106_value: this.state.HizliTeklifById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HizliTeklifGirisPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HizliTeklifGiris/HizliTeklifGirisPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.EserDetay_CiltBicimiAll = result?.data.eserDetay_CiltBicimiAll;

    formVars.hizliteklifgiris_454757_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.ciltBicimiID : null;
    formVars.hizliteklifgiris_454757_options = stateVars.EserDetay_CiltBicimiAll;
    stateVars.EserDetay_SelefonAll = result?.data.eserDetay_SelefonAll;

    formVars.hizliteklifgiris_673902_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.selefonID : null;
    formVars.hizliteklifgiris_673902_options = stateVars.EserDetay_SelefonAll;
    stateVars.EserDetay_IcBaskiRenkAll = result?.data.eserDetay_IcBaskiRenkAll;

    formVars.hizliteklifgiris_81822_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.icBaskiRenkID : null;
    formVars.hizliteklifgiris_81822_options = stateVars.EserDetay_IcBaskiRenkAll;
    stateVars.EserDetay_KapakCinsiAll = result?.data.eserDetay_KapakCinsiAll;

    formVars.hizliteklifgiris_253304_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakCinsiID : null;
    formVars.hizliteklifgiris_253304_options = stateVars.EserDetay_KapakCinsiAll;
    stateVars.EserDetay_IcBaskiKagitCinsiAll = result?.data.eserDetay_IcBaskiKagitCinsiAll;

    formVars.hizliteklifgiris_570219_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.icBaskiKagitCinsiID : null;
    formVars.hizliteklifgiris_570219_options = stateVars.EserDetay_IcBaskiKagitCinsiAll;
    stateVars.EserDetay_KitapBoyutAll = result?.data.eserDetay_KitapBoyutAll;

    formVars.hizliteklifgiris_923446_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kitapBoyutID : null;
    formVars.hizliteklifgiris_923446_options = stateVars.EserDetay_KitapBoyutAll;
    stateVars.ParaBirimAll = result?.data.paraBirimAll;

    formVars.hizliteklifgiris_932122_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.sayfaParaBirimID : null;
    formVars.hizliteklifgiris_932122_options = stateVars.ParaBirimAll;

    formVars.hizliteklifgiris_44572_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakParaBirimID : null;
    formVars.hizliteklifgiris_44572_options = stateVars.ParaBirimAll;

    formVars.hizliteklifgiris_359198_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.ciltParaBirimID : null;
    formVars.hizliteklifgiris_359198_options = stateVars.ParaBirimAll;
    stateVars.HizliTeklifById = result?.data.hizliTeklifById;
    formVars.hizliteklifgiris_923446_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kitapBoyutID : null;
    formVars.hizliteklifgiris_923446_options = stateVars.EserDetay_KitapBoyutAll;
    formVars.hizliteklifgiris_570219_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.icBaskiKagitCinsiID : null;
    formVars.hizliteklifgiris_570219_options = stateVars.EserDetay_IcBaskiKagitCinsiAll;
    formVars.hizliteklifgiris_253304_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakCinsiID : null;
    formVars.hizliteklifgiris_253304_options = stateVars.EserDetay_KapakCinsiAll;
    formVars.hizliteklifgiris_454757_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.ciltBicimiID : null;
    formVars.hizliteklifgiris_454757_options = stateVars.EserDetay_CiltBicimiAll;
    formVars.hizliteklifgiris_195740_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.icBaskiKagitGramajID : null;
    formVars.hizliteklifgiris_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    formVars.hizliteklifgiris_738987_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakGramajID : null;
    formVars.hizliteklifgiris_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    formVars.hizliteklifgiris_81822_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.icBaskiRenkID : null;
    formVars.hizliteklifgiris_81822_options = stateVars.EserDetay_IcBaskiRenkAll;
    formVars.hizliteklifgiris_673902_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.selefonID : null;
    formVars.hizliteklifgiris_673902_options = stateVars.EserDetay_SelefonAll;
    formVars.hizliteklifgiris_764861_value = ReactSystemFunctions.value(
      this,
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.sayfaBFiyat : null
    );
    formVars.hizliteklifgiris_932122_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.sayfaParaBirimID : null;
    formVars.hizliteklifgiris_932122_options = stateVars.ParaBirimAll;
    formVars.hizliteklifgiris_256283_value = ReactSystemFunctions.value(
      this,
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakBFiyat : null
    );
    formVars.hizliteklifgiris_44572_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakParaBirimID : null;
    formVars.hizliteklifgiris_44572_options = stateVars.ParaBirimAll;
    formVars.hizliteklifgiris_51009_value = ReactSystemFunctions.value(
      this,
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.ciltBFiyat : null
    );
    formVars.hizliteklifgiris_359198_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.ciltParaBirimID : null;
    formVars.hizliteklifgiris_359198_options = stateVars.ParaBirimAll;
    formVars.hizliteklifgiris_553252_value = ReactSystemFunctions.value(
      this,
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.gecerlilikTarihi : null
    );
    formVars.hizliteklifgiris_668439_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.digerKosullar : null
    );
    formVars.hizliteklifgiris_716106_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.aciklama : null
    );
    stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi = result?.data.eserDetay_IcBaskiKagitGramajByKagitCinsi;

    formVars.hizliteklifgiris_195740_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.icBaskiKagitGramajID : null;
    formVars.hizliteklifgiris_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.EserDetay_KapakGramajByKapakCinsi = result?.data.eserDetay_KapakGramajByKapakCinsi;

    formVars.hizliteklifgiris_738987_value =
      stateVars.HizliTeklifById?.length > 0 ? stateVars.HizliTeklifById[0]?.kapakGramajID : null;
    formVars.hizliteklifgiris_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifGirisPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifGirisPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.hizliteklifgiris_923446_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.kitapBoyutID : null
    );

    stateVars.dataSource_923446 = this.state.EserDetay_KitapBoyutAll;
    stateVars.dataSource_923446 = this.state.EserDetay_KitapBoyutAll;
    formVars.hizliteklifgiris_570219_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.icBaskiKagitCinsiID : null
    );

    stateVars.dataSource_570219 = this.state.EserDetay_IcBaskiKagitCinsiAll;
    stateVars.dataSource_570219 = this.state.EserDetay_IcBaskiKagitCinsiAll;
    formVars.hizliteklifgiris_253304_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.kapakCinsiID : null
    );

    stateVars.dataSource_253304 = this.state.EserDetay_KapakCinsiAll;
    stateVars.dataSource_253304 = this.state.EserDetay_KapakCinsiAll;
    formVars.hizliteklifgiris_454757_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.ciltBicimiID : null
    );

    stateVars.dataSource_454757 = this.state.EserDetay_CiltBicimiAll;
    stateVars.dataSource_454757 = this.state.EserDetay_CiltBicimiAll;
    formVars.hizliteklifgiris_195740_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.icBaskiKagitGramajID : null
    );

    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    formVars.hizliteklifgiris_738987_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.kapakGramajID : null
    );

    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    formVars.hizliteklifgiris_81822_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.icBaskiRenkID : null
    );

    stateVars.dataSource_81822 = this.state.EserDetay_IcBaskiRenkAll;
    stateVars.dataSource_81822 = this.state.EserDetay_IcBaskiRenkAll;
    formVars.hizliteklifgiris_673902_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.selefonID : null
    );

    stateVars.dataSource_673902 = this.state.EserDetay_SelefonAll;
    stateVars.dataSource_673902 = this.state.EserDetay_SelefonAll;
    formVars.hizliteklifgiris_764861_value = ReactSystemFunctions.value(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.sayfaBFiyat : null
    );

    formVars.hizliteklifgiris_932122_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.sayfaParaBirimID : null
    );

    stateVars.dataSource_932122 = this.state.ParaBirimAll;
    stateVars.dataSource_932122 = this.state.ParaBirimAll;
    formVars.hizliteklifgiris_256283_value = ReactSystemFunctions.value(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.kapakBFiyat : null
    );

    formVars.hizliteklifgiris_44572_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.kapakParaBirimID : null
    );

    stateVars.dataSource_44572 = this.state.ParaBirimAll;
    stateVars.dataSource_44572 = this.state.ParaBirimAll;
    formVars.hizliteklifgiris_51009_value = ReactSystemFunctions.value(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.ciltBFiyat : null
    );

    formVars.hizliteklifgiris_359198_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.ciltParaBirimID : null
    );

    stateVars.dataSource_359198 = this.state.ParaBirimAll;
    stateVars.dataSource_359198 = this.state.ParaBirimAll;
    formVars.hizliteklifgiris_553252_value = ReactSystemFunctions.value(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.gecerlilikTarihi : null
    );

    formVars.hizliteklifgiris_668439_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.digerKosullar : null
    );

    formVars.hizliteklifgiris_716106_value = ReactSystemFunctions.toString(
      this,
      this.state.HizliTeklifById?.length > 0 ? this.state.HizliTeklifById[0]?.aciklama : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HizliTeklifGirisComponent_694983_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifGirisComponent_570219_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_570219_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IcBaskiKagitCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifGiris/HizliTeklifGirisComponent_570219_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi = result?.data.eserDetay_IcBaskiKagitGramajByKagitCinsi;

    formVars.hizliteklifgiris_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifGirisComponent_570219_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifGirisComponent_570219_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifGirisComponent_253304_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_253304_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KapakCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifGiris/HizliTeklifGirisComponent_253304_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_KapakGramajByKapakCinsi = result?.data.eserDetay_KapakGramajByKapakCinsi;

    formVars.hizliteklifgiris_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifGirisComponent_253304_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifGirisComponent_253304_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifGirisComponent_508444_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_454757_value",
            "value",
            "EserDetay_CiltBicimiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_454757_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_570219_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_195740_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_81822_value",
            "value",
            "EserDetay_IcBaskiRenkAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_81822_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_253304_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_738987_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_923446_value",
            "value",
            "EserDetay_KitapBoyutAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_923446_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_673902_value", "value", "EserDetay_SelefonAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_673902_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_256283_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_256283_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_44572_value", "value", "ParaBirimAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_44572_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_764861_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_764861_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_932122_value", "value", "ParaBirimAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_932122_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_51009_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_51009_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_359198_value", "value", "ParaBirimAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_359198_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_553252_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifgiris_553252_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_716106_value", "value", "", "", "")
        ),
        "string"
      ),
      Aktif_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      CiltBicimiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_454757_value",
            "value",
            "EserDetay_CiltBicimiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      DigerKosullar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_668439_value", "value", "", "", "")
        ),
        "string"
      ),
      IcBaskiKagitCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiKagitGramajID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiRenkID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_81822_value",
            "value",
            "EserDetay_IcBaskiRenkAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KapakCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_253304_value",
            "value",
            "EserDetay_KapakCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KapakGramajID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KitapBoyutID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifgiris_923446_value",
            "value",
            "EserDetay_KitapBoyutAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SelefonID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_673902_value", "value", "EserDetay_SelefonAll", "id", "id")
        ),
        "Guid"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      KapakBFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_256283_value", "value", "", "", "")
        ),
        "number"
      ),
      KapakParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_44572_value", "value", "ParaBirimAll", "id", "id")
        ),
        "Guid"
      ),
      SayfaBFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_764861_value", "value", "", "", "")
        ),
        "number"
      ),
      SayfaParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_932122_value", "value", "ParaBirimAll", "id", "id")
        ),
        "Guid"
      ),
      CiltBFiyat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_51009_value", "value", "", "", "")
        ),
        "number"
      ),
      CiltParaBirimID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_359198_value", "value", "ParaBirimAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifEdenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      GecerlilikTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifgiris_553252_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifGiris/HizliTeklifGirisComponent_508444_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifGirisComponent_508444_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifGirisComponent_508444_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6d69bdbc_aca5_cd63_0c6b_dd95ed37de3e_notify",
        this.defaultML,
        "Teklif Kayıt Edildi!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
