const screenInputData = {
  PDFViewerInfo: [{"name":"FormTipi","type":"String","cardinality":1,"isExpandedPanel":null}],
	BandrolYazisi: [{"name":"prmId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TSiparisKart: [{"name":"TaslakmiKartmi","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"SiparisKartId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"FirmaAd","type":"String","cardinality":1,"isExpandedPanel":null}],
	BandrolBilgileri: [{"name":"prmTeklifTalepKartID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"siparisNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	YayinciBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AltKullaniciEkle: [{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EserBilgileri: [{"name":"prmTeklifTalepKartID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmKitapAd","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"teklifNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"uyeAd","type":"String","cardinality":1,"isExpandedPanel":null}],
	MusteriSahisBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	FormGoster: [{"name":"prmFormTipi","type":"String","cardinality":1,"isExpandedPanel":null}],
	EserDetayIcBaskiRenk_List_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	UretimDetayKart: [{"name":"prmTeklifTalepKartID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"siparisNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null}],
	EserDetayCiltBicimi_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	YazarBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SignupAdminChange: [{"name":"prmUyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmUyelikTipiKey","type":"Int32","cardinality":1,"isExpandedPanel":null},{"name":"prmUyelikTipi","type":"String","cardinality":1,"isExpandedPanel":null}],
	UretimListMatbaa: [{"name":"prmSiparisNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	TeklifTalepList: [{"name":"tabIndex","type":"Int32","cardinality":1,"isExpandedPanel":null}],
	SikayetKart: [{"name":"SikayetId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PDFViewerFormsImza: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SSSForm: [{"name":"SoruID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PDFViewerFormsVergiLevhasi: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TeklifVermeKart: [{"name":"prmTeklifTalepID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmUID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmMuhatapMiFirmaMi","type":"String","cardinality":1,"isExpandedPanel":null}],
	BandrolStokHareket: [{"name":"prmEserBilgiID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PDFViewerFormsTicariSicilGazete: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PreviewPDF: [{"name":"prmNewGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PDFViewerFormsYayinciSertifikasi: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	VerilenTeklifKarsilastirma: [{"name":"TeklifTalepKartID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TeslimTuru_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	EserDetayIcBaskiKagitCinsi_List_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	MatbaaBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PopupBandrolYapistirma: [{"name":"prmTeklifTalepKartID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"dteBicak","type":"DateTimeOffset","cardinality":1,"isExpandedPanel":null},{"name":"prmId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null}],
	HizliTeklifGiris: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AltRol_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	EserDetaySelefon_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	TedarikciSahisBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EserDetayKapakGramaj_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ParaBirim_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	MusteriTicariBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OneriSikayetKategori_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	GenelParametreKart: [{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null}],
	EserDetayKapakCinsi_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	HizliTeklifKarsilastirma: [{"name":"HizliTeklifTTempID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TeklifTalepKart: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditDisplay","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"HizlimiListemi","type":"String","cardinality":1,"isExpandedPanel":null}],
	SiparisTeslimDetayKart: [{"name":"prmTeklifTalepKartID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"siparisNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"prmBandrolYapistirmaID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PDFViewerFormsPOPSozlesme: [{"name":"prmGuid","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TedarikciTicariBilgileri: [{"name":"prmID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PDFViewer: [{"name":"FormTipi","type":"String","cardinality":1,"isExpandedPanel":null}],
	EserDetayIcBaskiKagitGramaj_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	UretimList: [{"name":"prmSiparisNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	EserDetayKitapBoyut_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
