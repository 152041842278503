import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPowerUserUyeList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPowerUserUyeList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  ReadFromLocalStorage: string;
  ReadFromLocalStorage_dummy: string;
  LogSave: number;
  LogSave_dummy: number;
  MyContext: any[];
  MyContext_dummy: any[];
  spPowerUserOnayList: any[];
  spPowerUserOnayList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  spPowerUserChangeOnayList: any[];
  spPowerUserChangeOnayList_dummy: any[];
  setPTotalCount02: any[];
  setPTotalCount02_dummy: any[];
  ChangeAktifPasif: number;
  ChangeAktifPasif_dummy: number;
  UyeFormKUserIdChangeByUyeId: number;
  UyeFormKUserIdChangeByUyeId_dummy: number;
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp271688Visible: "visible" | "hidden";
  isComp110236Visible: "visible" | "hidden";
  isComp226884Visible: "visible" | "hidden";
  isComp706006Visible: "visible" | "hidden";
  isComp640284Visible: "visible" | "hidden";
  isComp220763Visible: "visible" | "hidden";
  isComp405972Visible: "visible" | "hidden";
  isComp594567Visible: "visible" | "hidden";
  isComp33692Visible: "visible" | "hidden";
  isComp794638Visible: "visible" | "hidden";
  isComp508188Visible: "visible" | "hidden";
  isComp61170Visible: "visible" | "hidden";
}

export class PowerUserUyeList_ScreenBase extends React.PureComponent<IPowerUserUyeList_ScreenProps, any> {
  poweruseruyelist_902166_value_kuikaSelectBoxRef: React.RefObject<any>;
  poweruseruyelist_848693_value_kuikaSelectBoxRef: React.RefObject<any>;
  poweruseruyelist_672113_value_kuikaTableRef: React.RefObject<any>;
  poweruseruyelist_499837_value_kuikaPaginationRef: React.RefObject<any>;
  poweruseruyelist_229652_value_kuikaSelectBoxRef: React.RefObject<any>;
  poweruseruyelist_786151_value_kuikaSelectBoxRef: React.RefObject<any>;
  poweruseruyelist_424483_value_kuikaTableRef: React.RefObject<any>;
  poweruseruyelist_955414_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "45491453-4a44-4172-a7f9-31f59e1ddb2e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 560196, PropertyName: "value", Value: "Power User - Üye İşlemleri " },
        { Id: 956331, PropertyName: "label", Value: "Profil" },
        { Id: 353870, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 320222, PropertyName: "value", Value: "Üyelik işlemi tamamlanan kayıtları da göster" },
        { Id: 902166, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 848693, PropertyName: "placeholder", Value: "A-z" },
        { Id: 238008, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 672113, PropertyName: "nodatafoundmessage", Value: "Kayıt Bulunamadı!" },
        { Id: 904622, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 17881, PropertyName: "value", Value: "[datafield:uyeliktipi]" },
        { Id: 848691, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 662551, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 125269, PropertyName: "title", Value: "E-posta" },
        { Id: 732569, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 875497, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 229346, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 261385, PropertyName: "title", Value: "Telefon Numarası" },
        { Id: 216645, PropertyName: "value", Value: "[datafield:phonenumber]" },
        { Id: 60090, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 103994, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 347720, PropertyName: "title", Value: "Durumu" },
        { Id: 464327, PropertyName: "value", Value: "[datafield:uyelikdurum]" },
        { Id: 619551, PropertyName: "title", Value: "Aktiflik Durumu" },
        { Id: 220763, PropertyName: "value", Value: "[datafield:aktifpasiflabel]" },
        { Id: 661869, PropertyName: "value", Value: "Üye Admin Değiştirme Talep Onayı" },
        { Id: 566097, PropertyName: "value", Value: "Onay işlemi tamamlanan kayıtları da göster" },
        { Id: 229652, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 786151, PropertyName: "placeholder", Value: "A-z" },
        { Id: 6097, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 424483, PropertyName: "nodatafoundmessage", Value: "Kayıt Bulunamadı!" },
        { Id: 113693, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 517583, PropertyName: "value", Value: "[datafield:uyeliktipi]" },
        { Id: 360972, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 165057, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 60399, PropertyName: "title", Value: "E-posta" },
        { Id: 760761, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 586297, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 472363, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 845814, PropertyName: "title", Value: "Telefon Numarası" },
        { Id: 73893, PropertyName: "value", Value: "[datafield:phonenumber]" },
        { Id: 546373, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 22715, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 921872, PropertyName: "title", Value: "Durumu" },
        { Id: 301350, PropertyName: "value", Value: "[datafield:uyelikdurum]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  PowerUserUyeListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.poweruseruyelist_902166_value_kuikaSelectBoxRef = React.createRef();
    this.poweruseruyelist_848693_value_kuikaSelectBoxRef = React.createRef();
    this.poweruseruyelist_672113_value_kuikaTableRef = React.createRef();
    this.poweruseruyelist_499837_value_kuikaPaginationRef = React.createRef();
    this.poweruseruyelist_229652_value_kuikaSelectBoxRef = React.createRef();
    this.poweruseruyelist_786151_value_kuikaSelectBoxRef = React.createRef();
    this.poweruseruyelist_424483_value_kuikaTableRef = React.createRef();
    this.poweruseruyelist_955414_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      ReadFromLocalStorage: "",
      LogSave: 0,
      MyContext: [],
      spPowerUserOnayList: [],
      setPTotalCount01: [],
      spPowerUserChangeOnayList: [],
      setPTotalCount02: [],
      ChangeAktifPasif: 0,
      UyeFormKUserIdChangeByUyeId: 0,
      SaveRecord: false,
      dataSource_902166: [
        { key: "UyelikTipi", text: "Üyelik Tipi" },
        { key: "AdSoyad", text: "Ad Soyad" },
        { key: "SirketAd", text: "Şirket Adı" },
        { key: "BasvuruTarihi", text: "Başvuru Tarihi" },
        { key: "OnayDurumu", text: "Onay Durumu" }
      ],
      dataSource_848693: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      dataSource_229652: [
        { key: "UyelikTipi", text: "Üyelik Tipi" },
        { key: "AdSoyad", text: "Ad Soyad" },
        { key: "SirketAd", text: "Şirket Adı" },
        { key: "BasvuruTarihi", text: "Başvuru Tarihi" },
        { key: "OnayDurumu", text: "Onay Durumu" }
      ],
      dataSource_786151: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp271688Visible: "hidden",
      isComp110236Visible: "hidden",
      isComp226884Visible: "hidden",
      isComp706006Visible: "hidden",
      isComp640284Visible: "hidden",
      isComp220763Visible: "hidden",
      isComp405972Visible: "hidden",
      isComp594567Visible: "hidden",
      isComp33692Visible: "hidden",
      isComp794638Visible: "hidden",
      isComp508188Visible: "hidden",
      isComp61170Visible: "hidden"
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("poweruseruyelist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PowerUserUyeListPageInit();
    }

    if (false) {
      if (false) {
        await this.PowerUserUyeListRecuringAction_30_();
      }
      this.PowerUserUyeListRecuringAction_30_Interval = setInterval(async () => {
        await this.PowerUserUyeListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("poweruseruyelist", "");
  }

  componentWillUnmount() {
    if (this.PowerUserUyeListRecuringAction_30_Interval) {
      clearInterval(this.PowerUserUyeListRecuringAction_30_Interval);
    }
  }

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["POWERUSER", "ADMIIN"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("poweruseruyelist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PowerUserUyeListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.PowerUserUyeListRecuringAction_30_();
      }
      this.PowerUserUyeListRecuringAction_30_Interval = setInterval(async () => {
        await this.PowerUserUyeListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      poweruseruyelist_962544_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      poweruseruyelist_377646_value: false,
      poweruseruyelist_477024_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  PowerUserUyeListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("LogGuid");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PowerUserUyeListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      KeyIdValue_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      OperationName_0: ReactSystemFunctions.convertToTypeByName("Giriş Başarılı!", "string"),
      OperationTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TableName_0: ReactSystemFunctions.convertToTypeByName("Sisteme Giriş", "string"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toGuid(this, this.state.ReadFromLocalStorage),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyelikTamamGoster_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      UyelikTamamGoster_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PowerUserUyeList/PowerUserUyeListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogSave = result?.data.logSave;
    stateVars.MyContext = result?.data.myContext;
    formVars.poweruseruyelist_962544_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.poweruseruyelist_98455_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.poweruseruyelist_583513_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.poweruseruyelist_962544_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.poweruseruyelist_98455_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.poweruseruyelist_583513_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;
    stateVars.isComp271688Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110236Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp226884Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp706006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp640284Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp220763Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "isAktifPasifVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp405972Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "isAktifPasifVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.poweruseruyelist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    stateVars.isComp594567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp33692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794638Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp508188Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikDurumKey"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PowerUserUyeListComponent_312666_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PowerUserUyeList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "158742",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_956331_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PowerUserUyeList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "159494",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_353870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_377646_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_377646_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_377646_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_377646_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;
    formVars.poweruseruyelist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_902166_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_902166_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_902166_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_902166_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;
    formVars.poweruseruyelist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_848693_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_848693_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_848693_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_848693_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;
    formVars.poweruseruyelist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_392238_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_392238_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_392238_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_392238_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;
    formVars.poweruseruyelist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_640284_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "1"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "YayinciBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.PowerUserUyeListComponent_640284_onClick1_,
        "PowerUserUyeList",
        "YayinciBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_640284_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PowerUserUyeListComponent_640284_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "2"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "MatbaaBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.PowerUserUyeListComponent_640284_onClick2_,
        "PowerUserUyeList",
        "MatbaaBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_640284_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PowerUserUyeListComponent_640284_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "3"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "TedarikciTicariBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.PowerUserUyeListComponent_640284_onClick3_,
        "PowerUserUyeList",
        "TedarikciTicariBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_640284_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PowerUserUyeListComponent_640284_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "4"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "YazarBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.PowerUserUyeListComponent_640284_onClick4_,
        "PowerUserUyeList",
        "YazarBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_640284_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PowerUserUyeListComponent_640284_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "5"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "MusteriTicariBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.PowerUserUyeListComponent_640284_onClick5_,
        "PowerUserUyeList",
        "MusteriTicariBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_640284_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PowerUserUyeListComponent_640284_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "6"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "MusteriSahisBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.PowerUserUyeListComponent_640284_onClick6_,
        "PowerUserUyeList",
        "MusteriSahisBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_640284_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PowerUserUyeListComponent_640284_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikTipiKey"),
        "7"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "TedarikciSahisBilgileri",
        "prmID",
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "PowerUserUyeList",
        "TedarikciSahisBilgileri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_405972_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "poweruseruyelist_672113_value", "uyelikFormuId"),
        "Guid"
      ),
      UyelikTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b64bc5db_53be_47d9_a378_854d02812ab4_confirmation",
        this.defaultML,
        "Seçilen Üyenin Aktiflik Durumu değiştirilecektir. İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "PowerUserUyeList/PowerUserUyeListComponent_405972_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.ChangeAktifPasif = result?.data.changeAktifPasif;
      stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_405972_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_405972_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_499837_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_499837_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_499837_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_499837_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_477024_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_477024_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_477024_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_477024_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_229652_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_229652_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_229652_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_229652_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_786151_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_786151_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_786151_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_786151_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_357472_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_357472_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_357472_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_357472_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_508188_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "poweruseruyelist_424483_value", "uyelikFormuId"),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "poweruseruyelist_424483_value", "userId"),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "poweruseruyelist_424483_value", "kullaniciDetayId"),
        "Guid"
      ),
      AdminOlarakAtaPowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      UyelikTamamGoster_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8c44312b_2cc0_e106_63d0_642c9f967e54_confirmation",
        this.defaultML,
        "Admin değişikliği onaylanacaktır; işleme devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "PowerUserUyeList/PowerUserUyeListComponent_508188_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UyeFormKUserIdChangeByUyeId = result?.data.uyeFormKUserIdChangeByUyeId;
      stateVars.SaveRecord = result?.data.saveRecord_1;
      stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

      stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_508188_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_508188_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_61170_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "poweruseruyelist_424483_value", "kullaniciDetayId"),
        "Guid"
      ),
      AdminOlarakAtaPowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "eee3adbc_2669_a75a_b157_b599f821a607_confirmation",
        this.defaultML,
        "Admin değişikliği rededilecektir; işleme devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "PowerUserUyeList/PowerUserUyeListComponent_61170_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_2;
      stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

      stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PowerUserUyeListComponent_61170_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_61170_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListComponent_955414_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListComponent_955414_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListComponent_955414_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListComponent_955414_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserUyeListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyelikTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_377646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_238008_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_902166_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_848693_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      UyelikTamamGoster_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_477024_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_6097_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_229652_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_786151_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "poweruseruyelist_955414_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserUyeList/PowerUserUyeListRecuringAction_30_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.poweruseruyelist_962544_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.poweruseruyelist_98455_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.poweruseruyelist_583513_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.spPowerUserOnayList = result?.data.spPowerUserOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    stateVars.spPowerUserChangeOnayList = result?.data.spPowerUserChangeOnayList;

    stateVars.setPTotalCount02 = result?.data.setPTotalCount02;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserUyeListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserUyeListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.poweruseruyelist_962544_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.poweruseruyelist_98455_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.poweruseruyelist_583513_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_672113 = this.state.spPowerUserOnayList;
    formVars.poweruseruyelist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    stateVars.dataSource_424483 = this.state.spPowerUserChangeOnayList;
    formVars.poweruseruyelist_955414_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount02?.length > 0 ? this.state.setPTotalCount02[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [158742, 159494, , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.PowerUserUyeListPageInit();
    }
  }
}
