import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IGonderilenTeklifList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IGonderilenTeklifList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
}

export class GonderilenTeklifList_ScreenBase extends React.PureComponent<IGonderilenTeklifList_ScreenProps, any> {
  gonderilentekliflist_826826_value_kuikaSelectBoxRef: React.RefObject<any>;
  gonderilentekliflist_867802_value_kuikaTableRef: React.RefObject<any>;
  gonderilentekliflist_94486_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "4f94aa6e-25fe-46fc-b4e9-c3f2e37c02b8",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 172357, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 81550, PropertyName: "value", Value: "Gönderilen Teklifler" },
        { Id: 826826, PropertyName: "placeholder", Value: "Durum seçin..." },
        { Id: 702503, PropertyName: "label", Value: "Yeni Teklif Al" },
        { Id: 867802, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 810813, PropertyName: "title", Value: "Konu" },
        { Id: 881521, PropertyName: "value", Value: "Lorem ipsum kitap basımı" },
        { Id: 836657, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 6209, PropertyName: "value", Value: "13.06.2023" },
        { Id: 830935, PropertyName: "title", Value: "Firma" },
        { Id: 295495, PropertyName: "value", Value: "ABC Matbaa" },
        { Id: 614318, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 353810, PropertyName: "value", Value: "Ahmet Yılmaz" },
        { Id: 848148, PropertyName: "title", Value: "Ebat" },
        { Id: 552503, PropertyName: "value", Value: "21x20" },
        { Id: 686281, PropertyName: "title", Value: "Miktar" },
        { Id: 320205, PropertyName: "value", Value: "2000 Adet" },
        { Id: 324848, PropertyName: "title", Value: "Geçerlilik Sonu" },
        { Id: 492950, PropertyName: "value", Value: "30.06.2023" },
        { Id: 459624, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 560434, PropertyName: "value", Value: "Kargo" },
        { Id: 884227, PropertyName: "title", Value: "Durumu" },
        { Id: 96854, PropertyName: "value", Value: "Onaylandı" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.gonderilentekliflist_826826_value_kuikaSelectBoxRef = React.createRef();
    this.gonderilentekliflist_867802_value_kuikaTableRef = React.createRef();
    this.gonderilentekliflist_94486_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      dataSource_826826: [
        { key: "Onaylandı", text: "Onaylandı" },
        { key: "Taslak", text: "Taslak" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("gonderilentekliflist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("gonderilentekliflist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("gonderilentekliflist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  GonderilenTeklifListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GonderilenTeklifList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GonderilenTeklifListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GonderilenTeklifList",
      "Profil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GonderilenTeklifListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761] as number[];
  }
}
