import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullanilmiyorSuresizYayin_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullanilmiyorSuresizYayin_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class KullanilmiyorSuresizYayin_ScreenBase extends React.PureComponent<
  IKullanilmiyorSuresizYayin_ScreenProps,
  any
> {
  kullanilmiyorsuresizyayin_547851_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "2ca7e0e5-606f-403b-96fb-9f92d80a5538",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 65405, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 746823, PropertyName: "label", Value: "Profil" },
        { Id: 45690, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 246929, PropertyName: "value", Value: "Süresiz Yayın Bilgileri" },
        { Id: 803788, PropertyName: "value", Value: "Yayın Türü" },
        { Id: 736008, PropertyName: "value", Value: "Kitap Adı" },
        { Id: 723567, PropertyName: "value", Value: "Yazar Adı" },
        { Id: 996796, PropertyName: "value", Value: "Bandrole tabi mi?" },
        { Id: 756349, PropertyName: "value", Value: "Evet" },
        { Id: 184322, PropertyName: "value", Value: "Evet" },
        { Id: 71586, PropertyName: "value", Value: "Tıpkı Basım mı?" },
        { Id: 500275, PropertyName: "value", Value: "Evet" },
        { Id: 668189, PropertyName: "value", Value: "Evet" },
        { Id: 198322, PropertyName: "value", Value: "ISBN Numarası" },
        { Id: 387082, PropertyName: "value", Value: "Döküman (PDF Upload)" },
        { Id: 169929, PropertyName: "value", Value: "Ürün Tescil / ISBN Dökümanı (PDF Upload)" },
        { Id: 118176, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.kullanilmiyorsuresizyayin_547851_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullanilmiyorsuresizyayin", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("kullanilmiyorsuresizyayin", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullanilmiyorsuresizyayin", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kullanilmiyorsuresizyayin_560751_value: false,
      kullanilmiyorsuresizyayin_492233_value: false,
      kullanilmiyorsuresizyayin_18545_value: false,
      kullanilmiyorsuresizyayin_431856_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
