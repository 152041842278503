import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISignup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISignup_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  sbUyelikTipiList: any[];
  sbUyelikTipiList_dummy: any[];
  ReadFromSessionStorage: string;
  ReadFromSessionStorage_dummy: string;
  SignupTempByEPosta: any[];
  SignupTempByEPosta_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  VCodeCreate: any[];
  VCodeCreate_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  VCodeControl: any[];
  VCodeControl_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  Notify: boolean;
  Notify_dummy: boolean;
  GsmKontrol: number;
  GsmKontrol_dummy: number;
  fnPasswordControl: any;
  fnPasswordControl_dummy: any;
  UyelikValidasyonKontrol: any[];
  UyelikValidasyonKontrol_dummy: any[];
  Signup: any;
  Signup_dummy: any;
  SaveUser: any;
  SaveUser_dummy: any;
  AddUserToRole: boolean;
  AddUserToRole_dummy: boolean;
  KullaniciDetaySave: number;
  KullaniciDetaySave_dummy: number;
  SignupTempSave: number;
  SignupTempSave_dummy: number;
  WriteToSessionStorage: any;
  WriteToSessionStorage_dummy: any;
  goToNextScreenAfterSignup: any;
  goToNextScreenAfterSignup_dummy: any;
  isComp46066Visible: "visible" | "hidden";
  isComp377671Visible: "visible" | "hidden";
  isComp824157Visible: "visible" | "hidden";
  isComp776110Visible: "visible" | "hidden";
  isComp932651Visible: "visible" | "hidden";
  isComp412471Visible: "visible" | "hidden";
  isComp387716Enabled: "enabled" | "disabled";
  isComp120930Enabled: "enabled" | "disabled";
}

export class Signup_ScreenBase extends React.PureComponent<ISignup_ScreenProps, any> {
  signup_825972_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "460fd904-9b8a-43a8-8874-e42088e41655",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 717912, PropertyName: "value", Value: "Kayıt Bilgileri" },
        { Id: 997730, PropertyName: "value", Value: "Lütfen hesabınızı oluşturun." },
        { Id: 738150, PropertyName: "value", Value: "Zaten hesabınız var mı?" },
        { Id: 452489, PropertyName: "value", Value: "Giriş Yap" },
        { Id: 744581, PropertyName: "value", Value: "Üyelik Tipi" },
        { Id: 910653, PropertyName: "value", Value: "Ad" },
        { Id: 208841, PropertyName: "value", Value: "Soyad" },
        { Id: 833206, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 432007, PropertyName: "placeholder", Value: "0 (___) ___ __ __" },
        { Id: 34916, PropertyName: "value", Value: "E-posta" },
        { Id: 824157, PropertyName: "label", Value: "Doğrula" },
        { Id: 932651, PropertyName: "label", Value: "Tekrar Gönder" },
        { Id: 189273, PropertyName: "value", Value: "Doğrulama Kodu" },
        { Id: 957358, PropertyName: "label", Value: "Onayla" },
        { Id: 905126, PropertyName: "value", Value: "İşleme devam için mail adresini doğrulayınız!" },
        { Id: 185079, PropertyName: "value", Value: "Şifre" },
        { Id: 434410, PropertyName: "placeholder", Value: "***********" },
        {
          Id: 437103,
          PropertyName: "value",
          Value:
            "Şifre uzunluğu en az 8 karakter olmalıdır, En az 1 büyük, 1 küçük harf ve 1 özel karakter içermelidir!"
        },
        { Id: 514230, PropertyName: "value", Value: "Üyelik Sözleşmesi Onayı" },
        { Id: 228598, PropertyName: "value", Value: "KVKK Metni Onayı" },
        { Id: 387716, PropertyName: "label", Value: "Kayıt Ol" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.signup_825972_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GenelParametreler: [],
      sbUyelikTipiList: [],
      ReadFromSessionStorage: "",
      SignupTempByEPosta: [],
      ChangeVisibilityOf: "",
      ChangeEnabledOf: "",
      NAVIGATE: "",
      ValidasyonKontrol: [],
      VCodeCreate: [],
      SendMailSettings: [],
      SendMail: false,
      VCodeControl: [],
      TriggerEvent: "",
      Notify: false,
      GsmKontrol: 0,
      fnPasswordControl: "",
      UyelikValidasyonKontrol: [],
      Signup: "",
      SaveUser: "",
      AddUserToRole: false,
      KullaniciDetaySave: 0,
      SignupTempSave: 0,
      WriteToSessionStorage: "",
      goToNextScreenAfterSignup: "",
      isComp46066Visible: "hidden",
      isComp377671Visible: "hidden",
      isComp824157Visible: "visible",
      isComp776110Visible: "hidden",
      isComp932651Visible: "hidden",
      isComp412471Visible: "visible",
      isComp387716Enabled: "enabled",
      isComp120930Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("signup", "");
      return;
    }

    if (true) {
      await this.SignupPageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("signup", "");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("387716");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("signup", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SignupPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      signup_825972_value: this.state.SignupTempByEPosta?.at?.(0)?.uyelikTipID ?? undefined,
      signup_590646_value: this.state.SignupTempByEPosta?.at?.(0)?.ad ?? undefined,
      signup_488466_value: this.state.SignupTempByEPosta?.at?.(0)?.soyad ?? undefined,
      signup_432007_value: this.state.SignupTempByEPosta?.at?.(0)?.telNo ?? undefined,
      signup_120930_value: this.state.SignupTempByEPosta?.at?.(0)?.ePosta ?? undefined,
      signup_300972_value: this.state.SignupTempByEPosta?.at?.(0)?.sozlesmeOK ?? undefined,
      signup_799782_value: this.state.SignupTempByEPosta?.at?.(0)?.kvkkok ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SignupPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.sbUyelikTipiList = result?.data.sbUyelikTipiList;

    formVars.signup_825972_options = stateVars.sbUyelikTipiList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("username");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      EPosta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SignupTempByEPosta = result?.data.signupTempByEPosta;
    formVars.signup_825972_value =
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.uyelikTipID : null;
    formVars.signup_590646_value = ReactSystemFunctions.toString(
      this,
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.ad : null
    );
    formVars.signup_488466_value = ReactSystemFunctions.toString(
      this,
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.soyad : null
    );
    formVars.signup_432007_value = ReactSystemFunctions.toString(
      this,
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.telNo : null
    );
    formVars.signup_120930_value = ReactSystemFunctions.toString(
      this,
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.ePosta : null
    );
    formVars.signup_300972_value = ReactSystemFunctions.value(
      this,
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.sozlesmeOK : null
    );
    formVars.signup_799782_value = ReactSystemFunctions.value(
      this,
      stateVars.SignupTempByEPosta?.length > 0 ? stateVars.SignupTempByEPosta[0]?.kvkkok : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp46066Visible", "hidden");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), null)) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp377671Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), null)) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp824157Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), null)) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp120930Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), null)) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp776110Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromSessionStorage), null)) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.signup_825972_value = ReactSystemFunctions.toString(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.uyelikTipID : null
    );

    stateVars.dataSource_825972 = this.state.sbUyelikTipiList;
    stateVars.dataSource_825972 = this.state.sbUyelikTipiList;
    formVars.signup_590646_value = ReactSystemFunctions.toString(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.ad : null
    );

    formVars.signup_488466_value = ReactSystemFunctions.toString(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.soyad : null
    );

    formVars.signup_432007_value = ReactSystemFunctions.toString(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.telNo : null
    );

    formVars.signup_120930_value = ReactSystemFunctions.toString(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.ePosta : null
    );

    formVars.signup_434410_value = ReactSystemFunctions.toString(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.pass : null
    );

    formVars.signup_300972_value = ReactSystemFunctions.value(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.sozlesmeOK : null
    );

    formVars.signup_799782_value = ReactSystemFunctions.value(
      this,
      this.state.SignupTempByEPosta?.length > 0 ? this.state.SignupTempByEPosta[0]?.kvkkok : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SignupComponent_452489_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_824157_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_825972_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm4_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm5_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      EMail_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      actionkey_2: ReactSystemFunctions.convertToTypeByName("maildogrulama", "string"),
      uyeliktipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipi")
        ),
        "string"
      ),
      numara_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_3: ReactSystemFunctions.convertToTypeByName("4854ef0f-7ec9-4d8b-b698-375546a3030c", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      uyeliktipi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipi")
        ),
        "string"
      ),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      EMail_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      VCode_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_584359_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_824157_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.VCodeCreate = result?.data.vCodeCreate;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    stateVars.VCodeControl = result?.data.vCodeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_824157_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_824157_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp824157Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp932651Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp46066Visible", "visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_932651_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.SignupComponent_824157_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_957358_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      VCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_584359_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_957358_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VCodeControl = result?.data.vCodeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_957358_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_957358_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "7dba7e4d_405c_7a89_b0dd_d9d4f9593441_notify",
          this.defaultML,
          "Doğrulandı!"
        ),
        "success",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "ddf8d5db_edff_db6b_392e_6258271077a2_notify",
          this.defaultML,
          "Doğrulanmadı!"
        ),
        "danger",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp932651Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp120930Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp46066Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_957358_onClick6_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp824157Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp776110Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp824157Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp412471Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_957358_onClick11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_957358_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp377671Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SignupComponent_434410_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "enabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_514230_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("PDFViewer", "FormTipi", "Uyelik");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "PDFViewer",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "979982",
      null,
      "left",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_228598_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("PDFViewer", "FormTipi", "KVKK");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "PDFViewer",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "806891",
      null,
      "left",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_387716_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_434410_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_825972_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_300972_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_300972_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_799782_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_799782_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Gsm_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      pass_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")),
        "string"
      ),
      prm1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "id")
        ),
        "string"
      ),
      prm2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      prm3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      prm4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      prm5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      prm6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")),
        "string"
      ),
      prm7_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_300972_value", "value", "", "", "")
        ),
        "string"
      ),
      prm8_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_799782_value", "value", "", "", "")
        ),
        "string"
      ),
      prm1_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "id")
        ),
        "string"
      ),
      prm2_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      prm3_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      prm4_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      prm5_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      prm6_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")),
        "string"
      ),
      prm7_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_300972_value", "value", "", "", "")
        ),
        "string"
      ),
      prm8_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_799782_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GsmKontrol = result?.data.gsmKontrol;
    stateVars.UyelikValidasyonKontrol = result?.data.uyelikValidasyonKontrol;
    stateVars.UyelikValidasyonKontrol = result?.data.uyelikValidasyonKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_434410_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "disabled");
    stateVars.Signup = (
      (await ReactSystemFunctions.signup(
        this,
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        true,
        "",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        ""
      )) as any
    ).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("24e0168b-c016-47bd-bb66-1b9a221775f2", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("82ece41e-1376-4d93-b482-9d8c0ea42a05", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "2"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("db775535-6f5f-4209-adfb-ec684cd0ffea", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "3"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick4_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("1071ed67-b27f-4e71-903f-b93884588091", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "7"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick5_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("4ca4a695-d85e-492a-b22b-42905dc01cec", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "4"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick6_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("89b72ab1-7aba-4f4b-9125-c176f00e949c", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "5"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick7_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("56d0a434-2975-4a71-913c-2623c157b257", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "6"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick8_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick9_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "1"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCI",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "2"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MATBAA",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "3"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCITEDARIKCITICARI",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "7"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCITEDARIKCISAHIS",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "4"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAZAR",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "5"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MUSTERITICARI",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "6"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MUSTERISAHIS",
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signup_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_825972_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_799782_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_799782_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_434410_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_300972_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_300972_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      KVKKOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      KVKKOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")),
        "string"
      ),
      UyelikTipiKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "uyelikTipiKey")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Ad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      EPosta_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      KVKKOK_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_799782_value", "value", "", "", "")),
        "boolean"
      ),
      Pass_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_434410_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      SozlesmeOK_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_300972_value", "value", "", "", "")),
        "boolean"
      ),
      TelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      UyelikTipID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_825972_value", "value", "sbUyelikTipiList", "id", "id")
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick16_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetaySave = result?.data.kullaniciDetaySave;
    stateVars.SignupTempSave = result?.data.signupTempSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick17_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage(
      "username",
      ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_120930_value", "value", "", "", ""))
    );
    stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage("ForgotmuSignupmi", "signup");
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterSignup(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 979982, 806891] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SignupPageInit();
    }
  }
}
