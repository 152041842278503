import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashBoardMusteri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashBoardMusteri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  DB_GetDateRangesNames: any[];
  DB_GetDateRangesNames_dummy: any[];
  KullaniciLocalStorageByUsername: any[];
  KullaniciLocalStorageByUsername_dummy: any[];
  DB_GetDateRanges: any[];
  DB_GetDateRanges_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  DBMatbaa_TeklifTaslakCount: any[];
  DBMatbaa_TeklifTaslakCount_dummy: any[];
  DBMatbaa_TeklifOnayCount: any[];
  DBMatbaa_TeklifOnayCount_dummy: any[];
  DBMatbaa_TeklifOnaylananCount: any[];
  DBMatbaa_TeklifOnaylananCount_dummy: any[];
  DBMatbaa_TeklifOnaylanmayanCount: any[];
  DBMatbaa_TeklifOnaylanmayanCount_dummy: any[];
  DBMatbaa_SiparisTaslakCount: any[];
  DBMatbaa_SiparisTaslakCount_dummy: any[];
  DBMatbaa_SiparisOnayCount: any[];
  DBMatbaa_SiparisOnayCount_dummy: any[];
  DBMatbaa_SiparisOnaylananCount: any[];
  DBMatbaa_SiparisOnaylananCount_dummy: any[];
  DBMatbaa_SiparisOnaylanmayanCount: any[];
  DBMatbaa_SiparisOnaylanmayanCount_dummy: any[];
  DBMatbaa_UretimBaslayanCount: any[];
  DBMatbaa_UretimBaslayanCount_dummy: any[];
  DBMatbaa_UretimBitenCount: any[];
  DBMatbaa_UretimBitenCount_dummy: any[];
  SetLanguage: string;
  SetLanguage_dummy: string;
  KullaniciLocalStorageSave: number;
  KullaniciLocalStorageSave_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  isComp23040Visible: "visible" | "hidden";
  isComp819129Visible: "visible" | "hidden";
}

export class DashBoardMusteri_ScreenBase extends React.PureComponent<IDashBoardMusteri_ScreenProps, any> {
  dashboardmusteri_569673_value_kuikaDateRef: React.RefObject<any>;
  dashboardmusteri_623159_value_kuikaDateRef: React.RefObject<any>;
  dashboardmusteri_803480_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardmusteri_767386_value_kuikaTableRef: React.RefObject<any>;
  dashboardmusteri_512534_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "a204d861-7db2-49f3-b566-761659b9572c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 881224, PropertyName: "label", Value: "Profil" },
        { Id: 310750, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 903529, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 569673, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 393824, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 623159, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 702346, PropertyName: "value", Value: "Dönem" },
        { Id: 803480, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 646501, PropertyName: "value", Value: "Müşteri Dashboard" },
        { Id: 642226, PropertyName: "value", Value: "Teklif" },
        { Id: 823445, PropertyName: "value", Value: "Taslak Teklif Sayısı" },
        { Id: 823798, PropertyName: "value", Value: "Onay Bekleyen Teklif Sayısı" },
        { Id: 644231, PropertyName: "value", Value: "Onaylanan Teklif Sayısı" },
        { Id: 676289, PropertyName: "value", Value: "Reddedilen Teklif Sayısı" },
        { Id: 328697, PropertyName: "value", Value: "Sipariş" },
        { Id: 854852, PropertyName: "value", Value: "Taslak Sipariş Sayısı" },
        { Id: 273919, PropertyName: "value", Value: "Onay Bekleyen Sipariş Sayısı" },
        { Id: 480480, PropertyName: "value", Value: "Onaylanan Sipariş Sayısı" },
        { Id: 534650, PropertyName: "value", Value: "Reddedilen Sipariş Sayısı" },
        { Id: 59984, PropertyName: "value", Value: "Üretim" },
        { Id: 990925, PropertyName: "value", Value: "Üretim İşlemleri Başlatılan" },
        { Id: 501012, PropertyName: "value", Value: "Üretim İşlemleri Biten" },
        { Id: 590128, PropertyName: "value", Value: "........................................." },
        { Id: 743535, PropertyName: "value", Value: "---" },
        { Id: 449815, PropertyName: "value", Value: "........................................." },
        { Id: 665321, PropertyName: "value", Value: "---" },
        { Id: 891654, PropertyName: "value", Value: "........................................." },
        { Id: 623036, PropertyName: "value", Value: "--" },
        { Id: 71802, PropertyName: "value", Value: "........................................." },
        { Id: 877467, PropertyName: "value", Value: "---" },
        { Id: 508416, PropertyName: "value", Value: "Formlar" },
        { Id: 78295, PropertyName: "label", Value: "Müşteri Bilgi" },
        { Id: 206153, PropertyName: "value", Value: "Üye Listesi" },
        { Id: 628761, PropertyName: "value", Value: "Tümü" },
        { Id: 767386, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 427279, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 770805, PropertyName: "value", Value: "Yayıncı" },
        { Id: 30995, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 740743, PropertyName: "value", Value: "Ali Mavişehir" },
        { Id: 592024, PropertyName: "title", Value: "E-posta" },
        { Id: 954695, PropertyName: "value", Value: "ali.mavisehir@mail.com" },
        { Id: 958550, PropertyName: "title", Value: "Telefon numarası" },
        { Id: 126558, PropertyName: "value", Value: "+90555 666 77 88" },
        { Id: 389173, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 805996, PropertyName: "value", Value: "Şirket Adı 1" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardmusteri_569673_value_kuikaDateRef = React.createRef();
    this.dashboardmusteri_623159_value_kuikaDateRef = React.createRef();
    this.dashboardmusteri_803480_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardmusteri_767386_value_kuikaTableRef = React.createRef();
    this.dashboardmusteri_512534_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      DB_GetDateRangesNames: [],
      KullaniciLocalStorageByUsername: [],
      DB_GetDateRanges: [],
      SetValueOf: "",
      DBMatbaa_TeklifTaslakCount: [],
      DBMatbaa_TeklifOnayCount: [],
      DBMatbaa_TeklifOnaylananCount: [],
      DBMatbaa_TeklifOnaylanmayanCount: [],
      DBMatbaa_SiparisTaslakCount: [],
      DBMatbaa_SiparisOnayCount: [],
      DBMatbaa_SiparisOnaylananCount: [],
      DBMatbaa_SiparisOnaylanmayanCount: [],
      DBMatbaa_UretimBaslayanCount: [],
      DBMatbaa_UretimBitenCount: [],
      SetLanguage: "",
      KullaniciLocalStorageSave: 0,
      TriggerEvent: "",
      isComp23040Visible: "hidden",
      isComp819129Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardmusteri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashBoardMusteriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardmusteri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardmusteri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashBoardMusteriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardmusteri_81624_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      dashboardmusteri_569673_value: this.state.DB_GetDateRanges?.at?.(0)?.baslangic ?? undefined,
      dashboardmusteri_623159_value: this.state.DB_GetDateRanges?.at?.(0)?.bitis ?? undefined,
      dashboardmusteri_803480_value: this.state.KullaniciLocalStorageByUsername?.at?.(0)?.sonTarihAraligi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashBoardMusteriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      userName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardMusteri/DashBoardMusteriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardmusteri_753768_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    formVars.dashboardmusteri_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.dashboardmusteri_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    stateVars.DB_GetDateRangesNames = result?.data.dB_GetDateRangesNames;

    formVars.dashboardmusteri_803480_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null;
    formVars.dashboardmusteri_803480_options = stateVars.DB_GetDateRangesNames;
    stateVars.KullaniciLocalStorageByUsername = result?.data.kullaniciLocalStorageByUsername;
    formVars.dashboardmusteri_803480_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null;
    formVars.dashboardmusteri_803480_options = stateVars.DB_GetDateRangesNames;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardmusteri_569673_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.baslangic : null
    );
    formVars.dashboardmusteri_623159_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.bitis : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMusteriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMusteriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmusteri_569673_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmusteri_623159_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMusteriPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardMusteriPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardMusteri/DashBoardMusteriPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardmusteri_987484_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0 ? stateVars.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardmusteri_760596_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0 ? stateVars.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardmusteri_428466_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardmusteri_813356_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardmusteri_886151_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0 ? stateVars.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardmusteri_149881_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0 ? stateVars.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardmusteri_352573_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardmusteri_101710_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardmusteri_798525_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0 ? stateVars.DBMatbaa_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardmusteri_332979_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0 ? stateVars.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMusteriPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMusteriPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    formVars.dashboardmusteri_753768_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    formVars.dashboardmusteri_81624_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.dashboardmusteri_980974_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.dashboardmusteri_569673_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
    );

    formVars.dashboardmusteri_623159_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
    );

    formVars.dashboardmusteri_803480_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciLocalStorageByUsername?.length > 0
        ? this.state.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null
    );

    stateVars.dataSource_803480 = this.state.DB_GetDateRangesNames;
    stateVars.dataSource_803480 = this.state.DB_GetDateRangesNames;
    formVars.dashboardmusteri_987484_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTaslakCount?.length > 0 ? this.state.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_760596_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnayCount?.length > 0 ? this.state.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_428466_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_813356_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_886151_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisTaslakCount?.length > 0 ? this.state.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_149881_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnayCount?.length > 0 ? this.state.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_352573_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_101710_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_798525_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_332979_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBitenCount?.length > 0 ? this.state.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashBoardMusteriComponent_284229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMusteri",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "997406",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMusteriComponent_881224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMusteri",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "931509",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMusteriComponent_310750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMusteriComponent_569673_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardMusteri/DashBoardMusteriComponent_569673_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardmusteri_987484_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0 ? stateVars.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardmusteri_760596_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0 ? stateVars.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardmusteri_428466_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardmusteri_813356_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardmusteri_886151_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0 ? stateVars.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardmusteri_149881_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0 ? stateVars.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardmusteri_352573_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardmusteri_101710_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardmusteri_798525_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0 ? stateVars.DBMatbaa_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardmusteri_332979_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0 ? stateVars.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMusteriComponent_569673_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMusteriComponent_569673_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardmusteri_987484_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTaslakCount?.length > 0 ? this.state.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_760596_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnayCount?.length > 0 ? this.state.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_428466_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_813356_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_886151_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisTaslakCount?.length > 0 ? this.state.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_149881_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnayCount?.length > 0 ? this.state.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_352573_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_101710_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_798525_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_332979_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBitenCount?.length > 0 ? this.state.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMusteriComponent_623159_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_569673_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmusteri_623159_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardMusteri/DashBoardMusteriComponent_623159_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardmusteri_987484_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0 ? stateVars.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardmusteri_760596_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0 ? stateVars.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardmusteri_428466_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardmusteri_813356_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardmusteri_886151_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0 ? stateVars.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardmusteri_149881_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0 ? stateVars.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardmusteri_352573_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardmusteri_101710_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardmusteri_798525_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0 ? stateVars.DBMatbaa_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardmusteri_332979_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0 ? stateVars.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMusteriComponent_623159_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMusteriComponent_623159_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardmusteri_987484_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTaslakCount?.length > 0 ? this.state.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_760596_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnayCount?.length > 0 ? this.state.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_428466_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_813356_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_886151_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisTaslakCount?.length > 0 ? this.state.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_149881_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnayCount?.length > 0 ? this.state.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmusteri_352573_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_101710_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_798525_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmusteri_332979_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBitenCount?.length > 0 ? this.state.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMusteriComponent_803480_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SonTarihAraligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardmusteri_803480_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      ),
      userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      RangeName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardmusteri_803480_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardMusteri/DashBoardMusteriComponent_803480_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciLocalStorageSave = result?.data.kullaniciLocalStorageSave;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardmusteri_569673_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.baslangic : null
    );
    formVars.dashboardmusteri_623159_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.bitis : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMusteriComponent_803480_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMusteriComponent_803480_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmusteri_569673_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmusteri_623159_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
      ),
      null
    );
    await this.DashBoardMusteriPageInit();

    formVars.dashboardmusteri_569673_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
    );

    formVars.dashboardmusteri_623159_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [997406, 931509] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashBoardMusteriPageInit();
    }
  }
}
