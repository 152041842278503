import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUretimListMatbaa_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUretimListMatbaa_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  UretimList: any[];
  UretimList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  UretimListToExcel: any[];
  UretimListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp600415Visible: "visible" | "hidden";
  isComp635818Visible: "visible" | "hidden";
  isComp883652Visible: "visible" | "hidden";
  isComp777470Visible: "visible" | "hidden";
  isComp629890Visible: "visible" | "hidden";
  isComp58979Visible: "visible" | "hidden";
  isComp607188Visible: "visible" | "hidden";
  isComp605832Visible: "visible" | "hidden";
  isComp272820Visible: "visible" | "hidden";
  isComp232235Visible: "visible" | "hidden";
  isComp539439Visible: "visible" | "hidden";
  isComp394716Visible: "visible" | "hidden";
  isComp436184Visible: "visible" | "hidden";
  isComp918979Visible: "visible" | "hidden";
  isComp895598Visible: "visible" | "hidden";
  isComp188052Visible: "visible" | "hidden";
  isComp384836Visible: "visible" | "hidden";
  isComp108902Visible: "visible" | "hidden";
  isComp326434Visible: "visible" | "hidden";
  isComp889561Visible: "visible" | "hidden";
  isComp832466Visible: "visible" | "hidden";
  isComp461437Visible: "visible" | "hidden";
  isComp269858Visible: "visible" | "hidden";
  isComp456334Visible: "visible" | "hidden";
  isComp931608Visible: "visible" | "hidden";
  isComp768574Visible: "visible" | "hidden";
  isComp754297Visible: "visible" | "hidden";
  isComp251583Visible: "visible" | "hidden";
  isComp942659Visible: "visible" | "hidden";
  isComp949801Visible: "visible" | "hidden";
  isComp218655Visible: "visible" | "hidden";
}

export class UretimListMatbaa_ScreenBase extends React.PureComponent<IUretimListMatbaa_ScreenProps, any> {
  uretimlistmatbaa_399453_value_kuikaSelectBoxRef: React.RefObject<any>;
  uretimlistmatbaa_208535_value_kuikaSelectBoxRef: React.RefObject<any>;
  uretimlistmatbaa_348605_value_kuikaTableRef: React.RefObject<any>;
  uretimlistmatbaa_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "983e19ad-68a8-4350-9907-df17b5d58a1a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Üretim Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 251306, PropertyName: "value", Value: "Üretimi tamamlanan kayıtları da göster" },
        { Id: 399453, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 208535, PropertyName: "placeholder", Value: "A-z" },
        { Id: 813190, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Üretim Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Talep No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 476271, PropertyName: "title", Value: "Teklif No" },
        { Id: 391582, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 71915, PropertyName: "title", Value: "Sipariş No" },
        { Id: 192399, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 833287, PropertyName: "title", Value: "Muhatap" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 256601, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 831413, PropertyName: "title", Value: "Ebat" },
        { Id: 607456, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 443115, PropertyName: "title", Value: "Kağıt" },
        { Id: 4509, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 588416, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 483237, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 160919, PropertyName: "title", Value: "Kapak" },
        { Id: 440348, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 951325, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 818291, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 902025, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 437356, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 869134, PropertyName: "title", Value: "Renk" },
        { Id: 438848, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 416909, PropertyName: "title", Value: "Selefon" },
        { Id: 431135, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 904525, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 766629, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 52036, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 257784, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 713444, PropertyName: "title", Value: "Bandrol" },
        { Id: 329790, PropertyName: "value", Value: "[datafield:bandrol]" },
        { Id: 727252, PropertyName: "title", Value: "Bandrol Durumu" },
        { Id: 913330, PropertyName: "value", Value: "[datafield:bandroldurum]" },
        { Id: 832752, PropertyName: "title", Value: "Tıpkı Basım mı?" },
        { Id: 549220, PropertyName: "value", Value: "[datafield:tipkibasimmi]" },
        { Id: 243022, PropertyName: "title", Value: "İç Görsel Geliş Tarihi" },
        { Id: 938169, PropertyName: "value", Value: "[datafield:icgorseleklenmemaxtarihi]" },
        { Id: 932249, PropertyName: "title", Value: "İç Görsel Onay/Montaj Tarihi" },
        { Id: 855208, PropertyName: "value", Value: "[datafield:icgorselonaytarihi]" },
        { Id: 923038, PropertyName: "title", Value: "Kapak Görsel Geliş Tarihi" },
        { Id: 810248, PropertyName: "value", Value: "[datafield:kapakgorseleklenmemaxtarihi]" },
        { Id: 209971, PropertyName: "title", Value: "Kapak Görsel Onay/Montaj Tarihi" },
        { Id: 617, PropertyName: "value", Value: "[datafield:kapakgorselonaytarihi]" },
        { Id: 990986, PropertyName: "title", Value: "İç Görsel Baskı Tarihi" },
        { Id: 252593, PropertyName: "value", Value: "[datafield:icgorselbaskitarihi]" },
        { Id: 260064, PropertyName: "title", Value: "Kapak Görsel Baskı Tarihi" },
        { Id: 26097, PropertyName: "value", Value: "[datafield:kapakgorselbaskitarihi]" },
        { Id: 760411, PropertyName: "title", Value: "Kapak Takma/Diliş Tarihi" },
        { Id: 259693, PropertyName: "value", Value: "[datafield:ciltlemetarihi]" },
        { Id: 46727, PropertyName: "title", Value: "Son Bıçak Tarihi" },
        { Id: 802076, PropertyName: "value", Value: "[datafield:sonbicaktarihi]" },
        { Id: 389888, PropertyName: "title", Value: "Bandrol Yapıştırıldı" },
        { Id: 149235, PropertyName: "value", Value: "[datafield:bandroltarihi]" },
        { Id: 332226, PropertyName: "title", Value: "B.Yapıştırma Adet" },
        { Id: 367836, PropertyName: "value", Value: "[datafield:bandrolyapistirmaadet]" },
        { Id: 192668, PropertyName: "title", Value: "Depoya Teslim Edildi" },
        { Id: 5490, PropertyName: "value", Value: "[datafield:depoteslimtarihi]" },
        { Id: 961959, PropertyName: "title", Value: "Üretim Tamamlandı" },
        { Id: 906310, PropertyName: "value", Value: "[datafield:uretimtamanlanmatarihi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  UretimListMatbaaRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.uretimlistmatbaa_399453_value_kuikaSelectBoxRef = React.createRef();
    this.uretimlistmatbaa_208535_value_kuikaSelectBoxRef = React.createRef();
    this.uretimlistmatbaa_348605_value_kuikaTableRef = React.createRef();
    this.uretimlistmatbaa_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      UretimList: [],
      setPTotalCount01: [],
      RoleSadeceGoruntule: [],
      ChangeVisibilityOf: "",
      UretimListToExcel: [],
      ExportExcel: "",
      dataSource_399453: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TalepNo", text: "Talep No" },
        { key: "SiparisNo", text: "Sipariş No" },
        { key: "Muhatap", text: "Muhatap" },
        { key: "IcGorselBaskiTarihi", text: "İç Görsel Baskı Tarihi" },
        { key: "KapakGorselBaskiTarihi", text: "Kapak Görsel Baskı Tarihi" },
        { key: "CiltlemeTarihi", text: "Ciltleme Tarihi" },
        { key: "SonBicakTarihi", text: "Son Bıçak Tarihi" },
        { key: "BandrolTarihi", text: "Bandrol Tarihi" },
        { key: "DepoTeslimTarihi", text: "Depo Teslim Tarihi" }
      ],
      dataSource_208535: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp600415Visible: "hidden",
      isComp635818Visible: "hidden",
      isComp883652Visible: "hidden",
      isComp777470Visible: "hidden",
      isComp629890Visible: "hidden",
      isComp58979Visible: "hidden",
      isComp607188Visible: "hidden",
      isComp605832Visible: "hidden",
      isComp272820Visible: "hidden",
      isComp232235Visible: "hidden",
      isComp539439Visible: "hidden",
      isComp394716Visible: "hidden",
      isComp436184Visible: "hidden",
      isComp918979Visible: "hidden",
      isComp895598Visible: "hidden",
      isComp188052Visible: "hidden",
      isComp384836Visible: "hidden",
      isComp108902Visible: "hidden",
      isComp326434Visible: "hidden",
      isComp889561Visible: "hidden",
      isComp832466Visible: "hidden",
      isComp461437Visible: "hidden",
      isComp269858Visible: "hidden",
      isComp456334Visible: "hidden",
      isComp931608Visible: "hidden",
      isComp768574Visible: "hidden",
      isComp754297Visible: "hidden",
      isComp251583Visible: "hidden",
      isComp942659Visible: "hidden",
      isComp949801Visible: "hidden",
      isComp218655Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uretimlistmatbaa", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UretimListMatbaaPageInit();
    }

    if (false) {
      if (false) {
        await this.UretimListMatbaaRecuringAction_30_();
      }
      this.UretimListMatbaaRecuringAction_30_Interval = setInterval(async () => {
        await this.UretimListMatbaaRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("uretimlistmatbaa", "");
  }

  componentWillUnmount() {
    if (this.UretimListMatbaaRecuringAction_30_Interval) {
      clearInterval(this.UretimListMatbaaRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uretimlistmatbaa", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UretimListMatbaaPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.UretimListMatbaaRecuringAction_30_();
      }
      this.UretimListMatbaaRecuringAction_30_Interval = setInterval(async () => {
        await this.UretimListMatbaaRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uretimlistmatbaa_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      uretimlistmatbaa_714155_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UretimListMatbaaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UretimTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimListMatbaa/UretimListMatbaaPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.uretimlistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.uretimlistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.uretimlistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg071 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp600415Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListMatbaaPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListMatbaaPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimListMatbaaPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg071 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp600415Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListMatbaaPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListMatbaaPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimListMatbaaPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uretimlistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.uretimlistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.uretimlistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.UretimList;
    stateVars.isComp635818Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "bandrol"),
        "VAR"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp883652Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isBandrolBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp777470Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isBandrolBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629890Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "icGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp58979Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "icGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp607188Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "icGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp605832Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "icGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp272820Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "kapakGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp232235Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "kapakGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp539439Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "kapakGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp394716Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "kapakGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp436184Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "icGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918979Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "icGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895598Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "kapakGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188052Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "kapakGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp384836Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "ciltlemeTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108902Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "ciltlemeTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326434Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "sonBicakTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp889561Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "sonBicakTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp832466Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "bandrolTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp461437Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "bandrolTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269858Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "depoTeslimTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456334Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "depoTeslimTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp931608Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isTamamlandiIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp768574Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isTamamlandiIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942659Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isMatbaaInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600415Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp949801Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "isUretimEditIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218655Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "uretimTamanlandi"),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.uretimlistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UretimListMatbaaComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimListMatbaa",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimListMatbaa",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "181938",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_714155_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaComponent_714155_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaComponent_714155_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaComponent_714155_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_399453_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaComponent_399453_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaComponent_399453_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaComponent_399453_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_208535_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaComponent_208535_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaComponent_208535_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaComponent_208535_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_221648_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaComponent_221648_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaComponent_221648_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaComponent_221648_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_483757_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaComponent_483757_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimListToExcel = result?.data.uretimListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  UretimListMatbaaComponent_778259_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "TaslakmiKartmi", "Kart");
    KuikaAppManager.addToPageInputVariables(
      "TSiparisKart",
      "SiparisKartId",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "siparisKartId")
    );
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "FirmaAd", "-");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimListMatbaa",
      "TSiparisKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "880895",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_942659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimListMatbaa",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180819",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_949801_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UretimDetayKart",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables(
      "UretimDetayKart",
      "siparisNo",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "siparis_No")
    );
    KuikaAppManager.addToPageInputVariables("UretimDetayKart", "NewEditDisplay", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimListMatbaa",
      "UretimDetayKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "142528",
      null,
      "right",
      null,
      "33%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_218655_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UretimDetayKart",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables(
      "UretimDetayKart",
      "siparisNo",
      ReactSystemFunctions.value(this, "uretimlistmatbaa_348605_value", "siparis_No")
    );
    KuikaAppManager.addToPageInputVariables("UretimDetayKart", "NewEditDisplay", "D");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimListMatbaa",
      "UretimDetayKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "301227",
      null,
      "right",
      null,
      "33%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListMatbaaRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Firma", "string"),
      UretimTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_714155_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_813190_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_399453_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_208535_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlistmatbaa_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UretimListMatbaa/UretimListMatbaaRecuringAction_30_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.uretimlistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.uretimlistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.uretimlistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListMatbaaRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListMatbaaRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uretimlistmatbaa_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.uretimlistmatbaa_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.uretimlistmatbaa_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlistmatbaa_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 181938, 880895, 180819, 142528, 301227] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UretimListMatbaaPageInit();
    }
  }
}
