import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBaskiOncesiList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBaskiOncesiList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  BaskiOncesiList: any[];
  BaskiOncesiList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  BaskiOncesiListToExcel: any[];
  BaskiOncesiListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp600415Visible: "visible" | "hidden";
  isComp251583Visible: "visible" | "hidden";
  isComp266720Visible: "visible" | "hidden";
  isComp76521Visible: "visible" | "hidden";
  isComp566788Visible: "visible" | "hidden";
  isComp168118Visible: "visible" | "hidden";
  isComp285705Visible: "visible" | "hidden";
  isComp989604Visible: "visible" | "hidden";
  isComp768830Visible: "visible" | "hidden";
  isComp942659Visible: "visible" | "hidden";
  isComp713278Visible: "visible" | "hidden";
  isComp949801Visible: "visible" | "hidden";
}

export class BaskiOncesiList_ScreenBase extends React.PureComponent<IBaskiOncesiList_ScreenProps, any> {
  baskioncesilist_749588_value_kuikaSelectBoxRef: React.RefObject<any>;
  baskioncesilist_858270_value_kuikaSelectBoxRef: React.RefObject<any>;
  baskioncesilist_348605_value_kuikaTableRef: React.RefObject<any>;
  baskioncesilist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "65edf89b-3607-4620-8e37-3f85411b779c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Baskı Öncesi - Görsel Montaj  Listesi (Yayıncı)" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 980414, PropertyName: "value", Value: "Onay/Montaj tamamlanan kayıtları da göster" },
        { Id: 749588, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 858270, PropertyName: "placeholder", Value: "A-z" },
        { Id: 985995, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Baskı Öncesi Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Talep No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 476271, PropertyName: "title", Value: "Teklif No" },
        { Id: 391582, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 71915, PropertyName: "title", Value: "Sipariş No" },
        { Id: 192399, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 833287, PropertyName: "title", Value: "Firma" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 256601, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 831413, PropertyName: "title", Value: "Ebat" },
        { Id: 607456, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 443115, PropertyName: "title", Value: "Kağıt" },
        { Id: 4509, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 588416, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 483237, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 160919, PropertyName: "title", Value: "Kapak" },
        { Id: 440348, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 951325, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 818291, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 902025, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 57233, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 869134, PropertyName: "title", Value: "Renk" },
        { Id: 438848, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 416909, PropertyName: "title", Value: "Selefon" },
        { Id: 431135, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 904525, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 766629, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 52036, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 257784, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 713444, PropertyName: "title", Value: "Bandrol" },
        { Id: 329790, PropertyName: "value", Value: "[datafield:bandrol]" },
        { Id: 374524, PropertyName: "title", Value: "Bandrol Durumu" },
        { Id: 152884, PropertyName: "value", Value: "[datafield:bandroldurum]" },
        { Id: 832752, PropertyName: "title", Value: "Tıpkı Basım mı?" },
        { Id: 549220, PropertyName: "value", Value: "[datafield:tipkibasimmi]" },
        { Id: 765073, PropertyName: "title", Value: "İç Görsel / Montaj Durum" },
        { Id: 767679, PropertyName: "value", Value: "[datafield:baskioncesidurumic]" },
        { Id: 243022, PropertyName: "title", Value: "Kapak Görseli / Montaj Durumu" },
        { Id: 335698, PropertyName: "value", Value: "[datafield:baskioncesidurumkapak]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  BaskiOncesiListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.baskioncesilist_749588_value_kuikaSelectBoxRef = React.createRef();
    this.baskioncesilist_858270_value_kuikaSelectBoxRef = React.createRef();
    this.baskioncesilist_348605_value_kuikaTableRef = React.createRef();
    this.baskioncesilist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      BaskiOncesiList: [],
      setPTotalCount01: [],
      RoleSadeceGoruntule: [],
      ChangeVisibilityOf: "",
      BaskiOncesiListToExcel: [],
      ExportExcel: "",
      dataSource_749588: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TalepNo", text: "Talep No" },
        { key: "SiparisNo", text: "Sipariş No" },
        { key: "Firma", text: "Firma" }
      ],
      dataSource_858270: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp600415Visible: "visible",
      isComp251583Visible: "visible",
      isComp266720Visible: "hidden",
      isComp76521Visible: "hidden",
      isComp566788Visible: "hidden",
      isComp168118Visible: "hidden",
      isComp285705Visible: "hidden",
      isComp989604Visible: "hidden",
      isComp768830Visible: "hidden",
      isComp942659Visible: "hidden",
      isComp713278Visible: "hidden",
      isComp949801Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("baskioncesilist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BaskiOncesiListPageInit();
    }

    if (false) {
      if (false) {
        await this.BaskiOncesiListRecuringAction_30_();
      }
      this.BaskiOncesiListRecuringAction_30_Interval = setInterval(async () => {
        await this.BaskiOncesiListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("baskioncesilist", "");
  }

  componentWillUnmount() {
    if (this.BaskiOncesiListRecuringAction_30_Interval) {
      clearInterval(this.BaskiOncesiListRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("baskioncesilist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BaskiOncesiListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.BaskiOncesiListRecuringAction_30_();
      }
      this.BaskiOncesiListRecuringAction_30_Interval = setInterval(async () => {
        await this.BaskiOncesiListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      baskioncesilist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      baskioncesilist_807062_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  BaskiOncesiListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BaskiOncesiList/BaskiOncesiListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.baskioncesilist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.baskioncesilist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.baskioncesilist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg062 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp600415Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BaskiOncesiListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg062 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp600415Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BaskiOncesiListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg062 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp251583Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BaskiOncesiListPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg062 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp251583Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BaskiOncesiListPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BaskiOncesiListPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.baskioncesilist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.baskioncesilist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.baskioncesilist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;
    stateVars.isComp266720Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "bandrol"),
        "VAR"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp76521Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isBandrolBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp566788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isBaskiOncesiIcBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp168118Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isBaskiOncesiIcOnayIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285705Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isBaskiOncesiKapakBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989604Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isBaskiOncesiKapakOnayIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp768830Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942659Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isMatbaaInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp713278Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp949801Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "isBaskiOncesiEditIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.baskioncesilist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BaskiOncesiListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BaskiOncesiList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BaskiOncesiList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "419960",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_807062_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BaskiOncesiList/BaskiOncesiListComponent_807062_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListComponent_807062_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListComponent_807062_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;
    formVars.baskioncesilist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_749588_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BaskiOncesiList/BaskiOncesiListComponent_749588_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListComponent_749588_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListComponent_749588_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;
    formVars.baskioncesilist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_858270_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BaskiOncesiList/BaskiOncesiListComponent_858270_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListComponent_858270_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListComponent_858270_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;
    formVars.baskioncesilist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_382558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BaskiOncesiList/BaskiOncesiListComponent_382558_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListComponent_382558_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListComponent_382558_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;
    formVars.baskioncesilist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_79309_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      OnayMontajTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BaskiOncesiList/BaskiOncesiListComponent_79309_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaskiOncesiListToExcel = result?.data.baskiOncesiListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BaskiOncesiListComponent_778259_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "TaslakmiKartmi", "Kart");
    KuikaAppManager.addToPageInputVariables(
      "TSiparisKart",
      "SiparisKartId",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "siparisKartId")
    );
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "FirmaAd", "-");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BaskiOncesiList",
      "TSiparisKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "880895",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_942659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BaskiOncesiList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180819",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_949801_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "BOD");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "baskioncesilist_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BaskiOncesiList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "506424",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BaskiOncesiList/BaskiOncesiListComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BaskiOncesiListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      OnayMontajTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_807062_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_985995_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_749588_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "baskioncesilist_858270_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BaskiOncesiList/BaskiOncesiListRecuringAction_30_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.baskioncesilist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.baskioncesilist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.baskioncesilist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.BaskiOncesiList = result?.data.baskiOncesiList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BaskiOncesiListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BaskiOncesiListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.baskioncesilist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.baskioncesilist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.baskioncesilist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.BaskiOncesiList;
    formVars.baskioncesilist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 419960, 880895, 180819, 506424] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.BaskiOncesiListPageInit();
    }
  }
}
