import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISikayetList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISikayetList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  OneriSikayetKategori: any[];
  OneriSikayetKategori_dummy: any[];
  SikayetSearchRoleDependent: any[];
  SikayetSearchRoleDependent_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  isComp550872Visible: "visible" | "hidden";
  isComp474332Visible: "visible" | "hidden";
  isComp578122Visible: "visible" | "hidden";
  isComp810534Visible: "visible" | "hidden";
  isComp987933Visible: "visible" | "hidden";
  isComp844122Visible: "visible" | "hidden";
  isComp697497Visible: "visible" | "hidden";
}

export class SikayetList_ScreenBase extends React.PureComponent<ISikayetList_ScreenProps, any> {
  sikayetlist_135577_value_kuikaSelectBoxRef: React.RefObject<any>;
  sikayetlist_243705_value_kuikaTableRef: React.RefObject<any>;
  sikayetlist_499837_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "65343f21-2d57-4ebb-a0b3-369fe33e2722",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 190864, PropertyName: "value", Value: "Yardım ve Destek" },
        { Id: 956331, PropertyName: "label", Value: "Profil" },
        { Id: 353870, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 135577, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 740834, PropertyName: "label", Value: "Ara" },
        { Id: 474332, PropertyName: "label", Value: "Talep Oluştur" },
        { Id: 243705, PropertyName: "nodatafoundmessage", Value: "Yardım/Destek Kaydı Bulunamadı!" },
        { Id: 882612, PropertyName: "title", Value: "Title" },
        { Id: 221004, PropertyName: "value", Value: "[datafield:baslik]" },
        { Id: 810534, PropertyName: "value", Value: "Cevapla" },
        { Id: 134159, PropertyName: "value", Value: "[datafield:kategoritanim]" },
        { Id: 380438, PropertyName: "value", Value: "[datafield:onerisikayet]" },
        { Id: 831601, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 428178, PropertyName: "value", Value: "[datafield:kullanicitamadi]" },
        { Id: 517641, PropertyName: "value", Value: "[datafield:createdat]" },
        {
          Id: 844122,
          PropertyName: "value",
          Value:
            "Talebiniz tarafımıza ulaşmıştır. Ekibimiz en kısa sürede size geri dönüş yapacaktır. İlginiz ve anlayışınız için teşekkür ederiz."
        },
        { Id: 43555, PropertyName: "value", Value: "[datafield:adminadi]" },
        { Id: 742225, PropertyName: "value", Value: "[datafield:cevapzamanformat]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  SikayetListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.sikayetlist_135577_value_kuikaSelectBoxRef = React.createRef();
    this.sikayetlist_243705_value_kuikaTableRef = React.createRef();
    this.sikayetlist_499837_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      OneriSikayetKategori: [],
      SikayetSearchRoleDependent: [],
      setPTotalCount01: [],
      isComp550872Visible: "hidden",
      isComp474332Visible: "hidden",
      isComp578122Visible: "hidden",
      isComp810534Visible: "hidden",
      isComp987933Visible: "hidden",
      isComp844122Visible: "hidden",
      isComp697497Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sikayetlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SikayetListPageInit();
    }

    if (false) {
      if (false) {
        await this.SikayetListRecuringAction_30_();
      }
      this.SikayetListRecuringAction_30_Interval = setInterval(async () => {
        await this.SikayetListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("sikayetlist", "");
  }

  componentWillUnmount() {
    if (this.SikayetListRecuringAction_30_Interval) {
      clearInterval(this.SikayetListRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("sikayetlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SikayetListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.SikayetListRecuringAction_30_();
      }
      this.SikayetListRecuringAction_30_Interval = setInterval(async () => {
        await this.SikayetListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sikayetlist_962544_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SikayetListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_970793_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_135577_value", "value", "OneriSikayetKategori", "id", "id")
        ),
        "Guid"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetList/SikayetListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.sikayetlist_962544_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.sikayetlist_98455_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.sikayetlist_11923_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.isComp474332Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp578122Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp550872Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg102 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.OneriSikayetKategori = result?.data.oneriSikayetKategori;

    formVars.sikayetlist_135577_options = stateVars.OneriSikayetKategori;
    stateVars.SikayetSearchRoleDependent = result?.data.sikayetSearchRoleDependent;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.sikayetlist_962544_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.sikayetlist_98455_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.sikayetlist_11923_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_135577 = this.state.OneriSikayetKategori;
    stateVars.dataSource_135577 = this.state.OneriSikayetKategori;
    stateVars.isComp550872Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg102 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp474332Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_243705 = this.state.SikayetSearchRoleDependent;
    stateVars.isComp578122Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp810534Visible =
      ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "sikayetlist_243705_value", "cevap"), null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987933Visible =
      ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "sikayetlist_243705_value", "cevap"), null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp844122Visible =
      ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "sikayetlist_243705_value", "cevap"), null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp697497Visible =
      ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "sikayetlist_243705_value", "cevap"), null) ===
      true
        ? "visible"
        : "hidden";
    formVars.sikayetlist_43555_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0 ? this.state.SikayetSearchRoleDependent[0]?.adminAdi : null
    );

    formVars.sikayetlist_742225_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0
        ? this.state.SikayetSearchRoleDependent[0]?.cevapZamanFormat
        : null
    );

    formVars.sikayetlist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SikayetListComponent_312666_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SikayetList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "158742",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_956331_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SikayetList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "356141",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_353870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_135577_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      role_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      ),
      currentID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_970793_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_135577_value", "value", "OneriSikayetKategori", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetList/SikayetListComponent_135577_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SikayetSearchRoleDependent = result?.data.sikayetSearchRoleDependent;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetListComponent_135577_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetListComponent_135577_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_243705 = this.state.SikayetSearchRoleDependent;
    formVars.sikayetlist_43555_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0 ? this.state.SikayetSearchRoleDependent[0]?.adminAdi : null
    );

    formVars.sikayetlist_742225_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0
        ? this.state.SikayetSearchRoleDependent[0]?.cevapZamanFormat
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_740834_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      role_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      ),
      currentID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_970793_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_135577_value", "value", "OneriSikayetKategori", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetList/SikayetListComponent_740834_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SikayetSearchRoleDependent = result?.data.sikayetSearchRoleDependent;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetListComponent_740834_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetListComponent_740834_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_243705 = this.state.SikayetSearchRoleDependent;
    formVars.sikayetlist_43555_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0 ? this.state.SikayetSearchRoleDependent[0]?.adminAdi : null
    );

    formVars.sikayetlist_742225_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0
        ? this.state.SikayetSearchRoleDependent[0]?.cevapZamanFormat
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_474332_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SikayetKart", "SikayetId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SikayetList",
      "SikayetKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "680307",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_810534_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SikayetKart",
      "SikayetId",
      ReactSystemFunctions.value(this, "sikayetlist_243705_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SikayetList",
      "SikayetKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "319936",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_987933_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SikayetKart",
      "SikayetId",
      ReactSystemFunctions.value(this, "sikayetlist_243705_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SikayetList",
      "SikayetKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "332567",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListComponent_499837_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      role_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      ),
      currentID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_970793_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_135577_value", "value", "OneriSikayetKategori", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetList/SikayetListComponent_499837_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SikayetSearchRoleDependent = result?.data.sikayetSearchRoleDependent;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetListComponent_499837_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetListComponent_499837_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_243705 = this.state.SikayetSearchRoleDependent;
    formVars.sikayetlist_43555_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0 ? this.state.SikayetSearchRoleDependent[0]?.adminAdi : null
    );

    formVars.sikayetlist_742225_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0
        ? this.state.SikayetSearchRoleDependent[0]?.cevapZamanFormat
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SikayetListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_970793_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_135577_value", "value", "OneriSikayetKategori", "id", "id")
        ),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sikayetlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SikayetList/SikayetListRecuringAction_30_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.sikayetlist_962544_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.sikayetlist_98455_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.sikayetlist_11923_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.isComp474332Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp578122Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "ADMIIN"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SikayetSearchRoleDependent = result?.data.sikayetSearchRoleDependent;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SikayetListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SikayetListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.sikayetlist_962544_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.sikayetlist_98455_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.sikayetlist_11923_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_243705 = this.state.SikayetSearchRoleDependent;
    formVars.sikayetlist_43555_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0 ? this.state.SikayetSearchRoleDependent[0]?.adminAdi : null
    );

    formVars.sikayetlist_742225_value = ReactSystemFunctions.value(
      this,
      this.state.SikayetSearchRoleDependent?.length > 0
        ? this.state.SikayetSearchRoleDependent[0]?.cevapZamanFormat
        : null
    );

    formVars.sikayetlist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [158742, 356141, 680307, 319936, 332567] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SikayetListPageInit();
    }
  }
}
