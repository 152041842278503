import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUretimList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUretimList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  UretimList: any[];
  UretimList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  UretimListToExcel: any[];
  UretimListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp600415Visible: "visible" | "hidden";
  isComp251583Visible: "visible" | "hidden";
  isComp327210Visible: "visible" | "hidden";
  isComp696424Visible: "visible" | "hidden";
  isComp949042Visible: "visible" | "hidden";
  isComp340300Visible: "visible" | "hidden";
  isComp922425Visible: "visible" | "hidden";
  isComp461935Visible: "visible" | "hidden";
  isComp448901Visible: "visible" | "hidden";
  isComp496039Visible: "visible" | "hidden";
  isComp191379Visible: "visible" | "hidden";
  isComp979757Visible: "visible" | "hidden";
  isComp786337Visible: "visible" | "hidden";
  isComp559078Visible: "visible" | "hidden";
  isComp274873Visible: "visible" | "hidden";
  isComp941492Visible: "visible" | "hidden";
  isComp216336Visible: "visible" | "hidden";
  isComp980585Visible: "visible" | "hidden";
  isComp221323Visible: "visible" | "hidden";
  isComp198947Visible: "visible" | "hidden";
  isComp334535Visible: "visible" | "hidden";
  isComp948245Visible: "visible" | "hidden";
  isComp787881Visible: "visible" | "hidden";
  isComp439282Visible: "visible" | "hidden";
  isComp362765Visible: "visible" | "hidden";
  isComp225137Visible: "visible" | "hidden";
  isComp312613Visible: "visible" | "hidden";
  isComp754297Visible: "visible" | "hidden";
  isComp942659Visible: "visible" | "hidden";
}

export class UretimList_ScreenBase extends React.PureComponent<IUretimList_ScreenProps, any> {
  uretimlist_588259_value_kuikaSelectBoxRef: React.RefObject<any>;
  uretimlist_270508_value_kuikaSelectBoxRef: React.RefObject<any>;
  uretimlist_348605_value_kuikaTableRef: React.RefObject<any>;
  uretimlist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "885befd1-92c4-442f-a78c-62b1acf575e3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Üretim Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 891246, PropertyName: "value", Value: "Üretimi tamamlanan kayıtları da göster" },
        { Id: 588259, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 270508, PropertyName: "placeholder", Value: "A-z" },
        { Id: 208998, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Üretim Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Talep No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 476271, PropertyName: "title", Value: "Teklif No" },
        { Id: 391582, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 71915, PropertyName: "title", Value: "Sipariş No" },
        { Id: 192399, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 833287, PropertyName: "title", Value: "Firma" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 256601, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 831413, PropertyName: "title", Value: "Ebat" },
        { Id: 607456, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 443115, PropertyName: "title", Value: "Kağıt" },
        { Id: 4509, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 588416, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 483237, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 160919, PropertyName: "title", Value: "Kapak" },
        { Id: 440348, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 951325, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 818291, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 902025, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 57233, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 869134, PropertyName: "title", Value: "Renk" },
        { Id: 438848, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 416909, PropertyName: "title", Value: "Selefon" },
        { Id: 431135, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 904525, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 766629, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 52036, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 257784, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 713444, PropertyName: "title", Value: "Bandrol" },
        { Id: 329790, PropertyName: "value", Value: "[datafield:bandrol]" },
        { Id: 535004, PropertyName: "title", Value: "Bandrol Durumu" },
        { Id: 408693, PropertyName: "value", Value: "[datafield:bandroldurum]" },
        { Id: 832752, PropertyName: "title", Value: "Tıpkı Basım mı?" },
        { Id: 549220, PropertyName: "value", Value: "[datafield:tipkibasimmi]" },
        { Id: 243022, PropertyName: "title", Value: "İç Görsel Geliş Tarihi" },
        { Id: 938169, PropertyName: "value", Value: "[datafield:icgorseleklenmemaxtarihi]" },
        { Id: 923038, PropertyName: "title", Value: "Kapak Görsel Geliş Tarihi" },
        { Id: 810248, PropertyName: "value", Value: "[datafield:kapakgorseleklenmemaxtarihi]" },
        { Id: 932249, PropertyName: "title", Value: "İç Görsel Onay/Montaj Tarihi" },
        { Id: 855208, PropertyName: "value", Value: "[datafield:icgorselonaytarihi]" },
        { Id: 209971, PropertyName: "title", Value: "Kapak Görsel Onay/Montaj Tarihi" },
        { Id: 617, PropertyName: "value", Value: "[datafield:kapakgorselonaytarihi]" },
        { Id: 990986, PropertyName: "title", Value: "İç Görsel Baskı Tarihi" },
        { Id: 252593, PropertyName: "value", Value: "[datafield:icgorselbaskitarihi]" },
        { Id: 260064, PropertyName: "title", Value: "Kapak Görsel Baskı Tarihi" },
        { Id: 26097, PropertyName: "value", Value: "[datafield:kapakgorselbaskitarihi]" },
        { Id: 760411, PropertyName: "title", Value: "Kapak Takma/Diliş Tarihi" },
        { Id: 259693, PropertyName: "value", Value: "[datafield:ciltlemetarihi]" },
        { Id: 46727, PropertyName: "title", Value: "Son Bıçak Tarihi" },
        { Id: 802076, PropertyName: "value", Value: "[datafield:sonbicaktarihi]" },
        { Id: 389888, PropertyName: "title", Value: "Bandrol Yapıştırıldı" },
        { Id: 149235, PropertyName: "value", Value: "[datafield:bandroltarihi]" },
        { Id: 902989, PropertyName: "title", Value: "B.Yapıştırma Adet" },
        { Id: 372075, PropertyName: "value", Value: "[datafield:bandrolyapistirmaadet]" },
        { Id: 192668, PropertyName: "title", Value: "Depoya Teslim Edildi" },
        { Id: 5490, PropertyName: "value", Value: "[datafield:depoteslimtarihi]" },
        { Id: 246286, PropertyName: "title", Value: "Üretim Tamamlandı" },
        { Id: 220392, PropertyName: "value", Value: "[datafield:uretimtamanlanmatarihi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  UretimListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.uretimlist_588259_value_kuikaSelectBoxRef = React.createRef();
    this.uretimlist_270508_value_kuikaSelectBoxRef = React.createRef();
    this.uretimlist_348605_value_kuikaTableRef = React.createRef();
    this.uretimlist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      UretimList: [],
      setPTotalCount01: [],
      ChangeVisibilityOf: "",
      UretimListToExcel: [],
      ExportExcel: "",
      dataSource_588259: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TalepNo", text: "Talep No" },
        { key: "SiparisNo", text: "Sipariş No" },
        { key: "Firma", text: "Firma" },
        { key: "IcGorselBaskiTarihi", text: "İç Görsel Baskı Tarihi" },
        { key: "KapakGorselBaskiTarihi", text: "Kapak Görsel Baskı Tarihi" },
        { key: "CiltlemeTarihi", text: "Ciltleme Tarihi" },
        { key: "SonBicakTarihi", text: "Son Bıçak Tarihi" },
        { key: "BandrolTarihi", text: "Bandrol Tarihi" },
        { key: "DepoTeslimTarihi", text: "Depo Teslim Tarihi" }
      ],
      dataSource_270508: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp600415Visible: "hidden",
      isComp251583Visible: "hidden",
      isComp327210Visible: "hidden",
      isComp696424Visible: "hidden",
      isComp949042Visible: "hidden",
      isComp340300Visible: "hidden",
      isComp922425Visible: "hidden",
      isComp461935Visible: "hidden",
      isComp448901Visible: "hidden",
      isComp496039Visible: "hidden",
      isComp191379Visible: "hidden",
      isComp979757Visible: "hidden",
      isComp786337Visible: "hidden",
      isComp559078Visible: "hidden",
      isComp274873Visible: "hidden",
      isComp941492Visible: "hidden",
      isComp216336Visible: "hidden",
      isComp980585Visible: "hidden",
      isComp221323Visible: "hidden",
      isComp198947Visible: "hidden",
      isComp334535Visible: "hidden",
      isComp948245Visible: "hidden",
      isComp787881Visible: "hidden",
      isComp439282Visible: "hidden",
      isComp362765Visible: "hidden",
      isComp225137Visible: "hidden",
      isComp312613Visible: "hidden",
      isComp754297Visible: "hidden",
      isComp942659Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uretimlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UretimListPageInit();
    }

    if (false) {
      if (false) {
        await this.UretimListRecuringAction_30_();
      }
      this.UretimListRecuringAction_30_Interval = setInterval(async () => {
        await this.UretimListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("uretimlist", "");
  }

  componentWillUnmount() {
    if (this.UretimListRecuringAction_30_Interval) {
      clearInterval(this.UretimListRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uretimlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UretimListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.UretimListRecuringAction_30_();
      }
      this.UretimListRecuringAction_30_Interval = setInterval(async () => {
        await this.UretimListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uretimlist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      uretimlist_206848_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UretimListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_2: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UretimTamamGoster_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.uretimlist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.uretimlist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.uretimlist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg072 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp600415Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg072 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp600415Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg072 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp251583Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimListPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg072 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp251583Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UretimListPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UretimListPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uretimlist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.uretimlist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.uretimlist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.UretimList;
    stateVars.isComp327210Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "uretimlist_348605_value", "bandrol"), "VAR") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp696424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isBandrolBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp949042Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isBandrolBeklemedeIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp340300Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "icGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp922425Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "icGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp461935Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "kapakGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp448901Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "kapakGorselEklenmeMaxTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp496039Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "icGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp191379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "icGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp979757Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "kapakGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp786337Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "kapakGorselOnayTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559078Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "icGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274873Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "icGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp941492Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "kapakGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216336Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "kapakGorselBaskiTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp980585Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "ciltlemeTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp221323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "ciltlemeTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198947Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "sonBicakTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp334535Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "sonBicakTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp948245Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "bandrolTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787881Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "bandrolTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439282Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "depoTeslimTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp362765Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "depoTeslimTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225137Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isTamamlandiIconsVisibility"),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp312613Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isTamamlandiIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942659Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isMatbaaInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600415Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "uretimlist_348605_value", "isInceleIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.uretimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UretimListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "720127",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_206848_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListComponent_206848_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListComponent_206848_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListComponent_206848_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_588259_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListComponent_588259_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListComponent_588259_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListComponent_588259_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_270508_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListComponent_270508_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListComponent_270508_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListComponent_270508_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_279959_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListComponent_279959_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListComponent_279959_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListComponent_279959_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_308687_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListComponent_308687_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimListToExcel = result?.data.uretimListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  UretimListComponent_778259_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "TaslakmiKartmi", "Kart");
    KuikaAppManager.addToPageInputVariables(
      "TSiparisKart",
      "SiparisKartId",
      ReactSystemFunctions.value(this, "uretimlist_348605_value", "siparisKartId")
    );
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TSiparisKart", "FirmaAd", "-");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimList",
      "TSiparisKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "880895",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_942659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "uretimlist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "teklifNo",
      ReactSystemFunctions.value(this, "uretimlist_348605_value", "teklif_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "uyeAd",
      ReactSystemFunctions.value(this, "uretimlist_348605_value", "firma")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180819",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_949801_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UretimDetayKart",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "uretimlist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables(
      "UretimDetayKart",
      "siparisNo",
      ReactSystemFunctions.value(this, "uretimlist_348605_value", "siparis_No")
    );
    KuikaAppManager.addToPageInputVariables("UretimDetayKart", "NewEditDisplay", "D");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UretimList",
      "UretimDetayKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "142528",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UretimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListComponent_880048_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UretimList = result?.data.uretimList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.UretimList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UretimListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UretimTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_206848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SiparisNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmSiparisNo ?? this.props.screenInputs.prmsiparisno,
        "string"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_208998_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_588259_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uretimlist_270508_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uretimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UretimList/UretimListRecuringAction_30_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.uretimlist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.uretimlist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.uretimlist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.UretimList = result?.data.uretimList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UretimListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UretimListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uretimlist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.uretimlist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.uretimlist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.UretimList;
    formVars.uretimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 720127, 880895, 180819, 142528] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UretimListPageInit();
    }
  }
}
