import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  HorizontalStack,
  Image,
  KCol,
  KPanel,
  KRow,
  Label,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { DogrulamaKodu_ScreenBase } from "./dogrulamakodu-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class DogrulamaKodu_Screen extends DogrulamaKodu_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              dogrulamakodu_652343_value: "4fa33ff7-b3a8-4fb6-0aac-08dc79d41c9e",
              dogrulamakodu_639668_value: undefined,
              dogrulamakodu_13639_value: undefined,
              dogrulamakodu_317628_value: undefined,
              dogrulamakodu_367517_value: undefined
            }}
          >
            <div
              id="dogrulamakodu_body"
              style={
                {
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundImage:
                    "url(" +
                    KuikaAppManager.GetBackendUrl() +
                    "/resource/runtime/byid?resourceId=fd27c697-4f1a-455a-5f95-08dc95f442b9)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="805820"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KCol
                  id="360730"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KPanel
                    id="512194"
                    scrolling="hidden"
                    scrollRadius={false}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        height: "100vh",
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="736978"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="54434"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="675949"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 4,
                              borderTopRightRadius: 4,
                              borderBottomRightRadius: 4,
                              borderBottomLeftRadius: 4,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              width: "800px",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="201029"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="956931"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="740060"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 4,
                                    paddingRight: 8,
                                    paddingBottom: 4,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="701568"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 0,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="dogrulamakodu_652343_value">
                                    <Image
                                      id="652343"
                                      zoomOnClick={false}
                                      imageFit="fill"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          height: "160px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></Image>
                                  </Form.Item>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>

                          <KRow
                            id="746137"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="181723"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="86319"
                                visibility={this.state.isComp86319Visible}
                                value={ReactSystemFunctions.translate(this.ml, 86319, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "22pt",
                                    color: "rgba(22, 48, 32, 1)",
                                    lineHeight: "32px"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="56882"
                                visibility={this.state.isComp56882Visible}
                                value={ReactSystemFunctions.translate(this.ml, 56882, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "22pt",
                                    color: "rgba(22, 48, 32, 1)",
                                    lineHeight: "32px"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="332605"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 8,
                                paddingBottom: 0,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="41988"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 24,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="81142"
                                direction="vertical"
                                size={24}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="839650"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="dogrulamakodu_639668_value">
                                    <TextInput
                                      id="639668"
                                      visibility={this.state.isComp639668Visible}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        639668,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(255, 255, 255, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          width: "120px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>

                                  <Label
                                    id="971102"
                                    visibility={this.state.isComp971102Visible}
                                    value={ReactSystemFunctions.translate(this.ml, 971102, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 300,
                                        fontSize: "13pt",
                                        color: "rgba(155, 155, 155, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="792604"
                                    visibility={this.state.isComp792604Visible}
                                    value={ReactSystemFunctions.translate(this.ml, 792604, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 300,
                                        fontSize: "13pt",
                                        color: "rgba(155, 155, 155, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>

                                <KPanel
                                  id="489922"
                                  scrolling="hidden"
                                  scrollRadius={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      width: "450px",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="332404"
                                    direction="vertical"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="167495"
                                      value={ReactSystemFunctions.translate(this.ml, 167495, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(155, 155, 155, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="dogrulamakodu_13639_value">
                                      <TextInput
                                        id="13639"
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          13639,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </VerticalStack>
                                </KPanel>
                              </VerticalStack>
                            </KCol>
                          </KRow>

                          <KRow
                            id="221266"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="601554"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KPanel
                                id="858273"
                                scrolling="hidden"
                                scrollRadius={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    width: "450px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KRow
                                  id="300171"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="679656"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-end",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="619188"
                                      value={ReactSystemFunctions.translate(this.ml, 619188, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Montserrat",
                                          fontWeight: 400,
                                          fontSize: "8pt",
                                          color: "rgba(155, 155, 155, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Button
                                      id="9622"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DogrulamaKoduComponent_9622_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 9622, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(2, 145, 201, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(2, 145, 201, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>

                                  <KCol
                                    id="483180"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-end",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Button
                                      id="887191"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DogrulamaKoduComponent_887191_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 887191, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(2, 145, 201, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>
                                </KRow>

                                <Form.Item className="kFormItem" name="dogrulamakodu_317628_value">
                                  <TextInput
                                    id="317628"
                                    visibility={this.state.isComp317628Visible}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      317628,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>

                                <Form.Item className="kFormItem" name="dogrulamakodu_367517_value">
                                  <TextInput
                                    id="367517"
                                    visibility={this.state.isComp367517Visible}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      367517,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KPanel>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </KCol>
                    </KRow>
                  </KPanel>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(DogrulamaKodu_Screen))))
  )
);
export { tmp as DogrulamaKodu_Screen };
//export default tmp;
//export { tmp as DogrulamaKodu_Screen };
