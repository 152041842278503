import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KGallery,
  KPanel,
  KRow,
  Label,
  Tooltip,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { HizliTeklifKarsilastirma_ScreenBase } from "./hizliteklifkarsilastirma-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class HizliTeklifKarsilastirma_Screen extends HizliTeklifKarsilastirma_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form form={this.props.form} name="basic" initialValues={{}}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="hizliteklifkarsilastirma_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KRow
                  id="652945"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="405031"
                    xs={18}
                    sm={18}
                    md={18}
                    lg={18}
                    xl={18}
                    xxl={18}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="364938"
                      value={ReactSystemFunctions.translate(this.ml, 364938, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "rgba(2, 145, 201, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="831198"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="187072"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="521698"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.HizliTeklifKarsilastirmaComponent_521698_onClick();
                        }}
                        showCursorPointer
                        iconName="close"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(2, 145, 201, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="hizliteklifkarsilastirma_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="242660"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="663554"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KGallery
                      id="922676"
                      kuikaRef={this.hizliteklifkarsilastirma_922676_value_kuikaGalleryViewRef}
                      nodatafoundmessage={ReactSystemFunctions.translate(
                        this.ml,
                        922676,
                        "nodatafoundmessage",
                        this.defaultML
                      )}
                      dataSource={this.state.HizliTeklifKarsilastirmaList}
                      loading={false}
                      phoneColumnsCount={1}
                      tabletColumnsCount={2}
                      desktopColumnsCount={3}
                      hoverFontColor="black"
                      hoverBgColor="white"
                      horizontalGutter={0}
                      verticalGutter={0}
                      showNoDataFoundImage={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="881651"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="619477"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KPanel
                            id="700869"
                            scrolling="hidden"
                            scrollRadius={false}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KRow
                              id="128944"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="316290"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KRow
                                  id="546162"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 0,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="183963"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="969918"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="880694"
                                        value={ReactSystemFunctions.translate(this.ml, 880694, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Tooltip
                                        id="242082"
                                        title="[datafield:sirketAd]"
                                        placement="top"
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="351552"
                                          value="[datafield:sirketAd]"
                                          maxLine={2}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              height: "48px",
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </Tooltip>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="138826"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="748979"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 4,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="994494"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="88383"
                                        value={ReactSystemFunctions.translate(this.ml, 88383, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="57253"
                                        value={ReactSystemFunctions.translate(this.ml, 57253, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="692138"
                                        value="[datafield:sayfaBFiyat]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </VerticalStack>
                                  </KCol>

                                  <KCol
                                    id="510540"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 4,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="819458"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="295062"
                                        value={ReactSystemFunctions.translate(this.ml, 295062, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="59276"
                                        value={ReactSystemFunctions.translate(this.ml, 59276, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="949571"
                                        value="[datafield:kapakBFiyat]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </VerticalStack>
                                  </KCol>

                                  <KCol
                                    id="149241"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="710695"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="189441"
                                        value={ReactSystemFunctions.translate(this.ml, 189441, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="324046"
                                        value={ReactSystemFunctions.translate(this.ml, 324046, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="67546"
                                        value="[datafield:ciltBFiyat]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="809757"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 0,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="451790"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="873903"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="899165"
                                        value={ReactSystemFunctions.translate(this.ml, 899165, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="495891"
                                        value="[datafield:teklifFiyat]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 600,
                                            fontSize: "18px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </VerticalStack>
                                  </KCol>

                                  <KCol
                                    id="836520"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="271527"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="800509"
                                        value={ReactSystemFunctions.translate(this.ml, 800509, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="443212"
                                        value="[datafield:birimFiyat]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 600,
                                            fontSize: "18px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="925856"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="698556"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="645792"
                                      value={ReactSystemFunctions.translate(this.ml, 645792, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(140, 140, 140, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="511646"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 0,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="722142"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="573891"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="689512"
                                        value={ReactSystemFunctions.translate(this.ml, 689512, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="674119"
                                        value="[datafield:digerKosullar]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="26487"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 0,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="212866"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="535758"
                                      direction="vertical"
                                      size={6}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="653900"
                                        value={ReactSystemFunctions.translate(this.ml, 653900, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Tooltip
                                        id="882463"
                                        title="[datafield:aciklama]"
                                        placement="top"
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="178531"
                                          value="[datafield:aciklama]"
                                          maxLine={3}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              height: "64px",
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </Tooltip>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="189032"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="244986"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Button
                                      id="509249"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.HizliTeklifKarsilastirmaComponent_509249_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 509249, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(2, 145, 201, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>

                                  <KCol
                                    id="641913"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Button
                                      id="400438"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.HizliTeklifKarsilastirmaComponent_400438_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 400438, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(2, 145, 201, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KPanel>
                        </KCol>
                      </KRow>
                    </KGallery>
                  </KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(
      withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(HizliTeklifKarsilastirma_Screen)))
    )
  )
);
export { tmp as HizliTeklifKarsilastirma_Screen };
//export default tmp;
//export { tmp as HizliTeklifKarsilastirma_Screen };
