import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMatbaaBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMatbaaBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametrelerPDFMatbaa: any[];
  GenelParametrelerPDFMatbaa_dummy: any[];
  getGuid: any[];
  getGuid_dummy: any[];
  UyelikFormuById: any[];
  UyelikFormuById_dummy: any[];
  SelectCityAll: any[];
  SelectCityAll_dummy: any[];
  SelectDistrictAll: any[];
  SelectDistrictAll_dummy: any[];
  UyelikFormuDuzeltmeNotuByUyeID: any[];
  UyelikFormuDuzeltmeNotuByUyeID_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  DownloadFile: any;
  DownloadFile_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  KullaniciDetayUserIdUyeIdUpdate: number;
  KullaniciDetayUserIdUyeIdUpdate_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  POPSozlesmesiUpdate: number;
  POPSozlesmesiUpdate_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  getDownloadGuid: any[];
  getDownloadGuid_dummy: any[];
  POPSozlesmesiById: any[];
  POPSozlesmesiById_dummy: any[];
  ImzaSirkuleriUpdate: number;
  ImzaSirkuleriUpdate_dummy: number;
  ImzaSirkuleriById: any[];
  ImzaSirkuleriById_dummy: any[];
  TicariSicilGazetesiUpdate: number;
  TicariSicilGazetesiUpdate_dummy: number;
  TicariSicilGazetesiById: any[];
  TicariSicilGazetesiById_dummy: any[];
  VergiLevhasiUpdate: number;
  VergiLevhasiUpdate_dummy: number;
  VergiLevhasiById: any[];
  VergiLevhasiById_dummy: any[];
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  UpdateUserStartingScreen: string;
  UpdateUserStartingScreen_dummy: string;
  NedenGirisKontrol: number;
  NedenGirisKontrol_dummy: number;
  NedenGirisAciklama: any[];
  NedenGirisAciklama_dummy: any[];
  PowerUserOnay: number;
  PowerUserOnay_dummy: number;
  UyeRedUserDisabled: any[];
  UyeRedUserDisabled_dummy: any[];
  DisableUser: any;
  DisableUser_dummy: any;
  BelgeKontrolMatbaa: number;
  BelgeKontrolMatbaa_dummy: number;
  GoruntulemeCountKontrol: number;
  GoruntulemeCountKontrol_dummy: number;
  isComp54788Visible: "visible" | "hidden";
  isComp54090Visible: "visible" | "hidden";
  isComp346823Visible: "visible" | "hidden";
  isComp146223Visible: "visible" | "hidden";
  isComp761084Visible: "visible" | "hidden";
  isComp801285Visible: "visible" | "hidden";
  isComp291006Visible: "visible" | "hidden";
  isComp242134Visible: "visible" | "hidden";
  isComp787897Visible: "visible" | "hidden";
  isComp685945Visible: "visible" | "hidden";
  isComp227611Visible: "visible" | "hidden";
  isComp454357Visible: "visible" | "hidden";
  isComp285129Visible: "visible" | "hidden";
  isComp434751Visible: "visible" | "hidden";
  isComp966072Visible: "visible" | "hidden";
  isComp795871Visible: "visible" | "hidden";
  isComp365016Visible: "visible" | "hidden";
  isComp866135Visible: "visible" | "hidden";
  isComp439637Visible: "visible" | "hidden";
  isComp843473Visible: "visible" | "hidden";
  isComp479155Visible: "visible" | "hidden";
  isComp506702Visible: "visible" | "hidden";
  isComp550216Visible: "visible" | "hidden";
  isComp986341Visible: "visible" | "hidden";
  isComp981990Visible: "visible" | "hidden";
  isComp355708Visible: "visible" | "hidden";
  isComp848714Visible: "visible" | "hidden";
  isComp221266Visible: "visible" | "hidden";
  isComp299116Visible: "visible" | "hidden";
  isComp973170Enabled: "enabled" | "disabled";
  isComp138362Enabled: "enabled" | "disabled";
  isComp167918Enabled: "enabled" | "disabled";
  isComp338926Enabled: "enabled" | "disabled";
  isCompmatbaabilgileri_37212AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_346823AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_421558AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_509927AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_787897AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_627731AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_683816AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_966072AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_402987AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_441153AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_479155AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_802682AuthorizationVisible: "visible" | "hidden";
  isCompmatbaabilgileri_900070AuthorizationVisible: "visible" | "hidden";
}

export class MatbaaBilgileri_ScreenBase extends React.PureComponent<IMatbaaBilgileri_ScreenProps, any> {
  matbaabilgileri_546287_value_kuikaSelectBoxRef: React.RefObject<any>;
  matbaabilgileri_100145_value_kuikaSelectBoxRef: React.RefObject<any>;
  matbaabilgileri_633693_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "af0df4d3-8ac7-4515-9d94-a0db3261eb75",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 619404, PropertyName: "value", Value: "Matbaa Bilgileri" },
        { Id: 532883, PropertyName: "value", Value: "Şirket Adı" },
        { Id: 896297, PropertyName: "value", Value: "İmzalı Publink Matbaa Sözleşmesi" },
        { Id: 973170, PropertyName: "placeholder", Value: "İmzalı Publink Matbaa Sözleşmesi" },
        { Id: 929728, PropertyName: "value", Value: "Şirket İl" },
        { Id: 297686, PropertyName: "value", Value: "İmza Sirküleri" },
        { Id: 138362, PropertyName: "placeholder", Value: "İmza Sirküleri" },
        { Id: 458210, PropertyName: "value", Value: "Şirket İlçe" },
        { Id: 484543, PropertyName: "value", Value: "Ticari Sicil Gazetesi" },
        { Id: 167918, PropertyName: "placeholder", Value: "Ticari Sicil Gazetesi" },
        { Id: 158154, PropertyName: "value", Value: "Şirket Adresi" },
        { Id: 413413, PropertyName: "value", Value: "Vergi Levhası" },
        { Id: 338926, PropertyName: "placeholder", Value: "Vergi Levhası" },
        { Id: 796351, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 944498, PropertyName: "value", Value: "Vergi Kimlik Numarası" },
        { Id: 532312, PropertyName: "value", Value: "Matbaa Sertifika No" },
        { Id: 456105, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 52576, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 930281, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 633693, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 911517, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 206834, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 211962, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 437265, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 887191, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 513771, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 437789, PropertyName: "label", Value: "Red Et" },
        { Id: 747313, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.matbaabilgileri_546287_value_kuikaSelectBoxRef = React.createRef();
    this.matbaabilgileri_100145_value_kuikaSelectBoxRef = React.createRef();
    this.matbaabilgileri_633693_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      GenelParametrelerPDFMatbaa: [],
      getGuid: [],
      UyelikFormuById: [],
      SelectCityAll: [],
      SelectDistrictAll: [],
      UyelikFormuDuzeltmeNotuByUyeID: [],
      ChangeEnabledOf: "",
      GoBack: "",
      DownloadFile: "",
      SaveRecord: false,
      KullaniciDetayUserIdUyeIdUpdate: 0,
      UploadFile: "",
      POPSozlesmesiUpdate: 0,
      SetValueOf: "",
      Notify: false,
      getDownloadGuid: [],
      POPSozlesmesiById: [],
      ImzaSirkuleriUpdate: 0,
      ImzaSirkuleriById: [],
      TicariSicilGazetesiUpdate: 0,
      TicariSicilGazetesiById: [],
      VergiLevhasiUpdate: 0,
      VergiLevhasiById: [],
      ValidasyonKontrol: [],
      SendMailSettings: [],
      UyelikTipiLowercase: [],
      SendMail: false,
      UpdateUserStartingScreen: "",
      NedenGirisKontrol: 0,
      NedenGirisAciklama: [],
      PowerUserOnay: 0,
      UyeRedUserDisabled: [],
      DisableUser: "",
      BelgeKontrolMatbaa: 0,
      GoruntulemeCountKontrol: 0,
      isComp54788Visible: "hidden",
      isComp54090Visible: "hidden",
      isComp346823Visible: "hidden",
      isComp146223Visible: "hidden",
      isComp761084Visible: "hidden",
      isComp801285Visible: "hidden",
      isComp291006Visible: "hidden",
      isComp242134Visible: "hidden",
      isComp787897Visible: "hidden",
      isComp685945Visible: "hidden",
      isComp227611Visible: "hidden",
      isComp454357Visible: "hidden",
      isComp285129Visible: "hidden",
      isComp434751Visible: "hidden",
      isComp966072Visible: "hidden",
      isComp795871Visible: "hidden",
      isComp365016Visible: "hidden",
      isComp866135Visible: "hidden",
      isComp439637Visible: "hidden",
      isComp843473Visible: "hidden",
      isComp479155Visible: "hidden",
      isComp506702Visible: "hidden",
      isComp550216Visible: "hidden",
      isComp986341Visible: "hidden",
      isComp981990Visible: "hidden",
      isComp355708Visible: "hidden",
      isComp848714Visible: "hidden",
      isComp221266Visible: "hidden",
      isComp299116Visible: "hidden",
      isComp973170Enabled: "enabled",
      isComp138362Enabled: "enabled",
      isComp167918Enabled: "enabled",
      isComp338926Enabled: "enabled",
      isCompmatbaabilgileri_37212AuthorizationVisible: "visible",
      isCompmatbaabilgileri_346823AuthorizationVisible: "visible",
      isCompmatbaabilgileri_421558AuthorizationVisible: "visible",
      isCompmatbaabilgileri_509927AuthorizationVisible: "visible",
      isCompmatbaabilgileri_787897AuthorizationVisible: "visible",
      isCompmatbaabilgileri_627731AuthorizationVisible: "visible",
      isCompmatbaabilgileri_683816AuthorizationVisible: "visible",
      isCompmatbaabilgileri_966072AuthorizationVisible: "visible",
      isCompmatbaabilgileri_402987AuthorizationVisible: "visible",
      isCompmatbaabilgileri_441153AuthorizationVisible: "visible",
      isCompmatbaabilgileri_479155AuthorizationVisible: "visible",
      isCompmatbaabilgileri_802682AuthorizationVisible: "visible",
      isCompmatbaabilgileri_900070AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("matbaabilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.MatbaaBilgileriPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_37212AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_346823AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_421558AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_509927AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_787897AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_627731AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_683816AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_966072AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_402987AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_441153AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_479155AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_802682AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmatbaabilgileri_900070AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("matbaabilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("matbaabilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MatbaaBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      matbaabilgileri_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      matbaabilgileri_328718_value: this.state.UyelikFormuById?.at?.(0)?.sirketAd ?? undefined,
      matbaabilgileri_973170_value: this.state.UyelikFormuById?.at?.(0)?.popSozlesmesiDosyaAd ?? undefined,
      matbaabilgileri_546287_value: this.state.UyelikFormuById?.at?.(0)?.cityID ?? undefined,
      matbaabilgileri_138362_value: this.state.UyelikFormuById?.at?.(0)?.imzaSirkuleriDosyaAd ?? undefined,
      matbaabilgileri_100145_value: this.state.UyelikFormuById?.at?.(0)?.districtID ?? undefined,
      matbaabilgileri_167918_value: this.state.UyelikFormuById?.at?.(0)?.ticariSicilGazetesiDosyaAd ?? undefined,
      matbaabilgileri_909820_value: this.state.UyelikFormuById?.at?.(0)?.adres ?? undefined,
      matbaabilgileri_338926_value: this.state.UyelikFormuById?.at?.(0)?.vergiLevhasiDosyaAd ?? undefined,
      matbaabilgileri_202515_value: this.state.UyelikFormuById?.at?.(0)?.vergiDairesi ?? undefined,
      matbaabilgileri_229993_value: this.state.UyelikFormuById?.at?.(0)?.vergiKimlikNo ?? undefined,
      matbaabilgileri_303073_value: this.state.UyelikFormuById?.at?.(0)?.matbaaSertifikaNo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  MatbaaBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      prmID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MatbaaBilgileri/MatbaaBilgileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.matbaabilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.matbaabilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    stateVars.GenelParametrelerPDFMatbaa = result?.data.genelParametrelerPDFMatbaa;
    stateVars.getGuid = result?.data.getGuid;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_546287_options = stateVars.SelectCityAll;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_100145_options = stateVars.SelectDistrictAll;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectCityAll = result?.data.selectCityAll;

    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_546287_options = stateVars.SelectCityAll;
    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_100145_options = stateVars.SelectDistrictAll;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp973170Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp138362Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp167918Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp338926Enabled", "disabled");
    formVars.matbaabilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.matbaabilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    stateVars.isComp54090Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp801285Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    stateVars.dataSource_546287 = this.state.SelectCityAll;
    stateVars.dataSource_546287 = this.state.SelectCityAll;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454357Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866135Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp986341Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_633693 = this.state.UyelikFormuDuzeltmeNotuByUyeID;
    stateVars.isComp221266Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299116Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MatbaaBilgileri",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "802097",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MatbaaBilgileri",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "632658",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_54090_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_37212_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametrelerPDFMatbaa?.length > 0
          ? this.state.GenelParametrelerPDFMatbaa[0]?.ornekPOPSertifikaURLMatbaa
          : null
      ),
      "MatbaaSozlesmesi.pdf"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_346823_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_202515_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_229993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_303073_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      MatbaaSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isPOPSozlesmesi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_346823_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_346823_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_346823_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_346823_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_346823_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      POPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSozlesmesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_346823_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.POPSozlesmesiUpdate = result?.data.popSozlesmesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_346823_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_346823_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "matbaabilgileri_973170_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "22248e6f_a308_7e9e_a702_9e50508c5ee0_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_421558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isPOPSozlesmesi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_421558_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_421558_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_421558_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsPOPSozlesme",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MatbaaBilgileriComponent_421558_onClick2_,
      "MatbaaBilgileri",
      "PDFViewerFormsPOPSozlesme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "30644",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_421558_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_509927_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_509927_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.POPSozlesmesiById = result?.data.popSozlesmesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_509927_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_509927_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.POPSozlesmesiById?.length > 0 ? this.state.POPSozlesmesiById[0]?.popSozlesmesi : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.POPSozlesmesiById?.length > 0 ? this.state.POPSozlesmesiById[0]?.popSozlesmesiDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_291006_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isPOPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_291006_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_291006_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_291006_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_242134_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isPOPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_242134_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_242134_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_242134_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_546287_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_546287_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.matbaabilgileri_100145_options = stateVars.SelectDistrictAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_546287_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_546287_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_787897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_202515_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_229993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_303073_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      MatbaaSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isImzaSirkuleri_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_787897_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_787897_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_787897_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_787897_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_787897_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      ImzaSirkuleriDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_787897_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImzaSirkuleriUpdate = result?.data.imzaSirkuleriUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_787897_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_787897_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "matbaabilgileri_138362_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "25f604d1_9194_53ba_216e_0105c4266c1b_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_627731_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isImzaSirkuleri_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_627731_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_627731_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_627731_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsImza",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MatbaaBilgileriComponent_627731_onClick2_,
      "MatbaaBilgileri",
      "PDFViewerFormsImza",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "274530",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_627731_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_683816_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_683816_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.ImzaSirkuleriById = result?.data.imzaSirkuleriById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_683816_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_683816_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.ImzaSirkuleriById?.length > 0 ? this.state.ImzaSirkuleriById[0]?.imzaSirkuleri : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.ImzaSirkuleriById?.length > 0 ? this.state.ImzaSirkuleriById[0]?.imzaSirkuleriDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_285129_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_285129_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_285129_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_285129_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_434751_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_434751_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_434751_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_434751_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_966072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_202515_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_229993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_303073_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      MatbaaSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isTicariSicilGazetesi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_966072_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_11;
    stateVars.SaveRecord = result?.data.saveRecord_12;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_966072_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_966072_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_966072_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_966072_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicariSicilGazetesiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      TicariSicilGazetesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_966072_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TicariSicilGazetesiUpdate = result?.data.ticariSicilGazetesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_966072_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_966072_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "matbaabilgileri_167918_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "74eadf56_2e9a_5c8c_4e1e_8c52ffef0287_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_402987_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isTicariSicilGazetesi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_402987_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_13;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_402987_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_402987_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsTicariSicilGazete",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MatbaaBilgileriComponent_402987_onClick2_,
      "MatbaaBilgileri",
      "PDFViewerFormsTicariSicilGazete",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "845978",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_402987_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_441153_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_441153_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.TicariSicilGazetesiById = result?.data.ticariSicilGazetesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_441153_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_441153_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.TicariSicilGazetesiById?.length > 0
          ? this.state.TicariSicilGazetesiById[0]?.ticariSicilGazetesi
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.TicariSicilGazetesiById?.length > 0
          ? this.state.TicariSicilGazetesiById[0]?.ticariSicilGazetesiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_439637_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isTicariSicilGazetesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_439637_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_14;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_439637_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_439637_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_843473_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isTicariSicilGazetesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_843473_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_15;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_843473_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_843473_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_479155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_202515_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_229993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_303073_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      MatbaaSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isVergiLevhasi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_479155_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_16;
    stateVars.SaveRecord = result?.data.saveRecord_17;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_479155_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_479155_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_479155_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_479155_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      VergiLevhasiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      VergiLevhasiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_479155_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VergiLevhasiUpdate = result?.data.vergiLevhasiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_479155_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_479155_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "matbaabilgileri_338926_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "935261f2_3ad8_5381_e75f_c8f1e39d07e9_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_802682_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isVergiLevhasi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_802682_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_18;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_802682_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_802682_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsVergiLevhasi",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MatbaaBilgileriComponent_802682_onClick2_,
      "MatbaaBilgileri",
      "PDFViewerFormsVergiLevhasi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "307029",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_802682_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_900070_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_900070_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.VergiLevhasiById = result?.data.vergiLevhasiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_900070_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_900070_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.VergiLevhasiById?.length > 0 ? this.state.VergiLevhasiById[0]?.vergiLevhasi : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.VergiLevhasiById?.length > 0 ? this.state.VergiLevhasiById[0]?.vergiLevhasiDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_981990_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isVergiLevhasi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_981990_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_19;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_981990_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_981990_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_355708_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isVergiLevhasi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_355708_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_20;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.matbaabilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.matbaabilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );
    stateVars.isComp346823Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146223Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761084Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291006Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp242134Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787897Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp685945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227611Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966072Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365016Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439637Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843473Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp479155Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506702Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp355708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848714Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_355708_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_355708_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.matbaabilgileri_973170_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.matbaabilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.matbaabilgileri_138362_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.matbaabilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.matbaabilgileri_167918_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.matbaabilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.matbaabilgileri_338926_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.matbaabilgileri_202515_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.matbaabilgileri_229993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.matbaabilgileri_303073_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.matbaaSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_930281_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_52576_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_52576_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_52576_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_930281_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_21;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_930281_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_930281_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "matbaabilgileri_52576_value", "", null);

    stateVars.dataSource_633693 = this.state.UyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_437265_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      MatbaaSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_437265_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_22;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_437265_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437265_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_437265_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_437265_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437265_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "da699867_0ada_3f0a_bdba_74f4c50cc2ed_notify",
        this.defaultML,
        "Form Taslak Olarak Kayıt Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_887191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_973170_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_973170_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_138362_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_138362_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_167918_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_167918_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_338926_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_338926_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_202515_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_229993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "matbaabilgileri_303073_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_973170_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_138362_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_167918_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_338926_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm5_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_202515_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_229993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      MatbaaSertifikaNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "matbaabilgileri_303073_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(-1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_887191_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_23;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_887191_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_887191_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f72cffd9_5165_f69c_1a87_bbfaef033dab_notify",
        this.defaultML,
        "Form Kaydı Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_887191_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_887191_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("poweruseronayinagonder", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_3: ReactSystemFunctions.convertToTypeByName("9859035e-d0f0-48b5-997e-892a19c42cc1", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFMatbaa?.length > 0
            ? this.state.GenelParametrelerPDFMatbaa[0]?.powerUserMail
            : null
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFMatbaa?.length > 0
            ? this.state.GenelParametrelerPDFMatbaa[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      UyeSoyad_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_887191_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.matbaabilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.matbaabilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_887191_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_887191_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.getMyUsername(),
      "f6a25ed5-8a21-475f-bda2-ac103ccb9497"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_887191_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_887191_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_887191_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_887191_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_887191_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MatbaaBilgileriComponent_887191_onClick6_,
      "MatbaaBilgileri",
      "MatbaaBilgileriKontrol",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_887191_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.matbaabilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.matbaabilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_513771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_513771_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NedenGirisKontrol = result?.data.nedenGirisKontrol;
    stateVars.NedenGirisAciklama = result?.data.nedenGirisAciklama;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_513771_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_513771_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "37cf1520_d786_f317_c083_ce0d01ab4b0e_confirmation",
        this.defaultML,
        "Üyelik Formu Düzeltme İçin Geri Gönderilecek, İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MatbaaBilgileri/MatbaaBilgileriComponent_513771_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MatbaaBilgileriComponent_513771_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_513771_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
      ),
      "82ece41e-1376-4d93-b482-9d8c0ea42a05"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "67c9eb49_d8a2_0739_2e98_0929b0022ac4_notify",
        this.defaultML,
        "Üyelik Bilgileri Düzeltme İçin Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_513771_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_513771_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("powerusergerigonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("2f71eae3-f529-4bc4-b700-59e0a52004b6", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFMatbaa?.length > 0
            ? this.state.GenelParametrelerPDFMatbaa[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      Aciklama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.NedenGirisAciklama?.length > 0 ? this.state.NedenGirisAciklama[0]?.duzeltmeNotu : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_513771_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_513771_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_513771_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_437789_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_437789_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NedenGirisKontrol = result?.data.nedenGirisKontrol;
    stateVars.NedenGirisAciklama = result?.data.nedenGirisAciklama;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437789_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "059e2495_5b1b_8c0e_17e6_ea19a997ec92_confirmation",
        this.defaultML,
        "Üyelik Formu Red Edilecektir; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MatbaaBilgileri/MatbaaBilgileriComponent_437789_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437789_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "87e9a64d_0fea_7c5f_d9d5_db08c1fe143b_notify",
        this.defaultML,
        "Form Red Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437789_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_437789_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyeRedUserDisabled = result?.data.uyeRedUserDisabled;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437789_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyeRedUserDisabled?.length > 0 ? this.state.UyeRedUserDisabled[0]?.uyelikDurumKey : null
        ),
        "0"
      )
    ) {
      stateVars.DisableUser = await ReactSystemFunctions.disableUser(
        ReactSystemFunctions.toString(
          this,
          this.state.UyeRedUserDisabled?.length > 0 ? this.state.UyeRedUserDisabled[0]?.userName : null
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437789_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruserred", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("4239c471-f052-4cd0-ae85-0e4ad91e9471", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFMatbaa?.length > 0
            ? this.state.GenelParametrelerPDFMatbaa[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      Aciklama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.NedenGirisAciklama?.length > 0 ? this.state.NedenGirisAciklama[0]?.duzeltmeNotu : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_437789_onClick5_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_437789_onClick6_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_437789_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MatbaaBilgileriComponent_747313_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      ControlCount_1: ReactSystemFunctions.convertToTypeByName(4, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_747313_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BelgeKontrolMatbaa = result?.data.belgeKontrolMatbaa;
    stateVars.GoruntulemeCountKontrol = result?.data.goruntulemeCountKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_747313_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_747313_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d6242439_58d1_fed1_336d_8f64a4e39fcb_confirmation",
        this.defaultML,
        "Üyelik Onaylanacaktır; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MatbaaBilgileri/MatbaaBilgileriComponent_747313_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MatbaaBilgileriComponent_747313_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_747313_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
      ),
      "c967fbf7-96d2-49a1-9e96-26cb0719f825"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8a06d847_00c0_f005_1c8c_3f9448a88a61_notify",
        this.defaultML,
        "Form Onaylandı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_747313_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_747313_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("ee24e6c3-69ce-47a9-9dad-dff5e55542d0", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFMatbaa?.length > 0
            ? this.state.GenelParametrelerPDFMatbaa[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MatbaaBilgileri/MatbaaBilgileriComponent_747313_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MatbaaBilgileriComponent_747313_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MatbaaBilgileriComponent_747313_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [802097, 632658, 30644, 274530, 845978, 307029] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MatbaaBilgileriPageInit();
    }
    if (diId == 30644) {
      isErrorOccurred = await this.MatbaaBilgileriComponent_421558_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 274530) {
      isErrorOccurred = await this.MatbaaBilgileriComponent_627731_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 845978) {
      isErrorOccurred = await this.MatbaaBilgileriComponent_402987_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 307029) {
      isErrorOccurred = await this.MatbaaBilgileriComponent_802682_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
