import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHizliTeklifList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHizliTeklifList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  HizliTeklifList: any[];
  HizliTeklifList_dummy: any[];
  HizliTeklifListCount: any[];
  HizliTeklifListCount_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  HizliTeklifListToExcel: any[];
  HizliTeklifListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  HizliTeklifDelete: number;
  HizliTeklifDelete_dummy: number;
  isComp459227Visible: "visible" | "hidden";
  isComp332894Visible: "visible" | "hidden";
  isComp754297Visible: "visible" | "hidden";
}

export class HizliTeklifList_ScreenBase extends React.PureComponent<IHizliTeklifList_ScreenProps, any> {
  hizlitekliflist_348605_value_kuikaTableRef: React.RefObject<any>;
  hizlitekliflist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "3f6ebb50-def4-4092-aa9c-35e046b6cf1b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Hızlı Teklif Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 459227, PropertyName: "label", Value: "Hızlı Teklif Giriş" },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 467153, PropertyName: "title", Value: "Kitap Boyutu" },
        { Id: 658945, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 558595, PropertyName: "title", Value: "İç Kağıt Cinsi" },
        { Id: 586928, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 412616, PropertyName: "title", Value: "İç Kağıt Gramajı" },
        { Id: 707973, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 723618, PropertyName: "title", Value: "Kapak Cinsi" },
        { Id: 688924, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 447424, PropertyName: "title", Value: "Kapak Gramajı" },
        { Id: 163503, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 383155, PropertyName: "title", Value: "İç Baskı Renk" },
        { Id: 192130, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 553478, PropertyName: "title", Value: "Kapak Selefonu" },
        { Id: 720347, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 803198, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 685558, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 935301, PropertyName: "title", Value: "Sayfa Birim Fiyatı" },
        { Id: 513562, PropertyName: "value", Value: "[datafield:sayfabfiyat]" },
        { Id: 111944, PropertyName: "title", Value: "Kapak Birim Fiyatı" },
        { Id: 158336, PropertyName: "value", Value: "[datafield:kapakbfiyat]" },
        { Id: 324971, PropertyName: "title", Value: "Cilt Birim Fiyatı" },
        { Id: 740004, PropertyName: "value", Value: "[datafield:ciltbfiyat]" },
        { Id: 898118, PropertyName: "title", Value: "Diğer Koşullar" },
        { Id: 220328, PropertyName: "value", Value: "[datafield:digerkosullar]" },
        { Id: 461193, PropertyName: "title", Value: "Not" },
        { Id: 858917, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 863176, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 294763, PropertyName: "value", Value: "[datafield:gecerliliktarihi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.hizlitekliflist_348605_value_kuikaTableRef = React.createRef();
    this.hizlitekliflist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      HizliTeklifList: [],
      HizliTeklifListCount: [],
      RoleSadeceGoruntule: [],
      HizliTeklifListToExcel: [],
      ExportExcel: "",
      HizliTeklifDelete: 0,
      isComp459227Visible: "hidden",
      isComp332894Visible: "hidden",
      isComp754297Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hizlitekliflist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HizliTeklifListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hizlitekliflist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hizlitekliflist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HizliTeklifListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      hizlitekliflist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HizliTeklifListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizlitekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizlitekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HizliTeklifList/HizliTeklifListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.hizlitekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.hizlitekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.hizlitekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.HizliTeklifList = result?.data.hizliTeklifList;

    stateVars.HizliTeklifListCount = result?.data.hizliTeklifListCount;
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp459227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg091 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp332894Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg091 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp754297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg091 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.hizlitekliflist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.hizlitekliflist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.hizlitekliflist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.isComp459227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg091 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_348605 = this.state.HizliTeklifList;
    stateVars.isComp332894Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg091 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp754297Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg091 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.hizlitekliflist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.HizliTeklifListCount?.length > 0 ? this.state.HizliTeklifListCount[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HizliTeklifListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "973652",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifListComponent_459227_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("HizliTeklifGiris", "prmID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifList",
      "HizliTeklifGiris",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "234531",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifListComponent_866514_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("Hızlı Teklif Listesi", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifList/HizliTeklifListComponent_866514_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HizliTeklifListToExcel = result?.data.hizliTeklifListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  HizliTeklifListComponent_253641_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "hizlitekliflist_348605_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a9c85453_8190_a156_76ea_f27b8c2e42b7_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "HizliTeklifList/HizliTeklifListComponent_253641_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.HizliTeklifDelete = result?.data.hizliTeklifDelete;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  HizliTeklifListComponent_625771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "HizliTeklifGiris",
      "prmID",
      ReactSystemFunctions.value(this, "hizlitekliflist_348605_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifList",
      "HizliTeklifGiris",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "789886",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizlitekliflist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizlitekliflist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifList/HizliTeklifListComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HizliTeklifList = result?.data.hizliTeklifList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.HizliTeklifList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 973652, 234531, 789886] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HizliTeklifListPageInit();
    }
  }
}
