import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYayinciBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYayinciBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametrelerPDFYayinci: any[];
  GenelParametrelerPDFYayinci_dummy: any[];
  getGuid: any[];
  getGuid_dummy: any[];
  UyelikFormuById: any[];
  UyelikFormuById_dummy: any[];
  SelectCityAll: any[];
  SelectCityAll_dummy: any[];
  SelectDistrictAll: any[];
  SelectDistrictAll_dummy: any[];
  UyelikFormuDuzeltmeNotuByUyeID: any[];
  UyelikFormuDuzeltmeNotuByUyeID_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  DownloadFile: any;
  DownloadFile_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  KullaniciDetayUserIdUyeIdUpdate: number;
  KullaniciDetayUserIdUyeIdUpdate_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  POPSozlesmesiUpdate: number;
  POPSozlesmesiUpdate_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  getDownloadGuid: any[];
  getDownloadGuid_dummy: any[];
  POPSozlesmesiById: any[];
  POPSozlesmesiById_dummy: any[];
  ImzaSirkuleriUpdate: number;
  ImzaSirkuleriUpdate_dummy: number;
  ImzaSirkuleriById: any[];
  ImzaSirkuleriById_dummy: any[];
  TicariSicilGazetesiUpdate: number;
  TicariSicilGazetesiUpdate_dummy: number;
  TicariSicilGazetesiById: any[];
  TicariSicilGazetesiById_dummy: any[];
  VergiLevhasiUpdate: number;
  VergiLevhasiUpdate_dummy: number;
  VergiLevhasiById: any[];
  VergiLevhasiById_dummy: any[];
  YayinciSertifikasiUpdate: number;
  YayinciSertifikasiUpdate_dummy: number;
  YayinciSertifikasiById: any[];
  YayinciSertifikasiById_dummy: any[];
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  UpdateUserStartingScreen: string;
  UpdateUserStartingScreen_dummy: string;
  NedenGirisKontrol: number;
  NedenGirisKontrol_dummy: number;
  NedenGirisAciklama: any[];
  NedenGirisAciklama_dummy: any[];
  PowerUserOnay: number;
  PowerUserOnay_dummy: number;
  UyeRedUserDisabled: any[];
  UyeRedUserDisabled_dummy: any[];
  DisableUser: any;
  DisableUser_dummy: any;
  BelgeKontrolYayinci: number;
  BelgeKontrolYayinci_dummy: number;
  GoruntulemeCountKontrol: number;
  GoruntulemeCountKontrol_dummy: number;
  isComp54788Visible: "visible" | "hidden";
  isComp22702Visible: "visible" | "hidden";
  isComp329421Visible: "visible" | "hidden";
  isComp468550Visible: "visible" | "hidden";
  isComp48406Visible: "visible" | "hidden";
  isComp296143Visible: "visible" | "hidden";
  isComp514993Visible: "visible" | "hidden";
  isComp497885Visible: "visible" | "hidden";
  isComp829791Visible: "visible" | "hidden";
  isComp723585Visible: "visible" | "hidden";
  isComp814337Visible: "visible" | "hidden";
  isComp366519Visible: "visible" | "hidden";
  isComp671136Visible: "visible" | "hidden";
  isComp538704Visible: "visible" | "hidden";
  isComp290599Visible: "visible" | "hidden";
  isComp2990Visible: "visible" | "hidden";
  isComp954949Visible: "visible" | "hidden";
  isComp156825Visible: "visible" | "hidden";
  isComp769440Visible: "visible" | "hidden";
  isComp592406Visible: "visible" | "hidden";
  isComp66922Visible: "visible" | "hidden";
  isComp696038Visible: "visible" | "hidden";
  isComp741691Visible: "visible" | "hidden";
  isComp391896Visible: "visible" | "hidden";
  isComp259838Visible: "visible" | "hidden";
  isComp909781Visible: "visible" | "hidden";
  isComp988543Visible: "visible" | "hidden";
  isComp320227Visible: "visible" | "hidden";
  isComp976129Visible: "visible" | "hidden";
  isComp347316Visible: "visible" | "hidden";
  isComp805117Visible: "visible" | "hidden";
  isComp114208Visible: "visible" | "hidden";
  isComp966444Visible: "visible" | "hidden";
  isComp221266Visible: "visible" | "hidden";
  isComp590083Visible: "visible" | "hidden";
  isComp426623Enabled: "enabled" | "disabled";
  isComp976849Enabled: "enabled" | "disabled";
  isComp806164Enabled: "enabled" | "disabled";
  isComp390834Enabled: "enabled" | "disabled";
  isComp615210Enabled: "enabled" | "disabled";
  isCompyayincibilgileri_101671AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_329421AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_442305AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_493754AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_829791AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_246570AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_328110AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_290599AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_168870AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_327502AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_66922AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_521056AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_762500AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_988543AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_522833AuthorizationVisible: "visible" | "hidden";
  isCompyayincibilgileri_779951AuthorizationVisible: "visible" | "hidden";
}

export class YayinciBilgileri_ScreenBase extends React.PureComponent<IYayinciBilgileri_ScreenProps, any> {
  yayincibilgileri_546287_value_kuikaSelectBoxRef: React.RefObject<any>;
  yayincibilgileri_100145_value_kuikaSelectBoxRef: React.RefObject<any>;
  yayincibilgileri_254578_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b96cbe27-6b3a-460b-ad72-ba2ee80f933f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 619404, PropertyName: "value", Value: "Yayıncı Bilgileri" },
        { Id: 532883, PropertyName: "value", Value: "Şirket Adı" },
        { Id: 17730, PropertyName: "value", Value: "İmzalı Publink Yayıncı Sözleşmesi" },
        { Id: 426623, PropertyName: "placeholder", Value: "İmzalı Publink Yayıncı Sözleşmesi" },
        { Id: 929728, PropertyName: "value", Value: "Şirket İl Bilgisi Seçimi" },
        { Id: 996121, PropertyName: "value", Value: "İmza Sirküleri" },
        { Id: 976849, PropertyName: "placeholder", Value: "İmza Sirküleri" },
        { Id: 458210, PropertyName: "value", Value: "Şirket İlçe Bilgisi Seçimi" },
        { Id: 607468, PropertyName: "value", Value: "Ticari Sicil Gazetesi" },
        { Id: 806164, PropertyName: "placeholder", Value: "Ticari Sicil Gazetesi" },
        { Id: 158154, PropertyName: "value", Value: "Şirket Adresi" },
        { Id: 633630, PropertyName: "value", Value: "Vergi Levhası" },
        { Id: 390834, PropertyName: "placeholder", Value: "Vergi Levhası" },
        { Id: 796351, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 359553, PropertyName: "value", Value: "Yayıncı Sertifikası" },
        { Id: 615210, PropertyName: "placeholder", Value: "Yayıncı Sertifikası" },
        { Id: 944498, PropertyName: "value", Value: "Vergi Kimlik Numarası" },
        { Id: 532312, PropertyName: "value", Value: "Yayıncı Sertifika No" },
        { Id: 688920, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 433117, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 866476, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 254578, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 281862, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 257315, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 804049, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 626782, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 887191, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 531619, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 433622, PropertyName: "label", Value: "Red Et" },
        { Id: 395763, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.yayincibilgileri_546287_value_kuikaSelectBoxRef = React.createRef();
    this.yayincibilgileri_100145_value_kuikaSelectBoxRef = React.createRef();
    this.yayincibilgileri_254578_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      GenelParametrelerPDFYayinci: [],
      getGuid: [],
      UyelikFormuById: [],
      SelectCityAll: [],
      SelectDistrictAll: [],
      UyelikFormuDuzeltmeNotuByUyeID: [],
      ChangeEnabledOf: "",
      GoBack: "",
      DownloadFile: "",
      SaveRecord: false,
      KullaniciDetayUserIdUyeIdUpdate: 0,
      UploadFile: "",
      POPSozlesmesiUpdate: 0,
      SetValueOf: "",
      Notify: false,
      getDownloadGuid: [],
      POPSozlesmesiById: [],
      ImzaSirkuleriUpdate: 0,
      ImzaSirkuleriById: [],
      TicariSicilGazetesiUpdate: 0,
      TicariSicilGazetesiById: [],
      VergiLevhasiUpdate: 0,
      VergiLevhasiById: [],
      YayinciSertifikasiUpdate: 0,
      YayinciSertifikasiById: [],
      ValidasyonKontrol: [],
      SendMailSettings: [],
      UyelikTipiLowercase: [],
      SendMail: false,
      UpdateUserStartingScreen: "",
      NedenGirisKontrol: 0,
      NedenGirisAciklama: [],
      PowerUserOnay: 0,
      UyeRedUserDisabled: [],
      DisableUser: "",
      BelgeKontrolYayinci: 0,
      GoruntulemeCountKontrol: 0,
      isComp54788Visible: "hidden",
      isComp22702Visible: "hidden",
      isComp329421Visible: "hidden",
      isComp468550Visible: "hidden",
      isComp48406Visible: "hidden",
      isComp296143Visible: "hidden",
      isComp514993Visible: "hidden",
      isComp497885Visible: "hidden",
      isComp829791Visible: "hidden",
      isComp723585Visible: "hidden",
      isComp814337Visible: "hidden",
      isComp366519Visible: "hidden",
      isComp671136Visible: "hidden",
      isComp538704Visible: "hidden",
      isComp290599Visible: "hidden",
      isComp2990Visible: "hidden",
      isComp954949Visible: "hidden",
      isComp156825Visible: "hidden",
      isComp769440Visible: "hidden",
      isComp592406Visible: "hidden",
      isComp66922Visible: "hidden",
      isComp696038Visible: "hidden",
      isComp741691Visible: "hidden",
      isComp391896Visible: "hidden",
      isComp259838Visible: "hidden",
      isComp909781Visible: "hidden",
      isComp988543Visible: "hidden",
      isComp320227Visible: "hidden",
      isComp976129Visible: "hidden",
      isComp347316Visible: "hidden",
      isComp805117Visible: "hidden",
      isComp114208Visible: "hidden",
      isComp966444Visible: "hidden",
      isComp221266Visible: "hidden",
      isComp590083Visible: "hidden",
      isComp426623Enabled: "enabled",
      isComp976849Enabled: "enabled",
      isComp806164Enabled: "enabled",
      isComp390834Enabled: "enabled",
      isComp615210Enabled: "enabled",
      isCompyayincibilgileri_101671AuthorizationVisible: "visible",
      isCompyayincibilgileri_329421AuthorizationVisible: "visible",
      isCompyayincibilgileri_442305AuthorizationVisible: "visible",
      isCompyayincibilgileri_493754AuthorizationVisible: "visible",
      isCompyayincibilgileri_829791AuthorizationVisible: "visible",
      isCompyayincibilgileri_246570AuthorizationVisible: "visible",
      isCompyayincibilgileri_328110AuthorizationVisible: "visible",
      isCompyayincibilgileri_290599AuthorizationVisible: "visible",
      isCompyayincibilgileri_168870AuthorizationVisible: "visible",
      isCompyayincibilgileri_327502AuthorizationVisible: "visible",
      isCompyayincibilgileri_66922AuthorizationVisible: "visible",
      isCompyayincibilgileri_521056AuthorizationVisible: "visible",
      isCompyayincibilgileri_762500AuthorizationVisible: "visible",
      isCompyayincibilgileri_988543AuthorizationVisible: "visible",
      isCompyayincibilgileri_522833AuthorizationVisible: "visible",
      isCompyayincibilgileri_779951AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yayincibilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.YayinciBilgileriPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_101671AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_329421AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_442305AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_493754AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_829791AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_246570AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_328110AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_290599AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_168870AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_327502AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_66922AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_521056AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_762500AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_988543AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_522833AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompyayincibilgileri_779951AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("yayincibilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yayincibilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YayinciBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      yayincibilgileri_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      yayincibilgileri_328718_value: this.state.UyelikFormuById?.at?.(0)?.sirketAd ?? undefined,
      yayincibilgileri_426623_value: this.state.UyelikFormuById?.at?.(0)?.popSozlesmesiDosyaAd ?? undefined,
      yayincibilgileri_546287_value: this.state.UyelikFormuById?.at?.(0)?.cityID ?? undefined,
      yayincibilgileri_976849_value: this.state.UyelikFormuById?.at?.(0)?.imzaSirkuleriDosyaAd ?? undefined,
      yayincibilgileri_100145_value: this.state.UyelikFormuById?.at?.(0)?.districtID ?? undefined,
      yayincibilgileri_806164_value: this.state.UyelikFormuById?.at?.(0)?.ticariSicilGazetesiDosyaAd ?? undefined,
      yayincibilgileri_909820_value: this.state.UyelikFormuById?.at?.(0)?.adres ?? undefined,
      yayincibilgileri_390834_value: this.state.UyelikFormuById?.at?.(0)?.vergiLevhasiDosyaAd ?? undefined,
      yayincibilgileri_725329_value: this.state.UyelikFormuById?.at?.(0)?.vergiDairesi ?? undefined,
      yayincibilgileri_615210_value: this.state.UyelikFormuById?.at?.(0)?.yayinciSertifikasiDosyaAd ?? undefined,
      yayincibilgileri_512795_value: this.state.UyelikFormuById?.at?.(0)?.vergiKimlikNo ?? undefined,
      yayincibilgileri_798993_value: this.state.UyelikFormuById?.at?.(0)?.yayinciSertifikaNo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YayinciBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      prmID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YayinciBilgileri/YayinciBilgileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.yayincibilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.yayincibilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    stateVars.GenelParametrelerPDFYayinci = result?.data.genelParametrelerPDFYayinci;
    stateVars.getGuid = result?.data.getGuid;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_546287_options = stateVars.SelectCityAll;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_100145_options = stateVars.SelectDistrictAll;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectCityAll = result?.data.selectCityAll;

    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_546287_options = stateVars.SelectCityAll;
    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_100145_options = stateVars.SelectDistrictAll;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp426623Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp976849Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp806164Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp390834Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp615210Enabled", "disabled");
    formVars.yayincibilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.yayincibilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    stateVars.isComp22702Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp296143Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    stateVars.dataSource_546287 = this.state.SelectCityAll;
    stateVars.dataSource_546287 = this.state.SelectCityAll;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366519Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp156825Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp391896Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp347316Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_254578 = this.state.UyelikFormuDuzeltmeNotuByUyeID;
    stateVars.isComp221266Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp590083Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YayinciBilgileriComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YayinciBilgileri",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YayinciBilgileri",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "431564",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_22702_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_101671_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametrelerPDFYayinci?.length > 0
          ? this.state.GenelParametrelerPDFYayinci[0]?.ornekPOPSertifikaURLYayinci
          : null
      ),
      "YayıncıSozlesmesi.pdf"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_329421_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_725329_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_512795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_798993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isPOPSozlesmesi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_329421_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_329421_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_329421_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_329421_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_329421_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      POPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSozlesmesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_329421_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.POPSozlesmesiUpdate = result?.data.popSozlesmesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_329421_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_329421_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yayincibilgileri_426623_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2a9e5c6c_ff2b_5ca7_25e6_d1c21f9d5c53_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_442305_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isPOPSozlesmesi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_442305_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_442305_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_442305_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsPOPSozlesme",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YayinciBilgileriComponent_442305_onClick2_,
      "YayinciBilgileri",
      "PDFViewerFormsPOPSozlesme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "479300",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_442305_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_493754_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_493754_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.POPSozlesmesiById = result?.data.popSozlesmesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_493754_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_493754_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.POPSozlesmesiById?.length > 0 ? this.state.POPSozlesmesiById[0]?.popSozlesmesi : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.POPSozlesmesiById?.length > 0 ? this.state.POPSozlesmesiById[0]?.popSozlesmesiDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_514993_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isPOPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_514993_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_514993_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_514993_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_497885_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isPOPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_497885_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_497885_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_497885_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_546287_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_546287_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.yayincibilgileri_100145_options = stateVars.SelectDistrictAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_546287_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_546287_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_829791_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_725329_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_512795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_798993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isImzaSirkuleri_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_829791_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_829791_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_829791_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_829791_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_829791_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      ImzaSirkuleriDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_829791_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImzaSirkuleriUpdate = result?.data.imzaSirkuleriUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_829791_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_829791_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yayincibilgileri_976849_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "50ed8ceb_7661_db1a_8e19_4bf66f8d2c8e_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_246570_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isImzaSirkuleri_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_246570_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_246570_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_246570_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsImza",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YayinciBilgileriComponent_246570_onClick2_,
      "YayinciBilgileri",
      "PDFViewerFormsImza",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "968211",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_246570_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_328110_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_328110_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.ImzaSirkuleriById = result?.data.imzaSirkuleriById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_328110_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_328110_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.ImzaSirkuleriById?.length > 0 ? this.state.ImzaSirkuleriById[0]?.imzaSirkuleri : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.ImzaSirkuleriById?.length > 0 ? this.state.ImzaSirkuleriById[0]?.imzaSirkuleriDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_671136_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_671136_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_671136_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_671136_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_538704_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isImzaSirkuleri_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_538704_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_538704_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_538704_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_290599_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_725329_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_512795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_798993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isTicariSicilGazetesi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_290599_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_11;
    stateVars.SaveRecord = result?.data.saveRecord_12;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_290599_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_290599_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_290599_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_290599_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TicariSicilGazetesiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      TicariSicilGazetesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_290599_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TicariSicilGazetesiUpdate = result?.data.ticariSicilGazetesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_290599_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_290599_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yayincibilgileri_806164_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c1e416ed_1a39_3bee_feea_2645ac720e6e_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_168870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isTicariSicilGazetesi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_168870_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_13;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_168870_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_168870_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsTicariSicilGazete",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YayinciBilgileriComponent_168870_onClick2_,
      "YayinciBilgileri",
      "PDFViewerFormsTicariSicilGazete",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "641202",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_168870_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_327502_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_327502_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.TicariSicilGazetesiById = result?.data.ticariSicilGazetesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_327502_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_327502_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.TicariSicilGazetesiById?.length > 0
          ? this.state.TicariSicilGazetesiById[0]?.ticariSicilGazetesi
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.TicariSicilGazetesiById?.length > 0
          ? this.state.TicariSicilGazetesiById[0]?.ticariSicilGazetesiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_769440_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isTicariSicilGazetesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_769440_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_14;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_769440_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_769440_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_592406_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isTicariSicilGazetesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_592406_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_15;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_592406_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_592406_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_66922_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_725329_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_512795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_798993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isVergiLevhasi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_66922_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_16;
    stateVars.SaveRecord = result?.data.saveRecord_17;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_66922_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_66922_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_66922_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_66922_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      VergiLevhasiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      VergiLevhasiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_66922_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VergiLevhasiUpdate = result?.data.vergiLevhasiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_66922_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_66922_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yayincibilgileri_390834_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "37eba1c8_c31b_f335_cbf3_f83df7b87aa5_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_521056_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isVergiLevhasi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_521056_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_18;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_521056_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_521056_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsVergiLevhasi",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YayinciBilgileriComponent_521056_onClick2_,
      "YayinciBilgileri",
      "PDFViewerFormsVergiLevhasi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "95531",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_521056_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_762500_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_762500_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.VergiLevhasiById = result?.data.vergiLevhasiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_762500_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_762500_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.VergiLevhasiById?.length > 0 ? this.state.VergiLevhasiById[0]?.vergiLevhasi : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.VergiLevhasiById?.length > 0 ? this.state.VergiLevhasiById[0]?.vergiLevhasiDosyaAd : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_259838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isVergiLevhasi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_259838_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_19;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_259838_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_259838_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_909781_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isVergiLevhasi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_909781_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_20;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_909781_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_909781_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_988543_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_725329_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_512795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_798993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      isYayinciSertifikasi_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_988543_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_21;
    stateVars.SaveRecord = result?.data.saveRecord_22;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_988543_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_988543_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_988543_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_988543_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      YayinciSertifikasiData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      YayinciSertifikasiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_988543_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.YayinciSertifikasiUpdate = result?.data.yayinciSertifikasiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_988543_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_988543_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "yayincibilgileri_615210_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0e242711_65ec_c5da_70a5_bb8b60e6ed3f_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_522833_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isYayinciSertifikasi_1: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyelikFormuId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_522833_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.SaveRecord = result?.data.saveRecord_23;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_522833_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_522833_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsYayinciSertifikasi",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        this.state.getDownloadGuid?.length > 0 ? this.state.getDownloadGuid[0]?.getDownloadGuid : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YayinciBilgileriComponent_522833_onClick2_,
      "YayinciBilgileri",
      "PDFViewerFormsYayinciSertifikasi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "350654",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_522833_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_779951_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_779951_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.YayinciSertifikasiById = result?.data.yayinciSertifikasiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_779951_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_779951_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        this.state.YayinciSertifikasiById?.length > 0 ? this.state.YayinciSertifikasiById[0]?.yayinciSertifikasi : null
      ),
      ReactSystemFunctions.toString(
        this,
        this.state.YayinciSertifikasiById?.length > 0
          ? this.state.YayinciSertifikasiById[0]?.yayinciSertifikasiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_805117_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isYayinciSertifikasi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_805117_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_24;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_805117_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_805117_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_114208_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      isYayinciSertifikasi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyelikFormuId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_114208_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_25;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.sirketAd : null
    );
    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );
    formVars.yayincibilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.cityID : null;
    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );
    formVars.yayincibilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.districtID : null;
    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );
    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.adres : null
    );
    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );
    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiDairesi : null
    );
    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );
    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.vergiKimlikNo : null
    );
    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );
    stateVars.isComp329421Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468550Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp48406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497885Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isPOPSozlesmesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp829791Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp723585Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671136Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538704Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isImzaSirkuleri : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp290599Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp2990Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954949Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp769440Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp592406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isTicariSicilGazetesi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66922Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696038Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741691Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp259838Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp909781Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isVergiLevhasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp988543Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320227Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp976129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp114208Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.isYayinciSertifikasi : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966444Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0 ? stateVars.UyelikFormuById[0]?.uyelikDurumKey : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_114208_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_114208_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_328718_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.sirketAd : null
    );

    formVars.yayincibilgileri_426623_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.popSozlesmesiDosyaAd : null
    );

    formVars.yayincibilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.cityID : null
    );

    formVars.yayincibilgileri_976849_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.imzaSirkuleriDosyaAd : null
    );

    formVars.yayincibilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.districtID : null
    );

    formVars.yayincibilgileri_806164_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.ticariSicilGazetesiDosyaAd : null
    );

    formVars.yayincibilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.adres : null
    );

    formVars.yayincibilgileri_390834_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiLevhasiDosyaAd : null
    );

    formVars.yayincibilgileri_725329_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiDairesi : null
    );

    formVars.yayincibilgileri_615210_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikasiDosyaAd : null
    );

    formVars.yayincibilgileri_512795_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.vergiKimlikNo : null
    );

    formVars.yayincibilgileri_798993_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.yayinciSertifikaNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_866476_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_433117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_433117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_433117_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_866476_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_26;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_866476_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_866476_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "yayincibilgileri_433117_value", "", null);

    stateVars.dataSource_254578 = this.state.UyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_626782_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_626782_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_27;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_626782_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_626782_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_626782_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_626782_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_626782_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "29dd8e6e_799a_d513_f28d_618f1559bc1c_notify",
        this.defaultML,
        "Form Taslak Olarak Kayıt Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_887191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_426623_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_426623_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_976849_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_976849_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_806164_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_806164_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_390834_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_390834_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_615210_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_615210_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_328718_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_725329_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_512795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yayincibilgileri_798993_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_426623_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_976849_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_806164_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_390834_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_615210_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      SirketAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_328718_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_100145_value", "value", "SelectDistrictAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_725329_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_512795_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinciSertifikaNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yayincibilgileri_798993_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      BasvuruTarihi_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(-1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_887191_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_28;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_887191_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_887191_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "16396f66_1e1a_b8e8_3105_b5457afe7e89_notify",
        this.defaultML,
        "Form Kaydı Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_887191_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_887191_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("poweruseronayinagonder", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_3: ReactSystemFunctions.convertToTypeByName("9859035e-d0f0-48b5-997e-892a19c42cc1", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFYayinci?.length > 0
            ? this.state.GenelParametrelerPDFYayinci[0]?.powerUserMail
            : null
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFYayinci?.length > 0
            ? this.state.GenelParametrelerPDFYayinci[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      UyeSoyad_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_887191_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.yayincibilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.yayincibilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_887191_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_887191_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.getMyUsername(),
      "6747f284-222a-4c07-8ae3-986bc25476f3"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_887191_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_887191_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_887191_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_887191_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_887191_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.YayinciBilgileriComponent_887191_onClick6_,
      "YayinciBilgileri",
      "YayinciBilgileriKontrol",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_887191_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.yayincibilgileri_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.yayincibilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_531619_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_531619_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NedenGirisKontrol = result?.data.nedenGirisKontrol;
    stateVars.NedenGirisAciklama = result?.data.nedenGirisAciklama;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_531619_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_531619_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fa676bcb_43ef_f1e2_8c5a_24e7d8aff5f5_confirmation",
        this.defaultML,
        "Üyelik Formu Düzeltme İçin Geri Gönderilecek, İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YayinciBilgileri/YayinciBilgileriComponent_531619_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YayinciBilgileriComponent_531619_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_531619_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
      ),
      "24e0168b-c016-47bd-bb66-1b9a221775f2"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "53b401af_fb6f_6a02_3f96_eb7d5dd46f64_notify",
        this.defaultML,
        "Üyelik Bilgileri Düzeltme İçin Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_531619_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_531619_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("powerusergerigonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("2f71eae3-f529-4bc4-b700-59e0a52004b6", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFYayinci?.length > 0
            ? this.state.GenelParametrelerPDFYayinci[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      Aciklama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.NedenGirisAciklama?.length > 0 ? this.state.NedenGirisAciklama[0]?.duzeltmeNotu : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_531619_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_531619_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_531619_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_433622_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_433622_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NedenGirisKontrol = result?.data.nedenGirisKontrol;
    stateVars.NedenGirisAciklama = result?.data.nedenGirisAciklama;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_433622_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2e8874c8_d845_ca29_b283_e362b9bac250_confirmation",
        this.defaultML,
        "Üyelik Formu Red Edilecektir; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YayinciBilgileri/YayinciBilgileriComponent_433622_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_433622_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "629e0b8a_9a43_d2fa_ff11_ad7c06298dca_notify",
        this.defaultML,
        "Form Red Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_433622_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_433622_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyeRedUserDisabled = result?.data.uyeRedUserDisabled;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_433622_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UyeRedUserDisabled?.length > 0 ? this.state.UyeRedUserDisabled[0]?.uyelikDurumKey : null
        ),
        "0"
      )
    ) {
      stateVars.DisableUser = await ReactSystemFunctions.disableUser(
        ReactSystemFunctions.toString(
          this,
          this.state.UyeRedUserDisabled?.length > 0 ? this.state.UyeRedUserDisabled[0]?.userName : null
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_433622_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruserred", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("4239c471-f052-4cd0-ae85-0e4ad91e9471", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFYayinci?.length > 0
            ? this.state.GenelParametrelerPDFYayinci[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      Aciklama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.NedenGirisAciklama?.length > 0 ? this.state.NedenGirisAciklama[0]?.duzeltmeNotu : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_433622_onClick5_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_433622_onClick6_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_433622_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YayinciBilgileriComponent_395763_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      ControlCount_1: ReactSystemFunctions.convertToTypeByName(5, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_395763_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BelgeKontrolYayinci = result?.data.belgeKontrolYayinci;
    stateVars.GoruntulemeCountKontrol = result?.data.goruntulemeCountKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_395763_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_395763_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1013ead9_0a1a_eb46_361b_39409b721051_confirmation",
        this.defaultML,
        "Üyelik Onaylanacaktır; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "YayinciBilgileri/YayinciBilgileriComponent_395763_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.YayinciBilgileriComponent_395763_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_395763_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
      ),
      "c66813e5-5c42-414f-8d2b-edc1673f33ae"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e1d69d10_6a43_ea43_dc1c_04f63f70815b_notify",
        this.defaultML,
        "Form Onaylandı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_395763_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YayinciBilgileriComponent_395763_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("ee24e6c3-69ce-47a9-9dad-dff5e55542d0", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.userName : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametrelerPDFYayinci?.length > 0
            ? this.state.GenelParametrelerPDFYayinci[0]?.destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.firstName : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.uyelikTipi : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikFormuById?.length > 0 ? this.state.UyelikFormuById[0]?.lastName : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "YayinciBilgileri/YayinciBilgileriComponent_395763_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.YayinciBilgileriComponent_395763_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YayinciBilgileriComponent_395763_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 431564, 479300, 968211, 641202, 95531, 350654] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.YayinciBilgileriPageInit();
    }
    if (diId == 479300) {
      isErrorOccurred = await this.YayinciBilgileriComponent_442305_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 968211) {
      isErrorOccurred = await this.YayinciBilgileriComponent_246570_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 641202) {
      isErrorOccurred = await this.YayinciBilgileriComponent_168870_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 95531) {
      isErrorOccurred = await this.YayinciBilgileriComponent_521056_onClick2_();
      if (isErrorOccurred) return true;
    } else if (diId == 350654) {
      isErrorOccurred = await this.YayinciBilgileriComponent_522833_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
