import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITest_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITest_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class Test_ScreenBase extends React.PureComponent<ITest_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0abf2b14-1722-489a-b6e9-2a062b5b03d2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 400186, PropertyName: "value", Value: "ad soyad" },
        { Id: 493007, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 449110, PropertyName: "value", Value: "tc kimlik" },
        { Id: 798000, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 193918, PropertyName: "value", Value: "gsm" },
        { Id: 292218, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 512743, PropertyName: "value", Value: "mail adresi" },
        { Id: 743591, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("test", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("test", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("test", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
