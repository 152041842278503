import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITeklifVermeList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITeklifVermeList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  SetTabColor: any[];
  SetTabColor_dummy: any[];
  TeklifTaslakList: any[];
  TeklifTaslakList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  TeklifOnayList: any[];
  TeklifOnayList_dummy: any[];
  TeklifVerilenList: any[];
  TeklifVerilenList_dummy: any[];
  TeklifVerilmeyenList: any[];
  TeklifVerilmeyenList_dummy: any[];
  TeklifTaslakListTOExcel: any[];
  TeklifTaslakListTOExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  TeklifOnayListTOExcel: any[];
  TeklifOnayListTOExcel_dummy: any[];
  TeklifVerilenListTOExcel: any[];
  TeklifVerilenListTOExcel_dummy: any[];
  TeklifVerilmeyenListTOExcel: any[];
  TeklifVerilmeyenListTOExcel_dummy: any[];
  isComp737027Visible: "visible" | "hidden";
  isComp370428Visible: "visible" | "hidden";
  isComp714252Visible: "visible" | "hidden";
  isComp182116Visible: "visible" | "hidden";
  isComp209835Visible: "visible" | "hidden";
  isComp726791Visible: "visible" | "hidden";
  isComp530659Visible: "visible" | "hidden";
  isComp808736Visible: "visible" | "hidden";
  isComp323542Visible: "visible" | "hidden";
  isComp635971Visible: "visible" | "hidden";
  isComp398869Visible: "visible" | "hidden";
  isComp828632Visible: "visible" | "hidden";
  isComp943301Visible: "visible" | "hidden";
  isComp893800Visible: "visible" | "hidden";
  isComp272894Visible: "visible" | "hidden";
  isComp566575Visible: "visible" | "hidden";
  isComp579595Visible: "visible" | "hidden";
  isComp548593Visible: "visible" | "hidden";
  isComp333891Visible: "visible" | "hidden";
  isComp56156Visible: "visible" | "hidden";
  isComp857369Visible: "visible" | "hidden";
  isComp987720Visible: "visible" | "hidden";
  isComp54945Visible: "visible" | "hidden";
  isComp200123Visible: "visible" | "hidden";
  isComp681816Visible: "visible" | "hidden";
  isComp107750Visible: "visible" | "hidden";
  isComp99688Visible: "visible" | "hidden";
  isComp384340Visible: "visible" | "hidden";
  isComp545072Visible: "visible" | "hidden";
}

export class TeklifVermeList_ScreenBase extends React.PureComponent<ITeklifVermeList_ScreenProps, any> {
  teklifvermelist_944312_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_504983_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_867802_value_kuikaTableRef: React.RefObject<any>;
  teklifvermelist_958426_value_kuikaPaginationRef: React.RefObject<any>;
  teklifvermelist_583680_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_777486_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_793930_value_kuikaTableRef: React.RefObject<any>;
  teklifvermelist_961516_value_kuikaPaginationRef: React.RefObject<any>;
  teklifvermelist_917077_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_417300_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_427031_value_kuikaTableRef: React.RefObject<any>;
  teklifvermelist_387044_value_kuikaPaginationRef: React.RefObject<any>;
  teklifvermelist_621353_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_336778_value_kuikaSelectBoxRef: React.RefObject<any>;
  teklifvermelist_797165_value_kuikaTableRef: React.RefObject<any>;
  teklifvermelist_619083_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "2b54d262-bae0-4e60-ad67-374a18052010",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 964782, PropertyName: "value", Value: "Teklif Verme" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 926552, PropertyName: "label", Value: "Talepler & Taslak Teklifler" },
        { Id: 949824, PropertyName: "label", Value: "Verilen Teklif Onaylama" },
        { Id: 872566, PropertyName: "label", Value: "Verilen Teklifler" },
        { Id: 3299, PropertyName: "label", Value: "Verilmeyen Teklifler" },
        { Id: 209835, PropertyName: "label", Value: "Hızlı Teklif Hesapla" },
        { Id: 944312, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 504983, PropertyName: "placeholder", Value: "A-z" },
        { Id: 315662, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 867802, PropertyName: "nodatafoundmessage", Value: "Talep & Taslak Teklif Kaydı Bulunamadı." },
        { Id: 836657, PropertyName: "title", Value: "HızlıTeklif" },
        { Id: 402853, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 967638, PropertyName: "value", Value: "[datafield:taleptarihi]" },
        { Id: 13258, PropertyName: "title", Value: "Talep No" },
        { Id: 397342, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 59154, PropertyName: "title", Value: "Teklif No" },
        { Id: 998618, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 614318, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 353810, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 848148, PropertyName: "title", Value: "Ebat" },
        { Id: 552503, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 527802, PropertyName: "title", Value: "Kağıt" },
        { Id: 644573, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 8791, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 403746, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 321571, PropertyName: "title", Value: "Kapak" },
        { Id: 938671, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 95231, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 204338, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 416239, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 513278, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 487649, PropertyName: "title", Value: "Renk" },
        { Id: 992755, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 578447, PropertyName: "title", Value: "Selefon" },
        { Id: 831414, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 635670, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 327640, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 686281, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 320205, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 459624, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 560434, PropertyName: "value", Value: "[datafield:nakliyedurum]" },
        { Id: 215549, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 956403, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 3870, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 635192, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 422297, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 671385, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 884227, PropertyName: "title", Value: "Talep Durumu" },
        { Id: 96854, PropertyName: "value", Value: "[datafield:talepdurum]" },
        { Id: 932759, PropertyName: "title", Value: "Teklif Durumu" },
        { Id: 219875, PropertyName: "value", Value: "[datafield:teklifdurum]" },
        { Id: 583680, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 777486, PropertyName: "placeholder", Value: "A-z" },
        { Id: 647624, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 793930, PropertyName: "nodatafoundmessage", Value: "Onay Bekleyen Teklif Kaydı Bulunamadı." },
        { Id: 665918, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 130983, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 525792, PropertyName: "title", Value: "Teklif No" },
        { Id: 822345, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 564406, PropertyName: "title", Value: "Talep No" },
        { Id: 875182, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 314831, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 868850, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 299148, PropertyName: "title", Value: "Ebat" },
        { Id: 51831, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 848561, PropertyName: "title", Value: "Kağıt" },
        { Id: 428300, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 601822, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 50068, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 206745, PropertyName: "title", Value: "Kapak" },
        { Id: 638191, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 147498, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 437469, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 813107, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 991972, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 923035, PropertyName: "title", Value: "Renk" },
        { Id: 341444, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 184276, PropertyName: "title", Value: "Selefon" },
        { Id: 989641, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 879794, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 444957, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 10838, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 302357, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 564336, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 709086, PropertyName: "value", Value: "[datafield:nakliyedurum]" },
        { Id: 43930, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 57316, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 646528, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 661676, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 387841, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 600938, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 729425, PropertyName: "title", Value: "Talep Durumu" },
        { Id: 139557, PropertyName: "value", Value: "[datafield:talepdurum]" },
        { Id: 337377, PropertyName: "value", Value: "Süreci tamamlanan kayıtları da göster" },
        { Id: 917077, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 417300, PropertyName: "placeholder", Value: "A-z" },
        { Id: 642213, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 427031, PropertyName: "nodatafoundmessage", Value: "Verilen Teklif Teklif Kaydı Bulunamadı." },
        { Id: 357028, PropertyName: "title", Value: "HızlıTeklif" },
        { Id: 290310, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 283829, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 729791, PropertyName: "title", Value: "Talep No" },
        { Id: 30580, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 275837, PropertyName: "title", Value: "Teklif No" },
        { Id: 850332, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 436947, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 949165, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 707135, PropertyName: "title", Value: "Ebat" },
        { Id: 709051, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 445179, PropertyName: "title", Value: "Kağıt" },
        { Id: 668535, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 88002, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 319496, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 201674, PropertyName: "title", Value: "Kapak" },
        { Id: 322707, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 330764, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 288583, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 483561, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 132280, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 434614, PropertyName: "title", Value: "Renk" },
        { Id: 38571, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 635753, PropertyName: "title", Value: "Selefon" },
        { Id: 264004, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 6798, PropertyName: "title", Value: "SayfaSayısı" },
        { Id: 224344, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 998583, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 612975, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 236934, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 507877, PropertyName: "value", Value: "[datafield:nakliyedurum]" },
        { Id: 746071, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 241440, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 112349, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 424374, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 936010, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 375729, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 699280, PropertyName: "title", Value: "Talep Durumu" },
        { Id: 876308, PropertyName: "value", Value: "[datafield:talepdurum]" },
        { Id: 621353, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 336778, PropertyName: "placeholder", Value: "A-z" },
        { Id: 246757, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 797165, PropertyName: "nodatafoundmessage", Value: "Teklif Verilmeyen Talep Kaydı Bulunamadı." },
        { Id: 447760, PropertyName: "title", Value: "HızlıTeklif" },
        { Id: 128293, PropertyName: "title", Value: "Talep Tarihi" },
        { Id: 690690, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 355217, PropertyName: "title", Value: "Talep No" },
        { Id: 888918, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 375102, PropertyName: "title", Value: "Teklif No" },
        { Id: 857369, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 483745, PropertyName: "title", Value: "Teklif Muhattabı" },
        { Id: 527224, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 490516, PropertyName: "title", Value: "Ebat" },
        { Id: 921642, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 6952, PropertyName: "title", Value: "Kağıt" },
        { Id: 778808, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 791116, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 573109, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 187153, PropertyName: "title", Value: "Kapak" },
        { Id: 82300, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 261125, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 861546, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 639220, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 249409, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 182028, PropertyName: "title", Value: "Renk" },
        { Id: 531956, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 112613, PropertyName: "title", Value: "Selefon" },
        { Id: 912367, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 976758, PropertyName: "title", Value: "SayfaSayısı" },
        { Id: 519742, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 693405, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 129042, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 984550, PropertyName: "title", Value: "Teslim Türü" },
        { Id: 506610, PropertyName: "value", Value: "[datafield:nakliyedurum]" },
        { Id: 205442, PropertyName: "title", Value: "Teklif Tarihi" },
        { Id: 72035, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 987720, PropertyName: "title", Value: "Geçerlilik Tarihi" },
        { Id: 479564, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 54945, PropertyName: "title", Value: "Teklif Fiyatı" },
        { Id: 189391, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 200123, PropertyName: "title", Value: "Talep Durumu" },
        { Id: 574018, PropertyName: "value", Value: "[datafield:talepdurum]" },
        { Id: 905576, PropertyName: "title", Value: "[datafield:teklifverilmemenedeni]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.teklifvermelist_944312_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_504983_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_867802_value_kuikaTableRef = React.createRef();
    this.teklifvermelist_958426_value_kuikaPaginationRef = React.createRef();
    this.teklifvermelist_583680_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_777486_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_793930_value_kuikaTableRef = React.createRef();
    this.teklifvermelist_961516_value_kuikaPaginationRef = React.createRef();
    this.teklifvermelist_917077_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_417300_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_427031_value_kuikaTableRef = React.createRef();
    this.teklifvermelist_387044_value_kuikaPaginationRef = React.createRef();
    this.teklifvermelist_621353_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_336778_value_kuikaSelectBoxRef = React.createRef();
    this.teklifvermelist_797165_value_kuikaTableRef = React.createRef();
    this.teklifvermelist_619083_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      TriggerEvent: "",
      RoleSadeceGoruntule: [],
      ChangeVisibilityOf: "",
      SetTabColor: [],
      TeklifTaslakList: [],
      setPTotalCount01: [],
      TeklifOnayList: [],
      TeklifVerilenList: [],
      TeklifVerilmeyenList: [],
      TeklifTaslakListTOExcel: [],
      ExportExcel: "",
      TeklifOnayListTOExcel: [],
      TeklifVerilenListTOExcel: [],
      TeklifVerilmeyenListTOExcel: [],
      dataSource_944312: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "TalepNo", text: "Talep No" },
        { key: "TalepTarihi", text: "Talep Tarihi" },
        { key: "Muhatap", text: "Muhatap" }
      ],
      dataSource_504983: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      dataSource_583680: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "TalepNo", text: "Talep No" },
        { key: "TalepTarihi", text: "Talep Tarihi" },
        { key: "Muhatap", text: "Muhatap" }
      ],
      dataSource_777486: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      dataSource_917077: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "TalepNo", text: "Talep No" },
        { key: "TalepTarihi", text: "Talep Tarihi" },
        { key: "Muhatap", text: "Muhatap" }
      ],
      dataSource_417300: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      dataSource_621353: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TeklifTarihi", text: "Teklif Tarihi" },
        { key: "TalepNo", text: "Talep No" },
        { key: "TalepTarihi", text: "Talep Tarihi" },
        { key: "Muhatap", text: "Muhatap" }
      ],
      dataSource_336778: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp737027Visible: "hidden",
      isComp370428Visible: "hidden",
      isComp714252Visible: "hidden",
      isComp182116Visible: "visible",
      isComp209835Visible: "hidden",
      isComp726791Visible: "hidden",
      isComp530659Visible: "hidden",
      isComp808736Visible: "hidden",
      isComp323542Visible: "hidden",
      isComp635971Visible: "hidden",
      isComp398869Visible: "hidden",
      isComp828632Visible: "hidden",
      isComp943301Visible: "hidden",
      isComp893800Visible: "hidden",
      isComp272894Visible: "hidden",
      isComp566575Visible: "hidden",
      isComp579595Visible: "hidden",
      isComp548593Visible: "hidden",
      isComp333891Visible: "hidden",
      isComp56156Visible: "hidden",
      isComp857369Visible: "hidden",
      isComp987720Visible: "hidden",
      isComp54945Visible: "hidden",
      isComp200123Visible: "hidden",
      isComp681816Visible: "hidden",
      isComp107750Visible: "hidden",
      isComp99688Visible: "hidden",
      isComp384340Visible: "hidden",
      isComp545072Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("teklifvermelist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TeklifVermeListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("teklifvermelist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("teklifvermelist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TeklifVermeListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      teklifvermelist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      teklifvermelist_599014_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TeklifVermeListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TeklifVermeList/TeklifVermeListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.teklifvermelist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.teklifvermelist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.teklifvermelist_420731_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.TeklifVermeListComponent_926552_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifVermeListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.altRolID : null),
        "Guid"
      ),
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.userName : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TeklifVermeList/TeklifVermeListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp808736Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943301Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.teklifvermelist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.teklifvermelist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.teklifvermelist_420731_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.isComp726791Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_867802_value", "hizliTeklif"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808736Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp323542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_867802_value", "isTeklifGirildi"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp635971Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_867802_value", "isTeklifGirildi"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943301Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893800Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "hizliTeklif"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp566575Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTeklifOnaySurecIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579595Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTalepEdenTeklifOnayIcoVisibility"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp548593Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTalepEdenTeklifOnayIcoVisibility"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333891Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTalepEdenTeklifOnayIcoVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56156Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "hizliTeklif"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp107750Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTeklifOnaySurecIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp99688Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTalepEdenTeklifOnayIcoVisibility"),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp384340Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTalepEdenTeklifOnayIcoVisibility"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp545072Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "isTalepEdenTeklifOnayIcoVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TeklifVermeListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "659584",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_926552_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp737027Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp370428Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp714252Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp182116Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_926552_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifVermeListComponent_926552_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TabIndex_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_315662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_944312_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_504983_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_926552_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SetTabColor = result?.data.setTabColor;
    formVars.teklifvermelist_122811_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab1Color : null
    );
    formVars.teklifvermelist_147704_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab2Color : null
    );
    formVars.teklifvermelist_247521_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab3Color : null
    );
    formVars.teklifvermelist_331997_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab4Color : null
    );
    stateVars.TeklifTaslakList = result?.data.teklifTaslakList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_926552_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_926552_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.teklifvermelist_122811_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab1Color : null
    );

    formVars.teklifvermelist_147704_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab2Color : null
    );

    formVars.teklifvermelist_247521_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab3Color : null
    );

    formVars.teklifvermelist_331997_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab4Color : null
    );

    stateVars.dataSource_867802 = this.state.TeklifTaslakList;
    formVars.teklifvermelist_958426_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_961516_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_387044_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_619083_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_949824_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp182116Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp737027Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp714252Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp370428Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_949824_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifVermeListComponent_949824_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TabIndex_0: ReactSystemFunctions.convertToTypeByName(2, "number"),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_647624_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_583680_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_777486_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_949824_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SetTabColor = result?.data.setTabColor;
    formVars.teklifvermelist_122811_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab1Color : null
    );
    formVars.teklifvermelist_147704_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab2Color : null
    );
    formVars.teklifvermelist_247521_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab3Color : null
    );
    formVars.teklifvermelist_331997_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab4Color : null
    );
    stateVars.TeklifOnayList = result?.data.teklifOnayList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_949824_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_949824_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.teklifvermelist_122811_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab1Color : null
    );

    formVars.teklifvermelist_147704_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab2Color : null
    );

    formVars.teklifvermelist_247521_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab3Color : null
    );

    formVars.teklifvermelist_331997_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab4Color : null
    );

    formVars.teklifvermelist_958426_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    stateVars.dataSource_793930 = this.state.TeklifOnayList;
    formVars.teklifvermelist_961516_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_387044_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_619083_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_872566_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp182116Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp370428Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp714252Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp737027Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_872566_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifVermeListComponent_872566_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TabIndex_0: ReactSystemFunctions.convertToTypeByName(3, "number"),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_872566_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SetTabColor = result?.data.setTabColor;
    formVars.teklifvermelist_122811_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab1Color : null
    );
    formVars.teklifvermelist_147704_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab2Color : null
    );
    formVars.teklifvermelist_247521_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab3Color : null
    );
    formVars.teklifvermelist_331997_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab4Color : null
    );
    stateVars.TeklifVerilenList = result?.data.teklifVerilenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_872566_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_872566_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.teklifvermelist_122811_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab1Color : null
    );

    formVars.teklifvermelist_147704_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab2Color : null
    );

    formVars.teklifvermelist_247521_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab3Color : null
    );

    formVars.teklifvermelist_331997_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab4Color : null
    );

    formVars.teklifvermelist_958426_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_961516_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    stateVars.dataSource_427031 = this.state.TeklifVerilenList;
    formVars.teklifvermelist_387044_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_619083_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_3299_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp182116Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp370428Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp737027Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp714252Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_3299_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TeklifVermeListComponent_3299_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TabIndex_0: ReactSystemFunctions.convertToTypeByName(4, "number"),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_1: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_246757_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_621353_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_336778_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_3299_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SetTabColor = result?.data.setTabColor;
    formVars.teklifvermelist_122811_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab1Color : null
    );
    formVars.teklifvermelist_147704_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab2Color : null
    );
    formVars.teklifvermelist_247521_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab3Color : null
    );
    formVars.teklifvermelist_331997_dynamicbackgroundcolor = ReactSystemFunctions.toString(
      this,
      stateVars.SetTabColor?.length > 0 ? stateVars.SetTabColor[0]?.tab4Color : null
    );
    stateVars.TeklifVerilmeyenList = result?.data.teklifVerilmeyenList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_3299_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_3299_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.teklifvermelist_122811_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab1Color : null
    );

    formVars.teklifvermelist_147704_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab2Color : null
    );

    formVars.teklifvermelist_247521_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab3Color : null
    );

    formVars.teklifvermelist_331997_dynamicBackGroundColor = ReactSystemFunctions.toString(
      this,
      this.state.SetTabColor?.length > 0 ? this.state.SetTabColor[0]?.tab4Color : null
    );

    formVars.teklifvermelist_958426_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_961516_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    formVars.teklifvermelist_387044_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    stateVars.dataSource_797165 = this.state.TeklifVerilmeyenList;
    formVars.teklifvermelist_619083_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_944312_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_315662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_944312_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_504983_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_944312_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifTaslakList = result?.data.teklifTaslakList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_944312_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_944312_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_867802 = this.state.TeklifTaslakList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_504983_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_315662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_944312_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_504983_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_504983_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifTaslakList = result?.data.teklifTaslakList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_504983_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_504983_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_867802 = this.state.TeklifTaslakList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_949676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_315662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_944312_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_504983_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_949676_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifTaslakList = result?.data.teklifTaslakList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_949676_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_949676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_867802 = this.state.TeklifTaslakList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_111137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_315662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_944312_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_504983_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("Talepler & Taslak Teklifler", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_111137_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifTaslakListTOExcel = result?.data.teklifTaslakListTOExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TeklifVermeListComponent_530659_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifTalepKart",
      "prmID",
      ReactSystemFunctions.value(this, "teklifvermelist_867802_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "HizlimiListemi", "L");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifTalepKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "532621",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_323542_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "teklifvermelist_867802_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "N");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null)
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "52754",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_635971_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "teklifvermelist_867802_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "E");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null)
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "474892",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_958426_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_958426_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_315662_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_944312_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_504983_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_958426_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifTaslakList = result?.data.teklifTaslakList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_958426_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_958426_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_867802 = this.state.TeklifTaslakList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_583680_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_647624_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_583680_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_777486_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_583680_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifOnayList = result?.data.teklifOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_583680_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_583680_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_793930 = this.state.TeklifOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_777486_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_647624_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_583680_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_777486_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_777486_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifOnayList = result?.data.teklifOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_777486_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_777486_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_793930 = this.state.TeklifOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_753523_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_647624_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_583680_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_777486_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_753523_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifOnayList = result?.data.teklifOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_753523_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_753523_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_793930 = this.state.TeklifOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_990428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_647624_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_583680_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_777486_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("Verilen Teklif Onaylama", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_990428_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifOnayListTOExcel = result?.data.teklifOnayListTOExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TeklifVermeListComponent_600796_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifTalepKart",
      "prmID",
      ReactSystemFunctions.value(this, "teklifvermelist_793930_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "HizlimiListemi", "L");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifTalepKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "439340",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_740193_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "teklifvermelist_793930_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null)
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "234273",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_961516_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_961516_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_647624_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_583680_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_777486_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_961516_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifOnayList = result?.data.teklifOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_961516_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_961516_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_793930 = this.state.TeklifOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_599014_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_599014_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilenList = result?.data.teklifVerilenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_599014_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_599014_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_427031 = this.state.TeklifVerilenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_917077_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_917077_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilenList = result?.data.teklifVerilenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_917077_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_917077_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_427031 = this.state.TeklifVerilenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_417300_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_417300_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilenList = result?.data.teklifVerilenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_417300_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_417300_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_427031 = this.state.TeklifVerilenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_762656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_762656_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilenList = result?.data.teklifVerilenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_762656_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_762656_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_427031 = this.state.TeklifVerilenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_638173_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("Verilen Teklifler", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_638173_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilenListTOExcel = result?.data.teklifVerilenListTOExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TeklifVermeListComponent_999022_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifTalepKart",
      "prmID",
      ReactSystemFunctions.value(this, "teklifvermelist_793930_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "HizlimiListemi", "L");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifTalepKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "298647",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_911787_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmTeklifTalepID",
      ReactSystemFunctions.value(this, "teklifvermelist_427031_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables(
      "TeklifVermeKart",
      "prmUID",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null)
    );
    KuikaAppManager.addToPageInputVariables("TeklifVermeKart", "prmMuhatapMiFirmaMi", "Firma");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TeklifVermeList",
      "TeklifVermeKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "161755",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_387044_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_387044_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_599014_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_642213_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_917077_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_417300_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_387044_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilenList = result?.data.teklifVerilenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_387044_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_387044_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_427031 = this.state.TeklifVerilenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_621353_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_246757_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_621353_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_336778_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_621353_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilmeyenList = result?.data.teklifVerilmeyenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_621353_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_621353_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_797165 = this.state.TeklifVerilmeyenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_336778_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_246757_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_621353_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_336778_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_336778_onBlur",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilmeyenList = result?.data.teklifVerilmeyenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_336778_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_336778_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_797165 = this.state.TeklifVerilmeyenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_462877_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_619083_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_246757_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_621353_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_336778_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_462877_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilmeyenList = result?.data.teklifVerilmeyenList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TeklifVermeListComponent_462877_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TeklifVermeListComponent_462877_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_797165 = this.state.TeklifVerilmeyenList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TeklifVermeListComponent_294168_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeklifTamamGoster_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_246757_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_621353_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "teklifvermelist_336778_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("Verilmeyen Teklifler", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TeklifVermeList/TeklifVermeListComponent_294168_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TeklifVerilmeyenListTOExcel = result?.data.teklifVerilmeyenListTOExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 659584, 532621, 52754, 474892, 439340, 234273, 298647, 161755] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TeklifVermeListPageInit();
    }
  }
}
