import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  EserDetay_YayinTuruAll: any[];
  EserDetay_YayinTuruAll_dummy: any[];
  EserDetay_ZamanSecimiAll: any[];
  EserDetay_ZamanSecimiAll_dummy: any[];
  BaskiOncesiDurumAllic: any[];
  BaskiOncesiDurumAllic_dummy: any[];
  BaskiOncesiDurumAllkapak: any[];
  BaskiOncesiDurumAllkapak_dummy: any[];
  EserDetay_EserBilgileriById: any[];
  EserDetay_EserBilgileriById_dummy: any[];
  DokumanlarByKartIdISBN: any[];
  DokumanlarByKartIdISBN_dummy: any[];
  DokumanlarByKartIdEser: any[];
  DokumanlarByKartIdEser_dummy: any[];
  DokumanlarByKartIdICGORSEL: any[];
  DokumanlarByKartIdICGORSEL_dummy: any[];
  DokumanlarByKartIdKAPAKGORSEL: any[];
  DokumanlarByKartIdKAPAKGORSEL_dummy: any[];
  EserBilgileriDuzeltmeNotuByEserBilgileriID: any[];
  EserBilgileriDuzeltmeNotuByEserBilgileriID_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  EserBilgileri_TeklifEdenKisiEmail: any[];
  EserBilgileri_TeklifEdenKisiEmail_dummy: any[];
  TeklifEdenSirketAd: any[];
  TeklifEdenSirketAd_dummy: any[];
  EserBilgileri_TeklifTalepEdenEmail: any[];
  EserBilgileri_TeklifTalepEdenEmail_dummy: any[];
  TeklifAlanSirketAd: any[];
  TeklifAlanSirketAd_dummy: any[];
  getSiparisNobyTeklifNo: any[];
  getSiparisNobyTeklifNo_dummy: any[];
  EserDetay_BandrolStokByEserBilgileriID: any[];
  EserDetay_BandrolStokByEserBilgileriID_dummy: any[];
  EserDetay_BandrolStokSUM: any[];
  EserDetay_BandrolStokSUM_dummy: any[];
  EserBilgisiListSelectBoxByUyeIds: any[];
  EserBilgisiListSelectBoxByUyeIds_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  TipkiEserBilgileriniGetir: number;
  TipkiEserBilgileriniGetir_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  DokumanInsert: number;
  DokumanInsert_dummy: number;
  DokumanDeleteById: number;
  DokumanDeleteById_dummy: number;
  DokumanById: any[];
  DokumanById_dummy: any[];
  PrintPdf: string;
  PrintPdf_dummy: string;
  FileTypeControl: number;
  FileTypeControl_dummy: number;
  DeleteFileFromServer: string;
  DeleteFileFromServer_dummy: string;
  DownloadFileFromServer: string;
  DownloadFileFromServer_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getGonderilecekMailTip: any[];
  getGonderilecekMailTip_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  getDurumGuncelId: any[];
  getDurumGuncelId_dummy: any[];
  TeklifTalepKartKitapAdUpdate: number;
  TeklifTalepKartKitapAdUpdate_dummy: number;
  BelgeKontrolICGORSELveKAPAKGORSEL: number;
  BelgeKontrolICGORSELveKAPAKGORSEL_dummy: number;
  PowerUserOnayEserBilgisi: number;
  PowerUserOnayEserBilgisi_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  MontajOnayKontrol: number;
  MontajOnayKontrol_dummy: number;
  isComp380014Visible: "visible" | "hidden";
  isComp147643Visible: "visible" | "hidden";
  isComp689198Visible: "visible" | "hidden";
  isComp960528Visible: "visible" | "hidden";
  isComp585459Visible: "visible" | "hidden";
  isComp497206Visible: "visible" | "hidden";
  isComp263782Visible: "visible" | "hidden";
  isComp763210Visible: "visible" | "hidden";
  isComp966526Visible: "visible" | "hidden";
  isComp328200Visible: "visible" | "hidden";
  isComp274812Visible: "visible" | "hidden";
  isComp794966Visible: "visible" | "hidden";
  isComp559036Visible: "visible" | "hidden";
  isComp919708Visible: "visible" | "hidden";
  isComp435632Visible: "visible" | "hidden";
  isComp199531Visible: "visible" | "hidden";
  isComp920186Visible: "visible" | "hidden";
  isComp142626Visible: "visible" | "hidden";
  isComp224600Visible: "visible" | "hidden";
  isComp370832Visible: "visible" | "hidden";
  isComp756207Visible: "visible" | "hidden";
  isComp705243Visible: "visible" | "hidden";
  isComp542937Visible: "visible" | "hidden";
  isComp158669Visible: "visible" | "hidden";
  isComp741219Visible: "visible" | "hidden";
  isComp816111Visible: "visible" | "hidden";
  isComp929675Visible: "visible" | "hidden";
  isComp433689Visible: "visible" | "hidden";
  isComp907819Visible: "visible" | "hidden";
  isComp586668Visible: "visible" | "hidden";
  isComp26862Visible: "visible" | "hidden";
  isComp442162Visible: "visible" | "hidden";
  isComp583976Visible: "visible" | "hidden";
  isComp703152Visible: "visible" | "hidden";
  isComp39741Visible: "visible" | "hidden";
  isComp902363Visible: "visible" | "hidden";
  isComp812010Enabled: "enabled" | "disabled";
  isComp693380Enabled: "enabled" | "disabled";
  isComp836839Enabled: "enabled" | "disabled";
  isComp13964Enabled: "enabled" | "disabled";
  isComp325627Enabled: "enabled" | "disabled";
  isComp861278Enabled: "enabled" | "disabled";
  isComp295615Enabled: "enabled" | "disabled";
  isComp555402Enabled: "enabled" | "disabled";
  isComp957873Enabled: "enabled" | "disabled";
  isComp91530Enabled: "enabled" | "disabled";
  isComp585459Enabled: "enabled" | "disabled";
  isComp497206Enabled: "enabled" | "disabled";
  isComp284780Enabled: "enabled" | "disabled";
  isComp783769Enabled: "enabled" | "disabled";
}

export class EserBilgileri_ScreenBase extends React.PureComponent<IEserBilgileri_ScreenProps, any> {
  eserbilgileri_812010_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_555402_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_208611_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_13964_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_325627_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_861278_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_295615_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_91530_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_380689_value_kuikaTableRef: React.RefObject<any>;
  eserbilgileri_711309_value_kuikaTableRef: React.RefObject<any>;
  eserbilgileri_920186_value_kuikaNoneRef: React.RefObject<any>;
  eserbilgileri_647685_value_kuikaTableRef: React.RefObject<any>;
  eserbilgileri_705243_value_kuikaNoneRef: React.RefObject<any>;
  eserbilgileri_435280_value_kuikaTableRef: React.RefObject<any>;
  eserbilgileri_284780_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_783769_value_kuikaSelectBoxRef: React.RefObject<any>;
  eserbilgileri_282097_value_kuikaTableRef: React.RefObject<any>;
  eserbilgileri_733661_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "80f6c86e-9219-4c2f-85f5-4e3bf873bd0f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 588801, PropertyName: "value", Value: "Eser Bilgileri" },
        { Id: 512565, PropertyName: "value", Value: "Yayın Türü" },
        { Id: 71586, PropertyName: "value", Value: "Tıpkı Basım mı?" },
        { Id: 555402, PropertyName: "placeholder", Value: "Evet / Hayır" },
        { Id: 464556, PropertyName: "value", Value: "Tıpkı Basılacak Eseri Seçiniz" },
        { Id: 208611, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 575529, PropertyName: "label", Value: "Bilgileri Getir" },
        { Id: 736008, PropertyName: "value", Value: "Kitap Adı" },
        { Id: 723567, PropertyName: "value", Value: "Yazar Adı" },
        { Id: 644992, PropertyName: "value", Value: "Dergi Statüsünde Süreli Yayın" },
        { Id: 13964, PropertyName: "placeholder", Value: "Evet / Hayır" },
        { Id: 798035, PropertyName: "value", Value: "48 Sayfa Altı Çocuk Kitabı" },
        { Id: 325627, PropertyName: "placeholder", Value: "Evet / Hayır" },
        { Id: 472642, PropertyName: "value", Value: "Arkasında para ile satılamaz veya “Örnektir” ibaresi olan kitap" },
        { Id: 861278, PropertyName: "placeholder", Value: "Evet / Hayır" },
        { Id: 996796, PropertyName: "value", Value: "Bandrole tabi mi?" },
        { Id: 295615, PropertyName: "placeholder", Value: "Evet / Hayır" },
        { Id: 198322, PropertyName: "value", Value: "ISBN Numarası" },
        { Id: 749942, PropertyName: "value", Value: "Zaman Seçimi" },
        { Id: 169929, PropertyName: "value", Value: "Ürün Tescil / ISBN Dökümanı" },
        { Id: 380689, PropertyName: "nodatafoundmessage", Value: "Dosya Yüklenmedi!" },
        { Id: 152648, PropertyName: "title", Value: "Title" },
        { Id: 592475, PropertyName: "value", Value: "[datafield:dosyaad]" },
        { Id: 387082, PropertyName: "value", Value: "Döküman" },
        { Id: 711309, PropertyName: "nodatafoundmessage", Value: "Dosya Yüklenmedi!" },
        { Id: 400610, PropertyName: "title", Value: "Title" },
        { Id: 143182, PropertyName: "value", Value: "[datafield:dosyaad]" },
        { Id: 963805, PropertyName: "value", Value: "İç Görsel (PDF Dosyası)" },
        { Id: 647685, PropertyName: "nodatafoundmessage", Value: "Dosya Yüklenmedi!" },
        { Id: 22292, PropertyName: "title", Value: "Title" },
        { Id: 31768, PropertyName: "value", Value: "[datafield:dosyaad]" },
        { Id: 943607, PropertyName: "value", Value: "[datafield:dosya]" },
        { Id: 644843, PropertyName: "value", Value: "Kapak Görseli (PDF Dosyası)" },
        { Id: 435280, PropertyName: "nodatafoundmessage", Value: "Dosya Yüklenmedi!" },
        { Id: 455761, PropertyName: "title", Value: "Title" },
        { Id: 378387, PropertyName: "value", Value: "[datafield:dosyaad]" },
        { Id: 511714, PropertyName: "value", Value: "[datafield:dosya]" },
        { Id: 284780, PropertyName: "placeholder", Value: "İç Görsel Durumunu Seçiniz..." },
        { Id: 783769, PropertyName: "placeholder", Value: "Kapak Görseli Durumunu Seçiniz..." },
        { Id: 263782, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 585459, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 497206, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 282097, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 152954, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 466847, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 123043, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 882427, PropertyName: "value", Value: "Bandrol Stok Miktar :" },
        { Id: 590059, PropertyName: "value", Value: "Adet" },
        { Id: 759182, PropertyName: "header", Value: "Bandrol Stok Hareketleri" },
        { Id: 733661, PropertyName: "nodatafoundmessage", Value: "Stok hareketi bulunamadı!" },
        { Id: 398419, PropertyName: "title", Value: "Tarih" },
        { Id: 205301, PropertyName: "value", Value: "[datafield:gctarihformat]" },
        { Id: 816111, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 790941, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 542232, PropertyName: "title", Value: "Açıklama" },
        { Id: 516128, PropertyName: "value", Value: "[datafield:gcaciklama]" },
        { Id: 589915, PropertyName: "title", Value: "G/C" },
        { Id: 195721, PropertyName: "value", Value: "[datafield:stokgc]" },
        { Id: 948472, PropertyName: "title", Value: "Adet" },
        { Id: 159777, PropertyName: "value", Value: "[datafield:adet]" },
        { Id: 230639, PropertyName: "value", Value: "Stok Miktar :" },
        { Id: 499747, PropertyName: "value", Value: "Adet" },
        { Id: 907819, PropertyName: "value", Value: "Matbaa görür" },
        { Id: 879238, PropertyName: "label", Value: "Güncelle" },
        { Id: 26862, PropertyName: "label", Value: "İç Görsel Durumunu Güncelle" },
        { Id: 442162, PropertyName: "label", Value: "Kapak Görseli Durumunu Güncelle" },
        { Id: 583976, PropertyName: "value", Value: "Yayıncı görür" },
        { Id: 833540, PropertyName: "label", Value: "Güncelle" },
        { Id: 39741, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 526033, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 150995, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 453514, PropertyName: "label", Value: "Red Et" },
        { Id: 816954, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.eserbilgileri_812010_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_555402_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_208611_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_13964_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_325627_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_861278_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_295615_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_91530_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_380689_value_kuikaTableRef = React.createRef();
    this.eserbilgileri_711309_value_kuikaTableRef = React.createRef();
    this.eserbilgileri_920186_value_kuikaNoneRef = React.createRef();
    this.eserbilgileri_647685_value_kuikaTableRef = React.createRef();
    this.eserbilgileri_705243_value_kuikaNoneRef = React.createRef();
    this.eserbilgileri_435280_value_kuikaTableRef = React.createRef();
    this.eserbilgileri_284780_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_783769_value_kuikaSelectBoxRef = React.createRef();
    this.eserbilgileri_282097_value_kuikaTableRef = React.createRef();
    this.eserbilgileri_733661_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      EserDetay_YayinTuruAll: [],
      EserDetay_ZamanSecimiAll: [],
      BaskiOncesiDurumAllic: [],
      BaskiOncesiDurumAllkapak: [],
      EserDetay_EserBilgileriById: [],
      DokumanlarByKartIdISBN: [],
      DokumanlarByKartIdEser: [],
      DokumanlarByKartIdICGORSEL: [],
      DokumanlarByKartIdKAPAKGORSEL: [],
      EserBilgileriDuzeltmeNotuByEserBilgileriID: [],
      GenelParametreler: [],
      EserBilgileri_TeklifEdenKisiEmail: [],
      TeklifEdenSirketAd: [],
      EserBilgileri_TeklifTalepEdenEmail: [],
      TeklifAlanSirketAd: [],
      getSiparisNobyTeklifNo: [],
      EserDetay_BandrolStokByEserBilgileriID: [],
      EserDetay_BandrolStokSUM: [],
      EserBilgisiListSelectBoxByUyeIds: [],
      SetValueOf: "",
      ChangeVisibilityOf: "",
      ChangeEnabledOf: "",
      TipkiEserBilgileriniGetir: 0,
      UploadFile: "",
      DokumanInsert: 0,
      DokumanDeleteById: 0,
      DokumanById: [],
      PrintPdf: "",
      FileTypeControl: 0,
      DeleteFileFromServer: "",
      DownloadFileFromServer: "",
      SaveRecord: false,
      NAVIGATE: "",
      getGonderilecekMailTip: [],
      SendMailSettings: [],
      UyelikTipiLowercase: [],
      SendMail: false,
      Notify: false,
      getDurumGuncelId: [],
      TeklifTalepKartKitapAdUpdate: 0,
      BelgeKontrolICGORSELveKAPAKGORSEL: 0,
      PowerUserOnayEserBilgisi: 0,
      TriggerEvent: "",
      MontajOnayKontrol: 0,
      dataSource_555402: [
        { key: "0", text: "Hayır" },
        { key: "1", text: "Evet" }
      ],
      dataSource_13964: [
        { key: "0", text: "Hayır" },
        { key: "1", text: "Evet" }
      ],
      dataSource_325627: [
        { key: "0", text: "Hayır" },
        { key: "1", text: "Evet" }
      ],
      dataSource_861278: [
        { key: "0", text: "Hayır" },
        { key: "1", text: "Evet" }
      ],
      dataSource_295615: [
        { key: "0", text: "Hayır" },
        { key: "1", text: "Evet" }
      ],
      isComp380014Visible: "hidden",
      isComp147643Visible: "visible",
      isComp689198Visible: "visible",
      isComp960528Visible: "hidden",
      isComp585459Visible: "visible",
      isComp497206Visible: "visible",
      isComp263782Visible: "visible",
      isComp763210Visible: "hidden",
      isComp966526Visible: "hidden",
      isComp328200Visible: "hidden",
      isComp274812Visible: "hidden",
      isComp794966Visible: "hidden",
      isComp559036Visible: "hidden",
      isComp919708Visible: "hidden",
      isComp435632Visible: "hidden",
      isComp199531Visible: "hidden",
      isComp920186Visible: "hidden",
      isComp142626Visible: "hidden",
      isComp224600Visible: "hidden",
      isComp370832Visible: "hidden",
      isComp756207Visible: "hidden",
      isComp705243Visible: "hidden",
      isComp542937Visible: "hidden",
      isComp158669Visible: "hidden",
      isComp741219Visible: "hidden",
      isComp816111Visible: "hidden",
      isComp929675Visible: "hidden",
      isComp433689Visible: "hidden",
      isComp907819Visible: "hidden",
      isComp586668Visible: "hidden",
      isComp26862Visible: "hidden",
      isComp442162Visible: "hidden",
      isComp583976Visible: "hidden",
      isComp703152Visible: "hidden",
      isComp39741Visible: "hidden",
      isComp902363Visible: "hidden",
      isComp812010Enabled: "enabled",
      isComp693380Enabled: "enabled",
      isComp836839Enabled: "enabled",
      isComp13964Enabled: "enabled",
      isComp325627Enabled: "enabled",
      isComp861278Enabled: "enabled",
      isComp295615Enabled: "enabled",
      isComp555402Enabled: "enabled",
      isComp957873Enabled: "enabled",
      isComp91530Enabled: "enabled",
      isComp585459Enabled: "enabled",
      isComp497206Enabled: "enabled",
      isComp284780Enabled: "enabled",
      isComp783769Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserbilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserBilgileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserbilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserbilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserbilgileri_812010_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.yayinTuruID ?? undefined,
      eserbilgileri_555402_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.tipkiBasimmi ?? undefined,
      eserbilgileri_208611_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.tipkiEserID ?? undefined,
      eserbilgileri_693380_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.kitapAdi ?? undefined,
      eserbilgileri_836839_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.yazarAdi ?? undefined,
      eserbilgileri_13964_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.dergiSureliYayin ?? undefined,
      eserbilgileri_325627_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.cocukKitabiAzSayfa ?? undefined,
      eserbilgileri_861278_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.ornektir ?? undefined,
      eserbilgileri_295615_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.bandroleTabi ?? undefined,
      eserbilgileri_957873_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.isbnNumarasi ?? undefined,
      eserbilgileri_91530_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.zamanSecimiID ?? undefined,
      eserbilgileri_284780_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.baskiOncesiDurumIDic ?? undefined,
      eserbilgileri_783769_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.baskiOncesiDurumIDkapak ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  EserBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EserBilgileriID_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_12: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_13: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmtekliftalepkartId_14: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      prmTeklifTalepKartID_15: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      teklifno_16: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.teklifNo ?? this.props.screenInputs.teklifno,
        "string"
      ),
      EserBilgileriID_17: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EserBilgileriID_18: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EserBilgileri/EserBilgileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp816111Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.rolName : null),
        "MATBAA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929675Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.muhatapMiFirmaMi : null
        ),
        "Firma"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.EserDetay_YayinTuruAll = result?.data.eserDetay_YayinTuruAll;

    formVars.eserbilgileri_812010_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.yayinTuruID : null;
    formVars.eserbilgileri_812010_options = stateVars.EserDetay_YayinTuruAll;
    stateVars.EserDetay_ZamanSecimiAll = result?.data.eserDetay_ZamanSecimiAll;

    formVars.eserbilgileri_91530_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.zamanSecimiID
        : null;
    formVars.eserbilgileri_91530_options = stateVars.EserDetay_ZamanSecimiAll;
    stateVars.BaskiOncesiDurumAllic = result?.data.baskiOncesiDurumAllic;

    formVars.eserbilgileri_284780_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
        : null;
    formVars.eserbilgileri_284780_options = stateVars.BaskiOncesiDurumAllic;
    stateVars.BaskiOncesiDurumAllkapak = result?.data.baskiOncesiDurumAllkapak;

    formVars.eserbilgileri_783769_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
        : null;
    formVars.eserbilgileri_783769_options = stateVars.BaskiOncesiDurumAllkapak;
    stateVars.EserDetay_EserBilgileriById = result?.data.eserDetay_EserBilgileriById;
    formVars.eserbilgileri_812010_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.yayinTuruID : null;
    formVars.eserbilgileri_812010_options = stateVars.EserDetay_YayinTuruAll;
    formVars.eserbilgileri_555402_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiBasimmi : null;
    formVars.eserbilgileri_208611_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiEserID : null;
    formVars.eserbilgileri_208611_options = stateVars.EserBilgisiListSelectBoxByUyeIds;
    formVars.eserbilgileri_693380_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.kitapAdi : null
    );
    formVars.eserbilgileri_836839_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.yazarAdi : null
    );
    formVars.eserbilgileri_13964_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.dergiSureliYayin
        : null;
    formVars.eserbilgileri_325627_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.cocukKitabiAzSayfa
        : null;
    formVars.eserbilgileri_861278_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.ornektir : null;
    formVars.eserbilgileri_295615_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.bandroleTabi : null;
    formVars.eserbilgileri_957873_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.isbnNumarasi : null
    );
    formVars.eserbilgileri_91530_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.zamanSecimiID
        : null;
    formVars.eserbilgileri_91530_options = stateVars.EserDetay_ZamanSecimiAll;
    formVars.eserbilgileri_284780_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
        : null;
    formVars.eserbilgileri_284780_options = stateVars.BaskiOncesiDurumAllic;
    formVars.eserbilgileri_783769_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
        : null;
    formVars.eserbilgileri_783769_options = stateVars.BaskiOncesiDurumAllkapak;
    stateVars.isComp763210Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiBasimmi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966526Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp328200Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794966Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp919708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp920186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142626Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp705243Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp542937Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741219Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isrwDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp39741Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonTaslakVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp902363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DokumanlarByKartIdISBN = result?.data.dokumanlarByKartIdISBN;

    stateVars.DokumanlarByKartIdEser = result?.data.dokumanlarByKartIdEser;

    stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

    stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

    stateVars.EserBilgileriDuzeltmeNotuByEserBilgileriID = result?.data.eserBilgileriDuzeltmeNotuByEserBilgileriID;

    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.EserBilgileri_TeklifEdenKisiEmail = result?.data.eserBilgileri_TeklifEdenKisiEmail;
    stateVars.TeklifEdenSirketAd = result?.data.teklifEdenSirketAd;
    stateVars.EserBilgileri_TeklifTalepEdenEmail = result?.data.eserBilgileri_TeklifTalepEdenEmail;
    stateVars.TeklifAlanSirketAd = result?.data.teklifAlanSirketAd;
    stateVars.getSiparisNobyTeklifNo = result?.data.getSiparisNobyTeklifNo;
    stateVars.EserDetay_BandrolStokByEserBilgileriID = result?.data.eserDetay_BandrolStokByEserBilgileriID;

    stateVars.EserDetay_BandrolStokSUM = result?.data.eserDetay_BandrolStokSUM;
    formVars.eserbilgileri_93590_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0 ? stateVars.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );
    formVars.eserbilgileri_371842_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0 ? stateVars.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );
    stateVars.EserBilgisiListSelectBoxByUyeIds = result?.data.eserBilgisiListSelectBoxByUyeIds;

    formVars.eserbilgileri_208611_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiEserID : null;
    formVars.eserbilgileri_208611_options = stateVars.EserBilgisiListSelectBoxByUyeIds;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmKitapAd ?? this.props.screenInputs.prmkitapad, null)
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "eserbilgileri_693380_value",
        this.props.screenInputs.prmKitapAd ?? this.props.screenInputs.prmkitapad,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.yayinTuruID
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp380014Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp147643Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp689198Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp960528Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.yayinTuruKey
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp147643Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.yayinTuruKey
            : null
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp689198Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.yayinTuruKey
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp960528Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp812010Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp693380Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp836839Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp13964Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp325627Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp861278Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp295615Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp555402Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp957873Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp91530Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp585459Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp497206Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp585459Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp497206Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "BOE"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp284780Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "BOE"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp783769Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "D"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp263782Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.eserbilgileri_812010_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.yayinTuruID : null
    );

    stateVars.dataSource_812010 = this.state.EserDetay_YayinTuruAll;
    stateVars.dataSource_812010 = this.state.EserDetay_YayinTuruAll;
    formVars.eserbilgileri_555402_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.tipkiBasimmi
        : null
    );

    stateVars.isComp763210Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.tipkiBasimmi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserbilgileri_208611_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.tipkiEserID : null
    );

    stateVars.dataSource_208611 = this.state.EserBilgisiListSelectBoxByUyeIds;
    stateVars.dataSource_208611 = this.state.EserBilgisiListSelectBoxByUyeIds;
    formVars.eserbilgileri_693380_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.kitapAdi : null
    );

    formVars.eserbilgileri_836839_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.yazarAdi : null
    );

    formVars.eserbilgileri_13964_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.dergiSureliYayin
        : null
    );

    formVars.eserbilgileri_325627_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.cocukKitabiAzSayfa
        : null
    );

    formVars.eserbilgileri_861278_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.ornektir : null
    );

    formVars.eserbilgileri_295615_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.bandroleTabi
        : null
    );

    formVars.eserbilgileri_957873_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.isbnNumarasi
        : null
    );

    formVars.eserbilgileri_91530_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.zamanSecimiID
        : null
    );

    stateVars.dataSource_91530 = this.state.EserDetay_ZamanSecimiAll;
    stateVars.dataSource_91530 = this.state.EserDetay_ZamanSecimiAll;
    stateVars.isComp966526Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_380689 = this.state.DokumanlarByKartIdISBN;
    stateVars.isComp328200Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794966Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdEser;
    stateVars.isComp919708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserbilgileri_920186_savePath = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
    );

    stateVars.isComp920186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;
    stateVars.isComp142626Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp224600Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "BOD"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserbilgileri_705243_savePath = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
    );

    stateVars.isComp705243Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;
    stateVars.isComp542937Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp158669Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "BOD"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserbilgileri_284780_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
        : null
    );

    stateVars.dataSource_284780 = this.state.BaskiOncesiDurumAllic;
    stateVars.dataSource_284780 = this.state.BaskiOncesiDurumAllic;
    formVars.eserbilgileri_783769_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
        : null
    );

    stateVars.dataSource_783769 = this.state.BaskiOncesiDurumAllkapak;
    stateVars.dataSource_783769 = this.state.BaskiOncesiDurumAllkapak;
    stateVars.isComp741219Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isrwDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_282097 = this.state.EserBilgileriDuzeltmeNotuByEserBilgileriID;
    formVars.eserbilgileri_93590_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_BandrolStokSUM?.length > 0 ? this.state.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );

    stateVars.dataSource_733661 = this.state.EserDetay_BandrolStokByEserBilgileriID;
    stateVars.isComp816111Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "MATBAA"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929675Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.muhatapMiFirmaMi : null
        ),
        "Firma"
      ) === true
        ? "visible"
        : "hidden";
    formVars.eserbilgileri_371842_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_BandrolStokSUM?.length > 0 ? this.state.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );

    stateVars.isComp433689Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "BOE"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586668Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "BOD"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp39741Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonTaslakVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp902363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.isButtonsOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserBilgileriComponent_508315_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_812010_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_812010_value", "value", "EserDetay_YayinTuruAll", "id", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp380014Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_812010_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp147643Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp689198Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp960528Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_812010_onChange2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_812010_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_812010_value",
            "value",
            "EserDetay_YayinTuruAll",
            "id",
            "yayinTuruKey"
          )
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp147643Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_812010_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_812010_value",
            "value",
            "EserDetay_YayinTuruAll",
            "id",
            "yayinTuruKey"
          )
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp689198Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_812010_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_812010_value",
            "value",
            "EserDetay_YayinTuruAll",
            "id",
            "yayinTuruKey"
          )
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp960528Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_812010_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_812010_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmKitapAd ?? this.props.screenInputs.prmkitapad, null)
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "eserbilgileri_693380_value",
        this.props.screenInputs.prmKitapAd ?? this.props.screenInputs.prmkitapad,
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_555402_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_555402_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp763210Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_555402_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_555402_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_555402_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_555402_value", "value", "", "key", "")
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp763210Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_575529_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifTalepKartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      TipkiEserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_208611_value",
            "value",
            "EserBilgisiListSelectBoxByUyeIds",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      RolName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      ),
      KartId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      KartId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_575529_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TipkiEserBilgileriniGetir = result?.data.tipkiEserBilgileriniGetir;
    stateVars.EserDetay_EserBilgileriById = result?.data.eserDetay_EserBilgileriById;
    formVars.eserbilgileri_812010_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.yayinTuruID : null;
    formVars.eserbilgileri_555402_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiBasimmi : null;
    formVars.eserbilgileri_208611_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiEserID : null;
    formVars.eserbilgileri_693380_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.kitapAdi : null
    );
    formVars.eserbilgileri_836839_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.yazarAdi : null
    );
    formVars.eserbilgileri_13964_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.dergiSureliYayin
        : null;
    formVars.eserbilgileri_325627_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.cocukKitabiAzSayfa
        : null;
    formVars.eserbilgileri_861278_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.ornektir : null;
    formVars.eserbilgileri_295615_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.bandroleTabi : null;
    formVars.eserbilgileri_957873_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_EserBilgileriById?.length > 0 ? stateVars.EserDetay_EserBilgileriById[0]?.isbnNumarasi : null
    );
    formVars.eserbilgileri_91530_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.zamanSecimiID
        : null;
    formVars.eserbilgileri_284780_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
        : null;
    formVars.eserbilgileri_783769_value =
      stateVars.EserDetay_EserBilgileriById?.length > 0
        ? stateVars.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
        : null;
    stateVars.isComp763210Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.tipkiBasimmi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966526Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp328200Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794966Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp919708Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp920186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142626Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp705243Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp542937Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp741219Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isrwDuzeltmeNotuVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp39741Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonTaslakVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp902363Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.EserDetay_EserBilgileriById?.length > 0
            ? stateVars.EserDetay_EserBilgileriById[0]?.isButtonsOnayVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DokumanlarByKartIdISBN = result?.data.dokumanlarByKartIdISBN;

    stateVars.DokumanlarByKartIdEser = result?.data.dokumanlarByKartIdEser;

    stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

    stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_575529_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_575529_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.eserbilgileri_812010_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.yayinTuruID : null
    );

    formVars.eserbilgileri_555402_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.tipkiBasimmi
        : null
    );

    formVars.eserbilgileri_208611_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.tipkiEserID : null
    );

    formVars.eserbilgileri_693380_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.kitapAdi : null
    );

    formVars.eserbilgileri_836839_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.yazarAdi : null
    );

    formVars.eserbilgileri_13964_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.dergiSureliYayin
        : null
    );

    formVars.eserbilgileri_325627_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.cocukKitabiAzSayfa
        : null
    );

    formVars.eserbilgileri_861278_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0 ? this.state.EserDetay_EserBilgileriById[0]?.ornektir : null
    );

    formVars.eserbilgileri_295615_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.bandroleTabi
        : null
    );

    formVars.eserbilgileri_957873_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.isbnNumarasi
        : null
    );

    formVars.eserbilgileri_91530_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.zamanSecimiID
        : null
    );

    stateVars.dataSource_380689 = this.state.DokumanlarByKartIdISBN;

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdEser;

    stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;

    stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;
    formVars.eserbilgileri_284780_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
        : null
    );

    formVars.eserbilgileri_783769_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_EserBilgileriById?.length > 0
        ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_966526_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_966526_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_966526_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("ISBN", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_966526_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanInsert = result?.data.dokumanInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_966526_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_966526_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_966526_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_966526_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanlarByKartIdISBN = result?.data.dokumanlarByKartIdISBN;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_966526_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_966526_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_966526_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_966526_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_966526_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_966526_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.dataSource_380689 = this.state.DokumanlarByKartIdISBN;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_328200_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_380689_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5723fc14_c091_e3c6_f374_14f0797e376d_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_328200_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdISBN = result?.data.dokumanlarByKartIdISBN;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_328200_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_328200_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_380689 = this.state.DokumanlarByKartIdISBN;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_264626_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_380689_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_264626_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanById = result?.data.dokumanById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_264626_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_264626_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.DokumanById?.length > 0 ? this.state.DokumanById[0]?.dosya : null)
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_794966_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_794966_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_794966_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("Eser", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_794966_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanInsert = result?.data.dokumanInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_794966_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_794966_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_794966_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_794966_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanlarByKartIdEser = result?.data.dokumanlarByKartIdEser;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_794966_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_794966_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdEser;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_919708_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_711309_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "93b76939_13dc_65fc_a1d7_12b5b8c548e3_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_919708_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdEser = result?.data.dokumanlarByKartIdEser;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_919708_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_919708_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_711309 = this.state.DokumanlarByKartIdEser;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_872838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_711309_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_872838_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanById = result?.data.dokumanById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_872838_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_872838_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.DokumanById?.length > 0 ? this.state.DokumanById[0]?.dosya : null)
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_435632_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_435632_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_435632_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FileType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.type),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_435632_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FileTypeControl = result?.data.fileTypeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_435632_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_435632_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("ICGORSEL", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_435632_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanInsert = result?.data.dokumanInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_435632_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_435632_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_435632_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_435632_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_435632_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_435632_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_435632_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_435632_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_435632_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_435632_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_199531_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_199531_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_199531_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FileType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.type),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_199531_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FileTypeControl = result?.data.fileTypeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_199531_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_199531_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("ICGORSEL", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_199531_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanInsert = result?.data.dokumanInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_199531_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_199531_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_199531_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_199531_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_199531_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_199531_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_199531_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_199531_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_199531_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_199531_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_920186_onUploadFinish = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_920186_value", "fileId", "", "", "")
        ),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_920186_value", "fileName", "", "", "")
        ),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("ICGORSEL", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_920186_onUploadFinish",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanInsert = result?.data.dokumanInsert;
    stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_920186_onUploadFinish1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_920186_onUploadFinish1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_142626_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_647685_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1479bc69_de2e_4879_f612_499185c26cbd_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_142626_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_142626_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_142626_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DeleteFileFromServer = await ReactSystemFunctions.deleteFileFromServer(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
      ),
      ReactSystemFunctions.value(this, "eserbilgileri_647685_value", "dosya")
    );

    stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_224600_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_647685_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fb991693_2619_ab92_6315_06a2454c301c_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_224600_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdICGORSEL = result?.data.dokumanlarByKartIdICGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_224600_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_224600_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DeleteFileFromServer = await ReactSystemFunctions.deleteFileFromServer(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
      ),
      ReactSystemFunctions.value(this, "eserbilgileri_647685_value", "dosya")
    );

    stateVars.dataSource_647685 = this.state.DokumanlarByKartIdICGORSEL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_484214_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFileFromServer = await ReactSystemFunctions.downloadFileFromServer(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
      ),
      ReactSystemFunctions.value(this, "eserbilgileri_647685_value", "dosya")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_370832_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_370832_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_370832_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FileType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.type),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_370832_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FileTypeControl = result?.data.fileTypeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_370832_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_370832_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("KAPAKGORSEL", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_370832_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanInsert = result?.data.dokumanInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_370832_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_370832_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_370832_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_370832_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_370832_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_370832_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_370832_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_370832_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_370832_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_370832_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_756207_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_756207_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_756207_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FileType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.type),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_756207_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FileTypeControl = result?.data.fileTypeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_756207_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_756207_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("KAPAKGORSEL", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_756207_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanInsert = result?.data.dokumanInsert;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_756207_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_756207_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_756207_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_756207_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_756207_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_756207_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_756207_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_756207_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_756207_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EserBilgileriComponent_756207_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.name), null)) {
      stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EserBilgileriComponent_705243_onUploadFinish = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_705243_value", "fileId", "", "", "")
        ),
        "string"
      ),
      DosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_705243_value", "fileName", "", "", "")
        ),
        "string"
      ),
      DosyaEtiket_0: ReactSystemFunctions.convertToTypeByName("KAPAKGORSEL", "string"),
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EkleyenKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EklenmeTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_705243_onUploadFinish",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DokumanInsert = result?.data.dokumanInsert;
    stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_705243_onUploadFinish1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_705243_onUploadFinish1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_542937_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_435280_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "86c81160_6dd5_d0a3_d962_1d95ed27a8d0_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_542937_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_542937_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_542937_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DeleteFileFromServer = await ReactSystemFunctions.deleteFileFromServer(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
      ),
      ReactSystemFunctions.value(this, "eserbilgileri_435280_value", "dosya")
    );

    stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_158669_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "eserbilgileri_435280_value", "id"),
        "Guid"
      ),
      KartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6e8cca29_fc46_7e18_ac31_cbe963094b4a_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_158669_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DokumanDeleteById = result?.data.dokumanDeleteById;
      stateVars.DokumanlarByKartIdKAPAKGORSEL = result?.data.dokumanlarByKartIdKAPAKGORSEL;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_158669_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_158669_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.DeleteFileFromServer = await ReactSystemFunctions.deleteFileFromServer(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
      ),
      ReactSystemFunctions.value(this, "eserbilgileri_435280_value", "dosya")
    );

    stateVars.dataSource_435280 = this.state.DokumanlarByKartIdKAPAKGORSEL;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_551996_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFileFromServer = await ReactSystemFunctions.downloadFileFromServer(
      ReactSystemFunctions.toString(
        this,
        this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.bigFilePath : null
      ),
      ReactSystemFunctions.value(this, "eserbilgileri_435280_value", "dosya")
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_497206_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_585459_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_585459_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_585459_value", "value", "", "", "")
        ),
        "string"
      ),
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      EserBilgileriID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_497206_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.EserBilgileriDuzeltmeNotuByEserBilgileriID = result?.data.eserBilgileriDuzeltmeNotuByEserBilgileriID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_497206_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_497206_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "eserbilgileri_585459_value", "", null);

    stateVars.dataSource_282097 = this.state.EserBilgileriDuzeltmeNotuByEserBilgileriID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_702509_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BandrolStokHareket",
      "prmEserBilgiID",
      this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid
    );
    KuikaAppManager.addToPageInputVariables("BandrolStokHareket", "prmID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EserBilgileriComponent_702509_onClick1_,
      "EserBilgileri",
      "BandrolStokHareket",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "530439",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EserBilgileriComponent_702509_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      RolName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      ),
      EserBilgileriID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      RolName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_702509_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_BandrolStokByEserBilgileriID = result?.data.eserDetay_BandrolStokByEserBilgileriID;

    stateVars.EserDetay_BandrolStokSUM = result?.data.eserDetay_BandrolStokSUM;
    formVars.eserbilgileri_93590_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0 ? stateVars.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );
    formVars.eserbilgileri_371842_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0 ? stateVars.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_702509_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_702509_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.eserbilgileri_93590_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_BandrolStokSUM?.length > 0 ? this.state.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );

    stateVars.dataSource_733661 = this.state.EserDetay_BandrolStokByEserBilgileriID;
    formVars.eserbilgileri_371842_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_BandrolStokSUM?.length > 0 ? this.state.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_929675_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BandrolStokHareket",
      "prmEserBilgiID",
      this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid
    );
    KuikaAppManager.addToPageInputVariables(
      "BandrolStokHareket",
      "prmID",
      ReactSystemFunctions.value(this, "eserbilgileri_733661_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EserBilgileriComponent_929675_onClick1_,
      "EserBilgileri",
      "BandrolStokHareket",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "572242",
      null,
      "right",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EserBilgileriComponent_929675_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserBilgileriID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      RolName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      ),
      EserBilgileriID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      RolName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_929675_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_BandrolStokByEserBilgileriID = result?.data.eserDetay_BandrolStokByEserBilgileriID;

    stateVars.EserDetay_BandrolStokSUM = result?.data.eserDetay_BandrolStokSUM;
    formVars.eserbilgileri_93590_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0 ? stateVars.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );
    formVars.eserbilgileri_371842_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_BandrolStokSUM?.length > 0 ? stateVars.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_929675_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_929675_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.eserbilgileri_93590_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_BandrolStokSUM?.length > 0 ? this.state.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );

    stateVars.dataSource_733661 = this.state.EserDetay_BandrolStokByEserBilgileriID;
    formVars.eserbilgileri_371842_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_BandrolStokSUM?.length > 0 ? this.state.EserDetay_BandrolStokSUM[0]?.bakiyeAdet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_879238_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_284780_value", "value", "BaskiOncesiDurumAllic", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_284780_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_783769_value", "value", "BaskiOncesiDurumAllkapak", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_783769_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BaskiOncesiDurumIDic_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_284780_value", "value", "BaskiOncesiDurumAllic", "id", "id")
        ),
        "Guid"
      ),
      BaskiOncesiDurumIDicGTarihi_0: ReactSystemFunctions.convertToTypeByName(
        moment().format("YYYY-MM-DDTHH:mm:ss"),
        "Date"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BaskiOncesiDurumIDkapak_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_783769_value",
            "value",
            "BaskiOncesiDurumAllkapak",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      BaskiOncesiDurumIDkapakGTarihi_1: ReactSystemFunctions.convertToTypeByName(
        moment().format("YYYY-MM-DDTHH:mm:ss"),
        "Date"
      ),
      IcDurumKey_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_284780_value",
            "value",
            "BaskiOncesiDurumAllic",
            "id",
            "baskiOncesiDurumKey"
          )
        ),
        "number"
      ),
      KapakDurumKey_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_783769_value",
            "value",
            "BaskiOncesiDurumAllkapak",
            "id",
            "baskiOncesiDurumKey"
          )
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_879238_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.getGonderilecekMailTip = result?.data.getGonderilecekMailTip;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_879238_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_879238_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("gorselonay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSiparisNobyTeklifNo?.length > 0
            ? this.state.getSiparisNobyTeklifNo[0]?.formatted_siparisno
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.getGonderilecekMailTip?.length > 0
            ? this.state.getGonderilecekMailTip[0]?.gonderilecekMailTip
            : null
        ),
        "onay"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_879238_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_879238_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_879238_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_879238_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_879238_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("b353a3a7-760f-45e5-9bef-9eac05000415", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.userName
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SendMailSettings?.length > 0 ? this.state.SendMailSettings[0]?.mailSubject : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SendMailSettings?.length > 0 ? this.state.SendMailSettings[0]?.urLink : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      FirmaAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      ),
      uyekategorisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.UyelikTipiLowercase?.length > 0 ? this.state.UyelikTipiLowercase[0]?.uyelikTipiLower : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.getGonderilecekMailTip?.length > 0
            ? this.state.getGonderilecekMailTip[0]?.gonderilecekMailTip
            : null
        ),
        "onay"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_879238_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_879238_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("gorselrevize", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSiparisNobyTeklifNo?.length > 0
            ? this.state.getSiparisNobyTeklifNo[0]?.formatted_siparisno
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.getGonderilecekMailTip?.length > 0
            ? this.state.getGonderilecekMailTip[0]?.gonderilecekMailTip
            : null
        ),
        "revize"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_879238_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMailSettings = result?.data.sendMailSettings;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_879238_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("ec59ed1a-75f2-497a-bdeb-222a356d71e5", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.userName
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SendMailSettings?.length > 0 ? this.state.SendMailSettings[0]?.mailSubject : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SendMailSettings?.length > 0 ? this.state.SendMailSettings[0]?.urLink : null
        ),
        "string"
      ),
      PublinkDestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.getGonderilecekMailTip?.length > 0
            ? this.state.getGonderilecekMailTip[0]?.gonderilecekMailTip
            : null
        ),
        "revize"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_879238_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_879238_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_879238_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "42131271_eef6_1ce2_987d_37a69426f9b1_notify",
        this.defaultML,
        "Kayıt Güncellendi!"
      ),
      "success",
      "bottom-right",
      1,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_26862_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BagliDurumID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
            : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EserBilgileri/EserBilgileriComponent_26862_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDurumGuncelId = result?.data.getDurumGuncelId;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_26862_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_26862_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_442162_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BagliDurumID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
            : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_442162_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDurumGuncelId = result?.data.getDurumGuncelId;
    stateVars.SaveRecord = result?.data.saveRecord_5;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_442162_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_442162_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_833540_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BagliDurumID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDic
            : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      BagliDurumID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.EserDetay_EserBilgileriById?.length > 0
            ? this.state.EserDetay_EserBilgileriById[0]?.baskiOncesiDurumIDkapak
            : null
        ),
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      actionkey_4: ReactSystemFunctions.convertToTypeByName("gorselduzeltme", "string"),
      uyeliktipi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSiparisNobyTeklifNo?.length > 0
            ? this.state.getSiparisNobyTeklifNo[0]?.formatted_siparisno
            : null
        ),
        "string"
      ),
      UyelikTipi_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      templateId_6: ReactSystemFunctions.convertToTypeByName("621e79af-21d1-4120-97d8-f56f530b623b", "string"),
      receivers_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0]?.userName
            : null
        ),
        "string"
      ),
      sender_6: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_6: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_6: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_6: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_6: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0]?.firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      GonderenFirmaAdi_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      ),
      language_6: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_833540_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDurumGuncelId = result?.data.getDurumGuncelId;
    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.getDurumGuncelId = result?.data.getDurumGuncelId;
    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_833540_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_833540_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b90dab3c_2f97_5d8f_a4c5_c5669d33a5b3_notify",
        this.defaultML,
        "Kayıt Güncellendi!"
      ),
      "success",
      "bottom-right",
      1,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_39741_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_693380_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_693380_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_957873_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_957873_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      YayinTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_812010_value", "value", "EserDetay_YayinTuruAll", "id", "id")
        ),
        "Guid"
      ),
      KitapAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_693380_value", "value", "", "", "")
        ),
        "string"
      ),
      YazarAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_836839_value", "value", "", "", "")
        ),
        "string"
      ),
      ISBNNumarasi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_957873_value", "value", "", "", "")
        ),
        "string"
      ),
      ZamanSecimiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_91530_value", "value", "EserDetay_ZamanSecimiAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EserDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      DergiSureliYayin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_13964_value", "value", "", "key", "")
        ),
        "number"
      ),
      CocukKitabiAzSayfa_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_325627_value", "value", "", "key", "")
        ),
        "number"
      ),
      Ornektir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_861278_value", "value", "", "key", "")
        ),
        "number"
      ),
      BandroleTabi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_295615_value", "value", "", "key", "")
        ),
        "number"
      ),
      TipkiBasimmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_555402_value", "value", "", "key", "")
        ),
        "number"
      ),
      KitapAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_693380_value", "value", "", "", "")
        ),
        "string"
      ),
      TeklifTalepKartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "79561165_d9e0_1467_0429_8af67039473b_confirmation",
        this.defaultML,
        "Eser Bilgileri Taslak Olarak Kayıt Edilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_39741_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_8;
      stateVars.TeklifTalepKartKitapAdUpdate = result?.data.teklifTalepKartKitapAdUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_39741_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_39741_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "041a946d_945d_d68e_e49d_6beb5dda4246_notify",
        this.defaultML,
        "Form Kaydı Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_526033_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KartId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_526033_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BelgeKontrolICGORSELveKAPAKGORSEL = result?.data.belgeKontrolICGORSELveKAPAKGORSEL;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_526033_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_526033_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_693380_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_693380_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_957873_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "eserbilgileri_957873_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      YayinTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_812010_value", "value", "EserDetay_YayinTuruAll", "id", "id")
        ),
        "Guid"
      ),
      KitapAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_693380_value", "value", "", "", "")
        ),
        "string"
      ),
      YazarAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_836839_value", "value", "", "", "")
        ),
        "string"
      ),
      ISBNNumarasi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_957873_value", "value", "", "", "")
        ),
        "string"
      ),
      ZamanSecimiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_91530_value", "value", "EserDetay_ZamanSecimiAll", "id", "id")
        ),
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      EserDurumID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      DergiSureliYayin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_13964_value", "value", "", "key", "")
        ),
        "number"
      ),
      CocukKitabiAzSayfa_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_325627_value", "value", "", "key", "")
        ),
        "number"
      ),
      Ornektir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_861278_value", "value", "", "key", "")
        ),
        "number"
      ),
      BandroleTabi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_295615_value", "value", "", "key", "")
        ),
        "number"
      ),
      TipkiBasimmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_555402_value", "value", "", "key", "")
        ),
        "number"
      ),
      EserOnayBasvuruTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TipkiEserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "eserbilgileri_208611_value",
            "value",
            "EserBilgisiListSelectBoxByUyeIds",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KitapAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserbilgileri_693380_value", "value", "", "", "")
        ),
        "string"
      ),
      TeklifTalepKartId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4c800286_d905_e96a_1f6f_ce882d500e63_confirmation",
        this.defaultML,
        "Eser Bilgileri Onaya Gönderilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_526033_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_9;
      stateVars.TeklifTalepKartKitapAdUpdate = result?.data.teklifTalepKartKitapAdUpdate;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_526033_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_526033_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "71c2b18c_a5e6_4831_6f67_5cd7b916bca6_notify",
        this.defaultML,
        "Onaya Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_526033_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_526033_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("eserbilgigirildi", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.teklifNo ?? this.props.screenInputs.teklifno,
        "string"
      ),
      UyelikTipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      templateId_2: ReactSystemFunctions.convertToTypeByName("bf4dafe3-106e-4662-a7f7-6c1685b44506", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0]?.userName
            : null
        ),
        "string"
      ),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0]?.firstname
            : null
        ),
        "string"
      ),
      UyeSoyad_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifEdenKisiEmail?.length > 0
            ? this.state.EserBilgileri_TeklifEdenKisiEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      FirmaAdi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      ),
      language_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_526033_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_526033_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_526033_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_150995_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      EserOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      EserOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("eserbilgirevizyon", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.teklifNo ?? this.props.screenInputs.teklifno,
        "string"
      ),
      templateId_2: ReactSystemFunctions.convertToTypeByName("6612f154-f753-49fe-b178-b26c9286ec0d", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.userName
            : null
        ),
        "string"
      ),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      language_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "daca434b_b86b_5ed4_e798_0f47cb66b162_confirmation",
        this.defaultML,
        "Açıklama Girildi İse Eser Bilgisi Düzeltme İçin Geri Gönderilecek, İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_150995_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnayEserBilgisi = result?.data.powerUserOnayEserBilgisi;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_150995_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_150995_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "871ac8b1_4064_883f_efec_5229356d9c5c_notify",
        this.defaultML,
        "Eser Bilgileri Düzeltme için Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_453514_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      EserOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      EserOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("eserbilgired", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.teklifNo ?? this.props.screenInputs.teklifno,
        "string"
      ),
      templateId_2: ReactSystemFunctions.convertToTypeByName("f5383a30-4633-4828-8e08-154607acc403", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.userName
            : null
        ),
        "string"
      ),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      language_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c24a8c49_c2e5_bdf2_68bb_c083f17cc42d_confirmation",
        this.defaultML,
        "Eser Bilgileri Reddedilecek; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_453514_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnayEserBilgisi = result?.data.powerUserOnayEserBilgisi;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_453514_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_453514_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "07f21961_fcce_8eb8_3bd2_c349bcd0149e_notify",
        this.defaultML,
        "Form Red Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserBilgileriComponent_816954_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.EserBilgileriComponent_879238_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_816954_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EserBilgileriComponent_816954_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserBilgileri/EserBilgileriComponent_816954_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MontajOnayKontrol = result?.data.montajOnayKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserBilgileriComponent_816954_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_816954_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EserOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      EserOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      EserOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      EserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmTeklifTalepKartID ?? this.props.screenInputs.prmtekliftalepkartid,
        "Guid"
      ),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("eserbilgionay", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.teklifNo ?? this.props.screenInputs.teklifno,
        "string"
      ),
      UyelikTipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      templateId_3: ReactSystemFunctions.convertToTypeByName("9fbffb95-ab8f-409f-bc4d-5a74a827f34a", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.userName
            : null
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.EserBilgileri_TeklifTalepEdenEmail?.length > 0
            ? this.state.EserBilgileri_TeklifTalepEdenEmail[0]?.lastName
            : null
        ),
        "string"
      ),
      UyeAdi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      ),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "75aff55d_63d4_c9e7_52eb_f76aeeb900df_confirmation",
        this.defaultML,
        "Eser Bilgileri Onaylanacak; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EserBilgileri/EserBilgileriComponent_816954_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnayEserBilgisi = result?.data.powerUserOnayEserBilgisi;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EserBilgileriComponent_816954_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserBilgileriComponent_816954_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "93cba8eb_a5df_74df_56cf_4234c01508b7_notify",
        this.defaultML,
        "Eser Bilgileri Onaylandı!"
      ),
      "success",
      "bottom-right",
      2,
      "",
      "",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [530439, 572242] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.EserBilgileriPageInit();
    }
    if (diId == 530439) {
      isErrorOccurred = await this.EserBilgileriComponent_702509_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 572242) {
      isErrorOccurred = await this.EserBilgileriComponent_929675_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
