import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOneriSikayetKategori_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOneriSikayetKategori_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  OneriSikayetKategori: any[];
  OneriSikayetKategori_dummy: any[];
  OneriSikayetTanimSelect: any[];
  OneriSikayetTanimSelect_dummy: any[];
  DestekKategoriSearch: any[];
  DestekKategoriSearch_dummy: any[];
  DestekKategoriCount: any[];
  DestekKategoriCount_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  DestekKategoriDeleteById: number;
  DestekKategoriDeleteById_dummy: number;
}

export class OneriSikayetKategori_List_ScreenBase extends React.PureComponent<
  IOneriSikayetKategori_List_ScreenProps,
  any
> {
  onerisikayetkategori_list_836029_value_kuikaSelectBoxRef: React.RefObject<any>;
  onerisikayetkategori_list_399769_value_kuikaTableRef: React.RefObject<any>;
  onerisikayetkategori_list_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9e9fb0ad-bcf1-4b5a-800c-9a50d5f72c7c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Öneri Şikayet Kategori Listesi" },
        { Id: 324390, PropertyName: "label", Value: "Profil" },
        { Id: 249035, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 836029, PropertyName: "placeholder", Value: "Öneri/Şikayet Seçiniz..." },
        { Id: 416063, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 814413, PropertyName: "label", Value: "Yeni" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 4654506, PropertyName: "title", Value: "Öneri/Şikayet" },
        { Id: 8803046, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 423652, PropertyName: "title", Value: "Sıralama" },
        { Id: 313701, PropertyName: "value", Value: "[datafield:orderby]" },
        { Id: 6009264, PropertyName: "title", Value: "Kategori tanim" },
        { Id: 5962559, PropertyName: "value", Value: "[datafield:kategoritanim]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.onerisikayetkategori_list_836029_value_kuikaSelectBoxRef = React.createRef();
    this.onerisikayetkategori_list_399769_value_kuikaTableRef = React.createRef();
    this.onerisikayetkategori_list_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      OneriSikayetKategori: [],
      OneriSikayetTanimSelect: [],
      DestekKategoriSearch: [],
      DestekKategoriCount: [],
      SetValueOf: "",
      DestekKategoriDeleteById: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("onerisikayetkategori_list", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OneriSikayetKategori_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("onerisikayetkategori_list", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("onerisikayetkategori_list", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OneriSikayetKategori_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      onerisikayetkategori_list_942732_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  OneriSikayetKategori_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OneriSikayetKategori_List/OneriSikayetKategori_ListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.onerisikayetkategori_list_942732_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.onerisikayetkategori_list_181498_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.onerisikayetkategori_list_941904_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.OneriSikayetKategori = result?.data.oneriSikayetKategori;

    formVars.onerisikayetkategori_list_836029_options = stateVars.OneriSikayetKategori;
    stateVars.OneriSikayetTanimSelect = result?.data.oneriSikayetTanimSelect;
    stateVars.DestekKategoriSearch = result?.data.destekKategoriSearch;

    stateVars.DestekKategoriCount = result?.data.destekKategoriCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OneriSikayetKategori_ListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OneriSikayetKategori_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.onerisikayetkategori_list_942732_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.onerisikayetkategori_list_181498_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.onerisikayetkategori_list_941904_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_836029 = this.state.OneriSikayetKategori;
    stateVars.dataSource_836029 = this.state.OneriSikayetKategori;

    stateVars.dataSource_399769 = this.state.DestekKategoriSearch;
    formVars.onerisikayetkategori_list_172599_total = ReactSystemFunctions.value(
      this,
      this.state.DestekKategoriCount?.length > 0 ? this.state.DestekKategoriCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_981633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OneriSikayetKategori_List",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "156651",
      null,
      "left",
      null,
      "480px",
      "",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_324390_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OneriSikayetKategori_List",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "674239",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_249035_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_836029_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "onerisikayetkategori_list_172599_value",
      1,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OneriSikayetKategori_ListComponent_836029_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OneriSikayetKategori_ListComponent_836029_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OneriSikayetKategori_List/OneriSikayetKategori_ListComponent_836029_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DestekKategoriCount = result?.data.destekKategoriCount;
    stateVars.DestekKategoriSearch = result?.data.destekKategoriSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OneriSikayetKategori_ListComponent_836029_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OneriSikayetKategori_ListComponent_836029_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.DestekKategoriSearch;
    formVars.onerisikayetkategori_list_172599_total = ReactSystemFunctions.value(
      this,
      this.state.DestekKategoriCount?.length > 0 ? this.state.DestekKategoriCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "onerisikayetkategori_list_172599_value",
      1,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OneriSikayetKategori_ListComponent_416063_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OneriSikayetKategori_ListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OneriSikayetKategori_List/OneriSikayetKategori_ListComponent_416063_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DestekKategoriCount = result?.data.destekKategoriCount;
    stateVars.DestekKategoriSearch = result?.data.destekKategoriSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OneriSikayetKategori_ListComponent_416063_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OneriSikayetKategori_ListComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.DestekKategoriSearch;
    formVars.onerisikayetkategori_list_172599_total = ReactSystemFunctions.value(
      this,
      this.state.DestekKategoriCount?.length > 0 ? this.state.DestekKategoriCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OneriSikayetKategori_Form", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OneriSikayetKategori_List",
      "OneriSikayetKategori_Form",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "450px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OneriSikayetKategori_Form",
      "Id",
      ReactSystemFunctions.value(this, "onerisikayetkategori_list_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OneriSikayetKategori_List",
      "OneriSikayetKategori_Form",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "onerisikayetkategori_list_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "OneriSikayetKategori_List/OneriSikayetKategori_ListComponent_505853_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DestekKategoriDeleteById = result?.data.destekKategoriDeleteById;
      stateVars.DestekKategoriCount = result?.data.destekKategoriCount;
      stateVars.DestekKategoriSearch = result?.data.destekKategoriSearch;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.OneriSikayetKategori_ListComponent_505853_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OneriSikayetKategori_ListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.DestekKategoriSearch;
    formVars.onerisikayetkategori_list_172599_total = ReactSystemFunctions.value(
      this,
      this.state.DestekKategoriCount?.length > 0 ? this.state.DestekKategoriCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OneriSikayetKategori_ListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      OneriSikayetKategoriID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "onerisikayetkategori_list_836029_value",
            "value",
            "OneriSikayetKategori",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "onerisikayetkategori_list_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OneriSikayetKategori_List/OneriSikayetKategori_ListComponent_172599_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DestekKategoriCount = result?.data.destekKategoriCount;
    stateVars.DestekKategoriSearch = result?.data.destekKategoriSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OneriSikayetKategori_ListComponent_172599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OneriSikayetKategori_ListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.DestekKategoriSearch;
    formVars.onerisikayetkategori_list_172599_total = ReactSystemFunctions.value(
      this,
      this.state.DestekKategoriCount?.length > 0 ? this.state.DestekKategoriCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [674239, 486227, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OneriSikayetKategori_ListPageInit();
    }
  }
}
