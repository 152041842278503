import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import { KCol, KRow, WebView } from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { PDFViewerFormsImza_ScreenBase } from "./pdfviewerformsimza-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class PDFViewerFormsImza_Screen extends PDFViewerFormsImza_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form form={this.props.form} name="basic" initialValues={{}}>
            <div
              id="pdfviewerformsimza_body"
              style={
                {
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="234485"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KCol
                  id="476525"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <WebView
                    id="285810"
                    kuikaRef={this.pdfviewerformsimza_285810_value_kuikaWebViewRef}
                    url={this.state.ImzaSirkuleriById?.at?.(0)?.imzaSirkuleri ?? undefined}
                    style={
                      {
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderBottomLeftRadius: 4,
                        borderTopWidth: 1,
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                        borderLeftWidth: 1,
                        borderColor: "rgba(218, 218, 218, 1)",
                        borderStyle: "solid",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        height: "90vh",
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "block",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  ></WebView>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(PDFViewerFormsImza_Screen))))
  )
);
export { tmp as PDFViewerFormsImza_Screen };
//export default tmp;
//export { tmp as PDFViewerFormsImza_Screen };
