import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPowerUserEserList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPowerUserEserList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  ReadFromLocalStorage: string;
  ReadFromLocalStorage_dummy: string;
  LogSave: number;
  LogSave_dummy: number;
  MyContext: any[];
  MyContext_dummy: any[];
  spPowerUserEserOnayList: any[];
  spPowerUserEserOnayList_dummy: any[];
  spPowerUserEserOnayListCount: any[];
  spPowerUserEserOnayListCount_dummy: any[];
  isComp926480Visible: "visible" | "hidden";
  isComp631501Visible: "visible" | "hidden";
  isComp110236Visible: "visible" | "hidden";
  isComp226884Visible: "visible" | "hidden";
  isComp271688Visible: "visible" | "hidden";
  isComp995246Visible: "visible" | "hidden";
  isComp169095Visible: "visible" | "hidden";
}

export class PowerUserEserList_ScreenBase extends React.PureComponent<IPowerUserEserList_ScreenProps, any> {
  powerusereserlist_207258_value_kuikaSelectBoxRef: React.RefObject<any>;
  powerusereserlist_541132_value_kuikaSelectBoxRef: React.RefObject<any>;
  powerusereserlist_672113_value_kuikaTableRef: React.RefObject<any>;
  powerusereserlist_499837_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "09157c29-572c-44b1-b5ed-ba846ba0432f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 560196, PropertyName: "value", Value: "Power User - Eser Bilgileri İşlemleri" },
        { Id: 956331, PropertyName: "label", Value: "Profil" },
        { Id: 353870, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 207258, PropertyName: "placeholder", Value: "Onay Bekelyenler" },
        { Id: 96353, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 541132, PropertyName: "placeholder", Value: "Sırala" },
        { Id: 742584, PropertyName: "value", Value: "Onay Bekleyenler" },
        { Id: 474332, PropertyName: "label", Value: "Yeni Üyelik Ekle" },
        { Id: 672113, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 904622, PropertyName: "title", Value: "Muhatap" },
        { Id: 17881, PropertyName: "value", Value: "[datafield:muhatap]" },
        { Id: 848691, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 662551, PropertyName: "value", Value: "[datafield:kitapadi]" },
        { Id: 125269, PropertyName: "title", Value: "Yazar Adı" },
        { Id: 732569, PropertyName: "value", Value: "[datafield:yazaradi]" },
        { Id: 875497, PropertyName: "title", Value: "ISBN Numarasi" },
        { Id: 229346, PropertyName: "value", Value: "[datafield:isbnnumarasi]" },
        { Id: 261385, PropertyName: "title", Value: "Bandrole Tabi" },
        { Id: 60090, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 103994, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 347720, PropertyName: "title", Value: "Durumu" },
        { Id: 464327, PropertyName: "value", Value: "[datafield:eserdurum]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.powerusereserlist_207258_value_kuikaSelectBoxRef = React.createRef();
    this.powerusereserlist_541132_value_kuikaSelectBoxRef = React.createRef();
    this.powerusereserlist_672113_value_kuikaTableRef = React.createRef();
    this.powerusereserlist_499837_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      ReadFromLocalStorage: "",
      LogSave: 0,
      MyContext: [],
      spPowerUserEserOnayList: [],
      spPowerUserEserOnayListCount: [],
      isComp926480Visible: "hidden",
      isComp631501Visible: "hidden",
      isComp110236Visible: "hidden",
      isComp226884Visible: "hidden",
      isComp271688Visible: "hidden",
      isComp995246Visible: "hidden",
      isComp169095Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("powerusereserlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PowerUserEserListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("powerusereserlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("powerusereserlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PowerUserEserListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      powerusereserlist_962544_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  PowerUserEserListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("LogGuid");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserEserListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PowerUserEserListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      KeyIdValue_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      OperationName_0: ReactSystemFunctions.convertToTypeByName("Giriş Başarılı!", "string"),
      OperationTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TableName_0: ReactSystemFunctions.convertToTypeByName("Sisteme Giriş", "string"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toGuid(this, this.state.ReadFromLocalStorage),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "powerusereserlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "powerusereserlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PowerUserEserList/PowerUserEserListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogSave = result?.data.logSave;
    stateVars.MyContext = result?.data.myContext;
    formVars.powerusereserlist_962544_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.powerusereserlist_98455_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.powerusereserlist_583513_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.spPowerUserEserOnayList = result?.data.spPowerUserEserOnayList;

    stateVars.spPowerUserEserOnayListCount = result?.data.spPowerUserEserOnayListCount;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserEserListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserEserListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.powerusereserlist_962544_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.powerusereserlist_98455_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.powerusereserlist_583513_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_672113 = this.state.spPowerUserEserOnayList;
    stateVars.isComp631501Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "eserDurumKey"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110236Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "eserDurumKey"),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp226884Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "eserDurumKey"),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271688Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "eserDurumKey"),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995246Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "bandroleTabi"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169095Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "bandroleTabi"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.powerusereserlist_499837_total = ReactSystemFunctions.value(
      this,
      this.state.spPowerUserEserOnayListCount?.length > 0
        ? this.state.spPowerUserEserOnayListCount[0]?.recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PowerUserEserListComponent_312666_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PowerUserEserList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "158742",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserEserListComponent_956331_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PowerUserEserList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "797392",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserEserListComponent_353870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserEserListComponent_380125_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserBilgileri",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "powerusereserlist_672113_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "NewEditDisplay", "E");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "prmKitapAd", null);
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "teklifNo", "-");
    KuikaAppManager.addToPageInputVariables("EserBilgileri", "uyeAd", "-");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PowerUserEserList",
      "EserBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "102407",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PowerUserEserListComponent_499837_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "powerusereserlist_499837_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "powerusereserlist_499837_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PowerUserEserList/PowerUserEserListComponent_499837_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spPowerUserEserOnayList = result?.data.spPowerUserEserOnayList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PowerUserEserListComponent_499837_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PowerUserEserListComponent_499837_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_672113 = this.state.spPowerUserEserOnayList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [158742, 797392, 102407] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.PowerUserEserListPageInit();
    }
  }
}
