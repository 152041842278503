import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashBoardPOP_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashBoardPOP_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  ReadFromLocalStorage: string;
  ReadFromLocalStorage_dummy: string;
  LogSave: number;
  LogSave_dummy: number;
  MyContext: any[];
  MyContext_dummy: any[];
  DB_GetDateRangesNames: any[];
  DB_GetDateRangesNames_dummy: any[];
  KullaniciLocalStorageByUsername: any[];
  KullaniciLocalStorageByUsername_dummy: any[];
  DB_GetDateRanges: any[];
  DB_GetDateRanges_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  DBAdmin_TeklifTaslakCount: any[];
  DBAdmin_TeklifTaslakCount_dummy: any[];
  DBAdmin_TeklifOnayCount: any[];
  DBAdmin_TeklifOnayCount_dummy: any[];
  DBAdmin_TeklifOnaylananCount: any[];
  DBAdmin_TeklifOnaylananCount_dummy: any[];
  DBAdmin_TeklifOnaylanmayanCount: any[];
  DBAdmin_TeklifOnaylanmayanCount_dummy: any[];
  DBAdmin_SiparisTaslakCount: any[];
  DBAdmin_SiparisTaslakCount_dummy: any[];
  DBAdmin_SiparisOnaylananCount: any[];
  DBAdmin_SiparisOnaylananCount_dummy: any[];
  DBAdmin_SiparisOnayCount: any[];
  DBAdmin_SiparisOnayCount_dummy: any[];
  DBAdmin_SiparisOnaylanmayanCount: any[];
  DBAdmin_SiparisOnaylanmayanCount_dummy: any[];
  DBAdmin_UretimBitenCount: any[];
  DBAdmin_UretimBitenCount_dummy: any[];
  DBAdmin_UretimBaslayanCount: any[];
  DBAdmin_UretimBaslayanCount_dummy: any[];
  OneriDestekDashboardInfo: any[];
  OneriDestekDashboardInfo_dummy: any[];
  SetLanguage: string;
  SetLanguage_dummy: string;
  KullaniciLocalStorageSave: number;
  KullaniciLocalStorageSave_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
}

export class DashBoardPOP_ScreenBase extends React.PureComponent<IDashBoardPOP_ScreenProps, any> {
  dashboardpop_808069_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardpop_831517_value_kuikaDateRef: React.RefObject<any>;
  dashboardpop_916146_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "de134b63-bbc8-4a43-85ef-a0d450914d76",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 119224, PropertyName: "value", Value: "PUBLINK Dashboard" },
        { Id: 881224, PropertyName: "label", Value: "Profil" },
        { Id: 310750, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 862882, PropertyName: "value", Value: "Dönem" },
        { Id: 808069, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 172784, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 831517, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 318308, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 916146, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 599809, PropertyName: "value", Value: "Teklif" },
        { Id: 593750, PropertyName: "value", Value: "Taslak Teklif Sayısı" },
        { Id: 311813, PropertyName: "value", Value: "Onay Bekleyen Teklif Sayısı" },
        { Id: 505180, PropertyName: "value", Value: "Onaylanan Teklif Sayısı" },
        { Id: 81090, PropertyName: "value", Value: "Reddedilen Teklif Sayısı" },
        { Id: 822631, PropertyName: "value", Value: "Sipariş" },
        { Id: 659582, PropertyName: "value", Value: "Taslak Sipariş Sayısı" },
        { Id: 833866, PropertyName: "value", Value: "Onay Bekleyen Sipariş Sayısı" },
        { Id: 196167, PropertyName: "value", Value: "Onaylanan Sipariş Sayısı" },
        { Id: 800142, PropertyName: "value", Value: "Reddedilen Sipariş Sayısı" },
        { Id: 79779, PropertyName: "value", Value: "Üretim" },
        { Id: 159878, PropertyName: "value", Value: "Üretim İşlemleri Başlatılan" },
        { Id: 729616, PropertyName: "value", Value: "Üretim İşlemleri Biten" },
        { Id: 360746, PropertyName: "value", Value: "Yardım ve Destek" },
        { Id: 459948, PropertyName: "value", Value: "Toplam Kayıt Sayısı" },
        { Id: 153136, PropertyName: "value", Value: "Cevaplanmamış Kayıt Sayısı" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardpop_808069_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardpop_831517_value_kuikaDateRef = React.createRef();
    this.dashboardpop_916146_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      ReadFromLocalStorage: "",
      LogSave: 0,
      MyContext: [],
      DB_GetDateRangesNames: [],
      KullaniciLocalStorageByUsername: [],
      DB_GetDateRanges: [],
      SetValueOf: "",
      DBAdmin_TeklifTaslakCount: [],
      DBAdmin_TeklifOnayCount: [],
      DBAdmin_TeklifOnaylananCount: [],
      DBAdmin_TeklifOnaylanmayanCount: [],
      DBAdmin_SiparisTaslakCount: [],
      DBAdmin_SiparisOnaylananCount: [],
      DBAdmin_SiparisOnayCount: [],
      DBAdmin_SiparisOnaylanmayanCount: [],
      DBAdmin_UretimBitenCount: [],
      DBAdmin_UretimBaslayanCount: [],
      OneriDestekDashboardInfo: [],
      SetLanguage: "",
      KullaniciLocalStorageSave: 0,
      TriggerEvent: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardpop", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashBoardPOPPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardpop", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardpop", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashBoardPOPPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardpop_81624_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      dashboardpop_808069_value: this.state.KullaniciLocalStorageByUsername?.at?.(0)?.sonTarihAraligi ?? undefined,
      dashboardpop_831517_value: this.state.DB_GetDateRanges?.at?.(0)?.baslangic ?? undefined,
      dashboardpop_916146_value: this.state.DB_GetDateRanges?.at?.(0)?.bitis ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashBoardPOPPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("LogGuid");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardPOPPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      KeyIdValue_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      OperationName_0: ReactSystemFunctions.convertToTypeByName("Giriş Başarılı!", "string"),
      OperationTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TableName_0: ReactSystemFunctions.convertToTypeByName("Sisteme Giriş", "string"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toGuid(this, this.state.ReadFromLocalStorage),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardPOP/DashBoardPOPPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogSave = result?.data.logSave;
    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardpop_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.dashboardpop_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.dashboardpop_734865_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.DB_GetDateRangesNames = result?.data.dB_GetDateRangesNames;

    formVars.dashboardpop_808069_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null;
    formVars.dashboardpop_808069_options = stateVars.DB_GetDateRangesNames;
    stateVars.KullaniciLocalStorageByUsername = result?.data.kullaniciLocalStorageByUsername;
    formVars.dashboardpop_808069_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null;
    formVars.dashboardpop_808069_options = stateVars.DB_GetDateRangesNames;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardpop_831517_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.baslangic : null
    );
    formVars.dashboardpop_916146_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.bitis : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardPOPPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardpop_831517_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardpop_916146_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardPOPPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardPOP/DashBoardPOPPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBAdmin_TeklifTaslakCount = result?.data.dbAdmin_TeklifTaslakCount;
    formVars.dashboardpop_723374_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifTaslakCount?.length > 0 ? stateVars.DBAdmin_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnayCount = result?.data.dbAdmin_TeklifOnayCount;
    formVars.dashboardpop_336032_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnayCount?.length > 0 ? stateVars.DBAdmin_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnaylananCount = result?.data.dbAdmin_TeklifOnaylananCount;
    formVars.dashboardpop_918772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnaylananCount?.length > 0 ? stateVars.DBAdmin_TeklifOnaylananCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnaylanmayanCount = result?.data.dbAdmin_TeklifOnaylanmayanCount;
    formVars.dashboardpop_44033_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBAdmin_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_SiparisTaslakCount = result?.data.dbAdmin_SiparisTaslakCount;
    formVars.dashboardpop_392523_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisTaslakCount?.length > 0 ? stateVars.DBAdmin_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_SiparisOnaylananCount = result?.data.dbAdmin_SiparisOnaylananCount;
    formVars.dashboardpop_581509_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnaylananCount?.length > 0
        ? stateVars.DBAdmin_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_SiparisOnayCount = result?.data.dbAdmin_SiparisOnayCount;
    formVars.dashboardpop_257294_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnayCount?.length > 0 ? stateVars.DBAdmin_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_SiparisOnaylanmayanCount = result?.data.dbAdmin_SiparisOnaylanmayanCount;
    formVars.dashboardpop_90897_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBAdmin_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_UretimBitenCount = result?.data.dbAdmin_UretimBitenCount;
    formVars.dashboardpop_115873_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_UretimBitenCount?.length > 0 ? stateVars.DBAdmin_UretimBitenCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_UretimBaslayanCount = result?.data.dbAdmin_UretimBaslayanCount;
    formVars.dashboardpop_124410_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_UretimBaslayanCount?.length > 0 ? stateVars.DBAdmin_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.OneriDestekDashboardInfo = result?.data.oneriDestekDashboardInfo;
    formVars.dashboardpop_846112_value = ReactSystemFunctions.toString(
      this,
      stateVars.OneriDestekDashboardInfo?.length > 0 ? stateVars.OneriDestekDashboardInfo[0]?.toplamKayit : null
    );
    formVars.dashboardpop_411202_value = ReactSystemFunctions.toString(
      this,
      stateVars.OneriDestekDashboardInfo?.length > 0 ? stateVars.OneriDestekDashboardInfo[0]?.cevaplanmayanKayit : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardPOPPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    formVars.dashboardpop_81624_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.dashboardpop_980974_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.dashboardpop_734865_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    formVars.dashboardpop_808069_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciLocalStorageByUsername?.length > 0
        ? this.state.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null
    );

    stateVars.dataSource_808069 = this.state.DB_GetDateRangesNames;
    stateVars.dataSource_808069 = this.state.DB_GetDateRangesNames;
    formVars.dashboardpop_831517_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
    );

    formVars.dashboardpop_916146_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
    );

    formVars.dashboardpop_723374_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifTaslakCount?.length > 0 ? this.state.DBAdmin_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardpop_336032_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnayCount?.length > 0 ? this.state.DBAdmin_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardpop_918772_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnaylananCount?.length > 0
        ? this.state.DBAdmin_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_44033_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBAdmin_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_392523_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisTaslakCount?.length > 0 ? this.state.DBAdmin_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardpop_257294_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnayCount?.length > 0 ? this.state.DBAdmin_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardpop_581509_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnaylananCount?.length > 0
        ? this.state.DBAdmin_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_90897_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBAdmin_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_124410_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_UretimBaslayanCount?.length > 0 ? this.state.DBAdmin_UretimBaslayanCount[0]?.recordCount : null
    );

    formVars.dashboardpop_115873_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_UretimBitenCount?.length > 0 ? this.state.DBAdmin_UretimBitenCount[0]?.recordCount : null
    );

    formVars.dashboardpop_846112_value = ReactSystemFunctions.toString(
      this,
      this.state.OneriDestekDashboardInfo?.length > 0 ? this.state.OneriDestekDashboardInfo[0]?.toplamKayit : null
    );

    formVars.dashboardpop_411202_value = ReactSystemFunctions.toString(
      this,
      this.state.OneriDestekDashboardInfo?.length > 0
        ? this.state.OneriDestekDashboardInfo[0]?.cevaplanmayanKayit
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashBoardPOPComponent_284229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardPOP",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "997406",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_881224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardPOP",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "304294",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_310750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_808069_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SonTarihAraligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardpop_808069_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      ),
      userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      RangeName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardpop_808069_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardPOP/DashBoardPOPComponent_808069_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciLocalStorageSave = result?.data.kullaniciLocalStorageSave;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardpop_831517_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.baslangic : null
    );
    formVars.dashboardpop_916146_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.bitis : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPComponent_808069_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardPOPComponent_808069_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardpop_831517_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardpop_916146_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
      ),
      null
    );
    await this.DashBoardPOPPageInit();

    formVars.dashboardpop_831517_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
    );

    formVars.dashboardpop_916146_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_831517_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardPOP/DashBoardPOPComponent_831517_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBAdmin_TeklifTaslakCount = result?.data.dbAdmin_TeklifTaslakCount;
    formVars.dashboardpop_723374_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifTaslakCount?.length > 0 ? stateVars.DBAdmin_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnayCount = result?.data.dbAdmin_TeklifOnayCount;
    formVars.dashboardpop_336032_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnayCount?.length > 0 ? stateVars.DBAdmin_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnaylananCount = result?.data.dbAdmin_TeklifOnaylananCount;
    formVars.dashboardpop_918772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnaylananCount?.length > 0 ? stateVars.DBAdmin_TeklifOnaylananCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnaylanmayanCount = result?.data.dbAdmin_TeklifOnaylanmayanCount;
    formVars.dashboardpop_44033_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBAdmin_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_SiparisTaslakCount = result?.data.dbAdmin_SiparisTaslakCount;
    formVars.dashboardpop_392523_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisTaslakCount?.length > 0 ? stateVars.DBAdmin_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_SiparisOnaylananCount = result?.data.dbAdmin_SiparisOnaylananCount;
    formVars.dashboardpop_581509_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnaylananCount?.length > 0
        ? stateVars.DBAdmin_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_SiparisOnayCount = result?.data.dbAdmin_SiparisOnayCount;
    formVars.dashboardpop_257294_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnayCount?.length > 0 ? stateVars.DBAdmin_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_SiparisOnaylanmayanCount = result?.data.dbAdmin_SiparisOnaylanmayanCount;
    formVars.dashboardpop_90897_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBAdmin_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_UretimBaslayanCount = result?.data.dbAdmin_UretimBaslayanCount;
    formVars.dashboardpop_124410_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_UretimBaslayanCount?.length > 0 ? stateVars.DBAdmin_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_UretimBitenCount = result?.data.dbAdmin_UretimBitenCount;
    formVars.dashboardpop_115873_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_UretimBitenCount?.length > 0 ? stateVars.DBAdmin_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPComponent_831517_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardPOPComponent_831517_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardpop_723374_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifTaslakCount?.length > 0 ? this.state.DBAdmin_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardpop_336032_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnayCount?.length > 0 ? this.state.DBAdmin_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardpop_918772_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnaylananCount?.length > 0
        ? this.state.DBAdmin_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_44033_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBAdmin_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_392523_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisTaslakCount?.length > 0 ? this.state.DBAdmin_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardpop_257294_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnayCount?.length > 0 ? this.state.DBAdmin_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardpop_581509_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnaylananCount?.length > 0
        ? this.state.DBAdmin_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_90897_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBAdmin_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_124410_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_UretimBaslayanCount?.length > 0 ? this.state.DBAdmin_UretimBaslayanCount[0]?.recordCount : null
    );

    formVars.dashboardpop_115873_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_UretimBitenCount?.length > 0 ? this.state.DBAdmin_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_916146_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_831517_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardpop_916146_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardPOP/DashBoardPOPComponent_916146_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBAdmin_TeklifTaslakCount = result?.data.dbAdmin_TeklifTaslakCount;
    formVars.dashboardpop_723374_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifTaslakCount?.length > 0 ? stateVars.DBAdmin_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnayCount = result?.data.dbAdmin_TeklifOnayCount;
    formVars.dashboardpop_336032_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnayCount?.length > 0 ? stateVars.DBAdmin_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnaylananCount = result?.data.dbAdmin_TeklifOnaylananCount;
    formVars.dashboardpop_918772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnaylananCount?.length > 0 ? stateVars.DBAdmin_TeklifOnaylananCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_TeklifOnaylanmayanCount = result?.data.dbAdmin_TeklifOnaylanmayanCount;
    formVars.dashboardpop_44033_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBAdmin_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_SiparisTaslakCount = result?.data.dbAdmin_SiparisTaslakCount;
    formVars.dashboardpop_392523_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisTaslakCount?.length > 0 ? stateVars.DBAdmin_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_SiparisOnaylananCount = result?.data.dbAdmin_SiparisOnaylananCount;
    formVars.dashboardpop_581509_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnaylananCount?.length > 0
        ? stateVars.DBAdmin_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_SiparisOnayCount = result?.data.dbAdmin_SiparisOnayCount;
    formVars.dashboardpop_257294_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnayCount?.length > 0 ? stateVars.DBAdmin_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_SiparisOnaylanmayanCount = result?.data.dbAdmin_SiparisOnaylanmayanCount;
    formVars.dashboardpop_90897_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBAdmin_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBAdmin_UretimBaslayanCount = result?.data.dbAdmin_UretimBaslayanCount;
    formVars.dashboardpop_124410_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_UretimBaslayanCount?.length > 0 ? stateVars.DBAdmin_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBAdmin_UretimBitenCount = result?.data.dbAdmin_UretimBitenCount;
    formVars.dashboardpop_115873_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBAdmin_UretimBitenCount?.length > 0 ? stateVars.DBAdmin_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardPOPComponent_916146_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardPOPComponent_916146_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardpop_723374_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifTaslakCount?.length > 0 ? this.state.DBAdmin_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardpop_336032_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnayCount?.length > 0 ? this.state.DBAdmin_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardpop_918772_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnaylananCount?.length > 0
        ? this.state.DBAdmin_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_44033_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBAdmin_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_392523_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisTaslakCount?.length > 0 ? this.state.DBAdmin_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardpop_257294_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnayCount?.length > 0 ? this.state.DBAdmin_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardpop_581509_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnaylananCount?.length > 0
        ? this.state.DBAdmin_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_90897_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBAdmin_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardpop_124410_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_UretimBaslayanCount?.length > 0 ? this.state.DBAdmin_UretimBaslayanCount[0]?.recordCount : null
    );

    formVars.dashboardpop_115873_value = ReactSystemFunctions.toString(
      this,
      this.state.DBAdmin_UretimBitenCount?.length > 0 ? this.state.DBAdmin_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_649402_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardPOP",
      "SikayetList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardPOPComponent_642239_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardPOP",
      "SikayetList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [997406, 304294, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashBoardPOPPageInit();
    }
  }
}
