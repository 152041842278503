import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHizliTeklifAl_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHizliTeklifAl_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  EserDetay_CiltBicimiAll: any[];
  EserDetay_CiltBicimiAll_dummy: any[];
  EserDetay_SelefonAll: any[];
  EserDetay_SelefonAll_dummy: any[];
  EserDetay_IcBaskiRenkAll: any[];
  EserDetay_IcBaskiRenkAll_dummy: any[];
  EserDetay_KapakCinsiAll: any[];
  EserDetay_KapakCinsiAll_dummy: any[];
  EserDetay_IcBaskiKagitCinsiAll: any[];
  EserDetay_IcBaskiKagitCinsiAll_dummy: any[];
  EserDetay_KitapBoyutAll: any[];
  EserDetay_KitapBoyutAll_dummy: any[];
  EserDetay_IcBaskiKagitGramajByKagitCinsi: any[];
  EserDetay_IcBaskiKagitGramajByKagitCinsi_dummy: any[];
  EserDetay_KapakGramajByKapakCinsi: any[];
  EserDetay_KapakGramajByKapakCinsi_dummy: any[];
  getGuid: any[];
  getGuid_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
}

export class HizliTeklifAl_ScreenBase extends React.PureComponent<IHizliTeklifAl_ScreenProps, any> {
  hizliteklifal_923446_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_570219_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_195740_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_253304_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_738987_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_81822_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_673902_value_kuikaSelectBoxRef: React.RefObject<any>;
  hizliteklifal_454757_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "fac3bc85-9037-42b1-9a81-be356ce16608",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 319201, PropertyName: "value", Value: "Hızlı Teklif Karşılaştırma" },
        { Id: 641427, PropertyName: "value", Value: "Sayfa Sayısı" },
        { Id: 181252, PropertyName: "placeholder", Value: "15" },
        { Id: 717379, PropertyName: "value", Value: "Kitap Boyutu" },
        { Id: 500920, PropertyName: "value", Value: "Kullanılacak İç Kağıt Cinsi" },
        { Id: 631285, PropertyName: "value", Value: "Kullanılacak İç Kağıt Gramajı" },
        { Id: 120178, PropertyName: "value", Value: "Kullanılacak Kapak Cinsi" },
        { Id: 904765, PropertyName: "value", Value: "Kullanılacak Kapak Gramajı" },
        { Id: 871761, PropertyName: "value", Value: "İç Baskı Renk (Siyah Beyaz/Renkli)" },
        { Id: 919228, PropertyName: "value", Value: "Kullanılacak Kapak Selefonu" },
        { Id: 428624, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 138065, PropertyName: "value", Value: "Baskı Adet" },
        { Id: 458878, PropertyName: "placeholder", Value: "15" },
        { Id: 508444, PropertyName: "label", Value: "Hızlı Teklif Al" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.hizliteklifal_923446_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_570219_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_195740_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_253304_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_738987_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_81822_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_673902_value_kuikaSelectBoxRef = React.createRef();
    this.hizliteklifal_454757_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      EserDetay_CiltBicimiAll: [],
      EserDetay_SelefonAll: [],
      EserDetay_IcBaskiRenkAll: [],
      EserDetay_KapakCinsiAll: [],
      EserDetay_IcBaskiKagitCinsiAll: [],
      EserDetay_KitapBoyutAll: [],
      EserDetay_IcBaskiKagitGramajByKagitCinsi: [],
      EserDetay_KapakGramajByKapakCinsi: [],
      getGuid: [],
      SaveRecord: false,
      NAVIGATE: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hizliteklifal", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HizliTeklifAlPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hizliteklifal", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hizliteklifal", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HizliTeklifAlPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      hizliteklifal_181252_value: undefined,
      hizliteklifal_458878_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HizliTeklifAlPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HizliTeklifAl/HizliTeklifAlPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.EserDetay_CiltBicimiAll = result?.data.eserDetay_CiltBicimiAll;

    formVars.hizliteklifal_454757_options = stateVars.EserDetay_CiltBicimiAll;
    stateVars.EserDetay_SelefonAll = result?.data.eserDetay_SelefonAll;

    formVars.hizliteklifal_673902_options = stateVars.EserDetay_SelefonAll;
    stateVars.EserDetay_IcBaskiRenkAll = result?.data.eserDetay_IcBaskiRenkAll;

    formVars.hizliteklifal_81822_options = stateVars.EserDetay_IcBaskiRenkAll;
    stateVars.EserDetay_KapakCinsiAll = result?.data.eserDetay_KapakCinsiAll;

    formVars.hizliteklifal_253304_options = stateVars.EserDetay_KapakCinsiAll;
    stateVars.EserDetay_IcBaskiKagitCinsiAll = result?.data.eserDetay_IcBaskiKagitCinsiAll;

    formVars.hizliteklifal_570219_options = stateVars.EserDetay_IcBaskiKagitCinsiAll;
    stateVars.EserDetay_KitapBoyutAll = result?.data.eserDetay_KitapBoyutAll;

    formVars.hizliteklifal_923446_options = stateVars.EserDetay_KitapBoyutAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifAlPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifAlPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_923446 = this.state.EserDetay_KitapBoyutAll;
    stateVars.dataSource_923446 = this.state.EserDetay_KitapBoyutAll;

    stateVars.dataSource_570219 = this.state.EserDetay_IcBaskiKagitCinsiAll;
    stateVars.dataSource_570219 = this.state.EserDetay_IcBaskiKagitCinsiAll;

    stateVars.dataSource_253304 = this.state.EserDetay_KapakCinsiAll;
    stateVars.dataSource_253304 = this.state.EserDetay_KapakCinsiAll;

    stateVars.dataSource_81822 = this.state.EserDetay_IcBaskiRenkAll;
    stateVars.dataSource_81822 = this.state.EserDetay_IcBaskiRenkAll;

    stateVars.dataSource_673902 = this.state.EserDetay_SelefonAll;
    stateVars.dataSource_673902 = this.state.EserDetay_SelefonAll;

    stateVars.dataSource_454757 = this.state.EserDetay_CiltBicimiAll;
    stateVars.dataSource_454757 = this.state.EserDetay_CiltBicimiAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HizliTeklifAlComponent_694983_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifAlComponent_570219_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_570219_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IcBaskiKagitCinsiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifAl/HizliTeklifAlComponent_570219_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi = result?.data.eserDetay_IcBaskiKagitGramajByKagitCinsi;

    formVars.hizliteklifal_195740_options = stateVars.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifAlComponent_570219_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifAlComponent_570219_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;
    stateVars.dataSource_195740 = this.state.EserDetay_IcBaskiKagitGramajByKagitCinsi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifAlComponent_253304_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_253304_value", "value", "EserDetay_KapakCinsiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_253304_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KapakCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_253304_value", "value", "EserDetay_KapakCinsiAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifAl/HizliTeklifAlComponent_253304_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_KapakGramajByKapakCinsi = result?.data.eserDetay_KapakGramajByKapakCinsi;

    formVars.hizliteklifal_738987_options = stateVars.EserDetay_KapakGramajByKapakCinsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifAlComponent_253304_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifAlComponent_253304_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;
    stateVars.dataSource_738987 = this.state.EserDetay_KapakGramajByKapakCinsi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HizliTeklifAlComponent_508444_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_458878_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_458878_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_454757_value", "value", "EserDetay_CiltBicimiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_454757_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_570219_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_195740_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_81822_value", "value", "EserDetay_IcBaskiRenkAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_81822_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_253304_value", "value", "EserDetay_KapakCinsiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_253304_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_738987_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_923446_value", "value", "EserDetay_KitapBoyutAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_923446_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_181252_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_181252_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_673902_value", "value", "EserDetay_SelefonAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "hizliteklifal_673902_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BaskiAdet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_458878_value", "value", "", "", "")
        ),
        "number"
      ),
      CiltBicimiID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_454757_value", "value", "EserDetay_CiltBicimiAll", "id", "id")
        ),
        "Guid"
      ),
      IcBaskiKagitCinsiID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_570219_value",
            "value",
            "EserDetay_IcBaskiKagitCinsiAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiKagitGramajID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_195740_value",
            "value",
            "EserDetay_IcBaskiKagitGramajByKagitCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      IcBaskiRenkID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_81822_value", "value", "EserDetay_IcBaskiRenkAll", "id", "id")
        ),
        "Guid"
      ),
      KapakCinsiID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_253304_value", "value", "EserDetay_KapakCinsiAll", "id", "id")
        ),
        "Guid"
      ),
      KapakGramajID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "hizliteklifal_738987_value",
            "value",
            "EserDetay_KapakGramajByKapakCinsi",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      KitapBoyutID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_923446_value", "value", "EserDetay_KitapBoyutAll", "id", "id")
        ),
        "Guid"
      ),
      SayfaSayisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_181252_value", "value", "", "", "")
        ),
        "number"
      ),
      SelefonID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hizliteklifal_673902_value", "value", "EserDetay_SelefonAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HizliTeklifAl/HizliTeklifAlComponent_508444_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getGuid = result?.data.getGuid;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.HizliTeklifAlComponent_508444_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HizliTeklifAlComponent_508444_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "HizliTeklifKarsilastirma",
      "HizliTeklifTTempID",
      ReactSystemFunctions.value(this, this.state.getGuid?.length > 0 ? this.state.getGuid[0]?.getGuid : null)
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HizliTeklifAl",
      "HizliTeklifKarsilastirma",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "100744",
      null,
      "right",
      null,
      "75%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [100744] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HizliTeklifAlPageInit();
    }
  }
}
