import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAltKullaniciEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAltKullaniciEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  AdminMi: any[];
  AdminMi_dummy: any[];
  AltRolAll: any[];
  AltRolAll_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  KUserById: any[];
  KUserById_dummy: any[];
  getUyeYetkilimiByKUserId: any[];
  getUyeYetkilimiByKUserId_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GsmKontrol: number;
  GsmKontrol_dummy: number;
  VCodeCreate: any[];
  VCodeCreate_dummy: any[];
  SendSMS: string;
  SendSMS_dummy: string;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  VCodeControl: any[];
  VCodeControl_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  MailKontrol: number;
  MailKontrol_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SaveUser: any;
  SaveUser_dummy: any;
  getAltKullaniciId: any[];
  getAltKullaniciId_dummy: any[];
  AltKullaniciDetaySave: number;
  AltKullaniciDetaySave_dummy: number;
  DisableUser: any;
  DisableUser_dummy: any;
  EnableUser: any;
  EnableUser_dummy: any;
  KUserSave: number;
  KUserSave_dummy: number;
  AddUserToRole: boolean;
  AddUserToRole_dummy: boolean;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  isComp791613Visible: "visible" | "hidden";
  isComp785040Visible: "visible" | "hidden";
  isComp831495Visible: "visible" | "hidden";
  isComp182776Visible: "visible" | "hidden";
  isComp810193Visible: "visible" | "hidden";
  isComp964798Visible: "visible" | "hidden";
  isComp99549Visible: "visible" | "hidden";
  isComp774900Visible: "visible" | "hidden";
  isComp695039Visible: "visible" | "hidden";
  isComp518347Visible: "visible" | "hidden";
  isComp896775Visible: "visible" | "hidden";
  isComp522844Visible: "visible" | "hidden";
  isComp284362Visible: "visible" | "hidden";
  isComp955773Visible: "visible" | "hidden";
  isComp459326Visible: "visible" | "hidden";
  isComp204047Enabled: "enabled" | "disabled";
  isComp430076Enabled: "enabled" | "disabled";
  isComp459326Enabled: "enabled" | "disabled";
  isComp955773Enabled: "enabled" | "disabled";
  isComp444127Enabled: "enabled" | "disabled";
  isComp14117Enabled: "enabled" | "disabled";
}

export class AltKullaniciEkle_ScreenBase extends React.PureComponent<IAltKullaniciEkle_ScreenProps, any> {
  altkullaniciekle_212172_value_kuikaSelectBoxRef: React.RefObject<any>;
  altkullaniciekle_695039_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "75a31074-8a59-4234-a663-fec971115e5d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 770727, PropertyName: "value", Value: "Kullanıcı Kartı" },
        { Id: 494388, PropertyName: "value", Value: "Firma Adı" },
        { Id: 827406, PropertyName: "value", Value: "Ad" },
        { Id: 720101, PropertyName: "value", Value: "Soyad" },
        { Id: 384422, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 444127, PropertyName: "placeholder", Value: "05*******" },
        { Id: 791613, PropertyName: "label", Value: "Doğrula" },
        { Id: 669678, PropertyName: "value", Value: "Doğrulama Kodu" },
        { Id: 842284, PropertyName: "label", Value: "Onayla" },
        { Id: 734291, PropertyName: "value", Value: "E-posta" },
        { Id: 182776, PropertyName: "label", Value: "Doğrula" },
        { Id: 166075, PropertyName: "value", Value: "Doğrulama Kodu" },
        { Id: 549237, PropertyName: "label", Value: "Onayla" },
        { Id: 713312, PropertyName: "value", Value: "Sorumlu Olduğu Yayınlar" },
        { Id: 222821, PropertyName: "value", Value: "Rol" },
        { Id: 695039, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 518347, PropertyName: "value", Value: "Yönetici" },
        { Id: 896775, PropertyName: "label", Value: "Rol Tanımla" },
        { Id: 118882, PropertyName: "value", Value: "Kullanıcı aktif" },
        { Id: 555018, PropertyName: "value", Value: "Şirket Admin Kullanıcı Olarak Ata ve Onaya Gönder" },
        { Id: 284362, PropertyName: "label", Value: "Kaydet Trigger" },
        { Id: 955773, PropertyName: "label", Value: "Güncelle" },
        { Id: 459326, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.altkullaniciekle_212172_value_kuikaSelectBoxRef = React.createRef();
    this.altkullaniciekle_695039_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      AdminMi: [],
      AltRolAll: [],
      GenelParametreler: [],
      KUserById: [],
      getUyeYetkilimiByKUserId: [],
      ChangeEnabledOf: "",
      GsmKontrol: 0,
      VCodeCreate: [],
      SendSMS: "",
      ChangeVisibilityOf: "",
      VCodeControl: [],
      Notify: false,
      MailKontrol: 0,
      SendMailSettings: [],
      SendMail: false,
      NAVIGATE: "",
      SaveUser: "",
      getAltKullaniciId: [],
      AltKullaniciDetaySave: 0,
      DisableUser: "",
      EnableUser: "",
      KUserSave: 0,
      AddUserToRole: false,
      TriggerEvent: "",
      isComp791613Visible: "visible",
      isComp785040Visible: "hidden",
      isComp831495Visible: "hidden",
      isComp182776Visible: "visible",
      isComp810193Visible: "hidden",
      isComp964798Visible: "hidden",
      isComp99549Visible: "hidden",
      isComp774900Visible: "hidden",
      isComp695039Visible: "hidden",
      isComp518347Visible: "hidden",
      isComp896775Visible: "hidden",
      isComp522844Visible: "hidden",
      isComp284362Visible: "hidden",
      isComp955773Visible: "hidden",
      isComp459326Visible: "hidden",
      isComp204047Enabled: "enabled",
      isComp430076Enabled: "enabled",
      isComp459326Enabled: "enabled",
      isComp955773Enabled: "enabled",
      isComp444127Enabled: "enabled",
      isComp14117Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("altkullaniciekle", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AltKullaniciEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("altkullaniciekle", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("altkullaniciekle", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AltKullaniciEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      altkullaniciekle_204047_value: this.state.KUserById?.at?.(0)?.firstName ?? undefined,
      altkullaniciekle_430076_value: this.state.KUserById?.at?.(0)?.lastName ?? undefined,
      altkullaniciekle_444127_value: this.state.KUserById?.at?.(0)?.phoneNumber ?? undefined,
      altkullaniciekle_14117_value: this.state.KUserById?.at?.(0)?.userName ?? undefined,
      altkullaniciekle_695039_value: this.state.KUserById?.at?.(0)?.altRolID ?? undefined,
      altkullaniciekle_352364_value: this.state.KUserById?.at?.(0)?.isActive ?? undefined,
      altkullaniciekle_102471_value: this.state.KUserById?.at?.(0)?.adminOlarakAta ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AltKullaniciEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      prmKUserId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AltKullaniciEkle/AltKullaniciEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.altkullaniciekle_142361_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.AdminMi = result?.data.adminMi;
    stateVars.isComp695039Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, stateVars.AdminMi?.length > 0 ? stateVars.AdminMi[0]?.admin : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp518347Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.AdminMi?.length > 0 ? stateVars.AdminMi[0]?.admin : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.AltRolAll = result?.data.altRolAll;

    formVars.altkullaniciekle_695039_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.altRolID : null;
    formVars.altkullaniciekle_695039_options = stateVars.AltRolAll;
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.KUserById = result?.data.kUserById;
    formVars.altkullaniciekle_204047_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.firstName : null
    );
    formVars.altkullaniciekle_430076_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.lastName : null
    );
    formVars.altkullaniciekle_444127_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.phoneNumber : null
    );
    formVars.altkullaniciekle_14117_value = ReactSystemFunctions.toString(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.userName : null
    );
    formVars.altkullaniciekle_695039_value = stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.altRolID : null;
    formVars.altkullaniciekle_695039_options = stateVars.AltRolAll;
    formVars.altkullaniciekle_352364_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.isActive : null
    );
    formVars.altkullaniciekle_102471_value = ReactSystemFunctions.value(
      this,
      stateVars.KUserById?.length > 0 ? stateVars.KUserById[0]?.adminOlarakAta : null
    );
    stateVars.getUyeYetkilimiByKUserId = result?.data.getUyeYetkilimiByKUserId;
    stateVars.isComp774900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getUyeYetkilimiByKUserId?.length > 0 ? stateVars.getUyeYetkilimiByKUserId[0]?.yetkili : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp896775Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getUyeYetkilimiByKUserId?.length > 0 ? stateVars.getUyeYetkilimiByKUserId[0]?.yetkili : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522844Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getUyeYetkilimiByKUserId?.length > 0 ? stateVars.getUyeYetkilimiByKUserId[0]?.yetkili : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEklePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "E"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp204047Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEklePageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEklePageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEklePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "E"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp430076Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEklePageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEklePageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEklePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.altkullaniciekle_142361_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    formVars.altkullaniciekle_204047_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.firstName : null
    );

    formVars.altkullaniciekle_430076_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.lastName : null
    );

    formVars.altkullaniciekle_444127_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.phoneNumber : null
    );

    formVars.altkullaniciekle_14117_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.userName : null
    );

    stateVars.isComp774900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getUyeYetkilimiByKUserId?.length > 0 ? this.state.getUyeYetkilimiByKUserId[0]?.yetkili : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.altkullaniciekle_695039_value = ReactSystemFunctions.toString(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.altRolID : null
    );

    stateVars.dataSource_695039 = this.state.AltRolAll;
    stateVars.dataSource_695039 = this.state.AltRolAll;
    stateVars.isComp695039Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, this.state.AdminMi?.length > 0 ? this.state.AdminMi[0]?.admin : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp518347Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.AdminMi?.length > 0 ? this.state.AdminMi[0]?.admin : null),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp896775Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getUyeYetkilimiByKUserId?.length > 0 ? this.state.getUyeYetkilimiByKUserId[0]?.yetkili : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.altkullaniciekle_352364_value = ReactSystemFunctions.toBoolean(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.isActive : null
    );

    stateVars.isComp522844Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getUyeYetkilimiByKUserId?.length > 0 ? this.state.getUyeYetkilimiByKUserId[0]?.yetkili : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.altkullaniciekle_102471_value = ReactSystemFunctions.value(
      this,
      this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.adminOlarakAta : null
    );

    stateVars.isComp955773Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459326Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "N"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_23666_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_444127_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp459326Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp955773Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_791613_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Gsm_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      gsm_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_791613_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GsmKontrol = result?.data.gsmKontrol;
    stateVars.VCodeCreate = result?.data.vCodeCreate;
    stateVars.SendSMS = result?.data.sendSMS;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_791613_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_791613_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp791613Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp785040Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_842284_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      VCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_996053_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_842284_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VCodeControl = result?.data.vCodeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "870125e9_e043_9c45_9286_dee4d042e167_notify",
          this.defaultML,
          "Doğrulandı!"
        ),
        "success",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "ea53709f_aa1c_f1db_4b04_96f268c091fb_notify",
          this.defaultML,
          "Doğrulanmadı!"
        ),
        "danger",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp444127Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp785040Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp459326Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp955773Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp831495Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_842284_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_842284_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp791613Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_14117_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp459326Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp955773Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_182776_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      actionkey_2: ReactSystemFunctions.convertToTypeByName("maildogrulama", "string"),
      uyeliktipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_3: ReactSystemFunctions.convertToTypeByName("4854ef0f-7ec9-4d8b-b698-375546a3030c", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      uyeliktipi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      language_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_182776_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MailKontrol = result?.data.mailKontrol;
    stateVars.VCodeCreate = result?.data.vCodeCreate;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_182776_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_182776_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp182776Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp810193Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_549237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      VCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_249465_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_549237_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VCodeControl = result?.data.vCodeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "ec516915_0c33_ce58_5e30_391cb05209a1_notify",
          this.defaultML,
          "Doğrulandı!"
        ),
        "success",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "cb28a048_14bd_e597_cd23_7c0bb5716af6_notify",
          this.defaultML,
          "Doğrulanmadı!"
        ),
        "danger",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp14117Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp810193Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp459326Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp955773Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp964798Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_549237_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_549237_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VCodeControl?.length > 0 ? this.state.VCodeControl[0]?.dogrulama : null
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp182776Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_896775_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.AltKullaniciEkleComponent_896775_onClick1_,
      "AltKullaniciEkle",
      "MenuYetki",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "728604",
      null,
      "right",
      null,
      "50%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_896775_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_896775_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltRolAll = result?.data.altRolAll;

    formVars.altkullaniciekle_695039_options = stateVars.AltRolAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_896775_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_896775_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_695039 = this.state.AltRolAll;
    stateVars.dataSource_695039 = this.state.AltRolAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_284362_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("c66813e5-5c42-414f-8d2b-edc1673f33ae", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("c967fbf7-96d2-49a1-9e96-26cb0719f825", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "2"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("d7f40e29-7fa4-40c4-ae18-230255e8fb36", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "3"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("d7f40e29-7fa4-40c4-ae18-230255e8fb36", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "7"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("6fc118a3-1712-4cf2-9873-1031263e4db3", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "4"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("0c5ab6cd-0097-4f2a-8674-d3f48f6280bd", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "5"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_204047_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_430076_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_204047_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_430076_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolNameSaveUser : null
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("0c5ab6cd-0097-4f2a-8674-d3f48f6280bd", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName(0, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "6"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick6_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UyelikTipiKey_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "number"
      ),
      AdminOlarakAta_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_102471_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AltRolID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_695039_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_284362_onClick7_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAltKullaniciId = result?.data.getAltKullaniciId;
    stateVars.AltKullaniciDetaySave = result?.data.altKullaniciDetaySave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick8_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_352364_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.DisableUser = await ReactSystemFunctions.disableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_284362_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_284362_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_352364_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.EnableUser = await ReactSystemFunctions.enableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_955773_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "altkullaniciekle_444127_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PhoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_444127_value", "value", "", "", "")
        ),
        "string"
      ),
      UserNameNew_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.KUserById?.length > 0 ? this.state.KUserById[0]?.userName : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.NewEditDisplay ?? this.props.screenInputs.neweditdisplay,
        "E"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AltKullaniciEkle/AltKullaniciEkleComponent_955773_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.KUserSave = result?.data.kUserSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_352364_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.DisableUser = await ReactSystemFunctions.disableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_352364_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.EnableUser = await ReactSystemFunctions.enableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "26a66ccc_45ee_cdd5_35ae_8ad094dac218_notify",
        this.defaultML,
        "Kullanıcı Güncellendi!"
      ),
      "success",
      "bottom-right",
      1,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      UyelikTipiKey_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "number"
      ),
      AdminOlarakAta_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_102471_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AltRolID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_695039_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AltKullaniciEkle/AltKullaniciEkleComponent_955773_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAltKullaniciId = result?.data.getAltKullaniciId;
    stateVars.AltKullaniciDetaySave = result?.data.altKullaniciDetaySave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick6_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "6"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MUSTERISAHIS",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "5"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MUSTERITICARI",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "4"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAZAR",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "7"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCITEDARIKCISAHIS",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "3"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCITEDARIKCITICARI",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "2"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MATBAA",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.AltKullaniciEkleComponent_955773_onClick12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AltKullaniciEkleComponent_955773_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "altkullaniciekle_14117_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "1"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCI",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "altkullaniciekle_14117_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AltKullaniciEkleComponent_459326_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AltKullaniciEkleComponent_284362_onClick()) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9933e02e_15ae_1303_fdd7_a88ea08f1056_notify",
        this.defaultML,
        "Kullanıcı Eklendi!"
      ),
      "success",
      "bottom-right",
      1,
      "",
      "",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    if (diId == 728604) {
      isErrorOccurred = await this.AltKullaniciEkleComponent_896775_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
