import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IGenelParametreKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IGenelParametreKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametrelerAll: any[];
  GenelParametrelerAll_dummy: any[];
  UploadFile: any;
  UploadFile_dummy: any;
  GenelParametrelerSaveUyelikFormu: number;
  GenelParametrelerSaveUyelikFormu_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  GenelParametrelerSaveKVKFormu: number;
  GenelParametrelerSaveKVKFormu_dummy: number;
  GenelParametrelerSaveMatbaa: number;
  GenelParametrelerSaveMatbaa_dummy: number;
  GenelParametrelerSaveMusteriSahsi: number;
  GenelParametrelerSaveMusteriSahsi_dummy: number;
  GenelParametrelerSaveMusteriTicari: number;
  GenelParametrelerSaveMusteriTicari_dummy: number;
  GenelParametrelerSaveYayinci: number;
  GenelParametrelerSaveYayinci_dummy: number;
  GenelParametrelerSaveTedarikciSahsi: number;
  GenelParametrelerSaveTedarikciSahsi_dummy: number;
  GenelParametrelerSaveTedarikciTicari: number;
  GenelParametrelerSaveTedarikciTicari_dummy: number;
  GenelParametrelerSaveYazar: number;
  GenelParametrelerSaveYazar_dummy: number;
  SaveRecord: number;
  SaveRecord_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  PDFormInfoSave: number;
  PDFormInfoSave_dummy: number;
  isComp425821Visible: "visible" | "hidden";
  isComp21035Visible: "visible" | "hidden";
  isComp65626Visible: "visible" | "hidden";
  isComp195957Visible: "visible" | "hidden";
  isComp589306Visible: "visible" | "hidden";
  isComp754675Visible: "visible" | "hidden";
  isComp356102Visible: "visible" | "hidden";
  isComp439215Visible: "visible" | "hidden";
  isComp798337Visible: "visible" | "hidden";
  isComp806568Visible: "visible" | "hidden";
  isComp6693Visible: "visible" | "hidden";
  isComp275682Visible: "visible" | "hidden";
  isComp669074Visible: "visible" | "hidden";
  isComp334172Visible: "visible" | "hidden";
  isComp185186Visible: "visible" | "hidden";
  isComp673008Visible: "visible" | "hidden";
  isComp850825Visible: "visible" | "hidden";
  isComp162077Visible: "visible" | "hidden";
  isComp976049Visible: "visible" | "hidden";
  isComp882254Visible: "visible" | "hidden";
  isComp395673Visible: "visible" | "hidden";
  isComp33192Visible: "visible" | "hidden";
  isComp862326Visible: "visible" | "hidden";
  isComp967541Visible: "visible" | "hidden";
  isComp348929Visible: "visible" | "hidden";
  isComp280033Visible: "visible" | "hidden";
  isComp853053Visible: "visible" | "hidden";
  isComp514561Visible: "visible" | "hidden";
  isComp879049Visible: "visible" | "hidden";
  isComp805252Visible: "visible" | "hidden";
  isComp869278Visible: "visible" | "hidden";
  isComp771409Visible: "visible" | "hidden";
  isComp634624Visible: "visible" | "hidden";
  isComp964252Visible: "visible" | "hidden";
  isComp964724Visible: "visible" | "hidden";
  isComp318656Visible: "visible" | "hidden";
  isComp445833Visible: "visible" | "hidden";
  isComp217567Visible: "visible" | "hidden";
}

export class GenelParametreKart_ScreenBase extends React.PureComponent<IGenelParametreKart_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9396dff7-f2ea-462b-a6db-40426139d856",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 774752, PropertyName: "value", Value: "Genel Parametreler" },
        { Id: 265644, PropertyName: "label", Value: "Profil" },
        { Id: 694334, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 392100, PropertyName: "value", Value: "Üyelik Bilgilendirme" },
        { Id: 617414, PropertyName: "value", Value: "Üyelik Metni PDF" },
        { Id: 205016, PropertyName: "value", Value: "KVK Metni PDF" },
        { Id: 827406, PropertyName: "value", Value: "Matbaa Sözleşmesi PDF" },
        { Id: 861872, PropertyName: "value", Value: "Müşteri (Şahıs) Sözleşmesi PDF" },
        { Id: 697118, PropertyName: "value", Value: "Müşteri (Ticari) Sözleşmesi PDF" },
        { Id: 634900, PropertyName: "value", Value: "Yayıncı Sözleşmesi PDF" },
        { Id: 600687, PropertyName: "value", Value: "Yayıncı Tedarikçi (Şahıs) Sözleşmesi PDF" },
        { Id: 78236, PropertyName: "value", Value: "Yayıncı Tedarikçi (Ticari) Sözleşmesi PDF" },
        { Id: 614705, PropertyName: "value", Value: "Yazar Sözleşmesi PDF" },
        { Id: 148913, PropertyName: "value", Value: "Publink Destek Numarası" },
        { Id: 495277, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 144515, PropertyName: "value", Value: "Power User E-Mail" },
        { Id: 996154, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 797793, PropertyName: "value", Value: "Admin E-Mail" },
        { Id: 399979, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 426026, PropertyName: "value", Value: "Uygulama Bilgilendirme" },
        { Id: 558717, PropertyName: "value", Value: "Üye Onay (Power User)" },
        { Id: 350776, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 709882, PropertyName: "value", Value: "Eser Bilgisi Onay (Power User)" },
        { Id: 404808, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 951791, PropertyName: "value", Value: "Teklif Alma" },
        { Id: 682060, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 52275, PropertyName: "value", Value: "Teklif Verme" },
        { Id: 883998, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 772662, PropertyName: "value", Value: "Kabul Edilen Teklifler (Eser Bilgi Girişi)" },
        { Id: 999949, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 165903, PropertyName: "value", Value: "Teklif Listesi" },
        { Id: 873902, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 597134, PropertyName: "value", Value: "Teklif Listesi (Eser Bilgileri Onaylı)" },
        { Id: 764883, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 810294, PropertyName: "value", Value: "Taslak & Onay Bekleyen Siparişler" },
        { Id: 363686, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 605887, PropertyName: "value", Value: "Onay Beklenen Siparişler" },
        { Id: 594628, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 321217, PropertyName: "value", Value: "Sipariş Listesi (Matbaa)" },
        { Id: 976049, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 529701, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 392814, PropertyName: "value", Value: "Sipariş Listesi (Yayıncı)" },
        { Id: 395673, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 518863, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 851065, PropertyName: "value", Value: "Bandrol Takip Listesi (Matbaa)" },
        { Id: 831732, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 480203, PropertyName: "value", Value: "Bandrol Takip Listesi (Yayıncı)" },
        { Id: 886339, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 607474, PropertyName: "value", Value: "Görsel Montaj  Listesi (Matbaa)" },
        { Id: 786330, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 750928, PropertyName: "value", Value: "Görsel Montaj  Listesi (Yayıncı)" },
        { Id: 997107, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 869345, PropertyName: "value", Value: "Üretim Listesi (Matbaa)" },
        { Id: 513519, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 331628, PropertyName: "value", Value: "Üretim Listesi (Yayıncı)" },
        { Id: 877410, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 299657, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi (Matbaa)" },
        { Id: 955855, PropertyName: "placeholder", Value: "Giriniz.." },
        { Id: 180578, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi (Yayıncı)" },
        { Id: 438175, PropertyName: "placeholder", Value: "Giriniz.." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      GenelParametrelerAll: [],
      UploadFile: "",
      GenelParametrelerSaveUyelikFormu: 0,
      SetValueOf: "",
      GenelParametrelerSaveKVKFormu: 0,
      GenelParametrelerSaveMatbaa: 0,
      GenelParametrelerSaveMusteriSahsi: 0,
      GenelParametrelerSaveMusteriTicari: 0,
      GenelParametrelerSaveYayinci: 0,
      GenelParametrelerSaveTedarikciSahsi: 0,
      GenelParametrelerSaveTedarikciTicari: 0,
      GenelParametrelerSaveYazar: 0,
      SaveRecord: false,
      Notify: false,
      PDFormInfoSave: 0,
      isComp425821Visible: "hidden",
      isComp21035Visible: "hidden",
      isComp65626Visible: "hidden",
      isComp195957Visible: "hidden",
      isComp589306Visible: "hidden",
      isComp754675Visible: "hidden",
      isComp356102Visible: "hidden",
      isComp439215Visible: "hidden",
      isComp798337Visible: "hidden",
      isComp806568Visible: "hidden",
      isComp6693Visible: "hidden",
      isComp275682Visible: "hidden",
      isComp669074Visible: "hidden",
      isComp334172Visible: "hidden",
      isComp185186Visible: "hidden",
      isComp673008Visible: "hidden",
      isComp850825Visible: "hidden",
      isComp162077Visible: "hidden",
      isComp976049Visible: "hidden",
      isComp882254Visible: "hidden",
      isComp395673Visible: "hidden",
      isComp33192Visible: "hidden",
      isComp862326Visible: "hidden",
      isComp967541Visible: "hidden",
      isComp348929Visible: "hidden",
      isComp280033Visible: "hidden",
      isComp853053Visible: "hidden",
      isComp514561Visible: "hidden",
      isComp879049Visible: "hidden",
      isComp805252Visible: "hidden",
      isComp869278Visible: "hidden",
      isComp771409Visible: "hidden",
      isComp634624Visible: "hidden",
      isComp964252Visible: "hidden",
      isComp964724Visible: "hidden",
      isComp318656Visible: "hidden",
      isComp445833Visible: "hidden",
      isComp217567Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("genelparametrekart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.GenelParametreKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("genelparametrekart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("genelparametrekart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.GenelParametreKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      genelparametrekart_894458_value: this.state.GenelParametrelerAll?.at?.(0)?.signinUyelikFormuAD ?? undefined,
      genelparametrekart_209769_value: this.state.GenelParametrelerAll?.at?.(0)?.signinKVKFormuAD ?? undefined,
      genelparametrekart_204047_value: this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLMatbaaAD ?? undefined,
      genelparametrekart_189302_value:
        this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLMusteriSahsiAD ?? undefined,
      genelparametrekart_733959_value:
        this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLMusteriTicariAD ?? undefined,
      genelparametrekart_843069_value: this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLYayinciAD ?? undefined,
      genelparametrekart_709718_value:
        this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLTedarikciSahsiAD ?? undefined,
      genelparametrekart_507605_value:
        this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLTedarikciTicariAD ?? undefined,
      genelparametrekart_529939_value: this.state.GenelParametrelerAll?.at?.(0)?.popSertifikaURLYazarAD ?? undefined,
      genelparametrekart_495277_value: this.state.GenelParametrelerAll?.at?.(0)?.destekTelNo ?? undefined,
      genelparametrekart_996154_value: this.state.GenelParametrelerAll?.at?.(0)?.powerUserMail ?? undefined,
      genelparametrekart_399979_value: this.state.GenelParametrelerAll?.at?.(0)?.adminMail ?? undefined,
      genelparametrekart_425821_value: this.state.GenelParametrelerAll?.at?.(0)?.m011AD ?? undefined,
      genelparametrekart_350776_value: this.state.GenelParametrelerAll?.at?.(0)?.m011 ?? undefined,
      genelparametrekart_65626_value: this.state.GenelParametrelerAll?.at?.(0)?.m012AD ?? undefined,
      genelparametrekart_404808_value: this.state.GenelParametrelerAll?.at?.(0)?.m012 ?? undefined,
      genelparametrekart_589306_value: this.state.GenelParametrelerAll?.at?.(0)?.m021AD ?? undefined,
      genelparametrekart_682060_value: this.state.GenelParametrelerAll?.at?.(0)?.m021 ?? undefined,
      genelparametrekart_356102_value: this.state.GenelParametrelerAll?.at?.(0)?.m022AD ?? undefined,
      genelparametrekart_883998_value: this.state.GenelParametrelerAll?.at?.(0)?.m022 ?? undefined,
      genelparametrekart_798337_value: this.state.GenelParametrelerAll?.at?.(0)?.m041AD ?? undefined,
      genelparametrekart_999949_value: this.state.GenelParametrelerAll?.at?.(0)?.m041 ?? undefined,
      genelparametrekart_6693_value: this.state.GenelParametrelerAll?.at?.(0)?.m042AD ?? undefined,
      genelparametrekart_873902_value: this.state.GenelParametrelerAll?.at?.(0)?.m042 ?? undefined,
      genelparametrekart_669074_value: this.state.GenelParametrelerAll?.at?.(0)?.m043AD ?? undefined,
      genelparametrekart_764883_value: this.state.GenelParametrelerAll?.at?.(0)?.m043 ?? undefined,
      genelparametrekart_185186_value: this.state.GenelParametrelerAll?.at?.(0)?.m044AD ?? undefined,
      genelparametrekart_363686_value: this.state.GenelParametrelerAll?.at?.(0)?.m044 ?? undefined,
      genelparametrekart_850825_value: this.state.GenelParametrelerAll?.at?.(0)?.m045AD ?? undefined,
      genelparametrekart_594628_value: this.state.GenelParametrelerAll?.at?.(0)?.m045 ?? undefined,
      genelparametrekart_976049_value: this.state.GenelParametrelerAll?.at?.(0)?.m046AD ?? undefined,
      genelparametrekart_529701_value: this.state.GenelParametrelerAll?.at?.(0)?.m046 ?? undefined,
      genelparametrekart_395673_value: this.state.GenelParametrelerAll?.at?.(0)?.m047AD ?? undefined,
      genelparametrekart_518863_value: this.state.GenelParametrelerAll?.at?.(0)?.m047 ?? undefined,
      genelparametrekart_862326_value: this.state.GenelParametrelerAll?.at?.(0)?.m051AD ?? undefined,
      genelparametrekart_831732_value: this.state.GenelParametrelerAll?.at?.(0)?.m051 ?? undefined,
      genelparametrekart_348929_value: this.state.GenelParametrelerAll?.at?.(0)?.m052AD ?? undefined,
      genelparametrekart_886339_value: this.state.GenelParametrelerAll?.at?.(0)?.m052 ?? undefined,
      genelparametrekart_853053_value: this.state.GenelParametrelerAll?.at?.(0)?.m061AD ?? undefined,
      genelparametrekart_786330_value: this.state.GenelParametrelerAll?.at?.(0)?.m061 ?? undefined,
      genelparametrekart_879049_value: this.state.GenelParametrelerAll?.at?.(0)?.m062AD ?? undefined,
      genelparametrekart_997107_value: this.state.GenelParametrelerAll?.at?.(0)?.m062 ?? undefined,
      genelparametrekart_869278_value: this.state.GenelParametrelerAll?.at?.(0)?.m071AD ?? undefined,
      genelparametrekart_513519_value: this.state.GenelParametrelerAll?.at?.(0)?.m071 ?? undefined,
      genelparametrekart_634624_value: this.state.GenelParametrelerAll?.at?.(0)?.m072AD ?? undefined,
      genelparametrekart_877410_value: this.state.GenelParametrelerAll?.at?.(0)?.m072 ?? undefined,
      genelparametrekart_964724_value: this.state.GenelParametrelerAll?.at?.(0)?.m081AD ?? undefined,
      genelparametrekart_955855_value: this.state.GenelParametrelerAll?.at?.(0)?.m081 ?? undefined,
      genelparametrekart_445833_value: this.state.GenelParametrelerAll?.at?.(0)?.m082AD ?? undefined,
      genelparametrekart_438175_value: this.state.GenelParametrelerAll?.at?.(0)?.m082 ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  GenelParametreKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "GenelParametreKart/GenelParametreKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.genelparametrekart_350194_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.genelparametrekart_983232_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.GenelParametrelerAll = result?.data.genelParametrelerAll;
    formVars.genelparametrekart_894458_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.signinUyelikFormuAD : null
    );
    formVars.genelparametrekart_209769_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.signinKVKFormuAD : null
    );
    formVars.genelparametrekart_204047_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLMatbaaAD : null
    );
    formVars.genelparametrekart_189302_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0
        ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLMusteriSahsiAD
        : null
    );
    formVars.genelparametrekart_733959_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0
        ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLMusteriTicariAD
        : null
    );
    formVars.genelparametrekart_843069_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLYayinciAD : null
    );
    formVars.genelparametrekart_709718_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0
        ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLTedarikciSahsiAD
        : null
    );
    formVars.genelparametrekart_507605_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0
        ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLTedarikciTicariAD
        : null
    );
    formVars.genelparametrekart_529939_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.popSertifikaURLYazarAD : null
    );
    formVars.genelparametrekart_495277_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.destekTelNo : null
    );
    formVars.genelparametrekart_996154_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.powerUserMail : null
    );
    formVars.genelparametrekart_399979_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.adminMail : null
    );
    formVars.genelparametrekart_425821_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m011AD : null
    );
    formVars.genelparametrekart_350776_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m011 : null
    );
    formVars.genelparametrekart_65626_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m012AD : null
    );
    formVars.genelparametrekart_404808_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m012 : null
    );
    formVars.genelparametrekart_589306_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m021AD : null
    );
    formVars.genelparametrekart_682060_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m021 : null
    );
    formVars.genelparametrekart_356102_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m022AD : null
    );
    formVars.genelparametrekart_883998_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m022 : null
    );
    formVars.genelparametrekart_798337_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m041AD : null
    );
    formVars.genelparametrekart_999949_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m041 : null
    );
    formVars.genelparametrekart_6693_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m042AD : null
    );
    formVars.genelparametrekart_873902_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m042 : null
    );
    formVars.genelparametrekart_669074_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m043AD : null
    );
    formVars.genelparametrekart_764883_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m043 : null
    );
    formVars.genelparametrekart_185186_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m044AD : null
    );
    formVars.genelparametrekart_363686_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m044 : null
    );
    formVars.genelparametrekart_850825_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m045AD : null
    );
    formVars.genelparametrekart_594628_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m045 : null
    );
    formVars.genelparametrekart_976049_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m046AD : null
    );
    formVars.genelparametrekart_529701_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m046 : null
    );
    formVars.genelparametrekart_395673_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m047AD : null
    );
    formVars.genelparametrekart_518863_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m047 : null
    );
    formVars.genelparametrekart_862326_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m051AD : null
    );
    formVars.genelparametrekart_831732_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m051 : null
    );
    formVars.genelparametrekart_348929_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m052AD : null
    );
    formVars.genelparametrekart_886339_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m052 : null
    );
    formVars.genelparametrekart_853053_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m061AD : null
    );
    formVars.genelparametrekart_786330_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m061 : null
    );
    formVars.genelparametrekart_879049_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m062AD : null
    );
    formVars.genelparametrekart_997107_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m062 : null
    );
    formVars.genelparametrekart_869278_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m071AD : null
    );
    formVars.genelparametrekart_513519_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m071 : null
    );
    formVars.genelparametrekart_634624_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m072AD : null
    );
    formVars.genelparametrekart_877410_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m072 : null
    );
    formVars.genelparametrekart_964724_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m081AD : null
    );
    formVars.genelparametrekart_955855_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m081 : null
    );
    formVars.genelparametrekart_445833_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m082AD : null
    );
    formVars.genelparametrekart_438175_value = ReactSystemFunctions.toString(
      this,
      stateVars.GenelParametrelerAll?.length > 0 ? stateVars.GenelParametrelerAll[0]?.m082 : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.genelparametrekart_350194_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.genelparametrekart_983232_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    formVars.genelparametrekart_894458_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.signinUyelikFormuAD : null
    );

    formVars.genelparametrekart_209769_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.signinKVKFormuAD : null
    );

    formVars.genelparametrekart_204047_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.popSertifikaURLMatbaaAD : null
    );

    formVars.genelparametrekart_189302_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0
        ? this.state.GenelParametrelerAll[0]?.popSertifikaURLMusteriSahsiAD
        : null
    );

    formVars.genelparametrekart_733959_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0
        ? this.state.GenelParametrelerAll[0]?.popSertifikaURLMusteriTicariAD
        : null
    );

    formVars.genelparametrekart_843069_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.popSertifikaURLYayinciAD : null
    );

    formVars.genelparametrekart_709718_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0
        ? this.state.GenelParametrelerAll[0]?.popSertifikaURLTedarikciSahsiAD
        : null
    );

    formVars.genelparametrekart_507605_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0
        ? this.state.GenelParametrelerAll[0]?.popSertifikaURLTedarikciTicariAD
        : null
    );

    formVars.genelparametrekart_529939_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.popSertifikaURLYazarAD : null
    );

    formVars.genelparametrekart_495277_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.destekTelNo : null
    );

    formVars.genelparametrekart_996154_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.powerUserMail : null
    );

    formVars.genelparametrekart_399979_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.adminMail : null
    );

    formVars.genelparametrekart_425821_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m011AD : null
    );

    formVars.genelparametrekart_350776_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m011 : null
    );

    formVars.genelparametrekart_65626_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m012AD : null
    );

    formVars.genelparametrekart_404808_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m012 : null
    );

    formVars.genelparametrekart_589306_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m021AD : null
    );

    formVars.genelparametrekart_682060_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m021 : null
    );

    formVars.genelparametrekart_356102_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m022AD : null
    );

    formVars.genelparametrekart_883998_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m022 : null
    );

    formVars.genelparametrekart_798337_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m041AD : null
    );

    formVars.genelparametrekart_999949_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m041 : null
    );

    formVars.genelparametrekart_6693_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m042AD : null
    );

    formVars.genelparametrekart_873902_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m042 : null
    );

    formVars.genelparametrekart_669074_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m043AD : null
    );

    formVars.genelparametrekart_764883_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m043 : null
    );

    formVars.genelparametrekart_185186_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m044AD : null
    );

    formVars.genelparametrekart_363686_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m044 : null
    );

    formVars.genelparametrekart_850825_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m045AD : null
    );

    formVars.genelparametrekart_594628_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m045 : null
    );

    formVars.genelparametrekart_976049_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m046AD : null
    );

    formVars.genelparametrekart_529701_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m046 : null
    );

    formVars.genelparametrekart_395673_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m047AD : null
    );

    formVars.genelparametrekart_518863_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m047 : null
    );

    formVars.genelparametrekart_862326_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m051AD : null
    );

    formVars.genelparametrekart_831732_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m051 : null
    );

    formVars.genelparametrekart_348929_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m052AD : null
    );

    formVars.genelparametrekart_886339_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m052 : null
    );

    formVars.genelparametrekart_853053_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m061AD : null
    );

    formVars.genelparametrekart_786330_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m061 : null
    );

    formVars.genelparametrekart_879049_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m062AD : null
    );

    formVars.genelparametrekart_997107_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m062 : null
    );

    formVars.genelparametrekart_869278_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m071AD : null
    );

    formVars.genelparametrekart_513519_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m071 : null
    );

    formVars.genelparametrekart_634624_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m072AD : null
    );

    formVars.genelparametrekart_877410_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m072 : null
    );

    formVars.genelparametrekart_964724_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m081AD : null
    );

    formVars.genelparametrekart_955855_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m081 : null
    );

    formVars.genelparametrekart_445833_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m082AD : null
    );

    formVars.genelparametrekart_438175_value = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametrelerAll?.length > 0 ? this.state.GenelParametrelerAll[0]?.m082 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_488464_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GenelParametreKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "217466",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_265644_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GenelParametreKart",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "469335",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_694334_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_888815_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_888815_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_888815_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SigninUyelikFormu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      SigninUyelikFormuAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_888815_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveUyelikFormu = result?.data.genelParametrelerSaveUyelikFormu;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_888815_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_888815_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_894458_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_654094_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_654094_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_654094_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SigninKVKFormu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      SigninKVKFormuAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_654094_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveKVKFormu = result?.data.genelParametrelerSaveKVKFormu;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_654094_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_654094_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_209769_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_411183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_411183_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_411183_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLMatbaa_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLMatbaaAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_411183_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveMatbaa = result?.data.genelParametrelerSaveMatbaa;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_411183_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_411183_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_204047_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_577038_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_577038_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_577038_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLMusteriSahsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLMusteriSahsiAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_577038_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveMusteriSahsi = result?.data.genelParametrelerSaveMusteriSahsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_577038_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_577038_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_189302_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_975187_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_975187_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_975187_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLMusteriTicari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLMusteriTicariAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_975187_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveMusteriTicari = result?.data.genelParametrelerSaveMusteriTicari;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_975187_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_975187_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_733959_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_423939_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_423939_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_423939_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLYayinci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLYayinciAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_423939_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveYayinci = result?.data.genelParametrelerSaveYayinci;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_423939_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_423939_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_843069_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_894679_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_894679_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_894679_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLTedarikciSahsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLTedarikciSahsiAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_894679_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveTedarikciSahsi = result?.data.genelParametrelerSaveTedarikciSahsi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_894679_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_894679_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_709718_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_669540_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_669540_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_669540_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLTedarikciTicari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLTedarikciTicariAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_669540_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveTedarikciTicari = result?.data.genelParametrelerSaveTedarikciTicari;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_669540_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_669540_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_507605_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_527190_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_527190_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_527190_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrnekPOPSertifikaURLYazar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSertifikaURLYazarAD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_527190_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametrelerSaveYazar = result?.data.genelParametrelerSaveYazar;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_527190_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_527190_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_529939_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_987037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      DestekTelNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_495277_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_987037_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_987037_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_987037_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a61d7093_1e89_cc55_86fa_4d1b6f71f7fa_notify",
        this.defaultML,
        "Güncellendi!"
      ),
      "success",
      "bottom-right",
      3,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_626185_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      PowerUserMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_996154_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_626185_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_626185_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_626185_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "619b2c5a_e79d_d2cf_23b7_c9de644bf2ea_notify",
        this.defaultML,
        "Güncellendi!"
      ),
      "success",
      "bottom-right",
      3,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_669823_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      AdminMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_399979_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_669823_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_669823_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_669823_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c27ca5d6_6767_b0e4_93b0_0a3b1d3a7da5_notify",
        this.defaultML,
        "Güncellendi!"
      ),
      "success",
      "bottom-right",
      3,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_21035_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_21035_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_21035_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m011", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_21035_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_21035_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_21035_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_425821_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_971079_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m011", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_350776_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_971079_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_195957_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_195957_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_195957_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m012", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_195957_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_195957_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_195957_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_65626_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_257714_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m012", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_404808_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_257714_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_754675_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_754675_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_754675_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m021", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_754675_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_754675_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_754675_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_589306_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_207369_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m021", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_682060_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_207369_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_439215_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_439215_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_439215_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m022", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_439215_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_439215_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_439215_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_356102_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_416016_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m022", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_883998_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_416016_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_806568_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_806568_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_806568_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m041", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_806568_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_806568_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_806568_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_798337_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_996777_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m041", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_999949_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_996777_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_275682_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_275682_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_275682_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m042", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_275682_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_275682_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_275682_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_6693_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_112304_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m042", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_873902_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_112304_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_334172_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_334172_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_334172_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m043", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_334172_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_334172_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_334172_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_669074_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_663745_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m043", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_764883_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_663745_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_673008_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_673008_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_673008_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m044", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_673008_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_673008_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_673008_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_185186_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_383331_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m044", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_363686_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_383331_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_162077_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_162077_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_162077_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m045", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_162077_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_162077_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_162077_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_850825_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_60511_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m045", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_594628_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_60511_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_882254_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_882254_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_882254_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m046", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_882254_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_882254_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_882254_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_976049_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_6801_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m046", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_529701_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_6801_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_33192_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_33192_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_33192_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m047", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_33192_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_33192_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_33192_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_395673_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_348299_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m047", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_518863_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_348299_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_967541_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_967541_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_967541_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m051", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_967541_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_967541_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_967541_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_862326_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_168441_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m051", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_831732_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_168441_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_280033_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_280033_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_280033_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m052", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_280033_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_280033_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_280033_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_348929_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_575944_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m052", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_886339_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_575944_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_514561_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_514561_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_514561_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m061", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_514561_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_514561_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_514561_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_853053_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_961507_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m061", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_786330_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_961507_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_805252_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_805252_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_805252_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m062", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_805252_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_805252_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_805252_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_879049_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_617524_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m062", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_997107_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_617524_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_771409_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_771409_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_771409_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m071", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_771409_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_771409_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_771409_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_869278_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_599622_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m071", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_513519_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_599622_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_964252_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_964252_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_964252_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m072", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_964252_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_964252_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_964252_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_634624_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_993623_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m072", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_877410_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_993623_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_318656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_318656_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_318656_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m081", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_318656_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_318656_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_318656_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_964724_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_869692_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m081", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_955855_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_869692_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  GenelParametreKartComponent_217567_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_217567_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  GenelParametreKartComponent_217567_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m082", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_217567_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.GenelParametreKartComponent_217567_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GenelParametreKartComponent_217567_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "genelparametrekart_445833_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GenelParametreKartComponent_766702_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InfoCode_0: ReactSystemFunctions.convertToTypeByName("m082", "string"),
      FileName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      FileData_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "genelparametrekart_438175_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GenelParametreKart/GenelParametreKartComponent_766702_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PDFormInfoSave = result?.data.pdFormInfoSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [217466, 469335] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.GenelParametreKartPageInit();
    }
  }
}
