import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserDetayIcBaskiKagitCinsi_List_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserDetayIcBaskiKagitCinsi_List_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_IcBaskiKagitCinsiSelectById: any[];
  EserDetay_IcBaskiKagitCinsiSelectById_dummy: any[];
  EserDetay_IcBaskiKagitCinsiSave: number;
  EserDetay_IcBaskiKagitCinsiSave_dummy: number;
  isComp1253194Visible: "visible" | "hidden";
}

export class EserDetayIcBaskiKagitCinsi_List_Form_ScreenBase extends React.PureComponent<
  IEserDetayIcBaskiKagitCinsi_List_Form_ScreenProps,
  any
> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "12d629dd-009c-4271-aee2-d3a6a2dbf4d0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "İç Baskı Kağıt Cinsi" },
        { Id: 6013488, PropertyName: "value", Value: "İç Baskı Kağıt Cinsi" },
        { Id: 8106283, PropertyName: "value", Value: "İç Baskı Kağıt Cinsi key" },
        { Id: 71517, PropertyName: "value", Value: "Aktif" },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      EserDetay_IcBaskiKagitCinsiSelectById: [],
      EserDetay_IcBaskiKagitCinsiSave: 0,
      isComp1253194Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserdetayicbaskikagitcinsi_list_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserDetayIcBaskiKagitCinsi_List_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserdetayicbaskikagitcinsi_list_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserdetayicbaskikagitcinsi_list_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserDetayIcBaskiKagitCinsi_List_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserdetayicbaskikagitcinsi_list_form_4613942_value:
        this.state.EserDetay_IcBaskiKagitCinsiSelectById?.at?.(0)?.icBaskiKagitCinsi ?? undefined,
      eserdetayicbaskikagitcinsi_list_form_8032142_value:
        this.state.EserDetay_IcBaskiKagitCinsiSelectById?.at?.(0)?.icBaskiKagitCinsiKey ?? undefined,
      eserdetayicbaskikagitcinsi_list_form_867241_value:
        this.state.EserDetay_IcBaskiKagitCinsiSelectById?.at?.(0)?.aktifMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  EserDetayIcBaskiKagitCinsi_List_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayIcBaskiKagitCinsi_List_Form/EserDetayIcBaskiKagitCinsi_List_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiKagitCinsiSelectById = result?.data.eserDetay_IcBaskiKagitCinsiSelectById;
    formVars.eserdetayicbaskikagitcinsi_list_form_4613942_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_IcBaskiKagitCinsiSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiKagitCinsiSelectById[0]?.icBaskiKagitCinsi
        : null
    );
    formVars.eserdetayicbaskikagitcinsi_list_form_8032142_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_IcBaskiKagitCinsiSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiKagitCinsiSelectById[0]?.icBaskiKagitCinsiKey
        : null
    );
    formVars.eserdetayicbaskikagitcinsi_list_form_867241_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_IcBaskiKagitCinsiSelectById?.length > 0
        ? stateVars.EserDetay_IcBaskiKagitCinsiSelectById[0]?.aktifMi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayIcBaskiKagitCinsi_List_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayIcBaskiKagitCinsi_List_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.eserdetayicbaskikagitcinsi_list_form_4613942_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_IcBaskiKagitCinsiSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiKagitCinsiSelectById[0]?.icBaskiKagitCinsi
        : null
    );

    formVars.eserdetayicbaskikagitcinsi_list_form_8032142_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_IcBaskiKagitCinsiSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiKagitCinsiSelectById[0]?.icBaskiKagitCinsiKey
        : null
    );

    formVars.eserdetayicbaskikagitcinsi_list_form_867241_value = ReactSystemFunctions.value(
      this,
      this.state.EserDetay_IcBaskiKagitCinsiSelectById?.length > 0
        ? this.state.EserDetay_IcBaskiKagitCinsiSelectById[0]?.aktifMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserDetayIcBaskiKagitCinsi_List_FormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserDetayIcBaskiKagitCinsi_List_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AktifMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayicbaskikagitcinsi_list_form_867241_value", "value", "", "", "")
        ),
        "boolean"
      ),
      IcBaskiKagitCinsi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserdetayicbaskikagitcinsi_list_form_4613942_value", "value", "", "", "")
        ),
        "string"
      ),
      IcBaskiKagitCinsiKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayicbaskikagitcinsi_list_form_8032142_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayIcBaskiKagitCinsi_List_Form/EserDetayIcBaskiKagitCinsi_List_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_IcBaskiKagitCinsiSave = result?.data.eserDetay_IcBaskiKagitCinsiSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayIcBaskiKagitCinsi_List_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayIcBaskiKagitCinsi_List_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
