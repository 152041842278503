import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  RoleMenuGoster: any[];
  RoleMenuGoster_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp246193Visible: "visible" | "hidden";
  isComp192925Visible: "visible" | "hidden";
  isComp884300Visible: "visible" | "hidden";
  isComp985374Visible: "visible" | "hidden";
  isComp769054Visible: "visible" | "hidden";
  isComp923752Visible: "visible" | "hidden";
  isComp504493Visible: "visible" | "hidden";
  isComp406721Visible: "visible" | "hidden";
  isComp361522Visible: "visible" | "hidden";
  isComp533454Visible: "visible" | "hidden";
  isComp970680Visible: "visible" | "hidden";
  isComp411822Visible: "visible" | "hidden";
  isComp15063Visible: "visible" | "hidden";
  isComp64247Visible: "visible" | "hidden";
  isComp765434Visible: "visible" | "hidden";
  isComp518738Visible: "visible" | "hidden";
  isComp274493Visible: "visible" | "hidden";
  isComp884453Visible: "visible" | "hidden";
  isComp291782Visible: "visible" | "hidden";
  isComp446418Visible: "visible" | "hidden";
  isComp997757Visible: "visible" | "hidden";
  isComp166669Visible: "visible" | "hidden";
  isComp122527Visible: "visible" | "hidden";
  isComp228966Visible: "visible" | "hidden";
  isComp331618Visible: "visible" | "hidden";
  isComp853472Visible: "visible" | "hidden";
  isComp713421Visible: "visible" | "hidden";
  isComp246978Visible: "visible" | "hidden";
  isComp246851Visible: "visible" | "hidden";
  isComp336587Visible: "visible" | "hidden";
  isComp577042Visible: "visible" | "hidden";
  isComp52402Visible: "visible" | "hidden";
  isComp626072Visible: "visible" | "hidden";
  isComp288022Visible: "visible" | "hidden";
  isComp479491Visible: "visible" | "hidden";
  isComp843608Visible: "visible" | "hidden";
  isComp894707Visible: "visible" | "hidden";
  isComp373446Visible: "visible" | "hidden";
  isComp19935Visible: "visible" | "hidden";
  isComp375595Visible: "visible" | "hidden";
  isComp723274Visible: "visible" | "hidden";
  isComp152077Visible: "visible" | "hidden";
  isComp567043Visible: "visible" | "hidden";
  isComp481394Visible: "visible" | "hidden";
  isComp402894Visible: "visible" | "hidden";
  isComp285295Visible: "visible" | "hidden";
  isComp712330Visible: "visible" | "hidden";
  isComp255417Visible: "visible" | "hidden";
  isComp395331Visible: "visible" | "hidden";
  isComp411912Visible: "visible" | "hidden";
  isComp902617Visible: "visible" | "hidden";
  isComp49017Visible: "visible" | "hidden";
  isComp636754Visible: "visible" | "hidden";
  isComp501198Visible: "visible" | "hidden";
  isComp874444Visible: "visible" | "hidden";
  isComp623750Visible: "visible" | "hidden";
  isComp357361Visible: "visible" | "hidden";
  isComp64207Visible: "visible" | "hidden";
  isComp506233Visible: "visible" | "hidden";
  isComp313658Visible: "visible" | "hidden";
  isComp521233Visible: "visible" | "hidden";
  isComp835245Visible: "visible" | "hidden";
  isComp256986Visible: "visible" | "hidden";
  isComp683617Visible: "visible" | "hidden";
  isComp106170Visible: "visible" | "hidden";
  isComp676441Visible: "visible" | "hidden";
  isComp496085Visible: "visible" | "hidden";
  isComp82614Visible: "visible" | "hidden";
  isComp670211Visible: "visible" | "hidden";
  isComp252253Visible: "visible" | "hidden";
  isCompleftmenu_822360AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_246193AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_192925AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_884300AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_985374AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_769054AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_923752AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_670211AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_504493AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_406721AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_361522AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_533454AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_970680AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_411822AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_15063AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_64247AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_765434AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_518738AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_274493AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_884453AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_291782AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_446418AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_997757AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_166669AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_122527AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_228966AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_331618AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_853472AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_713421AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_246978AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_246851AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_336587AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_577042AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_52402AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_626072AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_288022AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_479491AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_843608AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_894707AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_373446AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_19935AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_375595AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_723274AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_152077AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_567043AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_481394AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_402894AuthorizationVisible: "visible" | "hidden";
}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "f197ea97-a51f-4d62-bd74-325b0289c2d6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 950642, PropertyName: "value", Value: "Dashboard" },
        { Id: 143058, PropertyName: "value", Value: "Üyelik Bilgileri" },
        { Id: 442039, PropertyName: "value", Value: "Üye Onay (Power User)" },
        { Id: 398599, PropertyName: "value", Value: "Eser Bilgisi Onay (Power User)" },
        { Id: 308498, PropertyName: "value", Value: "Teklif" },
        { Id: 60264, PropertyName: "value", Value: "Teklif Alma" },
        { Id: 726551, PropertyName: "value", Value: "Teklif Verme" },
        { Id: 376448, PropertyName: "value", Value: "Eser Bilgileri" },
        { Id: 148395, PropertyName: "value", Value: "Sipariş" },
        { Id: 107114, PropertyName: "value", Value: "Kabul Edilen Teklifler (Eser Bilgi Girişi)" },
        { Id: 828117, PropertyName: "value", Value: "Teklif Listesi" },
        { Id: 57952, PropertyName: "value", Value: "Teklif Listesi (Eser Bilgileri Onaylı)" },
        { Id: 579963, PropertyName: "value", Value: "Taslak & Onay Bekleyen Siparişler" },
        { Id: 292867, PropertyName: "value", Value: "Onay Beklenen Siparişler" },
        { Id: 428389, PropertyName: "value", Value: "Sipariş Listesi" },
        { Id: 799101, PropertyName: "value", Value: "Sipariş Listesi" },
        { Id: 212431, PropertyName: "value", Value: "Bandrol Takip" },
        { Id: 280726, PropertyName: "value", Value: "Bandrol Takip Listesi" },
        { Id: 765228, PropertyName: "value", Value: "Bandrol Takip Listesi" },
        { Id: 787770, PropertyName: "value", Value: "Baskı Öncesi" },
        { Id: 863818, PropertyName: "value", Value: "Görsel Montaj  Listesi" },
        { Id: 616724, PropertyName: "value", Value: "Görsel Montaj  Listesi" },
        { Id: 645789, PropertyName: "value", Value: "Üretim" },
        { Id: 656901, PropertyName: "value", Value: "Üretim Listesi" },
        { Id: 897638, PropertyName: "value", Value: "Üretim Listesi" },
        { Id: 635051, PropertyName: "value", Value: "Depo/Sevkiyat" },
        { Id: 974938, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi" },
        { Id: 344524, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi" },
        { Id: 525983, PropertyName: "value", Value: "Tanımlar" },
        { Id: 435130, PropertyName: "value", Value: "Hızlı Teklif Girişi" },
        { Id: 981883, PropertyName: "value", Value: "Eser Bilgileri" },
        { Id: 140116, PropertyName: "value", Value: "Kullanıcı Tanımlama" },
        { Id: 396221, PropertyName: "value", Value: "Kitap Boyutu" },
        { Id: 534929, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 687082, PropertyName: "value", Value: "İç Baskı Kâğıt Cinsi" },
        { Id: 311903, PropertyName: "value", Value: "İç Baskı Kâğıt Gramajı" },
        { Id: 189954, PropertyName: "value", Value: "Kapak Cinsi" },
        { Id: 520029, PropertyName: "value", Value: "Kapak Gramajı" },
        { Id: 550495, PropertyName: "value", Value: "İç Baskı Renk" },
        { Id: 149051, PropertyName: "value", Value: "Selefon" },
        { Id: 269960, PropertyName: "value", Value: "Para Birimi" },
        { Id: 46853, PropertyName: "value", Value: "Teslim Türü" },
        { Id: 885838, PropertyName: "value", Value: "Öneri Şikayet Kategori" },
        { Id: 101341, PropertyName: "value", Value: "Genel Parametreler" },
        { Id: 903452, PropertyName: "value", Value: "Log Takibi" },
        { Id: 110256, PropertyName: "value", Value: "Yardım ve Destek" },
        { Id: 718242, PropertyName: "value", Value: "Sıkça Sorulan Sorular" },
        { Id: 188381, PropertyName: "value", Value: "Logout" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      GenelParametreler: [],
      RoleMenuGoster: [],
      ChangeVisibilityOf: "",
      NAVIGATE: "",
      GoBack: "",
      ToggleVisibilityOf: "",
      Logout: false,
      goToSignInActivity: "",
      isComp246193Visible: "hidden",
      isComp192925Visible: "visible",
      isComp884300Visible: "visible",
      isComp985374Visible: "hidden",
      isComp769054Visible: "hidden",
      isComp923752Visible: "hidden",
      isComp504493Visible: "hidden",
      isComp406721Visible: "hidden",
      isComp361522Visible: "hidden",
      isComp533454Visible: "hidden",
      isComp970680Visible: "hidden",
      isComp411822Visible: "hidden",
      isComp15063Visible: "hidden",
      isComp64247Visible: "hidden",
      isComp765434Visible: "hidden",
      isComp518738Visible: "hidden",
      isComp274493Visible: "hidden",
      isComp884453Visible: "hidden",
      isComp291782Visible: "hidden",
      isComp446418Visible: "hidden",
      isComp997757Visible: "hidden",
      isComp166669Visible: "hidden",
      isComp122527Visible: "hidden",
      isComp228966Visible: "hidden",
      isComp331618Visible: "hidden",
      isComp853472Visible: "hidden",
      isComp713421Visible: "hidden",
      isComp246978Visible: "hidden",
      isComp246851Visible: "hidden",
      isComp336587Visible: "hidden",
      isComp577042Visible: "hidden",
      isComp52402Visible: "hidden",
      isComp626072Visible: "hidden",
      isComp288022Visible: "hidden",
      isComp479491Visible: "hidden",
      isComp843608Visible: "hidden",
      isComp894707Visible: "hidden",
      isComp373446Visible: "hidden",
      isComp19935Visible: "hidden",
      isComp375595Visible: "hidden",
      isComp723274Visible: "hidden",
      isComp152077Visible: "hidden",
      isComp567043Visible: "hidden",
      isComp481394Visible: "hidden",
      isComp402894Visible: "hidden",
      isComp285295Visible: "visible",
      isComp712330Visible: "hidden",
      isComp255417Visible: "hidden",
      isComp395331Visible: "visible",
      isComp411912Visible: "hidden",
      isComp902617Visible: "hidden",
      isComp49017Visible: "visible",
      isComp636754Visible: "hidden",
      isComp501198Visible: "hidden",
      isComp874444Visible: "visible",
      isComp623750Visible: "hidden",
      isComp357361Visible: "hidden",
      isComp64207Visible: "visible",
      isComp506233Visible: "hidden",
      isComp313658Visible: "hidden",
      isComp521233Visible: "visible",
      isComp835245Visible: "hidden",
      isComp256986Visible: "visible",
      isComp683617Visible: "hidden",
      isComp106170Visible: "hidden",
      isComp676441Visible: "hidden",
      isComp496085Visible: "visible",
      isComp82614Visible: "hidden",
      isComp670211Visible: "hidden",
      isComp252253Visible: "hidden",
      isCompleftmenu_822360AuthorizationVisible: "visible",
      isCompleftmenu_246193AuthorizationVisible: "visible",
      isCompleftmenu_192925AuthorizationVisible: "visible",
      isCompleftmenu_884300AuthorizationVisible: "visible",
      isCompleftmenu_985374AuthorizationVisible: "visible",
      isCompleftmenu_769054AuthorizationVisible: "visible",
      isCompleftmenu_923752AuthorizationVisible: "visible",
      isCompleftmenu_670211AuthorizationVisible: "visible",
      isCompleftmenu_504493AuthorizationVisible: "visible",
      isCompleftmenu_406721AuthorizationVisible: "visible",
      isCompleftmenu_361522AuthorizationVisible: "visible",
      isCompleftmenu_533454AuthorizationVisible: "visible",
      isCompleftmenu_970680AuthorizationVisible: "visible",
      isCompleftmenu_411822AuthorizationVisible: "visible",
      isCompleftmenu_15063AuthorizationVisible: "visible",
      isCompleftmenu_64247AuthorizationVisible: "visible",
      isCompleftmenu_765434AuthorizationVisible: "visible",
      isCompleftmenu_518738AuthorizationVisible: "visible",
      isCompleftmenu_274493AuthorizationVisible: "visible",
      isCompleftmenu_884453AuthorizationVisible: "visible",
      isCompleftmenu_291782AuthorizationVisible: "visible",
      isCompleftmenu_446418AuthorizationVisible: "visible",
      isCompleftmenu_997757AuthorizationVisible: "visible",
      isCompleftmenu_166669AuthorizationVisible: "visible",
      isCompleftmenu_122527AuthorizationVisible: "visible",
      isCompleftmenu_228966AuthorizationVisible: "visible",
      isCompleftmenu_331618AuthorizationVisible: "visible",
      isCompleftmenu_853472AuthorizationVisible: "visible",
      isCompleftmenu_713421AuthorizationVisible: "visible",
      isCompleftmenu_246978AuthorizationVisible: "visible",
      isCompleftmenu_246851AuthorizationVisible: "visible",
      isCompleftmenu_336587AuthorizationVisible: "visible",
      isCompleftmenu_577042AuthorizationVisible: "visible",
      isCompleftmenu_52402AuthorizationVisible: "visible",
      isCompleftmenu_626072AuthorizationVisible: "visible",
      isCompleftmenu_288022AuthorizationVisible: "visible",
      isCompleftmenu_479491AuthorizationVisible: "visible",
      isCompleftmenu_843608AuthorizationVisible: "visible",
      isCompleftmenu_894707AuthorizationVisible: "visible",
      isCompleftmenu_373446AuthorizationVisible: "visible",
      isCompleftmenu_19935AuthorizationVisible: "visible",
      isCompleftmenu_375595AuthorizationVisible: "visible",
      isCompleftmenu_723274AuthorizationVisible: "visible",
      isCompleftmenu_152077AuthorizationVisible: "visible",
      isCompleftmenu_567043AuthorizationVisible: "visible",
      isCompleftmenu_481394AuthorizationVisible: "visible",
      isCompleftmenu_402894AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
      return;
    }

    if (true) {
      await this.LeftMenuPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_822360AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_246193AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_192925AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_884300AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_985374AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_769054AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_923752AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_670211AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_504493AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_406721AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_361522AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_533454AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_970680AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_411822AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_15063AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_64247AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_765434AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_518738AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_274493AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_884453AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_291782AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_446418AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_997757AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_166669AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_122527AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_228966AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_331618AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_853472AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_713421AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_246978AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_246851AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_336587AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_577042AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_52402AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_626072AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_288022AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_479491AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_843608AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_894707AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_373446AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_19935AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_375595AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_723274AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_152077AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_567043AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_481394AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_402894AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIIN"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.LeftMenuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "LeftMenu/LeftMenuPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.LeftMenuPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  LeftMenuPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m001 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp246193Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m011 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp192925Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m012 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp884300Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m002 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp985374Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m021 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp769054Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp923752Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m004 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp504493Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m041 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp406721Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m042 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp361522Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m043 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp533454Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m044 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp970680Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m045 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp411822Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m046 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp15063Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m047 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp64247Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m005 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp765434Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m051 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp518738Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m052 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp274493Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m006 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp884453Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m061 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp291782Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m062 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp446418Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m007 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp997757Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m071 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp166669Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m072 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp122527Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m008 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp228966Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m081 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp331618Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit26_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m082 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp853472Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit27_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit27_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m009 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp713421Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit28_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit28_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m091 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp246978Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit29_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit29_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m092 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp246851Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit30_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit30_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m093 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp336587Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit31_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit31_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m094 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp577042Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit32_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit32_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit32_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m095 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp52402Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit33_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit33_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit33_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m096 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp626072Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit34_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit34_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit34_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m097 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp288022Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit35_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit35_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit35_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m098 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp479491Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit36_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit36_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit36_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m099 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp843608Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit37_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit37_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit37_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m991 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp894707Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit38_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit38_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit38_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m992 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp373446Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit39_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit39_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit39_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m993 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp19935Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit40_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit40_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit40_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m994 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp375595Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit41_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit41_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit41_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m095 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp723274Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit42_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit42_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit42_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m996 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp152077Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit43_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit43_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit43_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m101 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp567043Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit44_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit44_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit44_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m102 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp481394Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit45_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit45_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit45_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m103 : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp402894Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit46_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuPageInit46_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuPageInit46_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.leftmenu_870218_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m011 : null
    );

    formVars.leftmenu_244749_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m012 : null
    );

    formVars.leftmenu_533326_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m021 : null
    );

    formVars.leftmenu_273876_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m022 : null
    );

    formVars.leftmenu_99061_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m041 : null
    );

    formVars.leftmenu_527697_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m042 : null
    );

    formVars.leftmenu_489377_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m043 : null
    );

    formVars.leftmenu_398552_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m044 : null
    );

    formVars.leftmenu_774464_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m045 : null
    );

    formVars.leftmenu_536930_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m046 : null
    );

    formVars.leftmenu_996334_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m047 : null
    );

    formVars.leftmenu_664502_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m051 : null
    );

    formVars.leftmenu_836641_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m052 : null
    );

    formVars.leftmenu_231982_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m061 : null
    );

    formVars.leftmenu_922326_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m062 : null
    );

    formVars.leftmenu_213378_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m071 : null
    );

    formVars.leftmenu_759860_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m072 : null
    );

    formVars.leftmenu_535409_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m081 : null
    );

    formVars.leftmenu_395490_hoverText = ReactSystemFunctions.toString(
      this,
      this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.m082 : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  LeftMenuComponent_822360_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "ADMIIN"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick1_,
        "LeftMenu",
        "DashBoardPOP",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.rolName : null),
        "POWERUSER"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick2_,
        "LeftMenu",
        "DashBoardPowerUser",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick3_,
        "LeftMenu",
        "DashBoardYayinci",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "2"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick4_,
        "LeftMenu",
        "DashBoardMatbaa",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "3"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick5_,
        "LeftMenu",
        "DashBoardTedarikci",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "7"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick6_,
        "LeftMenu",
        "DashBoardTedarikci",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "4"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick7_,
        "LeftMenu",
        "DashBoardYazar",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "5"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.LeftMenuComponent_822360_onClick8_,
        "LeftMenu",
        "DashBoardMusteri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.LeftMenuComponent_822360_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  LeftMenuComponent_822360_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipiKey : null
        ),
        "6"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "LeftMenu",
        "DashBoardMusteri",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  LeftMenuComponent_620279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_246193_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp285295Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp712330Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp255417Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_132861_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "PowerUserUyeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_884300_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "PowerUserEserList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_861134_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "PowerUserEserList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_985374_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp395331Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp411912Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp902617Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_315375_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TeklifTalepList", "tabIndex", 1);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TeklifTalepList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_498558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TeklifVermeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_670211_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserBilgileriList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_504493_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp49017Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp636754Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp501198Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_624013_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "KabulEdilenTeklifList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_758682_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EseriOnaylananTeklifListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_761006_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EseriOnaylananTeklifList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_529589_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TSiparisListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_851977_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "MOnayBekleyenSiparisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_993722_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_776054_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_765434_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp874444Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp623750Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp357361Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_791368_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BandrolTakipListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_775127_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BandrolTakipList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_884453_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp64207Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp506233Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp313658Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_817650_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BaskiOncesiListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_885527_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BaskiOncesiList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_997757_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp521233Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp623750Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp835245Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_54683_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UretimListMatbaa", "prmSiparisNo", "");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UretimListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_317403_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UretimList", "prmSiparisNo", "");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UretimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_228966_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp256986Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp683617Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp106170Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_426317_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisTeslimListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_470465_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SiparisTeslimList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_713421_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp676441Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp496085Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp82614Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_246978_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "HizliTeklifList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_246851_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserBilgileriList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_336587_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "AltKullaniciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_577042_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayKitapBoyut_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_52402_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayCiltBicimi_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_626072_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayIcBaskiKagitCinsi_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_288022_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayIcBaskiKagitGramaj_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_479491_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayKapakCinsi_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_843608_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayKapakGramaj_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_894707_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetayIcBaskiRenk_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_373446_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "EserDetaySelefon_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_19935_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ParaBirim_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_375595_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TeslimTuru_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_723274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "OneriSikayetKategori_List",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_152077_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("GenelParametreKart", "NewEditDisplay", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "GenelParametreKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_567043_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "LogList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_481394_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SikayetList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_402894_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SSS",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_20424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
    ] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.LeftMenuPageInit();
    }
  }
}
