import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashBoardMatbaa_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashBoardMatbaa_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  KullaniciLocalStorageSave: number;
  KullaniciLocalStorageSave_dummy: number;
  DB_GetDateRanges: any[];
  DB_GetDateRanges_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  DBMatbaa_TeklifTalepCount: any[];
  DBMatbaa_TeklifTalepCount_dummy: any[];
  DBMatbaa_TeklifTaslakCount: any[];
  DBMatbaa_TeklifTaslakCount_dummy: any[];
  DBMatbaa_TeklifOnayCount: any[];
  DBMatbaa_TeklifOnayCount_dummy: any[];
  DBMatbaa_TeklifOnaylananCount: any[];
  DBMatbaa_TeklifOnaylananCount_dummy: any[];
  DBMatbaa_TeklifMusteriOnayCount: any[];
  DBMatbaa_TeklifMusteriOnayCount_dummy: any[];
  DBMatbaa_TeklifOnaylanmayanCount: any[];
  DBMatbaa_TeklifOnaylanmayanCount_dummy: any[];
  DBMatbaa_SiparisTaslakCount: any[];
  DBMatbaa_SiparisTaslakCount_dummy: any[];
  DBMatbaa_SiparisOnayCount: any[];
  DBMatbaa_SiparisOnayCount_dummy: any[];
  DBMatbaa_SiparisOnaylananCount: any[];
  DBMatbaa_SiparisOnaylananCount_dummy: any[];
  DBMatbaa_SiparisOnaylanmayanCount: any[];
  DBMatbaa_SiparisOnaylanmayanCount_dummy: any[];
  DBMatbaa_UretimBaslayanCount: any[];
  DBMatbaa_UretimBaslayanCount_dummy: any[];
  DBMatbaa_UretimBitenCount: any[];
  DBMatbaa_UretimBitenCount_dummy: any[];
  ReadFromLocalStorage: string;
  ReadFromLocalStorage_dummy: string;
  LogSave: number;
  LogSave_dummy: number;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleMenuGoster: any[];
  RoleMenuGoster_dummy: any[];
  DB_GetDateRangesNames: any[];
  DB_GetDateRangesNames_dummy: any[];
  KullaniciLocalStorageByUsername: any[];
  KullaniciLocalStorageByUsername_dummy: any[];
  SetLanguage: string;
  SetLanguage_dummy: string;
  isComp626421Visible: "visible" | "hidden";
  isComp698467Visible: "visible" | "hidden";
  isComp986991Visible: "visible" | "hidden";
  isComp940729Visible: "visible" | "hidden";
  isComp237303Visible: "visible" | "hidden";
  isComp390536Visible: "visible" | "hidden";
  isComp172074Visible: "visible" | "hidden";
  isComp992256Visible: "visible" | "hidden";
  isComp667789Visible: "visible" | "hidden";
  isComp968888Visible: "visible" | "hidden";
  isComp68870Visible: "visible" | "hidden";
  isComp969727Visible: "visible" | "hidden";
}

export class DashBoardMatbaa_ScreenBase extends React.PureComponent<IDashBoardMatbaa_ScreenProps, any> {
  dashboardmatbaa_549532_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardmatbaa_827054_value_kuikaDateRef: React.RefObject<any>;
  dashboardmatbaa_927974_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ff62140f-d0de-4f3a-9f2d-1f5c75f3bcc5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 633443, PropertyName: "value", Value: "Matbaa Dashboard" },
        { Id: 881224, PropertyName: "label", Value: "Profil" },
        { Id: 310750, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 732900, PropertyName: "value", Value: "Dönem" },
        { Id: 549532, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 341919, PropertyName: "value", Value: "Başlangıç Tarihi" },
        { Id: 827054, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 679121, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 927974, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 2860, PropertyName: "value", Value: "Teklif" },
        { Id: 664286, PropertyName: "value", Value: "Teklif Verilmeyen Talep Sayısı" },
        { Id: 103344, PropertyName: "value", Value: "Taslak Teklif Sayısı" },
        { Id: 426017, PropertyName: "value", Value: "Onay Bekleyen Teklif Sayısı" },
        { Id: 957860, PropertyName: "value", Value: "Müşteri Onay Bekleyen Teklif Sayısı" },
        { Id: 276111, PropertyName: "value", Value: "Onaylanan Teklif Sayısı" },
        { Id: 422538, PropertyName: "value", Value: "Reddedilen Teklif Sayısı" },
        { Id: 438259, PropertyName: "value", Value: "Sipariş" },
        { Id: 868679, PropertyName: "value", Value: "Taslak Sipariş Sayısı" },
        { Id: 438054, PropertyName: "value", Value: "Onay Bekleyen Sipariş Sayısı" },
        { Id: 89834, PropertyName: "value", Value: "Onaylanan Sipariş Sayısı" },
        { Id: 643230, PropertyName: "value", Value: "Reddedilen Sipariş Sayısı" },
        { Id: 494223, PropertyName: "value", Value: "Üretim" },
        { Id: 327057, PropertyName: "value", Value: "Üretim İşlemleri Başlatılan" },
        { Id: 235378, PropertyName: "value", Value: "Üretim İşlemleri Biten" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardmatbaa_549532_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardmatbaa_827054_value_kuikaDateRef = React.createRef();
    this.dashboardmatbaa_927974_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      KullaniciLocalStorageSave: 0,
      DB_GetDateRanges: [],
      SetValueOf: "",
      TriggerEvent: "",
      DBMatbaa_TeklifTalepCount: [],
      DBMatbaa_TeklifTaslakCount: [],
      DBMatbaa_TeklifOnayCount: [],
      DBMatbaa_TeklifOnaylananCount: [],
      DBMatbaa_TeklifMusteriOnayCount: [],
      DBMatbaa_TeklifOnaylanmayanCount: [],
      DBMatbaa_SiparisTaslakCount: [],
      DBMatbaa_SiparisOnayCount: [],
      DBMatbaa_SiparisOnaylananCount: [],
      DBMatbaa_SiparisOnaylanmayanCount: [],
      DBMatbaa_UretimBaslayanCount: [],
      DBMatbaa_UretimBitenCount: [],
      ReadFromLocalStorage: "",
      LogSave: 0,
      MyContext: [],
      RoleMenuGoster: [],
      DB_GetDateRangesNames: [],
      KullaniciLocalStorageByUsername: [],
      SetLanguage: "",
      isComp626421Visible: "hidden",
      isComp698467Visible: "hidden",
      isComp986991Visible: "hidden",
      isComp940729Visible: "hidden",
      isComp237303Visible: "hidden",
      isComp390536Visible: "hidden",
      isComp172074Visible: "hidden",
      isComp992256Visible: "hidden",
      isComp667789Visible: "hidden",
      isComp968888Visible: "hidden",
      isComp68870Visible: "hidden",
      isComp969727Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardmatbaa", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashBoardMatbaaPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardmatbaa", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardmatbaa", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashBoardMatbaaPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardmatbaa_81624_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      dashboardmatbaa_549532_value: this.state.KullaniciLocalStorageByUsername?.at?.(0)?.sonTarihAraligi ?? undefined,
      dashboardmatbaa_827054_value: this.state.DB_GetDateRanges?.at?.(0)?.baslangic ?? undefined,
      dashboardmatbaa_927974_value: this.state.DB_GetDateRanges?.at?.(0)?.bitis ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashBoardMatbaaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("LogGuid");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardMatbaaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      KeyIdValue_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      OperationName_0: ReactSystemFunctions.convertToTypeByName("Giriş Başarılı!", "string"),
      OperationTime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TableName_0: ReactSystemFunctions.convertToTypeByName("Sisteme Giriş", "string"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toGuid(this, this.state.ReadFromLocalStorage),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      userName_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardMatbaa/DashBoardMatbaaPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.LogSave = result?.data.logSave;
    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardmatbaa_81624_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.dashboardmatbaa_980974_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.dashboardmatbaa_570080_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    stateVars.isComp626421Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698467Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp986991Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp940729Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m042 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp237303Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m042 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m044 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp992256Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m044 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp667789Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m046 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp968888Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m046 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp68870Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m071 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969727Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0 ? stateVars.RoleMenuGoster[0]?.m081 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DB_GetDateRangesNames = result?.data.dB_GetDateRangesNames;

    formVars.dashboardmatbaa_549532_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null;
    formVars.dashboardmatbaa_549532_options = stateVars.DB_GetDateRangesNames;
    stateVars.KullaniciLocalStorageByUsername = result?.data.kullaniciLocalStorageByUsername;
    formVars.dashboardmatbaa_549532_value =
      stateVars.KullaniciLocalStorageByUsername?.length > 0
        ? stateVars.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null;
    formVars.dashboardmatbaa_549532_options = stateVars.DB_GetDateRangesNames;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardmatbaa_827054_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.baslangic : null
    );
    formVars.dashboardmatbaa_927974_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.bitis : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMatbaaPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmatbaa_827054_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmatbaa_927974_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashBoardMatbaaPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashBoardMatbaa/DashBoardMatbaaPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTalepCount = result?.data.dbMatbaa_TeklifTalepCount;
    formVars.dashboardmatbaa_976647_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTalepCount?.length > 0 ? stateVars.DBMatbaa_TeklifTalepCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardmatbaa_584436_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0 ? stateVars.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardmatbaa_494803_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0 ? stateVars.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardmatbaa_685769_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifMusteriOnayCount = result?.data.dbMatbaa_TeklifMusteriOnayCount;
    formVars.dashboardmatbaa_787877_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifMusteriOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifMusteriOnayCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardmatbaa_179065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardmatbaa_811980_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0 ? stateVars.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardmatbaa_489292_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0 ? stateVars.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardmatbaa_839990_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardmatbaa_84856_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardmatbaa_535199_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0 ? stateVars.DBMatbaa_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardmatbaa_207087_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0 ? stateVars.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMatbaaPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    formVars.dashboardmatbaa_81624_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.dashboardmatbaa_980974_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.dashboardmatbaa_570080_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    formVars.dashboardmatbaa_549532_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciLocalStorageByUsername?.length > 0
        ? this.state.KullaniciLocalStorageByUsername[0]?.sonTarihAraligi
        : null
    );

    stateVars.dataSource_549532 = this.state.DB_GetDateRangesNames;
    stateVars.dataSource_549532 = this.state.DB_GetDateRangesNames;
    formVars.dashboardmatbaa_827054_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
    );

    formVars.dashboardmatbaa_927974_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
    );

    formVars.dashboardmatbaa_976647_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTalepCount?.length > 0 ? this.state.DBMatbaa_TeklifTalepCount[0]?.recordCount : null
    );

    stateVars.isComp626421Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_584436_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTaslakCount?.length > 0 ? this.state.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );

    stateVars.isComp698467Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_494803_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnayCount?.length > 0 ? this.state.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );

    stateVars.isComp986991Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_787877_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifMusteriOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifMusteriOnayCount[0]?.recordCount
        : null
    );

    stateVars.isComp940729Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m042 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_685769_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    stateVars.isComp237303Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m042 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_179065_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    stateVars.isComp390536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m022 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_811980_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisTaslakCount?.length > 0 ? this.state.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );

    stateVars.isComp172074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m044 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_489292_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnayCount?.length > 0 ? this.state.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );

    stateVars.isComp992256Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m044 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_839990_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    stateVars.isComp667789Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m046 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_84856_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    stateVars.isComp968888Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m046 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_535199_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0]?.recordCount
        : null
    );

    stateVars.isComp68870Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m071 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardmatbaa_207087_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBitenCount?.length > 0 ? this.state.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );

    stateVars.isComp969727Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleMenuGoster?.length > 0 ? this.state.RoleMenuGoster[0]?.m081 : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_284229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "997406",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_881224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "236690",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_310750_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_549532_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SonTarihAraligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardmatbaa_549532_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      ),
      userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      RangeName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardmatbaa_549532_value",
            "value",
            "DB_GetDateRangesNames",
            "rangeName",
            "rangeName"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardMatbaa/DashBoardMatbaaComponent_549532_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciLocalStorageSave = result?.data.kullaniciLocalStorageSave;
    stateVars.DB_GetDateRanges = result?.data.dB_GetDateRanges;
    formVars.dashboardmatbaa_827054_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.baslangic : null
    );
    formVars.dashboardmatbaa_927974_value = ReactSystemFunctions.value(
      this,
      stateVars.DB_GetDateRanges?.length > 0 ? stateVars.DB_GetDateRanges[0]?.bitis : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaComponent_549532_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMatbaaComponent_549532_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmatbaa_827054_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardmatbaa_927974_value",
      ReactSystemFunctions.value(
        this,
        this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
      ),
      null
    );
    await this.DashBoardMatbaaPageInit();

    formVars.dashboardmatbaa_827054_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.baslangic : null
    );

    formVars.dashboardmatbaa_927974_value = ReactSystemFunctions.value(
      this,
      this.state.DB_GetDateRanges?.length > 0 ? this.state.DB_GetDateRanges[0]?.bitis : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_827054_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardMatbaa/DashBoardMatbaaComponent_827054_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTalepCount = result?.data.dbMatbaa_TeklifTalepCount;
    formVars.dashboardmatbaa_976647_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTalepCount?.length > 0 ? stateVars.DBMatbaa_TeklifTalepCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardmatbaa_584436_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0 ? stateVars.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardmatbaa_494803_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0 ? stateVars.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardmatbaa_685769_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifMusteriOnayCount = result?.data.dbMatbaa_TeklifMusteriOnayCount;
    formVars.dashboardmatbaa_787877_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifMusteriOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifMusteriOnayCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardmatbaa_179065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardmatbaa_811980_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0 ? stateVars.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardmatbaa_489292_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0 ? stateVars.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardmatbaa_839990_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardmatbaa_84856_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardmatbaa_535199_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0 ? stateVars.DBMatbaa_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardmatbaa_207087_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0 ? stateVars.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaComponent_827054_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMatbaaComponent_827054_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardmatbaa_976647_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTalepCount?.length > 0 ? this.state.DBMatbaa_TeklifTalepCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_584436_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTaslakCount?.length > 0 ? this.state.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_494803_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnayCount?.length > 0 ? this.state.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_787877_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifMusteriOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifMusteriOnayCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_685769_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_179065_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_811980_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisTaslakCount?.length > 0 ? this.state.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_489292_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnayCount?.length > 0 ? this.state.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_839990_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_84856_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_535199_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_207087_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBitenCount?.length > 0 ? this.state.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_927974_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      ),
      UyeId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      BasT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_827054_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitT_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardmatbaa_927974_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashBoardMatbaa/DashBoardMatbaaComponent_927974_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DBMatbaa_TeklifTalepCount = result?.data.dbMatbaa_TeklifTalepCount;
    formVars.dashboardmatbaa_976647_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTalepCount?.length > 0 ? stateVars.DBMatbaa_TeklifTalepCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifTaslakCount = result?.data.dbMatbaa_TeklifTaslakCount;
    formVars.dashboardmatbaa_584436_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifTaslakCount?.length > 0 ? stateVars.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnayCount = result?.data.dbMatbaa_TeklifOnayCount;
    formVars.dashboardmatbaa_494803_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnayCount?.length > 0 ? stateVars.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_TeklifOnaylananCount = result?.data.dbMatbaa_TeklifOnaylananCount;
    formVars.dashboardmatbaa_685769_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifMusteriOnayCount = result?.data.dbMatbaa_TeklifMusteriOnayCount;
    formVars.dashboardmatbaa_787877_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifMusteriOnayCount?.length > 0
        ? stateVars.DBMatbaa_TeklifMusteriOnayCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_TeklifOnaylanmayanCount = result?.data.dbMatbaa_TeklifOnaylanmayanCount;
    formVars.dashboardmatbaa_179065_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisTaslakCount = result?.data.dbMatbaa_SiparisTaslakCount;
    formVars.dashboardmatbaa_811980_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisTaslakCount?.length > 0 ? stateVars.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnayCount = result?.data.dbMatbaa_SiparisOnayCount;
    formVars.dashboardmatbaa_489292_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnayCount?.length > 0 ? stateVars.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_SiparisOnaylananCount = result?.data.dbMatbaa_SiparisOnaylananCount;
    formVars.dashboardmatbaa_839990_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_SiparisOnaylanmayanCount = result?.data.dbMatbaa_SiparisOnaylanmayanCount;
    formVars.dashboardmatbaa_84856_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? stateVars.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );
    stateVars.DBMatbaa_UretimBaslayanCount = result?.data.dbMatbaa_UretimBaslayanCount;
    formVars.dashboardmatbaa_535199_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBaslayanCount?.length > 0 ? stateVars.DBMatbaa_UretimBaslayanCount[0]?.recordCount : null
    );
    stateVars.DBMatbaa_UretimBitenCount = result?.data.dbMatbaa_UretimBitenCount;
    formVars.dashboardmatbaa_207087_value = ReactSystemFunctions.toString(
      this,
      stateVars.DBMatbaa_UretimBitenCount?.length > 0 ? stateVars.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashBoardMatbaaComponent_927974_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashBoardMatbaaComponent_927974_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardmatbaa_976647_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTalepCount?.length > 0 ? this.state.DBMatbaa_TeklifTalepCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_584436_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifTaslakCount?.length > 0 ? this.state.DBMatbaa_TeklifTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_494803_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnayCount?.length > 0 ? this.state.DBMatbaa_TeklifOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_787877_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifMusteriOnayCount?.length > 0
        ? this.state.DBMatbaa_TeklifMusteriOnayCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_685769_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylananCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_179065_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_TeklifOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_TeklifOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_811980_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisTaslakCount?.length > 0 ? this.state.DBMatbaa_SiparisTaslakCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_489292_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnayCount?.length > 0 ? this.state.DBMatbaa_SiparisOnayCount[0]?.recordCount : null
    );

    formVars.dashboardmatbaa_839990_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylananCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylananCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_84856_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_SiparisOnaylanmayanCount?.length > 0
        ? this.state.DBMatbaa_SiparisOnaylanmayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_535199_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBaslayanCount?.length > 0
        ? this.state.DBMatbaa_UretimBaslayanCount[0]?.recordCount
        : null
    );

    formVars.dashboardmatbaa_207087_value = ReactSystemFunctions.toString(
      this,
      this.state.DBMatbaa_UretimBitenCount?.length > 0 ? this.state.DBMatbaa_UretimBitenCount[0]?.recordCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_626421_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "TeklifVermeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_698467_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "TeklifVermeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_986991_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "TeklifVermeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_940729_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "EseriOnaylananTeklifListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_237303_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "EseriOnaylananTeklifListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_390536_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "TeklifVermeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_172074_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "TSiparisListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_992256_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "TSiparisListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_667789_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "SiparisListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_968888_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "SiparisListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_68870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UretimListMatbaa", "prmSiparisNo", "");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "UretimListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashBoardMatbaaComponent_969727_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashBoardMatbaa",
      "SiparisTeslimListMatbaa",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [997406, 236690, , , , , , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashBoardMatbaaPageInit();
    }
  }
}
