import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISiparisTeslimList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISiparisTeslimList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  SiparisTeslimTakipList: any[];
  SiparisTeslimTakipList_dummy: any[];
  setPTotalCount01: any[];
  setPTotalCount01_dummy: any[];
  SiparisTeslimTakipListToExcel: any[];
  SiparisTeslimTakipListToExcel_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  isComp787881Visible: "visible" | "hidden";
  isComp948245Visible: "visible" | "hidden";
  isComp362765Visible: "visible" | "hidden";
  isComp439282Visible: "visible" | "hidden";
  isComp109317Visible: "visible" | "hidden";
  isComp913087Visible: "visible" | "hidden";
  isComp145511Visible: "visible" | "hidden";
  isComp207396Visible: "visible" | "hidden";
  isComp600415Visible: "visible" | "hidden";
  isComp949801Visible: "visible" | "hidden";
}

export class SiparisTeslimList_ScreenBase extends React.PureComponent<ISiparisTeslimList_ScreenProps, any> {
  siparisteslimlist_490142_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisteslimlist_966563_value_kuikaSelectBoxRef: React.RefObject<any>;
  siparisteslimlist_348605_value_kuikaTableRef: React.RefObject<any>;
  siparisteslimlist_880048_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "54d341f8-8275-464f-ba9f-89349a7dac82",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364094, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi" },
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 447703, PropertyName: "value", Value: "Teslimi tamamlanan kayıtları da göster" },
        { Id: 490142, PropertyName: "placeholder", Value: "Sıralama" },
        { Id: 966563, PropertyName: "placeholder", Value: "A-z" },
        { Id: 95636, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 348605, PropertyName: "nodatafoundmessage", Value: "Sipariş Teslim Kaydı Bulunamadı." },
        { Id: 27045, PropertyName: "title", Value: "Talep No" },
        { Id: 736543, PropertyName: "value", Value: "[datafield:talep_no]" },
        { Id: 476271, PropertyName: "title", Value: "Teklif No" },
        { Id: 391582, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 71915, PropertyName: "title", Value: "Sipariş No" },
        { Id: 192399, PropertyName: "value", Value: "[datafield:siparis_no]" },
        { Id: 833287, PropertyName: "title", Value: "Firma" },
        { Id: 534463, PropertyName: "value", Value: "[datafield:firma]" },
        { Id: 256601, PropertyName: "title", Value: "Kitap Adı" },
        { Id: 744431, PropertyName: "value", Value: "[datafield:kitapad]" },
        { Id: 831413, PropertyName: "title", Value: "Ebat" },
        { Id: 607456, PropertyName: "value", Value: "[datafield:kitapboyuttanim]" },
        { Id: 443115, PropertyName: "title", Value: "Kağıt" },
        { Id: 4509, PropertyName: "value", Value: "[datafield:icbaskikagitcinsi]" },
        { Id: 588416, PropertyName: "title", Value: "Kağıt Gramaj" },
        { Id: 483237, PropertyName: "value", Value: "[datafield:icbaskikagitgramaj]" },
        { Id: 160919, PropertyName: "title", Value: "Kapak" },
        { Id: 440348, PropertyName: "value", Value: "[datafield:kapakcinsi]" },
        { Id: 951325, PropertyName: "title", Value: "Kapak Gramaj" },
        { Id: 818291, PropertyName: "value", Value: "[datafield:kapakgramaj]" },
        { Id: 902025, PropertyName: "title", Value: "Cilt Biçimi" },
        { Id: 57233, PropertyName: "value", Value: "[datafield:ciltbicimi]" },
        { Id: 869134, PropertyName: "title", Value: "Renk" },
        { Id: 438848, PropertyName: "value", Value: "[datafield:icbaskirenk]" },
        { Id: 416909, PropertyName: "title", Value: "Selefon" },
        { Id: 431135, PropertyName: "value", Value: "[datafield:selefon]" },
        { Id: 904525, PropertyName: "title", Value: "Sayfa Sayısı" },
        { Id: 766629, PropertyName: "value", Value: "[datafield:sayfasayisi]" },
        { Id: 52036, PropertyName: "title", Value: "Baskı Adet" },
        { Id: 257784, PropertyName: "value", Value: "[datafield:baskiadet]" },
        { Id: 713444, PropertyName: "title", Value: "Bandrol" },
        { Id: 329790, PropertyName: "value", Value: "[datafield:bandrol]" },
        { Id: 832752, PropertyName: "title", Value: "Tıpkı Basım mı?" },
        { Id: 549220, PropertyName: "value", Value: "[datafield:tipkibasimmi]" },
        { Id: 243022, PropertyName: "title", Value: "İç Görsel Geliş Tarihi" },
        { Id: 938169, PropertyName: "value", Value: "[datafield:icgorseleklenmemaxtarihi]" },
        { Id: 932249, PropertyName: "title", Value: "İç Görsel Onay/Montaj Tarihi" },
        { Id: 855208, PropertyName: "value", Value: "[datafield:icgorselonaytarihi]" },
        { Id: 923038, PropertyName: "title", Value: "Kapak Görsel Geliş Tarihi" },
        { Id: 810248, PropertyName: "value", Value: "[datafield:kapakgorseleklenmemaxtarihi]" },
        { Id: 209971, PropertyName: "title", Value: "Kapak Görsel Onay/Montaj Tarihi" },
        { Id: 617, PropertyName: "value", Value: "[datafield:kapakgorselonaytarihi]" },
        { Id: 990986, PropertyName: "title", Value: "İç Görsel Baskı Tarihi" },
        { Id: 252593, PropertyName: "value", Value: "[datafield:icgorselbaskitarihi]" },
        { Id: 260064, PropertyName: "title", Value: "Kapak Görsel Baskı Tarihi" },
        { Id: 26097, PropertyName: "value", Value: "[datafield:kapakgorselbaskitarihi]" },
        { Id: 760411, PropertyName: "title", Value: "Kapak Takma/Diliş Tarihi" },
        { Id: 259693, PropertyName: "value", Value: "[datafield:ciltlemetarihi]" },
        { Id: 46727, PropertyName: "title", Value: "Son Bıçak Tarihi" },
        { Id: 802076, PropertyName: "value", Value: "[datafield:sonbicaktarihi]" },
        { Id: 389888, PropertyName: "title", Value: "Bandrol Yapıştırıldı" },
        { Id: 591296, PropertyName: "title", Value: "Bandrol Yapıştırma Tarihi" },
        { Id: 149235, PropertyName: "value", Value: "[datafield:bandroltarihi]" },
        { Id: 192668, PropertyName: "title", Value: "Depoya Teslim Edildi" },
        { Id: 432961, PropertyName: "title", Value: "Depo Teslim Tarihi" },
        { Id: 5490, PropertyName: "value", Value: "[datafield:depoteslimtarihi]" },
        { Id: 155154, PropertyName: "title", Value: "B.Yapıştırma Adet" },
        { Id: 70833, PropertyName: "value", Value: "[datafield:bandroladetyapistirma]" },
        { Id: 562756, PropertyName: "title", Value: "İrsaliye No" },
        { Id: 822827, PropertyName: "value", Value: "[datafield:irsaliyeno]" },
        { Id: 568154, PropertyName: "title", Value: "Teslimat Şekli" },
        { Id: 210954, PropertyName: "value", Value: "[datafield:teslimturu]" },
        { Id: 360809, PropertyName: "title", Value: "Kargo Firma" },
        { Id: 381898, PropertyName: "value", Value: "[datafield:kargofirma]" },
        { Id: 849767, PropertyName: "title", Value: "Kargolama Tarihi" },
        { Id: 239822, PropertyName: "value", Value: "[datafield:kargotarihi]" },
        { Id: 304198, PropertyName: "title", Value: "Kargo No" },
        { Id: 414888, PropertyName: "value", Value: "[datafield:kargono]" },
        { Id: 475475, PropertyName: "title", Value: "Gönderildi/Teslim Edildi" },
        { Id: 227367, PropertyName: "title", Value: "Teslim Alındı" },
        { Id: 486407, PropertyName: "title", Value: "Teslim Tarihi" },
        { Id: 821358, PropertyName: "value", Value: "[datafield:teslimtarihi]" },
        { Id: 588951, PropertyName: "title", Value: "Teslim Alan" },
        { Id: 836208, PropertyName: "value", Value: "[datafield:teslimalanfullname]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  SiparisTeslimListRecuringAction_30_Interval: any;

  constructor(props) {
    super(props);
    this.siparisteslimlist_490142_value_kuikaSelectBoxRef = React.createRef();
    this.siparisteslimlist_966563_value_kuikaSelectBoxRef = React.createRef();
    this.siparisteslimlist_348605_value_kuikaTableRef = React.createRef();
    this.siparisteslimlist_880048_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      RoleSadeceGoruntule: [],
      SiparisTeslimTakipList: [],
      setPTotalCount01: [],
      SiparisTeslimTakipListToExcel: [],
      ExportExcel: "",
      dataSource_490142: [
        { key: "TeklifNo", text: "Teklif No" },
        { key: "TalepNo", text: "Talep No" },
        { key: "SiparisNo", text: "Sipariş No" },
        { key: "Firma", text: "Firma" },
        { key: "IcGorselBaskiTarihi", text: "İç Görsel Baskı Tarihi" },
        { key: "KapakGorselBaskiTarihi", text: "Kapak Görsel Baskı Tarihi" },
        { key: "CiltlemeTarihi", text: "Ciltleme Tarihi" },
        { key: "SonBicakTarihi", text: "Son Bıçak Tarihi" },
        { key: "BandrolTarihi", text: "Bandrol Tarihi" },
        { key: "DepoTeslimTarihi", text: "Depo Teslim Tarihi" },
        { key: "TeslimTarihi", text: "Müşteri Teslim Tarihi" }
      ],
      dataSource_966563: [
        { key: "ASC", text: "A-Z" },
        { key: "DESC", text: "Z-A" }
      ],
      isComp787881Visible: "hidden",
      isComp948245Visible: "hidden",
      isComp362765Visible: "hidden",
      isComp439282Visible: "hidden",
      isComp109317Visible: "hidden",
      isComp913087Visible: "hidden",
      isComp145511Visible: "hidden",
      isComp207396Visible: "hidden",
      isComp600415Visible: "hidden",
      isComp949801Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("siparisteslimlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SiparisTeslimListPageInit();
    }

    if (false) {
      if (false) {
        await this.SiparisTeslimListRecuringAction_30_();
      }
      this.SiparisTeslimListRecuringAction_30_Interval = setInterval(async () => {
        await this.SiparisTeslimListRecuringAction_30_();
      }, 30000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("siparisteslimlist", "");
  }

  componentWillUnmount() {
    if (this.SiparisTeslimListRecuringAction_30_Interval) {
      clearInterval(this.SiparisTeslimListRecuringAction_30_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("siparisteslimlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SiparisTeslimListPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (false && true) {
        await this.SiparisTeslimListRecuringAction_30_();
      }
      this.SiparisTeslimListRecuringAction_30_Interval = setInterval(async () => {
        await this.SiparisTeslimListRecuringAction_30_();
      }, 30000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      siparisteslimlist_801926_value: this.state.MyContext?.at?.(0)?.profilFoto ?? undefined,
      siparisteslimlist_278541_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SiparisTeslimListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_2: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      TeslimTamamGoster_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SiparisTeslimList/SiparisTeslimListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.siparisteslimlist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.siparisteslimlist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.siparisteslimlist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    stateVars.isComp600415Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0 ? stateVars.RoleSadeceGoruntule[0]?.sg082 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.siparisteslimlist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.siparisteslimlist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.siparisteslimlist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;
    stateVars.isComp787881Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isBandroIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp948245Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isBandroIconsVisibility"),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp362765Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTeslimIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439282Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTeslimIconsVisibility"),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp109317Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTeslimGonderildiIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp913087Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTeslimGonderildiIconsVisibility"),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145511Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTeslimAlindiIconsVisibility"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207396Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTeslimAlindiIconsVisibility"),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600415Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RoleSadeceGoruntule?.length > 0 ? this.state.RoleSadeceGoruntule[0]?.sg082 : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp949801Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "isTakipMuhatapEditIconsVisibility"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.siparisteslimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SiparisTeslimListComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisTeslimList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "242761",
      null,
      "left",
      null,
      "480px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisTeslimList",
      "Profil",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "415042",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_278541_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeslimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListComponent_278541_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListComponent_278541_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListComponent_278541_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;
    formVars.siparisteslimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_490142_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeslimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListComponent_490142_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListComponent_490142_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListComponent_490142_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;
    formVars.siparisteslimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_966563_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeslimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListComponent_966563_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListComponent_966563_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListComponent_966563_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;
    formVars.siparisteslimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_489370_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeslimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListComponent_489370_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListComponent_489370_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListComponent_489370_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;
    formVars.siparisteslimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_241439_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeslimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListComponent_241439_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisTeslimTakipListToExcel = result?.data.siparisTeslimTakipListToExcel;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SiparisTeslimListComponent_949801_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SiparisTeslimDetayKart",
      "prmTeklifTalepKartID",
      ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "teklifTalepKartId")
    );
    KuikaAppManager.addToPageInputVariables(
      "SiparisTeslimDetayKart",
      "siparisNo",
      ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "siparis_No")
    );
    KuikaAppManager.addToPageInputVariables(
      "SiparisTeslimDetayKart",
      "prmBandrolYapistirmaID",
      ReactSystemFunctions.value(this, "siparisteslimlist_348605_value", "bandrolYapistirmaId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SiparisTeslimList",
      "SiparisTeslimDetayKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "142528",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListComponent_880048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MuhatapmiFirmami_0: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeId : null),
        "Guid"
      ),
      TeslimTamamGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListComponent_880048_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListComponent_880048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListComponent_880048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SiparisTeslimListRecuringAction_30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      MuhatapmiFirmami_1: ReactSystemFunctions.convertToTypeByName("Muhatap", "string"),
      TeslimTamamGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_278541_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ara_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_95636_value", "value", "", "", "")
        ),
        "string"
      ),
      OrderByColumn_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_490142_value", "value", "", "key", "")
        ),
        "string"
      ),
      AscDesc_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_966563_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "siparisteslimlist_880048_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (false) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SiparisTeslimList/SiparisTeslimListRecuringAction_30_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.siparisteslimlist_801926_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.profilFoto : null
    );
    formVars.siparisteslimlist_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.fullName : null
    );
    formVars.siparisteslimlist_590045_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.uyeAd : null
    );
    stateVars.SiparisTeslimTakipList = result?.data.siparisTeslimTakipList;

    stateVars.setPTotalCount01 = result?.data.setPTotalCount01;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SiparisTeslimListRecuringAction_30_1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SiparisTeslimListRecuringAction_30_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.siparisteslimlist_801926_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.profilFoto : null
    );

    formVars.siparisteslimlist_172357_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.fullName : null
    );

    formVars.siparisteslimlist_590045_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null
    );

    stateVars.dataSource_348605 = this.state.SiparisTeslimTakipList;
    formVars.siparisteslimlist_880048_total = ReactSystemFunctions.value(
      this,
      this.state.setPTotalCount01?.length > 0 ? this.state.setPTotalCount01[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [242761, 415042, 142528] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SiparisTeslimListPageInit();
    }
  }
}
