import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVerilenTeklifKarsilastirma_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVerilenTeklifKarsilastirma_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  VerilenTeklifKarsilastirmaList: any[];
  VerilenTeklifKarsilastirmaList_dummy: any[];
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  AlinanTeklifOnay: number;
  AlinanTeklifOnay_dummy: number;
  UyelikTipiLowercase: any[];
  UyelikTipiLowercase_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  isComp150386Visible: "visible" | "hidden";
}

export class VerilenTeklifKarsilastirma_ScreenBase extends React.PureComponent<
  IVerilenTeklifKarsilastirma_ScreenProps,
  any
> {
  verilenteklifkarsilastirma_922676_value_kuikaGalleryViewRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b834f2ef-d94b-4f06-a885-4e31d252a7b0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 364938, PropertyName: "value", Value: "Verilen Teklifler" },
        { Id: 922676, PropertyName: "nodatafoundmessage", Value: "Teklif Bulunamadı!" },
        { Id: 880694, PropertyName: "value", Value: "Firma" },
        { Id: 242082, PropertyName: "title", Value: "[datafield:sirketad]" },
        { Id: 351552, PropertyName: "value", Value: "[datafield:sirketad]" },
        { Id: 391106, PropertyName: "value", Value: "Teklif No" },
        { Id: 345671, PropertyName: "value", Value: "[datafield:teklif_no]" },
        { Id: 736210, PropertyName: "value", Value: "Teklif Tarihi" },
        { Id: 582945, PropertyName: "value", Value: "[datafield:tekliftarihi]" },
        { Id: 700970, PropertyName: "value", Value: "Geçerlilik Tarihi" },
        { Id: 303288, PropertyName: "value", Value: "[datafield:teklifgecerliliktarihi]" },
        { Id: 39173, PropertyName: "value", Value: "Birim Fiyatı" },
        { Id: 668452, PropertyName: "value", Value: "[datafield:teklifbirimfiyat]" },
        { Id: 88383, PropertyName: "value", Value: "Teklif Fiyatı" },
        { Id: 692138, PropertyName: "value", Value: "[datafield:tekliffiyat]" },
        { Id: 312308, PropertyName: "value", Value: "Kur" },
        { Id: 313774, PropertyName: "value", Value: "[datafield:kur]" },
        { Id: 954231, PropertyName: "value", Value: "İndirim (%)" },
        { Id: 764473, PropertyName: "value", Value: "[datafield:indirimyuzde]" },
        { Id: 648871, PropertyName: "value", Value: "TRY Tutar" },
        { Id: 936910, PropertyName: "value", Value: "[datafield:tekliffiyatytl]" },
        { Id: 3864, PropertyName: "value", Value: "TRY İndirimli Tutar" },
        { Id: 633612, PropertyName: "value", Value: "[datafield:teklifindirimlifiyatytl]" },
        { Id: 619827, PropertyName: "value", Value: '"Fiyatlara KDV dahil değildir."' },
        { Id: 689512, PropertyName: "value", Value: "diğer Koşullar" },
        { Id: 674119, PropertyName: "value", Value: "[datafield:digerkosullar]" },
        { Id: 107637, PropertyName: "value", Value: "Teslim Türü" },
        { Id: 476655, PropertyName: "value", Value: "[datafield:teslimturu]" },
        { Id: 653900, PropertyName: "value", Value: "Notlar" },
        { Id: 882463, PropertyName: "title", Value: "[datafield:aciklama]" },
        { Id: 178531, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 150386, PropertyName: "label", Value: "Seç" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.verilenteklifkarsilastirma_922676_value_kuikaGalleryViewRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      MyContext: [],
      VerilenTeklifKarsilastirmaList: [],
      GenelParametreler: [],
      AlinanTeklifOnay: 0,
      UyelikTipiLowercase: [],
      SendMailSettings: [],
      SendMail: false,
      Notify: false,
      isComp150386Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("verilenteklifkarsilastirma", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.VerilenTeklifKarsilastirmaPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("verilenteklifkarsilastirma", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("verilenteklifkarsilastirma", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.VerilenTeklifKarsilastirmaPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  VerilenTeklifKarsilastirmaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      TeklifTalepKartID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TeklifTalepKartID ?? this.props.screenInputs.tekliftalepkartid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VerilenTeklifKarsilastirma/VerilenTeklifKarsilastirmaPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.VerilenTeklifKarsilastirmaList = result?.data.verilenTeklifKarsilastirmaList;

    stateVars.isComp150386Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VerilenTeklifKarsilastirmaList?.length > 0
            ? stateVars.VerilenTeklifKarsilastirmaList[0]?.isTeklifSecRowVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GenelParametreler = result?.data.genelParametreler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.VerilenTeklifKarsilastirmaPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VerilenTeklifKarsilastirmaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_922676 = this.state.VerilenTeklifKarsilastirmaList;
    stateVars.isComp150386Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.VerilenTeklifKarsilastirmaList?.length > 0
            ? this.state.VerilenTeklifKarsilastirmaList[0]?.isTeklifSecRowVisibility
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  VerilenTeklifKarsilastirmaComponent_732103_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TeklifTalepKart",
      "prmID",
      this.props.screenInputs.TeklifTalepKartID ?? this.props.screenInputs.tekliftalepkartid
    );
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "NewEditDisplay", "D");
    KuikaAppManager.addToPageInputVariables("TeklifTalepKart", "HizlimiListemi", "L");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VerilenTeklifKarsilastirma",
      "TeklifTalepKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "841732",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VerilenTeklifKarsilastirmaComponent_521698_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VerilenTeklifKarsilastirmaComponent_150386_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TeklifOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      TeklifOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserId : null),
        "Guid"
      ),
      TeklifTalepKartID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "verilenteklifkarsilastirma_922676_value", "teklifTalepKartID"),
        "Guid"
      ),
      TeklifKartId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "verilenteklifkarsilastirma_922676_value", "teklifKartId"),
        "Guid"
      ),
      UyelikTipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      actionkey_2: ReactSystemFunctions.convertToTypeByName("teklifkabultomatbaa", "string"),
      uyeliktipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyelikTipi : null
        ),
        "string"
      ),
      numara_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "verilenteklifkarsilastirma_922676_value", "teklif_No"),
        "string"
      ),
      templateId_3: ReactSystemFunctions.convertToTypeByName("af9d8652-99b9-44a7-9a3c-e1e3b7129124", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.VerilenTeklifKarsilastirmaList?.length > 0
            ? this.state.VerilenTeklifKarsilastirmaList[0]?.userName
            : null
        ),
        "string"
      ),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GenelParametreler?.length > 0 ? this.state.GenelParametreler[0]?.destekTelNo : null
        ),
        "string"
      ),
      UyeIsmi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.VerilenTeklifKarsilastirmaList?.length > 0
            ? this.state.VerilenTeklifKarsilastirmaList[0]?.sirketAd
            : null
        ),
        "string"
      ),
      UyeSoyad_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      FirmaAdi_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.uyeAd : null),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "804d0f84_7148_183f_2c8c_755860681f88_confirmation",
        this.defaultML,
        "Seçilen Teklif Onaylanacak; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "VerilenTeklifKarsilastirma/VerilenTeklifKarsilastirmaComponent_150386_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AlinanTeklifOnay = result?.data.alinanTeklifOnay;
      stateVars.UyelikTipiLowercase = result?.data.uyelikTipiLowercase;
      stateVars.SendMailSettings = result?.data.sendMailSettings;
      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.VerilenTeklifKarsilastirmaComponent_150386_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VerilenTeklifKarsilastirmaComponent_150386_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4825ee5c_1f7b_ee61_1f4d_9092e66233e2_notify",
        this.defaultML,
        "Teklif Onaylandı!"
      ),
      "success",
      "bottom-right",
      1,
      null,
      null,
      null
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [841732] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.VerilenTeklifKarsilastirmaPageInit();
    }
  }
}
