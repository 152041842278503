import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserDetayCiltBicimi_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserDetayCiltBicimi_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  EserDetay_CiltBicimiSelectById: any[];
  EserDetay_CiltBicimiSelectById_dummy: any[];
  EserDetay_CiltBicimiSave: number;
  EserDetay_CiltBicimiSave_dummy: number;
  isComp3555606Visible: "visible" | "hidden";
}

export class EserDetayCiltBicimi_Form_ScreenBase extends React.PureComponent<
  IEserDetayCiltBicimi_Form_ScreenProps,
  any
> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0c5f2670-4a4a-45f5-92bd-6ebf64ae222e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 3314718, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 9889888, PropertyName: "value", Value: "Cilt bicimi key" },
        { Id: 384350, PropertyName: "value", Value: "Aktif" },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      EserDetay_CiltBicimiSelectById: [],
      EserDetay_CiltBicimiSave: 0,
      isComp3555606Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserdetayciltbicimi_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserDetayCiltBicimi_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserdetayciltbicimi_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserdetayciltbicimi_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserDetayCiltBicimi_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      eserdetayciltbicimi_form_3738153_value:
        this.state.EserDetay_CiltBicimiSelectById?.at?.(0)?.ciltBicimi ?? undefined,
      eserdetayciltbicimi_form_6354225_value:
        this.state.EserDetay_CiltBicimiSelectById?.at?.(0)?.ciltBicimiKey ?? undefined,
      eserdetayciltbicimi_form_420020_value: this.state.EserDetay_CiltBicimiSelectById?.at?.(0)?.aktifMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  EserDetayCiltBicimi_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayCiltBicimi_Form/EserDetayCiltBicimi_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_CiltBicimiSelectById = result?.data.eserDetay_CiltBicimiSelectById;
    formVars.eserdetayciltbicimi_form_3738153_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_CiltBicimiSelectById?.length > 0
        ? stateVars.EserDetay_CiltBicimiSelectById[0]?.ciltBicimi
        : null
    );
    formVars.eserdetayciltbicimi_form_6354225_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserDetay_CiltBicimiSelectById?.length > 0
        ? stateVars.EserDetay_CiltBicimiSelectById[0]?.ciltBicimiKey
        : null
    );
    formVars.eserdetayciltbicimi_form_420020_value = ReactSystemFunctions.value(
      this,
      stateVars.EserDetay_CiltBicimiSelectById?.length > 0 ? stateVars.EserDetay_CiltBicimiSelectById[0]?.aktifMi : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayCiltBicimi_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayCiltBicimi_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.eserdetayciltbicimi_form_3738153_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_CiltBicimiSelectById?.length > 0
        ? this.state.EserDetay_CiltBicimiSelectById[0]?.ciltBicimi
        : null
    );

    formVars.eserdetayciltbicimi_form_6354225_value = ReactSystemFunctions.toString(
      this,
      this.state.EserDetay_CiltBicimiSelectById?.length > 0
        ? this.state.EserDetay_CiltBicimiSelectById[0]?.ciltBicimiKey
        : null
    );

    formVars.eserdetayciltbicimi_form_420020_value = ReactSystemFunctions.value(
      this,
      this.state.EserDetay_CiltBicimiSelectById?.length > 0
        ? this.state.EserDetay_CiltBicimiSelectById[0]?.aktifMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserDetayCiltBicimi_FormComponent_983968_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserDetayCiltBicimi_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AktifMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayciltbicimi_form_420020_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CiltBicimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserdetayciltbicimi_form_3738153_value", "value", "", "", "")
        ),
        "string"
      ),
      CiltBicimiKey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "eserdetayciltbicimi_form_6354225_value", "value", "", "", "")
        ),
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserDetayCiltBicimi_Form/EserDetayCiltBicimi_FormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserDetay_CiltBicimiSave = result?.data.eserDetay_CiltBicimiSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserDetayCiltBicimi_FormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserDetayCiltBicimi_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
