import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  BigFileUpload,
  Button,
  Collapse,
  CollapsePanel,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KDrawer,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextArea,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { EserBilgileri_ScreenBase } from "./eserbilgileri-base";

import { BandrolStokHareket_Screen } from "../bandrolstokhareket/bandrolstokhareket";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class EserBilgileri_Screen extends EserBilgileri_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              eserbilgileri_812010_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.yayinTuruID ?? undefined,
              eserbilgileri_555402_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.tipkiBasimmi ?? undefined,
              eserbilgileri_208611_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.tipkiEserID ?? undefined,
              eserbilgileri_693380_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.kitapAdi ?? undefined,
              eserbilgileri_836839_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.yazarAdi ?? undefined,
              eserbilgileri_13964_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.dergiSureliYayin ?? undefined,
              eserbilgileri_325627_value:
                this.state.EserDetay_EserBilgileriById?.at?.(0)?.cocukKitabiAzSayfa ?? undefined,
              eserbilgileri_861278_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.ornektir ?? undefined,
              eserbilgileri_295615_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.bandroleTabi ?? undefined,
              eserbilgileri_957873_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.isbnNumarasi ?? undefined,
              eserbilgileri_91530_value: this.state.EserDetay_EserBilgileriById?.at?.(0)?.zamanSecimiID ?? undefined,
              eserbilgileri_284780_value:
                this.state.EserDetay_EserBilgileriById?.at?.(0)?.baskiOncesiDurumIDic ?? undefined,
              eserbilgileri_783769_value:
                this.state.EserDetay_EserBilgileriById?.at?.(0)?.baskiOncesiDurumIDkapak ?? undefined,
              eserbilgileri_585459_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="eserbilgileri_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KRow
                  id="445969"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="371410"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="588801"
                      value={ReactSystemFunctions.translate(this.ml, 588801, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "rgba(2, 145, 201, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="549946"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="508315"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.EserBilgileriComponent_508315_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(2, 145, 201, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="eserbilgileri_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="290687"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="240184"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KPanel
                      id="371494"
                      scrolling="hidden"
                      scrollRadius={false}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          textAlign: "-webkit-center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="623881"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="209375"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KPanel
                            id="93365"
                            scrolling="hidden"
                            scrollRadius={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KRow
                              id="328913"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="892617"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="914644"
                                  direction="vertical"
                                  size={1}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="512565"
                                    value={ReactSystemFunctions.translate(this.ml, 512565, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(186, 187, 187, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Form.Item className="kFormItem" name="eserbilgileri_812010_value">
                                    <KSelectBox
                                      id="812010"
                                      onChange={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.EserBilgileriComponent_812010_onChange();
                                      }}
                                      editability={this.state.isComp812010Enabled}
                                      kuikaRef={this.eserbilgileri_812010_value_kuikaSelectBoxRef}
                                      options={this.state.EserDetay_YayinTuruAll}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        812010,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={true}
                                      autoClearSearchValue={true}
                                      showSearch={true}
                                      widthType="fill"
                                      containsNullItem={false}
                                      sortBy="none"
                                      datavaluefield="id"
                                      datatextfield="yayinTuru"
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></KSelectBox>
                                  </Form.Item>
                                </VerticalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="380014"
                              visibility={this.state.isComp380014Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="838986"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KRow
                                  id="989318"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 8,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="510801"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="581997"
                                      direction="vertical"
                                      size={1}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="71586"
                                        value={ReactSystemFunctions.translate(this.ml, 71586, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="eserbilgileri_555402_value">
                                        <KSelectBox
                                          id="555402"
                                          onChange={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_555402_onChange();
                                          }}
                                          editability={this.state.isComp555402Enabled}
                                          kuikaRef={this.eserbilgileri_555402_value_kuikaSelectBoxRef}
                                          options={this.state.dataSource_555402}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            555402,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={true}
                                          autoClearSearchValue={true}
                                          showSearch={true}
                                          widthType="fill"
                                          containsNullItem={false}
                                          sortBy="none"
                                          datavaluefield="key"
                                          datatextfield="text"
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></KSelectBox>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="763210"
                                  visibility={this.state.isComp763210Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.EserDetay_EserBilgileriById[0]?.tipkiBasimmi?.toString(),
                                      "1"
                                    )
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 12,
                                      borderTopRightRadius: 12,
                                      borderBottomRightRadius: 12,
                                      borderBottomLeftRadius: 12,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(250, 173, 20, 0.25)",
                                      paddingTop: 16,
                                      paddingRight: 0,
                                      paddingBottom: 16,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="133320"
                                    xs={16}
                                    sm={16}
                                    md={16}
                                    lg={16}
                                    xl={16}
                                    xxl={16}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="464556"
                                      value={ReactSystemFunctions.translate(this.ml, 464556, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="eserbilgileri_208611_value">
                                      <KSelectBox
                                        id="208611"
                                        kuikaRef={this.eserbilgileri_208611_value_kuikaSelectBoxRef}
                                        options={this.state.EserBilgisiListSelectBoxByUyeIds}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          208611,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="kitapAdi"
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="190669"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-end",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Button
                                      id="575529"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.EserBilgileriComponent_575529_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 575529, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(2, 145, 201, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="393333"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 16,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="337219"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="103396"
                                      direction="vertical"
                                      size={1}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="736008"
                                        value={ReactSystemFunctions.translate(this.ml, 736008, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="eserbilgileri_693380_value">
                                        <TextInput
                                          id="693380"
                                          editability={this.state.isComp693380Enabled}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            693380,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={true}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          autoComplete={true}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="566344"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="543109"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="428401"
                                      direction="vertical"
                                      size={1}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="723567"
                                        value={ReactSystemFunctions.translate(this.ml, 723567, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="eserbilgileri_836839_value">
                                        <TextInput
                                          id="836839"
                                          editability={this.state.isComp836839Enabled}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            836839,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={true}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          autoComplete={true}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="147643"
                                  visibility={this.state.isComp147643Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="117433"
                                    xs={18}
                                    sm={18}
                                    md={18}
                                    lg={18}
                                    xl={18}
                                    xxl={18}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="770406"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="644992"
                                        value={ReactSystemFunctions.translate(this.ml, 644992, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="596829"
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    xxl={6}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="eserbilgileri_13964_value">
                                      <KSelectBox
                                        id="13964"
                                        editability={this.state.isComp13964Enabled}
                                        kuikaRef={this.eserbilgileri_13964_value_kuikaSelectBoxRef}
                                        options={this.state.dataSource_13964}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          13964,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="key"
                                        datatextfield="text"
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="689198"
                                  visibility={this.state.isComp689198Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="876967"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="291506"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="749268"
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                        xxl={18}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="798035"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            798035,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="920263"
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        xxl={6}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="eserbilgileri_325627_value">
                                          <KSelectBox
                                            id="325627"
                                            editability={this.state.isComp325627Enabled}
                                            kuikaRef={this.eserbilgileri_325627_value_kuikaSelectBoxRef}
                                            options={this.state.dataSource_325627}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              325627,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={false}
                                            autoClearSearchValue={true}
                                            showSearch={false}
                                            widthType="fill"
                                            containsNullItem={false}
                                            sortBy="none"
                                            datavaluefield="key"
                                            datatextfield="text"
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KSelectBox>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="898204"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="665118"
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                        xxl={18}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="472642"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            472642,
                                            "value",
                                            this.defaultML
                                          )}
                                          maxLine={2}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="755090"
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        xxl={6}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="eserbilgileri_861278_value">
                                          <KSelectBox
                                            id="861278"
                                            editability={this.state.isComp861278Enabled}
                                            kuikaRef={this.eserbilgileri_861278_value_kuikaSelectBoxRef}
                                            options={this.state.dataSource_861278}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              861278,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={false}
                                            autoClearSearchValue={true}
                                            showSearch={false}
                                            widthType="fill"
                                            containsNullItem={false}
                                            sortBy="none"
                                            datavaluefield="key"
                                            datatextfield="text"
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KSelectBox>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="157453"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="15516"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="12628"
                                      direction="vertical"
                                      size={1}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="996796"
                                        value={ReactSystemFunctions.translate(this.ml, 996796, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="eserbilgileri_295615_value">
                                        <KSelectBox
                                          id="295615"
                                          editability={this.state.isComp295615Enabled}
                                          kuikaRef={this.eserbilgileri_295615_value_kuikaSelectBoxRef}
                                          options={this.state.dataSource_295615}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            295615,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={false}
                                          autoClearSearchValue={true}
                                          showSearch={false}
                                          widthType="fill"
                                          containsNullItem={false}
                                          sortBy="none"
                                          datavaluefield="key"
                                          datatextfield="text"
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></KSelectBox>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="530310"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="157614"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="437120"
                                      direction="vertical"
                                      size={1}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="198322"
                                        value={ReactSystemFunctions.translate(this.ml, 198322, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="eserbilgileri_957873_value">
                                        <TextInput
                                          id="957873"
                                          editability={this.state.isComp957873Enabled}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            957873,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={false}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          autoComplete={true}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="960528"
                                  visibility={this.state.isComp960528Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="921119"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="301206"
                                      direction="vertical"
                                      size={1}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="749942"
                                        value={ReactSystemFunctions.translate(this.ml, 749942, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(186, 187, 187, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="eserbilgileri_91530_value">
                                        <KSelectBox
                                          id="91530"
                                          editability={this.state.isComp91530Enabled}
                                          kuikaRef={this.eserbilgileri_91530_value_kuikaSelectBoxRef}
                                          options={this.state.EserDetay_ZamanSecimiAll}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            91530,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={false}
                                          autoClearSearchValue={true}
                                          showSearch={false}
                                          widthType="fill"
                                          containsNullItem={false}
                                          sortBy="none"
                                          datavaluefield="id"
                                          datatextfield="zamanSecimi"
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></KSelectBox>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="307452"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="860631"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="543194"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="718471"
                                        xs={22}
                                        sm={22}
                                        md={22}
                                        lg={22}
                                        xl={22}
                                        xxl={22}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="169929"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            169929,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(186, 187, 187, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="996732"
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        xxl={2}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="966526"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_966526_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp966526Visible}
                                          condition={(rowData) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                              "1"
                                            )
                                          }
                                          iconName="upload"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="187190"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 16,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="613878"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <KTable
                                          id="380689"
                                          kuikaRef={this.eserbilgileri_380689_value_kuikaTableRef}
                                          form={this.props.form}
                                          dataSource={this.state.DokumanlarByKartIdISBN}
                                          size="middle"
                                          bordered={true}
                                          showHeader={false}
                                          loading={false}
                                          nodatafoundmessage={ReactSystemFunctions.translate(
                                            this.ml,
                                            380689,
                                            "nodatafoundmessage",
                                            this.defaultML
                                          )}
                                          searchable={false}
                                          sorter={false}
                                          filtering={false}
                                          pagination={false}
                                          striped={false}
                                          stripedColor="#F5F7FA"
                                          insertRowActive={false}
                                          transformedOnMobileResolution={false}
                                          tableWidthMode="fit"
                                          columnChooser={false}
                                          insertRowPosition="top"
                                          pageSize={10}
                                          showSizeChanger={false}
                                          multiSelect={false}
                                          fixedHeader={false}
                                          resizableColumns={false}
                                          editable={false}
                                          globalSearch={false}
                                          showNoDataFound={true}
                                          export={false}
                                          editableAlwaysActive={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KTableHeader
                                            id="487253"
                                            hideOnMobileResolution={false}
                                            textDirection="Default"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(186, 187, 187, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></KTableHeader>

                                          <KTableRow
                                            id="604141"
                                            hoverFontColor="red"
                                            hoverBgColor="#F5F5F5"
                                            style={
                                              {
                                                borderTopWidth: 1,
                                                borderColor: "rgba(244, 244, 244, 1)",
                                                borderStyle: "solid",
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KTableColumn
                                              id="152648"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                152648,
                                                "title",
                                                this.defaultML
                                              )}
                                              alwaysVisibleOnMobileResolution={false}
                                              hideFiltering={false}
                                              hideSorting={false}
                                              hideSearch={false}
                                              footerOptions="none"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="505916"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="953064"
                                                  xs={21}
                                                  sm={21}
                                                  md={21}
                                                  lg={21}
                                                  xl={21}
                                                  xxl={21}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="592475"
                                                    value="[datafield:dosyaAd]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="208800"
                                                  xs={3}
                                                  sm={3}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                  xxl={3}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-right",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <HorizontalStack
                                                    id="613995"
                                                    direction="horizontal"
                                                    size={4}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="328200"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_328200_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp328200Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                                          "1"
                                                        )
                                                      }
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 84, 28, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="264626"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_264626_onClick();
                                                      }}
                                                      showCursorPointer
                                                      iconName="remove_red_eye"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(48, 77, 48, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </HorizontalStack>
                                                </KCol>
                                              </KRow>
                                            </KTableColumn>
                                          </KTableRow>
                                        </KTable>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="46274"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="274812"
                                      visibility={this.state.isComp274812Visible}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="195667"
                                        xs={22}
                                        sm={22}
                                        md={22}
                                        lg={22}
                                        xl={22}
                                        xxl={22}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="387082"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            387082,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(186, 187, 187, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="597885"
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        xxl={2}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="794966"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_794966_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp794966Visible}
                                          condition={(rowData) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                              "1"
                                            )
                                          }
                                          iconName="upload"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="559036"
                                      visibility={this.state.isComp559036Visible}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 16,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="824238"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <KTable
                                          id="711309"
                                          kuikaRef={this.eserbilgileri_711309_value_kuikaTableRef}
                                          form={this.props.form}
                                          dataSource={this.state.DokumanlarByKartIdEser}
                                          size="middle"
                                          bordered={true}
                                          showHeader={false}
                                          loading={false}
                                          nodatafoundmessage={ReactSystemFunctions.translate(
                                            this.ml,
                                            711309,
                                            "nodatafoundmessage",
                                            this.defaultML
                                          )}
                                          searchable={false}
                                          sorter={false}
                                          filtering={false}
                                          pagination={false}
                                          striped={false}
                                          stripedColor="#F5F7FA"
                                          insertRowActive={false}
                                          transformedOnMobileResolution={false}
                                          tableWidthMode="fit"
                                          columnChooser={false}
                                          insertRowPosition="top"
                                          pageSize={10}
                                          showSizeChanger={false}
                                          multiSelect={false}
                                          fixedHeader={false}
                                          resizableColumns={false}
                                          editable={false}
                                          globalSearch={false}
                                          showNoDataFound={true}
                                          export={false}
                                          editableAlwaysActive={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KTableHeader
                                            id="738376"
                                            hideOnMobileResolution={false}
                                            textDirection="Default"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(186, 187, 187, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></KTableHeader>

                                          <KTableRow
                                            id="548644"
                                            hoverFontColor="red"
                                            hoverBgColor="#F5F5F5"
                                            style={
                                              {
                                                borderTopWidth: 1,
                                                borderColor: "rgba(244, 244, 244, 1)",
                                                borderStyle: "solid",
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KTableColumn
                                              id="400610"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                400610,
                                                "title",
                                                this.defaultML
                                              )}
                                              alwaysVisibleOnMobileResolution={false}
                                              hideFiltering={false}
                                              hideSorting={false}
                                              hideSearch={false}
                                              footerOptions="none"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="31909"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="955916"
                                                  xs={20}
                                                  sm={20}
                                                  md={20}
                                                  lg={20}
                                                  xl={20}
                                                  xxl={20}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="143182"
                                                    value="[datafield:dosyaAd]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="919300"
                                                  xs={4}
                                                  sm={4}
                                                  md={4}
                                                  lg={4}
                                                  xl={4}
                                                  xxl={4}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-right",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <HorizontalStack
                                                    id="810049"
                                                    direction="horizontal"
                                                    size={4}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="919708"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_919708_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp919708Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                                          "1"
                                                        )
                                                      }
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 84, 28, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="872838"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_872838_onClick();
                                                      }}
                                                      showCursorPointer
                                                      iconName="remove_red_eye"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(48, 77, 48, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </HorizontalStack>
                                                </KCol>
                                              </KRow>
                                            </KTableColumn>
                                          </KTableRow>
                                        </KTable>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="598779"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="199122"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="882379"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="6795"
                                        xs={22}
                                        sm={22}
                                        md={22}
                                        lg={22}
                                        xl={22}
                                        xxl={22}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="963805"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            963805,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(186, 187, 187, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="886781"
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        xxl={2}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="435632"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_435632_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp435632Visible}
                                          iconName="upload"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="199531"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_199531_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp199531Visible}
                                          iconName="upload"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="663823"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="787297"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <BigFileUpload
                                          id="920186"
                                          onUploadFinish={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_920186_onUploadFinish();
                                          }}
                                          visibility={this.state.isComp920186Visible}
                                          condition={(rowData) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                              "1"
                                            )
                                          }
                                          kuikaRef={this.eserbilgileri_920186_value_kuikaNoneRef}
                                          savePath={this.state.GenelParametreler?.at?.(0)?.bigFilePath ?? undefined}
                                          chunkNumber={10}
                                          label="Yüklemek için dosyayı bu alana tıklayın veya sürükleyin."
                                          style={{} as any}
                                        ></BigFileUpload>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="45188"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 16,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="845995"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <KTable
                                          id="647685"
                                          kuikaRef={this.eserbilgileri_647685_value_kuikaTableRef}
                                          form={this.props.form}
                                          dataSource={this.state.DokumanlarByKartIdICGORSEL}
                                          size="middle"
                                          bordered={true}
                                          transformedOnMobileResolution={false}
                                          showHeader={false}
                                          loading={false}
                                          nodatafoundmessage={ReactSystemFunctions.translate(
                                            this.ml,
                                            647685,
                                            "nodatafoundmessage",
                                            this.defaultML
                                          )}
                                          tableWidthMode="fit"
                                          searchable={false}
                                          sorter={false}
                                          filtering={false}
                                          pagination={false}
                                          fixedHeader={false}
                                          pageSize={10}
                                          showSizeChanger={false}
                                          columnChooser={false}
                                          striped={false}
                                          stripedColor="#F5F7FA"
                                          insertRowActive={false}
                                          insertRowPosition="top"
                                          multiSelect={false}
                                          resizableColumns={false}
                                          editable={false}
                                          globalSearch={false}
                                          showNoDataFound={true}
                                          export={false}
                                          editableAlwaysActive={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KTableHeader
                                            id="677917"
                                            hideOnMobileResolution={false}
                                            textDirection="Default"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(186, 187, 187, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></KTableHeader>

                                          <KTableRow
                                            id="625302"
                                            hoverFontColor="red"
                                            hoverBgColor="#F5F5F5"
                                            style={
                                              {
                                                borderTopWidth: 1,
                                                borderColor: "rgba(244, 244, 244, 1)",
                                                borderStyle: "solid",
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KTableColumn
                                              id="22292"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                22292,
                                                "title",
                                                this.defaultML
                                              )}
                                              alwaysVisibleOnMobileResolution={false}
                                              hideFiltering={false}
                                              hideSorting={false}
                                              hideSearch={false}
                                              footerOptions="none"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="651730"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="988901"
                                                  xs={11}
                                                  sm={11}
                                                  md={11}
                                                  lg={11}
                                                  xl={11}
                                                  xxl={11}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="31768"
                                                    value="[datafield:dosyaAd]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="272688"
                                                  xs={10}
                                                  sm={10}
                                                  md={10}
                                                  lg={10}
                                                  xl={10}
                                                  xxl={10}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="943607"
                                                    value="[datafield:dosya]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="75539"
                                                  xs={3}
                                                  sm={3}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                  xxl={3}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-right",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <HorizontalStack
                                                    id="32780"
                                                    direction="horizontal"
                                                    size={4}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="142626"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_142626_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp142626Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                                          "1"
                                                        )
                                                      }
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 84, 28, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="224600"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_224600_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp224600Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.props.screenInputs.NewEditDisplay,
                                                          "BOD"
                                                        )
                                                      }
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 84, 28, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="484214"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_484214_onClick();
                                                      }}
                                                      showCursorPointer
                                                      iconName="download"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(48, 77, 48, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </HorizontalStack>
                                                </KCol>
                                              </KRow>
                                            </KTableColumn>
                                          </KTableRow>
                                        </KTable>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="487548"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="678014"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="115301"
                                        xs={22}
                                        sm={22}
                                        md={22}
                                        lg={22}
                                        xl={22}
                                        xxl={22}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="644843"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            644843,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(186, 187, 187, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="849017"
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        xxl={2}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="370832"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_370832_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp370832Visible}
                                          iconName="upload"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="756207"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_756207_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp756207Visible}
                                          iconName="upload"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="496880"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="580085"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <BigFileUpload
                                          id="705243"
                                          onUploadFinish={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_705243_onUploadFinish();
                                          }}
                                          visibility={this.state.isComp705243Visible}
                                          condition={(rowData) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                              "1"
                                            )
                                          }
                                          kuikaRef={this.eserbilgileri_705243_value_kuikaNoneRef}
                                          savePath={this.state.GenelParametreler?.at?.(0)?.bigFilePath ?? undefined}
                                          chunkNumber={10}
                                          label="Yüklemek için dosyayı bu alana tıklayın veya sürükleyin."
                                          style={{} as any}
                                        ></BigFileUpload>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="405111"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 16,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="793040"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <KTable
                                          id="435280"
                                          kuikaRef={this.eserbilgileri_435280_value_kuikaTableRef}
                                          form={this.props.form}
                                          dataSource={this.state.DokumanlarByKartIdKAPAKGORSEL}
                                          size="middle"
                                          bordered={true}
                                          transformedOnMobileResolution={false}
                                          showHeader={false}
                                          loading={false}
                                          nodatafoundmessage={ReactSystemFunctions.translate(
                                            this.ml,
                                            435280,
                                            "nodatafoundmessage",
                                            this.defaultML
                                          )}
                                          tableWidthMode="fit"
                                          searchable={false}
                                          sorter={false}
                                          filtering={false}
                                          pagination={false}
                                          fixedHeader={false}
                                          pageSize={10}
                                          showSizeChanger={false}
                                          columnChooser={false}
                                          striped={false}
                                          stripedColor="#F5F7FA"
                                          insertRowActive={false}
                                          insertRowPosition="top"
                                          multiSelect={false}
                                          resizableColumns={false}
                                          editable={false}
                                          globalSearch={false}
                                          showNoDataFound={true}
                                          export={false}
                                          editableAlwaysActive={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KTableHeader
                                            id="531096"
                                            hideOnMobileResolution={false}
                                            textDirection="Default"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(186, 187, 187, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></KTableHeader>

                                          <KTableRow
                                            id="789280"
                                            hoverFontColor="red"
                                            hoverBgColor="#F5F5F5"
                                            style={
                                              {
                                                borderTopWidth: 1,
                                                borderColor: "rgba(244, 244, 244, 1)",
                                                borderStyle: "solid",
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KTableColumn
                                              id="455761"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                455761,
                                                "title",
                                                this.defaultML
                                              )}
                                              alwaysVisibleOnMobileResolution={false}
                                              hideFiltering={false}
                                              hideSorting={false}
                                              hideSearch={false}
                                              footerOptions="none"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="938679"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="375896"
                                                  xs={11}
                                                  sm={11}
                                                  md={11}
                                                  lg={11}
                                                  xl={11}
                                                  xxl={11}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="378387"
                                                    value="[datafield:dosyaAd]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="212417"
                                                  xs={10}
                                                  sm={10}
                                                  md={10}
                                                  lg={10}
                                                  xl={10}
                                                  xxl={10}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="511714"
                                                    value="[datafield:dosya]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="61531"
                                                  xs={3}
                                                  sm={3}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                  xxl={3}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-right",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <HorizontalStack
                                                    id="832782"
                                                    direction="horizontal"
                                                    size={4}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="542937"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_542937_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp542937Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                                          "1"
                                                        )
                                                      }
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 84, 28, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="158669"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_158669_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp158669Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.props.screenInputs.NewEditDisplay,
                                                          "BOD"
                                                        )
                                                      }
                                                      iconName="delete"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 84, 28, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="551996"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_551996_onClick();
                                                      }}
                                                      showCursorPointer
                                                      iconName="download"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(48, 77, 48, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </HorizontalStack>
                                                </KCol>
                                              </KRow>
                                            </KTableColumn>
                                          </KTableRow>
                                        </KTable>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="718160"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="13788"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="817909"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="181354"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="eserbilgileri_284780_value">
                                          <KSelectBox
                                            id="284780"
                                            editability={this.state.isComp284780Enabled}
                                            kuikaRef={this.eserbilgileri_284780_value_kuikaSelectBoxRef}
                                            options={this.state.BaskiOncesiDurumAllic}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              284780,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={false}
                                            autoClearSearchValue={true}
                                            showSearch={false}
                                            widthType="fill"
                                            containsNullItem={false}
                                            sortBy="none"
                                            datavaluefield="id"
                                            datatextfield="baskiOncesiDurum"
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KSelectBox>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="19450"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="138205"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="892985"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="eserbilgileri_783769_value">
                                          <KSelectBox
                                            id="783769"
                                            editability={this.state.isComp783769Enabled}
                                            kuikaRef={this.eserbilgileri_783769_value_kuikaSelectBoxRef}
                                            options={this.state.BaskiOncesiDurumAllkapak}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              783769,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={false}
                                            autoClearSearchValue={true}
                                            showSearch={false}
                                            widthType="fill"
                                            containsNullItem={false}
                                            sortBy="none"
                                            datavaluefield="id"
                                            datatextfield="baskiOncesiDurum"
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KSelectBox>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="741219"
                                  visibility={this.state.isComp741219Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.EserDetay_EserBilgileriById[0]?.isrwDuzeltmeNotuVisibility?.toString(),
                                      "1"
                                    )
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="390408"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="119884"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="278349"
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 8,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="263782"
                                          visibility={this.state.isComp263782Visible}
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            263782,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(186, 187, 187, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="484763"
                                        xs={16}
                                        sm={16}
                                        md={16}
                                        lg={16}
                                        xl={16}
                                        xxl={16}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="eserbilgileri_585459_value">
                                          <TextArea
                                            id="585459"
                                            visibility={this.state.isComp585459Visible}
                                            editability={this.state.isComp585459Enabled}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              585459,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={false}
                                            autoSize={false}
                                            showCount={false}
                                            formatter="None"
                                            readOnly={false}
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></TextArea>
                                        </Form.Item>

                                        <Button
                                          id="497206"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_497206_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp497206Visible}
                                          editability={this.state.isComp497206Enabled}
                                          label={ReactSystemFunctions.translate(
                                            this.ml,
                                            497206,
                                            "label",
                                            this.defaultML
                                          )}
                                          size="middle"
                                          loading={false}
                                          ghost={false}
                                          block={false}
                                          htmlType="button"
                                          iconPosition="left"
                                          danger={false}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(2, 145, 201, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 2,
                                              paddingRight: 16,
                                              paddingBottom: 2,
                                              paddingLeft: 16,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(255, 255, 255, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Button>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="486408"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="41843"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 0,
                                            paddingBottom: 8,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <KTable
                                          id="282097"
                                          kuikaRef={this.eserbilgileri_282097_value_kuikaTableRef}
                                          form={this.props.form}
                                          dataSource={this.state.EserBilgileriDuzeltmeNotuByEserBilgileriID}
                                          size="middle"
                                          bordered={true}
                                          showHeader={true}
                                          loading={false}
                                          nodatafoundmessage={ReactSystemFunctions.translate(
                                            this.ml,
                                            282097,
                                            "nodatafoundmessage",
                                            this.defaultML
                                          )}
                                          searchable={false}
                                          sorter={false}
                                          filtering={false}
                                          pagination={false}
                                          hideTableOnEmpty={true}
                                          striped={false}
                                          stripedColor="#F5F7FA"
                                          insertRowActive={false}
                                          transformedOnMobileResolution={false}
                                          tableWidthMode="fit"
                                          columnChooser={false}
                                          insertRowPosition="top"
                                          pageSize={10}
                                          showSizeChanger={false}
                                          multiSelect={false}
                                          fixedHeader={false}
                                          resizableColumns={false}
                                          editable={false}
                                          globalSearch={false}
                                          showNoDataFound={true}
                                          export={false}
                                          editableAlwaysActive={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KTableHeader
                                            id="84362"
                                            hideOnMobileResolution={false}
                                            textDirection="Default"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(186, 187, 187, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></KTableHeader>

                                          <KTableRow
                                            id="284528"
                                            hoverFontColor="red"
                                            hoverBgColor="#F5F5F5"
                                            style={
                                              {
                                                borderTopWidth: 1,
                                                borderColor: "rgba(244, 244, 244, 1)",
                                                borderStyle: "solid",
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KTableColumn
                                              id="152954"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                152954,
                                                "title",
                                                this.defaultML
                                              )}
                                              alwaysVisibleOnMobileResolution={false}
                                              hideFiltering={false}
                                              hideSorting={false}
                                              hideSearch={false}
                                              footerOptions="none"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="652844"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="384599"
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={24}
                                                  xl={24}
                                                  xxl={24}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="466847"
                                                    value="[datafield:duzeltmeNotu]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>
                                              </KRow>

                                              <KRow
                                                id="827923"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="737207"
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={24}
                                                  xl={24}
                                                  xxl={24}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      textAlign: "-webkit-right",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="123043"
                                                    value="[datafield:userInfo]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(140, 140, 140, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>
                                              </KRow>
                                            </KTableColumn>
                                          </KTableRow>
                                        </KTable>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="335483"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>
                                </KRow>

                                <KRow
                                  id="351905"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="844058"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="922562"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 0,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="152176"
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="882427"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            882427,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="643064"
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="793854"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="93590"
                                            value={
                                              this.state.EserDetay_BandrolStokSUM?.at?.(0)?.bakiyeAdet ?? undefined
                                            }
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <Label
                                            id="590059"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              590059,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KCol>

                                      <KCol
                                        id="333001"
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="702509"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_702509_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="add_circle"
                                          hoverText="Bandrol Stok Harekeri Ekle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "36px",
                                              color: "rgba(250, 173, 20, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="106912"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="901102"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Collapse
                                          id="37144"
                                          accordion={true}
                                          collapsible={true}
                                          destroyInactivePanel={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <CollapsePanel
                                            id="759182"
                                            collapsible={true}
                                            forceRender={false}
                                            header={ReactSystemFunctions.translate(
                                              this.ml,
                                              759182,
                                              "header",
                                              this.defaultML
                                            )}
                                            showArrow={true}
                                            key="759182"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KTable
                                              id="733661"
                                              kuikaRef={this.eserbilgileri_733661_value_kuikaTableRef}
                                              form={this.props.form}
                                              dataSource={this.state.EserDetay_BandrolStokByEserBilgileriID}
                                              size="middle"
                                              bordered={true}
                                              loading={false}
                                              transformedOnMobileResolution={false}
                                              showHeader={true}
                                              fixedHeader={false}
                                              editable={false}
                                              tableWidthMode="fit"
                                              globalSearch={false}
                                              searchable={false}
                                              sorter={false}
                                              filtering={false}
                                              pagination={false}
                                              pageSize={10}
                                              showSizeChanger={false}
                                              columnChooser={false}
                                              resizableColumns={false}
                                              striped={false}
                                              stripedColor="#F5F7FA"
                                              insertRowActive={false}
                                              insertRowPosition="top"
                                              showNoDataFound={true}
                                              nodatafoundmessage={ReactSystemFunctions.translate(
                                                this.ml,
                                                733661,
                                                "nodatafoundmessage",
                                                this.defaultML
                                              )}
                                              multiSelect={false}
                                              export={false}
                                              editableAlwaysActive={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KTableHeader
                                                id="664946"
                                                hideOnMobileResolution={false}
                                                textDirection="Default"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(186, 187, 187, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></KTableHeader>

                                              <KTableRow
                                                id="971330"
                                                hoverFontColor="red"
                                                hoverBgColor="#F5F5F5"
                                                style={
                                                  {
                                                    borderTopWidth: 1,
                                                    borderColor: "rgba(244, 244, 244, 1)",
                                                    borderStyle: "solid",
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KTableColumn
                                                  id="398419"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    398419,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  hideFiltering={false}
                                                  hideSorting={false}
                                                  hideSearch={false}
                                                  footerOptions="none"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="205301"
                                                    value="[datafield:gcTarihFormat]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="816111"
                                                  visibility={this.state.isComp816111Visible}
                                                  condition={(rowData) =>
                                                    ReactSystemFunctions.isNotEqualTo(
                                                      this.state.MyContext[0]?.rolName?.toString(),
                                                      "MATBAA"
                                                    )
                                                  }
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    816111,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  hideFiltering={false}
                                                  hideSorting={false}
                                                  hideSearch={false}
                                                  footerOptions="none"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="790941"
                                                    value="[datafield:sirketAd]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="542232"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    542232,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  hideFiltering={false}
                                                  hideSorting={false}
                                                  hideSearch={false}
                                                  footerOptions="none"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="516128"
                                                    value="[datafield:gcAciklama]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="589915"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    589915,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  hideFiltering={false}
                                                  hideSorting={false}
                                                  hideSearch={false}
                                                  footerOptions="none"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="195721"
                                                    value="[datafield:stokGC]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="948472"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    948472,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  hideFiltering={false}
                                                  hideSorting={false}
                                                  hideSearch={false}
                                                  footerOptions="none"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="159777"
                                                    value="[datafield:adet]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KTableColumn>

                                                <KTableColumn
                                                  id="506543"
                                                  title={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    506543,
                                                    "title",
                                                    this.defaultML
                                                  )}
                                                  alwaysVisibleOnMobileResolution={false}
                                                  hideFiltering={false}
                                                  hideSorting={false}
                                                  hideSearch={false}
                                                  footerOptions="none"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-right",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <HorizontalStack
                                                    id="809854"
                                                    direction="horizontal"
                                                    size={12}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="929675"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.EserBilgileriComponent_929675_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp929675Visible}
                                                      condition={(rowData) =>
                                                        ReactSystemFunctions.isEqualTo(
                                                          this.state.MyContext[0]?.muhatapMiFirmaMi?.toString(),
                                                          "Firma"
                                                        )
                                                      }
                                                      iconName="edit"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(2, 145, 201, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </HorizontalStack>
                                                </KTableColumn>
                                              </KTableRow>
                                            </KTable>

                                            <KRow
                                              id="155485"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="404206"
                                                xs={8}
                                                sm={8}
                                                md={8}
                                                lg={8}
                                                xl={8}
                                                xxl={8}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></KCol>

                                              <KCol
                                                id="399109"
                                                xs={8}
                                                sm={8}
                                                md={8}
                                                lg={8}
                                                xl={8}
                                                xxl={8}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-right",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="230639"
                                                  value={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    230639,
                                                    "value",
                                                    this.defaultML
                                                  )}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                ></Label>
                                              </KCol>

                                              <KCol
                                                id="73254"
                                                xs={8}
                                                sm={8}
                                                md={8}
                                                lg={8}
                                                xl={8}
                                                xxl={8}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-right",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="71938"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="371842"
                                                    value={
                                                      this.state.EserDetay_BandrolStokSUM?.at?.(0)?.bakiyeAdet ??
                                                      undefined
                                                    }
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="499747"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      499747,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>
                                            </KRow>
                                          </CollapsePanel>
                                        </Collapse>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="433689"
                                  visibility={this.state.isComp433689Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEditDisplay, "BOE")
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 8,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="252344"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="907819"
                                      visibility={this.state.isComp907819Visible}
                                      value={ReactSystemFunctions.translate(this.ml, 907819, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Button
                                      id="879238"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.EserBilgileriComponent_879238_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 879238, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(2, 145, 201, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          width: "445px",
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="586668"
                                  visibility={this.state.isComp586668Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEditDisplay, "BOD")
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="375430"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="521573"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 8,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="383886"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Button
                                          id="26862"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_26862_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp26862Visible}
                                          label={ReactSystemFunctions.translate(
                                            this.ml,
                                            26862,
                                            "label",
                                            this.defaultML
                                          )}
                                          size="middle"
                                          loading={false}
                                          ghost={false}
                                          block={false}
                                          htmlType="button"
                                          iconPosition="left"
                                          danger={false}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(2, 145, 201, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 16,
                                              paddingBottom: 8,
                                              paddingLeft: 16,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(255, 255, 255, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Button>

                                        <Button
                                          id="442162"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_442162_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp442162Visible}
                                          label={ReactSystemFunctions.translate(
                                            this.ml,
                                            442162,
                                            "label",
                                            this.defaultML
                                          )}
                                          size="middle"
                                          loading={false}
                                          ghost={false}
                                          block={false}
                                          htmlType="button"
                                          iconPosition="left"
                                          danger={false}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(2, 145, 201, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 16,
                                              paddingBottom: 8,
                                              paddingLeft: 16,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(255, 255, 255, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Button>
                                      </KCol>

                                      <KCol
                                        id="727131"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="583976"
                                          visibility={this.state.isComp583976Visible}
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            583976,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Button
                                          id="833540"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.EserBilgileriComponent_833540_onClick();
                                          }}
                                          showCursorPointer
                                          label={ReactSystemFunctions.translate(
                                            this.ml,
                                            833540,
                                            "label",
                                            this.defaultML
                                          )}
                                          size="middle"
                                          loading={false}
                                          ghost={false}
                                          block={false}
                                          htmlType="button"
                                          iconPosition="left"
                                          danger={false}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(2, 145, 201, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 16,
                                              paddingBottom: 8,
                                              paddingLeft: 16,
                                              width: "445px",
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(255, 255, 255, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Button>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="703152"
                                  visibility={this.state.isComp703152Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.EserDetay_EserBilgileriById[0]?.isButtonsSaveVisibility?.toString(),
                                      "1"
                                    )
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="682365"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="752605"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Button
                                        id="39741"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserBilgileriComponent_39741_onClick();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp39741Visible}
                                        condition={(rowData) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.EserDetay_EserBilgileriById[0]?.isButtonTaslakVisibility?.toString(),
                                            "1"
                                          )
                                        }
                                        label={ReactSystemFunctions.translate(this.ml, 39741, "label", this.defaultML)}
                                        size="middle"
                                        loading={false}
                                        ghost={false}
                                        block={false}
                                        htmlType="button"
                                        iconPosition="left"
                                        danger={false}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(0, 167, 229, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 16,
                                            paddingBottom: 8,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(2, 145, 201, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Button>

                                      <Button
                                        id="526033"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserBilgileriComponent_526033_onClick();
                                        }}
                                        showCursorPointer
                                        label={ReactSystemFunctions.translate(this.ml, 526033, "label", this.defaultML)}
                                        size="middle"
                                        loading={false}
                                        ghost={false}
                                        block={false}
                                        htmlType="button"
                                        iconPosition="left"
                                        danger={false}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(2, 145, 201, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 16,
                                            paddingBottom: 8,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(255, 255, 255, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Button>
                                    </HorizontalStack>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="902363"
                                  visibility={this.state.isComp902363Visible}
                                  condition={(rowData) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.EserDetay_EserBilgileriById[0]?.isButtonsOnayVisibility?.toString(),
                                      "1"
                                    )
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="858615"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="609173"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Button
                                        id="150995"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserBilgileriComponent_150995_onClick();
                                        }}
                                        showCursorPointer
                                        label={ReactSystemFunctions.translate(this.ml, 150995, "label", this.defaultML)}
                                        size="middle"
                                        loading={false}
                                        ghost={false}
                                        block={false}
                                        htmlType="button"
                                        iconPosition="left"
                                        danger={false}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(0, 167, 229, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 16,
                                            paddingBottom: 8,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(2, 145, 201, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Button>

                                      <Button
                                        id="453514"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserBilgileriComponent_453514_onClick();
                                        }}
                                        showCursorPointer
                                        label={ReactSystemFunctions.translate(this.ml, 453514, "label", this.defaultML)}
                                        size="middle"
                                        loading={false}
                                        ghost={false}
                                        block={false}
                                        htmlType="button"
                                        iconPosition="left"
                                        danger={false}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(245, 34, 45, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 16,
                                            paddingBottom: 8,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(255, 255, 255, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Button>

                                      <Button
                                        id="816954"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.EserBilgileriComponent_816954_onClick();
                                        }}
                                        showCursorPointer
                                        label={ReactSystemFunctions.translate(this.ml, 816954, "label", this.defaultML)}
                                        size="middle"
                                        loading={false}
                                        ghost={false}
                                        block={false}
                                        htmlType="button"
                                        iconPosition="left"
                                        danger={false}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(2, 145, 201, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 16,
                                            paddingBottom: 8,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            color: "rgba(255, 255, 255, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Button>
                                    </HorizontalStack>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KPanel>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KDrawer
                  id="530439"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible530439: false }, () => {
                      this.callCallbackFunction(530439);
                    });
                  }}
                  visible={this.state.NavVisible530439}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible530439 && (
                      <BandrolStokHareket_Screen
                        onClose={() => {
                          this.setState({ NavVisible530439: false }, () => {
                            this.callCallbackFunction(530439);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="572242"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible572242: false }, () => {
                      this.callCallbackFunction(572242);
                    });
                  }}
                  visible={this.state.NavVisible572242}
                  width="480px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible572242 && (
                      <BandrolStokHareket_Screen
                        onClose={() => {
                          this.setState({ NavVisible572242: false }, () => {
                            this.callCallbackFunction(572242);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(EserBilgileri_Screen))))
  )
);
export { tmp as EserBilgileri_Screen };
//export default tmp;
//export { tmp as EserBilgileri_Screen };
