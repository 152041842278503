import { useHistory } from "react-router-dom";
import { ReactSystemFunctions } from "../utilty/react-system-functions";

declare let window: any;

export const withHistory = (Component: any) => {
  return (props: any) => {
    const history: any = useHistory();
    const kNavigator = new KNavigator(history);
    return <Component {...props} navigator={kNavigator} />;
  };
};

export class KNavigator {
  history: any;

  constructor(history: any) {
    this.history = history;
  }

  public gotoLogin = () => {
    this.history.push({
      pathname: "/signin"
    });
  };

  public gotoStartingScreen = async (screen?: string) => {
    let screenName: string = ReactSystemFunctions.getScreenNameByActiveUserRoles();

    if (
      (screenName === "" || screenName === undefined) &&
      window.userInfo?.startingScreenId &&
      window.userInfo.startingScreenId.length > 0
    ) {
      screenName = ReactSystemFunctions.getScreenNameById(window.userInfo.startingScreenId);
    }

    if (screenName === "" || screenName === undefined) {
      this.history.push({
        pathname: screen ?? "/DashBoardMatbaa"
      });
    } else {
      this.history.push({
        pathname: `/${screenName}`
      });
    }
  };

  public goToResetPasswordScreen = () => {
    this.history.push("/" + "resetpassword");
  };

  public goToVerificationCodeScreen = () => {
    this.history.push("/" + "dogrulamakodu");
  };

  public gotoRoot = () => {
    this.history.push({
      pathname: "/"
    });
  };

  public gotoSignup = () => {
    this.history.push("/signup");
  };

  public goto = (type, id) => {
    this.history.push(`/${type}/${id}`);
  };

  public goto2 = (type) => {
    this.history.push(type);
  };

  public gotoUrl = (url) => {
    this.history.push(url);
  };

  public goBack = () => {
    if (this.history.length > 1) {
      this.history.goBack();
    } else {
      this.gotoStartingScreen();
    }
  };

  public gotoForgotPassword = () => {
    this.history.push("/forgotpassword");
  };

  public gotoDashboard = () => {
    this.history.push("/dashboard");
  };
}
